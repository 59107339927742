import React, { useState } from 'react';
import BridgeContext from './BridgeContext';

interface Props {
  children: any;
}

const BridgeState = ({ children }: Props) => {
  const [count, setCount] = useState(1);
  const [tokenName, setName] = useState<any>();
  const [tokenImage, setImage] = useState();
  const [tokenPrice, setPrice] = useState();
  const [fromNetworkName, setFromNetwork] = useState();
  const [toNetworkName, setToNetwork] = useState();
  const [fromNetworkImage, setFromImage] = useState();
  const [toNetworkImage, setToImage] = useState();
  const [isApprove,setApprove]=useState(false)
  const [isWidthdraw, setWidthDraw] = useState(false);
  const [progress,setProgress]=useState(0)
  const [globalArray]=useState([])
  const [isRouterProtocol,setRouterProtocol]=useState(false)
  
  
  const Clear = () => {
    console.log("CLEARED!!!!!!!!!!!!!!!!!");
    setCount(0);
  };
  const update = () => {
    if (count >4) {
      console.log('hello');
    } else {
      setCount(count + 1);
      return count + 1;
    }
  };
  return (
    <>
      <BridgeContext.Provider
        value={{
          count,
          setCount,
          update,
          Clear,
          setName,
          tokenName,
          isWidthdraw,
          progress,
          globalArray,
          isRouterProtocol,

          setImage,
          tokenImage,
          setPrice,
          tokenPrice,
          fromNetworkName,
          toNetworkName,
          fromNetworkImage,
          toNetworkImage,
          isApprove,
          setFromNetwork,
          setToNetwork,
          setFromImage,
          setToImage,
          setApprove,
          setWidthDraw,
          setProgress,
          setRouterProtocol
        }}
      >
        {children}
      </BridgeContext.Provider>
    </>
  );
};

export default BridgeState;

import * as React from 'react';

function SvgComponent(props) {
  return (
    <svg
      width={44}
      height={47}
      style={{ cursor: 'pointer' }}
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M40.18 15.075c.54.24 1 .41 1.36.62 4.9 2.54 9.78 5.11 14.7 7.61.391.152.722.43.94.79.212.365.303.789.26 1.21v27.56a7.433 7.433 0 00.91 4.13 7.469 7.469 0 003 3c3.85 2.21 8.1 0 8.15-4.44.09-8.76 0-17.53 0-26.29a1.953 1.953 0 00-.29-.78 1.825 1.825 0 00-.59-.58 3728.769 3728.769 0 00-27.61-14.36 2.001 2.001 0 00-.83-.09c-.278.029-.544.121-.78.27-5.1 3.7-10.164 7.43-15.19 11.19a2.09 2.09 0 00-.5.61c-.12.237-.192.495-.21.76-.06 10.25-.33 20.5 0 30.73.32 9.64 5.33 16.89 13.12 22.27 9.29 6.41 19.39 6.72 29.83 3.72.23-.07.45-.13.68-.18.128.033.252.08.37.14-1.13.63-2.1 1.23-3.12 1.73a44.437 44.437 0 01-27.89 3.91 44.391 44.391 0 01-24.62-13.66 44.327 44.327 0 01-6.32-51.669A44.33 44.33 0 0117.62 9.375c12.91-9.66 27.39-12.2 42.39-6 16.56 6.79 26.59 19.39 28.86 37.4a40.179 40.179 0 01-4.73 24.33c-6.92 12.84-21.13 14.7-31.71 8.76-8.24-4.64-12.25-12-12.28-21.42v-36.31c-.02-.35-.02-.7 0-1.05l.03-.01z"
        fill="url(#prefix__paint0_linear_2769:2613)"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear_2769:2613"
          x1={44.583}
          y1={0}
          x2={44.583}
          y2={89.348}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E80163" stopOpacity={0.9} />
          <stop offset={1} stopColor="#2155E9" stopOpacity={0.9} />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgComponent;

import React from 'react';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import styled from 'styled-components';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
const DetailsSubDiv = styled.div`
  width: 230px;
  display: flex;
  align-items: start;
`;
const TokenDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
`;
const TokenInfo = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: start;
  gap: 0.5rem;
  @media (max-width: 430px) {
    margin-top: -6px;
  }
`;
const TokenName = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  font-family: 'Inter';
  font-style: normal;
  color: #212121;
`;
const TokenValue = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  font-family: 'Inter';
  font-style: normal;
  color: #616161;
  @media (max-width: 430px) {
    display: none;
  }
`;

interface Props {
  stepNumber: number;
}

const ProcessInfo = ({ stepNumber }: Props) => {
  const step1 = '33';
  const step2 = '66';
  const step3 = '100';
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <DetailsSubDiv>
        <TokenDiv>
          <div style={{ width: 30, height: mobile ? 20 : 30 }}>
            {stepNumber === 4 ? (
              <CheckCircleIcon style={{ width: '15px', height: '15px', color: '#673AB7', marginLeft: '0.5rem' }} />
            ) : (
              <>
                {mobile ? (
                  <div style={{ marginTop: '-4px', width: '80%', marginLeft: 8 }}>
                    <CircularProgressbar
                      value={parseInt(stepNumber == 1 ? step1 : stepNumber == 2 ? step2 : stepNumber == 3 ? step3 : '')}
                      styles={buildStyles({
                        pathColor: '#673AB7',
                      })}
                    />
                  </div>
                ) : (
                  <CircularProgressbar
                    value={parseInt(stepNumber == 1 ? step1 : stepNumber == 2 ? step2 : stepNumber == 3 ? step3 : '')}
                    styles={buildStyles({
                      pathColor: '#673AB7',
                    })}
                  />
                )}
              </>
            )}
          </div>
          <TokenInfo>
            <TokenName>{stepNumber === 3 ? 'Bridge Done' : 'In Process'}</TokenName>
            <TokenValue>
              {stepNumber}/3&nbsp;
              {stepNumber === 0 ? 'Confirm' : ''}
              {stepNumber === 1 ? 'Transfer' : ''}
              {stepNumber === 2 ? 'Change Network' : ''}
              {stepNumber === 3 ? 'Sucess' : ''}
            </TokenValue>
          </TokenInfo>
        </TokenDiv>
      </DetailsSubDiv>
    </>
  );
};

export default ProcessInfo;

import React from 'react';
import { Menu, MenuItem, ProSidebar, SubMenu } from 'react-pro-sidebar';
import styled from 'styled-components';
import UnifarmLargeLogo from '../../assets/images/brand/Logo.svg';
import CloseIcon from '@mui/icons-material/Close';
import NetworkContext from '../NetworkContext/NetworkContext';
import TradeOn from '../TradeOn';
import Merger from '../../assets/svg/Merger';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { Link } from 'react-router-dom';
import Governance from '../../assets/svg/governance';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import CachedIcon from '@mui/icons-material/Cached';
import OpacityOutlinedIcon from '@mui/icons-material/OpacityOutlined';
// import LP_Farm from '../../assets/V2/Network/LP_Farm.png';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import AssignmentIcon from '@mui/icons-material/Assignment';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import LaunchIcon from '@mui/icons-material/Launch';
import { FaLayerGroup } from 'react-icons/fa';
import Telegrammer from '../../assets/images/telegram1.png';
import Discorder from '../../assets/images/Discord1.png';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useHistory } from 'react-router-dom';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { VersionSideBar } from 'components/VersionSidebar/VersionSidebar';
const FixedProSideBar = styled(ProSidebar)`
  position: fixed;
  width: 216px !important;
`;

const MobileSideBarFlexHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  align-items: center;
  padding-right: 1rem;
  padding-left: 1rem;
`;

const UnifarmMobileLargeLogo = styled.img`
  width: 150px;
`;

const UnifarmButtonGroup = styled.div`
  display: flex;
  margin-top: 1rem;
  justify-content: center;
  padding-right: 11px;
  @media (max-width: 1279px) {
    align-items: center;
    flex-direction: column;
  }
`;

const FiberManualRecordIconFlexWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
`;

export const SmallFiberManualRecordIcon = styled(FiberManualRecordIcon)`
  font-size: 7px;
`;

const FlexBoxFooter = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 30px;
  margin-left: 2rem;
  height: 100px;
  width: 90px;
`;

const Join = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #212121;
  margin-bottom: 1rem;
`;

const Socials = styled.div`
  display: flex;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #212121;
  margin-bottom: 15px;
  column-gap: 10px;
`;
interface SubMenuList {
  fieldName: string;
  fieldPath: string;
}

export interface MenuItemsList {
  fieldName: string;
  fieldIcon: JSX.Element;
  fieldPath: string | null;
  subMenus: SubMenuList[] | null;
}

const menuItems: MenuItemsList[] = [
  {
    fieldName: 'Yield Farming',
    fieldIcon: <OpacityOutlinedIcon color="action" style={{ fontSize: 20 }} />,
    fieldPath: null,
    subMenus: [
      { fieldName: 'All Yield Farms', fieldPath: '/farms' },
      { fieldName: 'My Stakes', fieldPath: '/stakes' },
    ],
  },
  {
    fieldName: 'Liquidity Farming',
    fieldIcon: <DashboardIcon color="action" style={{ fontSize: 20 }} />,
    fieldPath: null,
    subMenus: [
      { fieldName: 'All LP Farms', fieldPath: '/liquidity-farming' },
      { fieldName: 'My LP Stakes', fieldPath: '/stakes' },
    ],
  },
  // {
  //   fieldName: 'Trade',
  //   fieldIcon: <SwapHorizIcon color="action" style={{ fontSize: 22 }} />,
  //   fieldPath: null,
  //   subMenus: [
  //     { fieldName: 'Exchange', fieldPath: '/exchange' },
  //     { fieldName: 'Liquidity', fieldPath: '/liquidity' },
  //   ],
  // },
  {
    fieldName: 'Flip',
    fieldIcon: <CachedIcon color="action" style={{ fontSize: 20 }} />,
    fieldPath: '/flip',
    subMenus: null,
  },
  {
    fieldName: 'Vesting',
    fieldIcon: <AssignmentIcon color="action" style={{ fontSize: 20 }} />,
    fieldPath: '/vesting',
    subMenus: null,
  },
  {
    fieldName: 'ORO-UFARM Merger',
    fieldIcon: <Merger />,
    fieldPath: '/oro-ufarm-merger',
    subMenus: null,
  },
  // {
  //   fieldName: 'Governance',
  //   fieldIcon: <Governance color="action" style={{ fontSize: 22 }} />,
  //   fieldPath: '/governance',
  //   subMenus: null,
  // },
  {
    fieldName: 'Share Some Love',
    fieldIcon: <InsertLinkOutlinedIcon color="action" style={{ fontSize: 22 }} />,
    fieldPath: '/referral',
    subMenus: null,
  },
  {
    fieldName: 'UniLaunch',
    fieldIcon: <LaunchIcon color="action" style={{ fontSize: 18 }}></LaunchIcon>,
    fieldPath: '/launch',
    subMenus: null,
  },
  {
    fieldName: 'BUY UFARM',
    fieldIcon: <ShoppingCartIcon color="action" style={{ fontSize: 18 }}></ShoppingCartIcon>,
    fieldPath: '/buy-ufarm',
    subMenus: null,
  },
  // {
  //   fieldName: 'Setu (Bridge)',
  //   fieldIcon: <FaLayerGroup style={{ fontSize: 18, color: 'gray' }}> </FaLayerGroup>,
  //   fieldPath: null,
  //   subMenus: [
  //     { fieldName: 'Bridge Your Token', fieldPath: '/bridge' },
  //     { fieldName: 'Transactions', fieldPath: '/bridge-transaction' },
  //     { fieldName: 'Liquidity Pool', fieldPath: '/bridge-pool' },
  //   ],
  // },
];

interface MobileSideBarProps {
  setMobileOpen: (isOpen: boolean) => void;
}

export default function MobileSideBar({ setMobileOpen }: MobileSideBarProps) {
  const history = useHistory();

  return (
    <FixedProSideBar collapsed={false}>
      <MobileSideBarFlexHeader>
        <UnifarmMobileLargeLogo src={UnifarmLargeLogo} onClick={() => history.push('/')} />
        <CloseIcon style={{ color: 'black' }} onClick={() => setMobileOpen(false)} />
      </MobileSideBarFlexHeader>

      <UnifarmButtonGroup>
        <TradeOn />
        <NetworkContext />
      </UnifarmButtonGroup>

      <Menu iconShape="square">
        {menuItems.map((items) => {
          return items.subMenus === null ? (
            <>
              {items.fieldName === 'ORO-UFARM Merger' ? (
                <Tippy theme="light" placement="right" content={'ORO-UFARM Merger'}>
                  <MenuItem
                    icon={items.fieldIcon}
                    style={{ color: 'black' }}
                    // className={MainMenu == index ? "active" : null}
                  >
                    <Link to={items.fieldPath} style={{ color: 'black' }}>
                      {items.fieldName}
                    </Link>
                  </MenuItem>
                </Tippy>
              ) : (
                <MenuItem
                  icon={items.fieldIcon}
                  style={{ color: 'black' }}
                  // className={MainMenu == index ? "active" : null}
                >
                  <Link to={items.fieldPath} style={{ color: 'black' }}>
                    {items.fieldName}
                  </Link>
                </MenuItem>
              )}
            </>
          ) : (
            <SubMenu
              title={items.fieldName}
              icon={items.fieldIcon}
              onClick={() => {
                return null;
              }}
              /* className={MainMenu == index ? "active" : null} */
            >
              {items.subMenus.map((subItems, submenuIndex) => {
                return (
                  <MenuItem
                    key={submenuIndex}
                    /* className={
                    MainMenu == index
                      ? SubMenu1 == index1
                        ? "active"
                        : null
                      : null
                  } */
                  >
                    <FiberManualRecordIconFlexWrapper>
                      <SmallFiberManualRecordIcon />
                      &nbsp;
                      <Link to={subItems.fieldPath} style={{ color: 'black' }}>
                        {subItems.fieldName}
                      </Link>
                    </FiberManualRecordIconFlexWrapper>
                  </MenuItem>
                );
              })}
            </SubMenu>
          );
        })}
      </Menu>

      <FlexBoxFooter>
        <Join>Join:</Join>
        <Socials>
          <a target="_blank" rel="noreferrer" href="https://telegram.me/unifarm">
            <img src={Telegrammer} width={32} height={32} />
          </a>
          <span style={{ marginTop: '3px' }}>Telegram</span>
        </Socials>
        <Socials>
          <a target="_blank" rel="noreferrer" href="https://discord.com/invite/tTaUr3AckV">
            <img src={Discorder} width={32} height={32} />
          </a>
          <span style={{ marginTop: '3px' }}>Discord</span>
        </Socials>
        <VersionSideBar />
      </FlexBoxFooter>
    </FixedProSideBar>
  );
}

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const useStyles = makeStyles(() => ({
  CopyButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'transparent',
    border: 'none',
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: 'Inter !important',
    fontStyle: 'normal',
    color: 'grey',
    cursor: 'pointer',
    textTransform: 'none',
    '&:hover': {
      background: 'transparent',
    },
  },
}));

interface ExternalLinkProps {
  url: string;
  path: string;
}

export const ExternalLink = ({ url, path }: ExternalLinkProps) => {
  const classes = useStyles();

  return (
    <a
      href={`${url}/${path}`}
      target="_blank"
      rel="noreferrer"
      style={{ color: 'grey', textDecoration: 'none' }}
    >
      <Button className={classes.CopyButton}>
        <OpenInNewIcon style={{ width: '20px', height: '20px', marginRight: '5px', color: 'gray' }} />
        View in Explorer
      </Button>
    </a>
  );
};

import React from 'react';
import {
  HeadingText,
  ImageArrow,
  MetamaskCardRoot,
  MetamaskContent,
  MetamaskRoot,
  MetamaskTitle,
  MetamskImageWrap,
  MetaskContentWrap,
} from './OnBoardingStyle';
import MetamskCheck from '../../assets/Onboardimages/MetamaskCheck.png';
import MetamskWarn from '../../assets/Onboardimages/MetamaskWarn.png';
import Arrow from '../../assets/Onboardimages/Arrow.png';
interface ispropsMetamask{
    installMetamaskClick:()=>void
    buyUfarmClick:()=>void
}
const Metamask = ({installMetamaskClick,buyUfarmClick}:ispropsMetamask) => {
  return (
    <MetamaskRoot>
      <HeadingText>Do you have MetaMask installed in your device?</HeadingText>
      <MetamaskCard
        img={MetamskCheck}
        title="MetaMask Installed"
        content="I already have MetaMask installed on my device and it is configured"
        ArrowClick={installMetamaskClick}
      />
      <MetamaskCard
        img={MetamskWarn}
        title="MetaMask is not installed"
        content="I dont have MetaMask installed on my device and it is not configured"
        ArrowClick={buyUfarmClick}
      />
    </MetamaskRoot>
  );
};
export default Metamask;
interface isprops {
  img: string;
  title: string;
  content: string;
  ArrowClick:()=>void
}
const MetamaskCard = ({ img, title, content,ArrowClick }: isprops) => {
  const [isShow, setShow] = React.useState(false);

  return (
    <MetamaskCardRoot onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)} onClick={ArrowClick}>
      <MetamskImageWrap>
        <img src={img} />
      </MetamskImageWrap>
      <MetaskContentWrap>
        <MetamaskTitle>
          {title}
          {isShow ? <ImageArrow src={Arrow}/> : null}
        </MetamaskTitle>
        <MetamaskContent>{content}</MetamaskContent>
      </MetaskContentWrap>
    </MetamaskCardRoot>
  );
};

interface BlockedFarms {
  [chainId: number]: {
    legacyAddress: string;
    newAddress: string;
  }[];
}

export const blockedFarms: BlockedFarms = {
  1: [
    {
      legacyAddress: '0x8c8687fC965593DFb2F0b4EAeFD55E9D8df348df',
      newAddress: '0x1614f18fc94f47967a3fbe5ffcd46d4e7da3d787',
    },
  ],
  56: [
    {
      legacyAddress: '0xfd004a476a395108eb1a6e960c962bd856e5b3c6',
      newAddress: '0x559cd5b11ca882cedda823ac06275558a92b7064',
    },
    {
      legacyAddress: '0x6fc13eace26590b80cccab1ba5d51890577d83b2',
      newAddress: '0x846F52020749715F02AEf25b5d1d65e48945649D',
    },
  ],
};

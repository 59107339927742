import { createReducer } from '@reduxjs/toolkit';
import { TokenMetaData } from '../lists/reducer';
import { setIdoProjects, setRefetch } from './action';

export enum SaleType {
  PUBLIC,
  WHITELISTED,
}

export enum SaleStatus {
  /** indicates active sale */
  ACTIVE = 'ACTIVE',
  /** indicates upcoming sale */
  UPCOMING = 'UPCOMING',
  /** indicates sold out sale */
  FUNDED = 'FUNDED',
  /** indicates close sale */
  CLOSED = 'CLOSED',
}

export interface User {
  minAllocAmount: number;
  allocateAmount: number;
  /** has user buyed */
  hasBuyed: boolean;
  /** buyed amount */
  buyedAmount: number;
  /** user buyed amount*/
  buyedTokens: number;
  /** sold token amount */
  sellTokenAmount: number;
  /** has insurance buyed */
  hasInsuranceBuyed: boolean;
  /** first claim start time */
  firstClaimTime: number;
  /** second claim start time */
  secondClaimTime: number;
  /** third claim start time */
  thirdClaimTime: number;
}

export type Eligibility = {
  /** label of eligibility */
  eligibilityLabel: string;
  /** whole eligibility description */
  eligibilityDescription: string;
};

export type ProjectExtraDetails = {
  /** IDO contract address */
  idoAddress: string;
  /** eligibility createria's */
  eligibility: Eligibility[];
  /** a big size sell token logo */
  sellTokenLogo: string;
};

export type Project = {
  /** payment token details */
  paymentToken: TokenMetaData[];
  /** sell token details */
  sellToken: TokenMetaData;
  /** start time */
  startTime: number;
  /** end time */
  endTime: number;
  /** purcahse cap */
  purchaseCap: number;
  /** total participants count */
  participantsCount: number;
  /** total raised amount */
  totalRaised: number;
  /** total Raised percentage */
  totalRaisedInPercentage: number;
  /** userData */
  userData: User;
  /** extra details */
  extra: ProjectExtraDetails;
  /** sale status */
  saleStatus: SaleStatus;
  /** total insurance sold */
  totalInsuranceSold: number;
};

export interface IDO {
  projects: Project[];
  refetch: boolean;
}

const idoState: IDO = {
  projects: [],
  refetch: false,
};

const ido = createReducer<IDO>(idoState, (builder) => {
  builder.addCase(setIdoProjects, (state, { payload: { projects } }) => {
    return {
      ...state,
      projects,
    };
  }),
    builder.addCase(setRefetch, (state, { payload: { refetch } }) => {
      return {
        ...state,
        refetch,
      };
    });
});

export default ido;

import { createReducer } from '@reduxjs/toolkit';
import { setVestingScheduleData, setVestingData, setTabPosition } from './action';

export enum UserActivation {
  NOT_REQUESTED,PENDING,COMPLETED
};

export interface VestingDataType {
  vestingName:	string;
  vestingContractAddress:	string;
  allocatedTokens:	number;
  hasActivated:	UserActivation;
  totalClaimed:	number;
  totalVested:	number;
  unClaimedTokens:	number;
  nextUnlock:	string;
  isAirdrop:	boolean;
  availableToClaim: number;
}

export interface VestScheduleType {
  vestingType: string;
  vestingContractAddress: string;
  claimToken: number;
  chainId: number;
}

export interface Vesting {
  vestingSchedule: Array<VestScheduleType>;
}

export interface VestingData {
  vestingData: Array<VestingDataType>;
}

export interface VestingStateType {
  vestingSchedule : Array<VestScheduleType>;
  vestingData: Array<VestingDataType>;
  tabPosition: number;
}

const vestingState: VestingStateType = {
  tabPosition: 0,
  vestingSchedule: [],
  vestingData: []

}

const vesting = createReducer<VestingStateType>(vestingState, (builder) => {
  builder.addCase(setVestingScheduleData, (state, { payload : { vestingSchedule } }: any) => {
    return {
      ...state,
      vestingSchedule,
    };
  }),

  builder.addCase(setVestingData, (state, { payload : { vestingData } }: any) => {
    return {
      ...state,
      vestingData,
    };
  }),

  builder.addCase(setTabPosition, (state, { payload : { tabPosition } }: any) => {
    return {
      ...state,
      tabPosition,
    };
  })
});

export default vesting;

import { createStyles, makeStyles } from '@material-ui/core/styles';
import { AbstractConnector } from '@web3-react/abstract-connector';
import { useWeb3React } from '@web3-react/core';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { walletconnect } from '../../connectors';
import { useClosePopup, usePopupStatus } from '../../store/application/hooks';
import { PopUpTypes } from '../../store/application/reducer';
import { getConnectorName, getProviderLogo } from '../../utilities';
import Modal from '../Modal';
import AccountDetails from './AccountDetails';
import { BSC_CHAIN, networks } from '../../constants/chain';
import { wallets } from '../../constants';
import Wallet from '../Web3Modal/Wallet';
import { MenuItem } from '@material-ui/core';
import check from '../../assets/svg/check.svg';
import { useApplicationUserState, useChangeAppChainId } from '../../store/user/hooks';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletLinkConnector } from '@web3-react/walletlink-connector';

const useStyles = makeStyles((theme) =>
  createStyles({
    Root: {
      '&>.MuiDialog-container>.MuiPaper-root.MuiDialog-paperWidthSm ': {
        width: '600px !important',
        borderRadius: '15px !important',
      },
      [theme.breakpoints.down('xs')]: {
        '&>.MuiDialog-container>.MuiPaper-root.MuiDialog-paper': {
          width: '100% !important',
          margin: '15px !important',
        },
      },
    },
    Root2: {
      '&>.MuiDialog-container>.MuiPaper-root.MuiDialog-paperWidthSm ': {
        width: '520px !important',
        borderRadius: '15px !important',
      },
      [theme.breakpoints.down('xs')]: {
        '&>.MuiDialog-container>.MuiPaper-root.MuiDialog-paper': {
          width: '100% !important',
          margin: '15px !important',
        },
      },
    },
    MainDiv: {
      paddingTop: '1rem',
      paddingBottom: '1rem',
    },
    TitleWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    NetworkContainer: {
      background: '#dfebff',
      color: '#7e96b8',
      padding: '10px 15px',
      borderRadius: 10,
    },
    NetworkText: {
      fontSize: 18,
      fontWeight: 400,
      lineHeight: '24px',
      color: '#191f2a',
      marginLeft: '1rem',
    },
    NetworkSelect: {
      display: 'flex',
      marginTop: '1rem',
      marginLeft: '1rem',
      flexFlow: 'wrap',

      rowGap: 16,
      [theme.breakpoints.down('xs')]: {
        marginLeft: 1,
        flexWrap: 'wrap',
      },
    },
    NetworkDiv: {
      width: '100px',
      display: 'flex',
      flexDirection: 'column',
      fontSize: '14px',
      fontWeight: 600,
      fontFamily: 'Inter',
      fontStyle: 'normal',
      borderRadius: 15,
    },
    NetworkIcon: {
      width: '50px',
      height: '50px',
    },
    providerWrapper: {
      display: 'flex',
      marginLeft: '1rem',
      [theme.breakpoints.down('xs')]: {
        marginLeft: 15,
      },
    },
    providerButton: {
      width: '130px',
      background: 'transparent',
      border: 'none',
    },
    IconWrapper: {
      position: 'absolute',
      left: '57px',
      top: '39px',
    },
    CheckIcon: {
      color: 'green',
    },
  })
);

enum Screens {
  WALLET,
  ACCOUNT,
}

export const Web3Modal = (): JSX.Element => {
  const [screen, changeScreen] = useState<Screens>(Screens.WALLET);

  const isOpen = usePopupStatus(PopUpTypes.WALLET);

  const { active, connector, activate, account } = useWeb3React();

  const [, setActiveConnector] = useState<AbstractConnector | null>(null);

  const ref = useRef();

  const classes = useStyles();

  useEffect(() => {
    if (active && account) {
      changeScreen(Screens.ACCOUNT);
    } else {
      changeScreen(Screens.WALLET);
    }
  }, [active, account, changeScreen]);

  const activation = async (connector: AbstractConnector | undefined) => {
    // this is needed here

    // if (connector instanceof WalletConnectConnector) {
    //   walletconnect(appChainId).walletConnectProvider = undefined;
    // }
    // connector &&
    //   activate(connector, undefined, true)
    //     .then(() => {
    //       setActiveConnector(connector);
    //       close();
    //     })
    //     .catch(() => {
    //       close();
    //     });
    let walletindex = localStorage.getItem('wallet');
    if (connector instanceof InjectedConnector && window.ethereum.isMetaMask) {
      connector &&
        activate(connector, undefined, true)
          .then(() => {
            setActiveConnector(connector);
          })
          .catch((error) => {
            console.log(error, 'error in metamask');
          });
      localStorage.setItem('wallet', '1');
    } else if (connector instanceof WalletLinkConnector) {
      connector &&
        activate(connector, undefined, true)
          .then(() => {
            setActiveConnector(connector);
          })
          .catch(() => {
            console.log('error in coinbase');
          });
      localStorage.setItem('wallet', '2');
    } else {
      connector &&
        activate(connector, undefined, true)
          .then(() => {
            setActiveConnector(connector);
          })
          .catch(() => {
            console.log('error in walletconnect');
          });
      localStorage.setItem('wallet', '1');
    }
    close();
  };

  const close = useClosePopup();
  const { appChainId } = useApplicationUserState();
  const changeAppNetwork = useChangeAppChainId();

  function getScreens(): JSX.Element {
    if (screen === Screens.ACCOUNT) {
      return (
        <AccountDetails
          providerName={getConnectorName(connector)}
          providerLogo={getProviderLogo(connector)}
          userAddress={account}
        />
      );
    }
    return <>{getProviders()}</>;
  }

  function getProviders(): JSX.Element {
    return (
      <Fragment>
        <div style={{ marginTop: '1rem' }}>
          <span className={classes.NetworkContainer}>1</span>
          &nbsp;&nbsp;
          <span className={classes.NetworkText}>Choose Network</span>
          <div className={classes.NetworkSelect}>
            {Object.keys(networks).map((keys) => {
              const network = networks[Number(keys)];

              return (
                <MenuItem
                  ref={ref}
                  onClick={() => changeAppNetwork(network.chainId, true)}
                  key={network.chainId}
                  className={classes.NetworkDiv}
                >
                  <div>
                    <img src={network.icon} height="55" width="55" />
                  </div>
                  <div>{network.name}</div>
                  {appChainId === network.chainId && (
                    <div className={classes.IconWrapper}>
                      <img src={check} alt="chain" />
                    </div>
                  )}
                </MenuItem>
              );
            })}
          </div>
        </div>

        <div className={classes.MainDiv}>
          <div className={classes.TitleWrapper}>
            <span className={classes.NetworkContainer}>2</span>
            <span className={classes.NetworkText}>Choose Wallet</span>
          </div>
          <div className={classes.providerWrapper}>
            {Object.keys(wallets)
              .slice(0, appChainId !== BSC_CHAIN ? 3 : 4)
              .map((key, index) => {
                const provider = wallets[key];

                /// check if metamask installed or not

                if (provider.name === 'Metamask' && !window.ethereum) {
                  return (
                    <a
                      key={index}
                      className={classes.providerButton}
                      href="https://metamask.io/"
                      rel="noreferrer"
                      // target="_blank"
                    >
                      <Wallet image={provider.logoUri} imageWidth={55} name={'Install MetaMask'} />
                    </a>
                  );
                }

                // // check if bitkeep wallet is installed
                // if (provider.name === 'Bitkeep' && !window.ethereum) {
                //   return (
                //     <a
                //       key={index}
                //       className={classes.providerButton}
                //       href="https://bitkeep.com/"
                //       rel="noreferrer"
                //       target="_blank"
                //     >
                //       <Wallet image={provider.logoUri} imageWidth={55} name={'Install Bitkeep'} />
                //     </a>
                //   );
                // }

                return (
                  <button
                    key={index}
                    className={classes.providerButton}
                    onClick={() => {
                      return activation(provider.connector);
                    }}
                  >
                    <Wallet image={provider.logoUri} imageWidth={55} name={provider.name} />
                  </button>
                );
              })}
          </div>
        </div>
      </Fragment>
    );
  }

  return (
    <Modal
      open={isOpen}
      title={active ? 'Account Details' : 'Connect Wallet'}
      close={close}
      className={active ? classes.Root2 : classes.Root}
      headerClass="SettingHeader"
    >
      {getScreens()}
    </Modal>
  );
};

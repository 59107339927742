import React, { useRef } from 'react';
import { Menu, MenuItem, ProSidebar, SubMenu } from 'react-pro-sidebar';
import styled from 'styled-components';
import UnifarmLargeLogo from '../../assets/images/brand/Logo.svg';
import CloseIcon from '@mui/icons-material/Close';
import NetworkContext from '../NetworkContext/NetworkContext';
import TradeOn from '../TradeOn';
import './MobileSidebar.scss';
import LaunchIcon from '@mui/icons-material/Launch';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
// import Merger from '../../assets/svg/Merger';
//import Trade from '../../assets/svg/trade';
// import Governance from "../../assets/svg/governance";
import Refferal from '../../assets/svg/refferal';
// import Bridge from "../../assets/svg/bridge";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { Link, useHistory } from 'react-router-dom';
// import Governance from '../../assets/svg/governance';
import Telegrammer from '../../assets/images/telegram1.png';
import Discorder from '../../assets/images/Discord1.png';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
// import CachedIcon from '@mui/icons-material/Cached';
import { FaLayerGroup } from 'react-icons/fa';
// import AssignmentIcon from '@mui/icons-material/Assignment';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import OpacityOutlinedIcon from '@mui/icons-material/OpacityOutlined';
// import LP_Farm from '../../assets/V2/Network/LP_Farm.png';
//import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import DashboardIcon from '@mui/icons-material/Dashboard';
// import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import BuyUfarmButton from 'components/BuyUFarm/BuyUfarmButton';
import { VersionSideBar } from 'components/VersionSidebar/VersionSidebar';
const MobileSideBarFlexHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  align-items: center;
  padding-right: 1rem;
  padding-left: 1rem;
`;

const UnifarmMobileLargeLogo = styled.img`
  width: 150px;
`;

const UnifarmButtonGroup = styled.div`
  display: flex;
  margin-top: 1rem;
  justify-content: center;
  padding-right: 11px;
  flex-direction: column;
  align-items: center;
`;

const FiberManualRecordIconFlexWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
`;

export const SmallFiberManualRecordIcon = styled(FiberManualRecordIcon)`
  font-size: 7px;
`;

const FlexBoxFooter = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 30px;
  margin-left: 2rem;
  height: 100px;
  width: 90px;
`;

const Join = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #212121;
  margin-bottom: 1rem;
`;

const Socials = styled.div`
  display: flex;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #212121;
  margin-bottom: 15px;
  column-gap: 10px;
`;
const MenuWrapper = styled.div`
  height: 330px;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;
interface SubMenuList {
  fieldName: string;
  fieldPath: string;
}

export interface MenuItemsList {
  fieldName: string;
  fieldIcon: JSX.Element;
  fieldPath: string | null;
  subMenus: SubMenuList[] | null;
}

const menuItems: MenuItemsList[] = [
  {
    fieldName: 'Yield Farming',
    fieldIcon: <OpacityOutlinedIcon color="action" style={{ fontSize: 20 }} />,
    fieldPath: null,
    subMenus: [
      { fieldName: 'All Yield Farms', fieldPath: '/farms' },
      { fieldName: 'My Stakes', fieldPath: '/stakes' },
    ],
  },
  {
    fieldName: 'Liquidity Farming',
    fieldIcon: <DashboardIcon color="action" style={{ fontSize: 20 }} />,
    fieldPath: null,
    subMenus: [
      { fieldName: 'All LP Farms', fieldPath: '/liquidity-farming' },
      // { fieldName: 'My LP Stakes', fieldPath: '/stakes' },
      { fieldName: 'My LP Stakes', fieldPath: '/liquidity-farming/stakes?tab=active' },
    ],
  },
  /* {
    fieldName: 'Trade',
    fieldIcon: <SwapHorizIcon color="action" style={{ fontSize: 22 }} />,
    fieldPath: null,
    subMenus: [
      { fieldName: 'Exchange', fieldPath: '/exchange' },
      { fieldName: 'Liquidity', fieldPath: '/liquidity' },
    ],
  },
  // {
  //   fieldName: 'Flip',
  //   fieldIcon: <CachedIcon color="action" style={{ fontSize: 20 }} />,
  //   fieldPath: '/flip',
  //   subMenus: null,
  // },
  {
    fieldName: 'Vesting',
    fieldIcon: <AssignmentIcon color="action" style={{ fontSize: 20 }} />,
    fieldPath: '/vesting',
    subMenus: null,
  },
  {
    fieldName: 'ORO-UFARM Merger',
    fieldIcon: <Merger />,
    fieldPath: '/oro-ufarm-merger',
    subMenus: null,
  },
  // {
  //   fieldName: "Bridge",
  //   fieldIcon: <Bridge />,
  //   fieldPath: null,
  //   subMenus: null,
  // },
  // {
  //   fieldName: "Governance",
  //   fieldIcon: <Governance />,
  //   fieldPath: null,
  //   subMenus: null,
  // },

  /* {
    fieldName: 'Governance',
    fieldIcon: <Governance color="action"> </Governance>,
    fieldPath: '/governance',
    subMenus: null,
  }, */
  {
    fieldName: 'Share Some Love',
    fieldIcon: <InsertLinkOutlinedIcon color="action" style={{ fontSize: 22 }} />,
    fieldPath: '/referral',
    subMenus: null,
  },
  {
    fieldName: 'UniLaunch',
    fieldIcon: <LaunchIcon color="action" style={{ fontSize: 18 }}></LaunchIcon>,
    fieldPath: '/launch',
    subMenus: null,
  },
  {
    fieldName: 'BUY UFARM',
    fieldIcon: <ShoppingCartIcon color="action" style={{ fontSize: 18 }}></ShoppingCartIcon>,
    fieldPath: '/buy-ufarm',
    subMenus: null,
  },
  {
    fieldName: 'Setu (Bridge)',
    fieldIcon: <FaLayerGroup style={{ fontSize: 18, color: 'gray' }}> </FaLayerGroup>,
    fieldPath: null,
    subMenus: [
      { fieldName: 'Bridge Your Token', fieldPath: '/setu' },
      { fieldName: 'Transactions', fieldPath: '/setu-transaction' },
      { fieldName: 'Liquidity Pool', fieldPath: '/setu-pool' },
    ],
  },
];

interface MobileSideBarProps {
  open: boolean;
  close: () => void;
  ItemClick: () => void;
  BuyUfarmClick: () => void;
}

export default function MobileSideBar2({ open, close, ItemClick, BuyUfarmClick }: MobileSideBarProps) {
  //const location = useLocation();
  const bottomRef = useRef(null);
  const FixedProSideBar = styled(ProSidebar)`
    position: fixed;
    width: ${open ? ' 0px !important' : '216px !important'};
    min-width: ${open ? '0px !important' : '216px !important'};
  `;

  const history = useHistory();
  const MyClick = (data) => {
    console.log(data);
    bottomRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <FixedProSideBar collapsed={open}>
      <MobileSideBarFlexHeader>
        <UnifarmMobileLargeLogo src={UnifarmLargeLogo} onClick={() => history.push('/')} />
        <CloseIcon style={{ color: 'black', marginTop: '9px' }} onClick={close} />
      </MobileSideBarFlexHeader>

      <UnifarmButtonGroup>
        <BuyUfarmButton buyUfarmClick={BuyUfarmClick} />
        <TradeOn />
        <NetworkContext />
      </UnifarmButtonGroup>
      <MenuWrapper>
        <Menu iconShape="square">
          {menuItems.map((items) => {
            return items.subMenus === null ? (
              <>
                {items.fieldName === 'ORO-UFARM Merger' ? (
                  <Tippy theme="light" placement="right" content={'ORO-UFARM Merger'}>
                    <MenuItem icon={items.fieldIcon} style={{ color: 'black' }}>
                      <Link to={items.fieldPath} style={{ color: 'black' }}>
                        {items.fieldName}
                      </Link>
                    </MenuItem>
                  </Tippy>
                ) : (
                  <MenuItem icon={items.fieldIcon} style={{ color: 'black' }} onClick={ItemClick}>
                    <Link to={items.fieldPath} style={{ color: 'black' }}>
                      {items.fieldName}
                    </Link>
                  </MenuItem>
                )}
              </>
            ) : (
              <SubMenu
                title={items.fieldName}
                icon={items.fieldIcon}
                onClick={() => MyClick(items.fieldName)}
                ref={bottomRef}
              >
                {items.subMenus.map((subItems, submenuIndex) => {
                  return (
                    <MenuItem key={submenuIndex} onClick={ItemClick}>
                      <FiberManualRecordIconFlexWrapper>
                        <SmallFiberManualRecordIcon />
                        &nbsp;
                        <Link to={subItems.fieldPath} style={{ color: 'black' }}>
                          {subItems.fieldName}
                        </Link>
                      </FiberManualRecordIconFlexWrapper>
                      <div ref={bottomRef} />
                    </MenuItem>
                  );
                })}
              </SubMenu>
            );
          })}
        </Menu>
      </MenuWrapper>

      {open ? null : (
        <FlexBoxFooter>
          <Join>Join:</Join>
          <Socials>
            <a target="_blank" rel="noreferrer" href="https://telegram.me/unifarm">
              <img src={Telegrammer} width={32} height={32} />
            </a>
            <span style={{ marginTop: '3px' }}>Telegram</span>
          </Socials>
          <Socials>
            <a target="_blank" rel="noreferrer" href="https://discord.com/invite/tTaUr3AckV">
              <img src={Discorder} width={32} height={32} />
            </a>
            <span style={{ marginTop: '3px' }}>Discord</span>
          </Socials>
          <VersionSideBar />
        </FlexBoxFooter>
      )}
    </FixedProSideBar>
  );
}

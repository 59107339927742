import { useWeb3React } from "@web3-react/core";
import {useEffect, useState } from 'react';
import Axios from "axios";

export const useUnstoppableDomain = (): string => {
    const [domain, setDomain] = useState<string>();
    const { account } = useWeb3React()
    // const account = '0xF33FeF8B41e4F83Fc5b9B9d61EBa973cC7d9E49B';
    const SECRET_API_TOKEN = '70edb61b-c510-4ef7-9152-1433af8efaa5'
    useEffect(() => {
      const getDomain = async () => {
        try {
            const response = await Axios({
                method: 'GET',
                url: `https://resolve.unstoppabledomains.com/reverse/${account}`,
                headers: {
                  'Authorization' : `Bearer ${SECRET_API_TOKEN}`
                },
              });
              console.log({ response }); 
              setDomain(response.data["meta"]["domain"]) 
        } catch (err) {
          console.log(err.message);
        }   
      };
      getDomain();
    }, [account]);
    console.log(typeof domain, "domain;;;;;;;;;;;;;;;;;;;;;;;;")
    return domain;
    
  };



import { createReducer } from '@reduxjs/toolkit';
import { BigNumber } from 'ethers';
import { setUserIdoClaims } from './action';

export interface IdoClaimData {
  /* transaction hash */
  id: string;
  /**user address */
  account: string;
  /**claim type */
  tokenType: number;
  /** amount */
  amount: number;
  /** timestamp */
  timeStamp: number;
}

interface Claimed {
  /** claims */
  claimData: IdoClaimData[];
}

const mints: Claimed = {
  claimData: null,
};

const idoClaims = createReducer<Claimed>(mints, (builder) => {
  // first case to set
  builder.addCase(setUserIdoClaims, (state, { payload }) => {
    return {
      claimData: payload.ido,
    };
  });
});

export default idoClaims;

interface Tokenlist {
  [chainId: number]: string;
}

// normal YFV2
export const tokenlistUrls: Tokenlist = {
  1: 'https://raw.githubusercontent.com/InfernalHeir/tokenlist/mainnet-01/unifarm-tokenlist.json',
  56: 'https://raw.githubusercontent.com/InfernalHeir/tokenlist/mainnet-01/unifarm.tokenlist.56.json',
  137: 'https://raw.githubusercontent.com/InfernalHeir/tokenlist/mainnet-01/unifarm.tokenlist.137.json',
  43114: 'https://raw.githubusercontent.com/InfernalHeir/tokenlist/mainnet-01/unifarm.tokenlist.43114.json',
};

export const allLists: string[] = [
  'https://raw.githubusercontent.com/InfernalHeir/tokenlist/mainnet-01/unifarm-tokenlist.json',
  'https://raw.githubusercontent.com/InfernalHeir/tokenlist/mainnet-01/unifarm.tokenlist.56.json',
  'https://raw.githubusercontent.com/InfernalHeir/tokenlist/mainnet-01/unifarm.tokenlist.137.json',
  'https://raw.githubusercontent.com/InfernalHeir/tokenlist/mainnet-01/unifarm.tokenlist.43114.json',
];

interface V2TokenList {
  [chainId: number]: {
    /** tokens */
    tokens: string;
    /** lp tokens */
    lpTokens: string;
  };
}

// v2 tokenlist
export const tokenlistV2: V2TokenList = {
  1: {
    tokens: 'https://raw.githubusercontent.com/ramverma-oro/v2-tokenlist/mainnet/ethereum/tokenlist.json',
    lpTokens: 'https://raw.githubusercontent.com/ramverma-oro/v2-tokenlist/mainnet/ethereum/tokenlist-lp.json',
  },
  56: {
    tokens: 'https://raw.githubusercontent.com/ramverma-oro/v2-tokenlist/mainnet/bsc/tokenlist.json',
    lpTokens: 'https://raw.githubusercontent.com/ramverma-oro/v2-tokenlist/mainnet/bsc/tokenlist-lp.json',
  },
  137: {
    tokens: 'https://raw.githubusercontent.com/ramverma-oro/v2-tokenlist/mainnet/polygon/tokenlist.json',
    lpTokens: 'https://raw.githubusercontent.com/ramverma-oro/v2-tokenlist/mainnet/polygon/tokenlist-lp.json',
  },
};

import useQueryString from 'hooks/useQueryString';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { validateAddress } from 'utilities';
import { InjectedConnector } from '@web3-react/injected-connector';
import { hexlify, hexStripZeros } from 'ethers/lib/utils';
import {
  BSC_CHAIN,
  POLYGON_CHAIN,
  ETH_CHAIN,
  AVAX_CHAIN,
  FANTOM_CHAIN,
  ARBITRUM_CHAIN,
  OPTIMISM_CHAIN,
} from '../../constants/chain';
import { useWeb3React } from '@web3-react/core';
import { WalletLinkConnector } from '@web3-react/walletlink-connector';
import { AppDispatch, AppState } from '..';
import {
  setAppChainId,
  setCollapseSidebar,
  setPoolsTabPosition,
  setRefererAddress,
  setStakesTabPosition,
  setView,
  updateTxnDeadline,
  updateSlippageTolerance,
} from './actions';
import { PoolsTabPosition, StakeTabPosition, Views } from './reducer';
// import { activateOrDeactivateGaslessMode } from "./actions";
import { chainConfig } from '../../constants/chain';

export const useChangeAppChainId = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { connector, library, chainId } = useWeb3React();
  const { appChainId } = useApplicationUserState();
  return useCallback(
    async (newAppChainId: number, noSwitch?: boolean) => {
      const isMetaMask = window.ethereum && window.ethereum.isMetaMask;
      try {
        if ((connector instanceof InjectedConnector && isMetaMask) || connector instanceof WalletLinkConnector) {
          const ethereum = window.ethereum;
          if (newAppChainId === BSC_CHAIN && !noSwitch) {
            await library.provider.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: hexlify(BSC_CHAIN) }],
            });
          } else if (newAppChainId === POLYGON_CHAIN && !noSwitch) {
            await library.provider.request({
              method: 'wallet_switchEthereumChain',
              params: [
                {
                  chainId: hexStripZeros(hexlify(POLYGON_CHAIN)),
                },
              ],
            });
          } else if (newAppChainId === ETH_CHAIN && !noSwitch) {
            await library.provider.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: hexStripZeros(hexlify(ETH_CHAIN)) }],
            });
          } else if (newAppChainId === AVAX_CHAIN && !noSwitch) {
            await library.provider.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: hexStripZeros(hexlify(AVAX_CHAIN)) }],
            });
          } else if (newAppChainId === FANTOM_CHAIN && !noSwitch) {
            await ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: hexStripZeros(hexlify(FANTOM_CHAIN)) }],
            });
          } else if (newAppChainId === ARBITRUM_CHAIN && !noSwitch) {
            await library.provider.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: hexStripZeros(hexlify(ARBITRUM_CHAIN)) }],
            });
          } else if (newAppChainId === OPTIMISM_CHAIN && !noSwitch) {
            await ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: hexStripZeros(hexlify(OPTIMISM_CHAIN)) }],
            });
          }
        }
        dispatch(
          setAppChainId({
            appChainId: newAppChainId,
          })
        );
        return true;
      } catch (err) {
        if (err.code === 4902) {
          const ethereum = window?.ethereum;
          await ethereum.request({
            method: 'wallet_addEthereumChain',
            params: chainConfig[newAppChainId],
          });
        }
        console.log('Error in useChangeAppChainId(): ', err.message);
        return false;
      }
    },
    [dispatch, connector]
  );
};

export const useApplicationUserState = () => {
  return useSelector((state: AppState) => state.user);
};

export const useSetCollapseSideBar = () => {
  const dispatch = useDispatch<AppDispatch>();
  return useCallback(
    (collapseSideBar: boolean) => {
      dispatch(
        setCollapseSidebar({
          collapseSideBar,
        })
      );
    },
    [dispatch]
  );
};

export const useSetView = () => {
  const dispatch = useDispatch();
  return useCallback(
    (view: Views) => {
      dispatch(
        setView({
          view,
        })
      );
    },
    [dispatch]
  );
};

export const useChangePoolsTabPosition = () => {
  const dispatch = useDispatch();
  return useCallback(
    (poolTabPosition: PoolsTabPosition) => {
      dispatch(
        setPoolsTabPosition({
          poolTabPosition,
        })
      );
    },
    [dispatch]
  );
};

export const useChangeStakesTabPosition = () => {
  const dispatch = useDispatch();
  return useCallback(
    (myStakeTabPosition: StakeTabPosition) => {
      dispatch(
        setStakesTabPosition({
          myStakeTabPosition,
        })
      );
    },
    [dispatch]
  );
};

export const useTxnDeadline = (): [number, (deadline: number) => void] => {
  const dispatch = useDispatch();

  return [
    useSelector((state: AppState) => state.user.txnDeadline),
    useCallback((txnDeadline) => dispatch(updateTxnDeadline({ txnDeadline })), [dispatch]),
  ];
};

export const useTxnDeadlineTimestamp = (): number => {
  const [txnDeadline] = useTxnDeadline();
  const deadlineSeconds = txnDeadline * 60;

  return useMemo(() => Math.floor(Date.now() / 1000 + deadlineSeconds), [deadlineSeconds]);
};

export const useSlippageTolerance = (): [number, (tolerance: number) => void] => {
  const dispatch = useDispatch();

  return [
    useSelector((state: AppState) => state.user.slippageTolerance || 0.1),
    useCallback((slippageTolerance) => dispatch(updateSlippageTolerance({ slippageTolerance })), [dispatch]),
  ];
};

export const useSelectReferAddress = (): string => {
  return useSelector((state: AppState) => {
    return state.user.referrer;
  });
};

export const useSetReferrer = () => {
  var { referralAddress } = useQueryString() as { referralAddress: string };

  const { account } = useWeb3React();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!referralAddress) return null;
    if (referralAddress === undefined) {
      return null;
    }
    // check the referral addrerss
    if (!validateAddress(referralAddress as string)) {
      return null;
    }

    if (referralAddress.toLowerCase() === account.toLowerCase()) {
      return null;
    }
    dispatch(
      setRefererAddress({
        referrer: referralAddress,
      })
    );
  }, [referralAddress, dispatch, account]);
};

/* 
/* export const useGasSettings = ():boolean => {
  return useSelector((state: AppState) => state.user.gasLessMode);
};

export const useChoseGasLessMode = (): <()=> void> => {
  const dispatch = useDispatch();
  const isGaslessModeActive = useGasSettings();
  return useCallback(() => {
    dispatch(
      activateOrDeactivateGaslessMode({
        gasLessMode: !isGaslessModeActive,
      })
    );
  }, [isGaslessModeActive,dispatch]);
}; */

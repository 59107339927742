import { createReducer } from '@reduxjs/toolkit';
import { CohortYF2, TokenMetaDataYF2 } from '../../../utilities/V2/types';
import { TokenMetaDataV2 } from '../../lists/reducer';
import { setFarmData, setFarmTokenPublicData, setUserFarmPublicData } from './action';

// farm details
export interface FarmDetails {
  /** farm name  */
  farmName: string;
  /** farm symbol */
  farmSymbol: string;
  /** farm token icon */
  farmIcon: string[];
  /** farm token price */
  farmTokenPrice: number;
  /** reward tokens metadata */
  rewards: TokenMetaDataV2[];
  /** reward token addresses */
  rewardTokenAddress: string[];
  /** per block rewards */
  perBlockRewards: number[];
  /** is Booster Available */
  isBoosterAvailable: boolean;
  /** chainId */
  chainId: number;
  // we have two addition fields
  /** token0 */
  token0?: TokenMetaDataV2;
  /** token1 */
  token1?: TokenMetaDataV2;
  /** dex add liquidity page */
  dexAddLiquidityPage?: string;
}

// this data can be update after wards
export interface FarmData {
  /** farmID */
  farmId: string;
  /** farm active staking */
  activeStaking: number;
  /** farm prior epoch TVLs */
  priorEpochTvls: number[];
  /** total value locked in USD */
  usdTotalStaking: number;
  /** pool filled percentage */
  poolFilled: number;
  /** is hot farm */
  isHotFarm: boolean;
  /** total stake limit */
  totalStakeLimit: number;
  /** farm end time */
  farmEndTime: number;
  /** is farm ends */
  isFarmEnds: boolean;
  /** checking staking locked */
  hasStakeLocked: boolean;
  /** checking unstaking locked */
  hasUnstakeLocked: boolean;
  /** fetched at block number */
  fetchedAtBlockNumber: number;
  /** apy */
  apy: number;
  /** boost upto apy */
  boostUptoAPY: number;
  /** has liquidity mining availble */
  hasLiquidityMiningAvailable: boolean;
}

export interface UserFarmData {
  /** user farm token balance */
  userFarmTokenBalance: number;
  /** user farm total staking */
  userFarmTotalStaking: number;
}

export interface Farm {
  /** cohort details */
  cohort: CohortYF2;
  /** farm token */
  token: TokenMetaDataYF2;
  /** farm details */
  farmDetails: FarmDetails;
  /** farm public data */
  farmData: FarmData;
  /** user related farm data */
  userFarmData: UserFarmData;
}

interface PublicFarm {
  /** list of farms */
  farms: Farm[] | null;
}

const farms: PublicFarm = {
  farms: null,
};

const v2Farms = createReducer<PublicFarm>(farms, (builder) => {
  // farm public data (off chain)
  builder.addCase(setFarmTokenPublicData, (state, { payload }) => {
    let farmsPublicData = [] as Farm[];
    for (var k = 0; k < payload.length; k++) {
      let { cohort, token, farmDetails } = payload[k];
      farmsPublicData.push({
        ...state[k],
        cohort,
        token,
        farmDetails,
      });
    }
    return { farms: farmsPublicData };
  });
  // farm public data (on chain)
  builder.addCase(setFarmData, (state, { payload }) => {
    let farms = [] as Farm[];
    let k = 0;
    while (k < state.farms.length) {
      let { farmData } = payload[k];
      farms.push({ ...state.farms[k], farmData });
      k++;
    }
    return { farms };
  });
  // user farm public data
  builder.addCase(setUserFarmPublicData, (state, { payload }) => {
    state.farms = state?.farms?.map((farm, i) => {
      return {
        ...farm,
        userFarmData: payload?.userFarmPublicData[i],
      };
    });
    return state;
  });
});

export default v2Farms;

import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from 'store';
import { setAppView, setAPYCalculatorSettings, setBoosterPackModalSettings, setVersion } from './action';
import { APYCalculatorSetting, BoosterPackModalSetting, DappVersion, View } from './reducer';

export const useSwitchAppVersion = (): ((version: DappVersion) => void) => {
  const dispatch = useDispatch<AppDispatch>();
  return useCallback(
    (version: DappVersion) => {
      dispatch(setVersion({ version }));
    },
    [dispatch]
  );
};

export const useSwitchView = () => {
  const dispatch = useDispatch<AppDispatch>();
  return useCallback(
    (view: View) => {
      dispatch(setAppView({ view }));
    },
    [dispatch]
  );
};

export const useEnableAPYCalculatorSetting = () => {
  const dispatch = useDispatch<AppDispatch>();

  return useCallback(
    (isAPYCalculatorOpen: boolean, farmId?: string) => {
      let requestedFarmId = !isAPYCalculatorOpen ? null : farmId;
      dispatch(
        setAPYCalculatorSettings({
          apyCalculatorSettings: {
            isAPYCalculatorOpen,
            requestedFarmId,
          },
        })
      );
    },
    [dispatch]
  );
};

export const useToggleBoosterPackModal = () => {
  let dispatch = useDispatch<AppDispatch>();

  return useCallback(
    (isOpen: boolean, nftTokenId?: number) => {
      let requestedNftTokenId = !isOpen ? null : nftTokenId;
      dispatch(
        setBoosterPackModalSettings({
          boosterPackModalSettings: {
            isBoosterPackModalOpen: isOpen,
            requestedNftTokenId,
          },
        })
      );
    },
    [dispatch]
  );
};

// apy calculator setting
export const useApyCalculatorSetting = (): APYCalculatorSetting => {
  return useSelector((state: AppState) => state.dapp.apyCalculatorSettings);
};

// booster modal setting
export const useBoosterSetting = (): BoosterPackModalSetting => {
  return useSelector((state: AppState) => state.dapp.boosterPackModalSettings);
};

// app version
export const useAppVersion = (): DappVersion => {
  return useSelector((state: AppState) => state.dapp.version);
};

// app view
export const useAppView = (): View => {
  return useSelector((state: AppState) => state.dapp.view);
};

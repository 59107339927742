import { createReducer } from '@reduxjs/toolkit';
import { BigNumber } from 'ethers';
import { setUserStakeDetails, setUserClaims } from './action';

export interface ClaimData {
  /** nft token Id */
  tokenId: number;
  /** rValue collected by the event */
  rValue: BigNumber;
  /** block number when user claimed */
  blockNumber: number;
  /** transaction hash */
  transactionHash: string;
  /** time stamp */
  timeStamp: number;
}

export type StakeV2 = {
  /** farm Id for filter */
  farmId: string;
  /** farm id */
  fid: number;
  /** NFT token Id */
  nftTokenId: number;
  /** user staked amount */
  stakedAmount: BigNumber;
  /** staking start block */
  startBlock: number;
  /** when removed */
  endBlock: number;
  /** has user buyed the booster pack */
  hasBoosterBuyed: boolean;
  /** staked on */
  stakedOn: number;
};

export interface MintDetails {
  /** staking details */
  stake: StakeV2;
  /** claims */
  claims?: ClaimData[];
}

interface Mint {
  /** mints details */
  mintData: StakeV2[];
  /** claims */
  claimData: ClaimData[];
}

const mints: Mint = {
  mintData: null,
  claimData: null,
};

const users = createReducer<Mint>(mints, (builder) => {
  // first case to set
  builder.addCase(setUserStakeDetails, (state, { payload }) => {
    return {
      ...state,
      mintData: payload.stake,
      claimData: state.claimData,
    };
  });
  builder.addCase(setUserClaims, (state, { payload }) => {
    // iterate payload
    return {
      ...state,
      mintData: state.mintData,
      claimData: payload.claims,
    };
  });
});

export default users;

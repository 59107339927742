import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { BscConnector } from '@binance-chain/bsc-connector';
import { WalletLinkConnector } from '@web3-react/walletlink-connector';

import {
  avaxRpcUrl,
  AVAX_CHAIN,
  bscRpcUrl,
  BSC_CHAIN,
  ethRpcUrl,
  ETH_CHAIN,
  polygonRpcUrl,
  POLYGON_CHAIN,
  FANTOM_CHAIN,
  fantomRpcUrl,
  ARBITRUM_CHAIN,
  arbitrumRpcUrl,
  OPTIMISM_CHAIN,
  optimismRpcUrl,
} from '../constants/chain';

const supportedChainIds: number[] = [
  ETH_CHAIN,
  BSC_CHAIN,
  POLYGON_CHAIN,
  AVAX_CHAIN,
  FANTOM_CHAIN,
  ARBITRUM_CHAIN,
  OPTIMISM_CHAIN,
];

const RPC: { [chainId: number]: string } = {
  1: ethRpcUrl,
  56: bscRpcUrl,
  137: polygonRpcUrl,
  250: fantomRpcUrl,
  43114: avaxRpcUrl,
  42161: arbitrumRpcUrl,
  10: optimismRpcUrl,
};

export const injected = new InjectedConnector({
  supportedChainIds,
});

export const walletconnect = new WalletConnectConnector({
  rpc: RPC,
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
  supportedChainIds,
  qrcodeModalOptions: {
    mobileLinks: ['rainbow', 'metamask', 'argent', 'trust', 'imtoken', 'pillar', 'trustwallet'],
    desktopLinks: ['trustwallet'],
  },
});

export const coinbaseWallet = new WalletLinkConnector({
  url: RPC[ETH_CHAIN],
  appName: 'app.unifarm.co',
  supportedChainIds,
});

export const binance = new BscConnector({
  supportedChainIds,
});

import { Button, Container, useMediaQuery } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import { useTheme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import Drawer from '@mui/material/Drawer';
import { SnackbarOrigin } from '@mui/material/Snackbar';
import { useWeb3React } from '@web3-react/core';
import clsx from 'clsx';
import BuyUfarmButton from 'components/BuyUFarm/BuyUfarmButton';
import BuyUfarmOnboarding from 'components/BuyUfarmOnboarding';
import React, { useEffect, useState } from 'react';
import 'react-pro-sidebar/dist/css/styles.css';

import styled from 'styled-components';
import { useApplicationUserState, useSetCollapseSideBar } from '../../store/user/hooks';
import Network from '../NetworkContext/NetworkContext';
import DesktopSideBar from '../SideBar/DeskTopSideBar';
import MobileSideBar from '../SideBar/IpadSidebar';
import MobileSidebar2 from '../SideBar/MobileSidebar2';
import '../SideBar/SideBar.scss';
import SwitchToLegacyInterface from '../SwitchToLeagcyInterface';
import TradeOn from '../TradeOn';
import ConnectWallet from './ConnectWallet';
import ConnectWallet2 from './ConnectWallet2';
import MobileHeader from './MobileHeader';
import useStyles from './style';
//import { useReloadOnChainIdError } from "../../hooks/useReloadOnChainIdError";
import { useLocation } from 'react-router-dom';

export interface State extends SnackbarOrigin {
  open: boolean;
}

interface IsDrawer {
  children: React.ReactNode;
}

const FlexBox = styled.div`
  display: flex;
  align-items: center;
`;
const IpadBox = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
`;

export default function MiniDrawer(props: IsDrawer) {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const ipad = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isopen, setIsopen] = useState(true);
  const location = useLocation();
  const setCollapseSideBar = useSetCollapseSideBar();

  const { collapseSideBar } = useApplicationUserState();
  const { active } = useWeb3React();
  const [isOnboarding, setOnboarding] = useState(false);
  // reload on chainId Error
  //useReloadOnChainIdError();
  useEffect(() => {
    if (active) {
      setOnboarding(false);
    }
    if (location.pathname.toString() !== '/') {
      setOnboarding(false);
    }
  }, [active, location.pathname]);
  return (
    <div className={classes.root}>
      <CssBaseline />
      {mobile ? (
        <>
          <Button onClick={() => setIsopen(false)} style={{ position: 'absolute', height: 60 }}></Button>
          <MobileHeader setMobileOpen={() => setIsopen(false)} />
        </>
      ) : ipad ? (
        <>
          <Button onClick={() => setMobileOpen(false)} style={{ position: 'absolute', height: 60 }}></Button>
          <MobileHeader setMobileOpen={() => setMobileOpen(true)} />
        </>
      ) : (
        <AppBar
          position="fixed"
          classes={{ colorPrimary: classes.AppBarBg }}
          style={{ zIndex: 11 }}
          className={clsx(classes.appBar, {
            [classes.appBarShift]: !collapseSideBar,
          })}
        >
          <Toolbar style={{ display: 'flex', justifyContent: 'space-between', paddingLeft: '20px' }}>
            <FlexBox>
              {collapseSideBar ? (
                <IconButton onClick={() => setCollapseSideBar(false)}>
                  <MenuIcon style={{ color: 'black' }} />
                </IconButton>
              ) : (
                <IconButton onClick={() => setCollapseSideBar(true)}>
                  <MenuOpenIcon style={{ color: 'black' }} />
                </IconButton>
              )}
              {ipad ? null : <SwitchToLegacyInterface />}
            </FlexBox>
            {ipad ? (
              <IpadBox>
                <TradeOn />
                <Network />
                <ConnectWallet />
              </IpadBox>
            ) : (
              <FlexBox>
                <BuyUfarmButton buyUfarmClick={() => setOnboarding(true)} />
                <TradeOn />
                <Network />
                <ConnectWallet2 />
              </FlexBox>
            )}
          </Toolbar>
        </AppBar>
      )}

      {mobile ? (
        <MobileSidebar2
          open={isopen}
          close={() => setIsopen(true)}
          ItemClick={() => setIsopen(true)}
          BuyUfarmClick={() => {
            setOnboarding(true), setIsopen(true);
          }}
        />
      ) : ipad ? (
        <Drawer open={mobileOpen} onClose={() => setMobileOpen(false)}>
          <MobileSideBar setMobileOpen={() => setMobileOpen(false)} />
        </Drawer>
      ) : (
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: !collapseSideBar,
            [classes.drawerClose]: collapseSideBar,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: !collapseSideBar,
              [classes.drawerClose]: collapseSideBar,
            }),
          }}
          style={{ zIndex: 11 }}
        >
          <DesktopSideBar open={collapseSideBar} />
        </Drawer>
      )}

      <Container className={classes.content} maxWidth="lg">
        <>{isOnboarding ? <BuyUfarmOnboarding CloseClick={() => setOnboarding(false)} /> : props.children}</>
      </Container>
    </div>
  );
}

import React from 'react';
import styled from 'styled-components';
import tick from '../../../../../assets/V2/Detail/tick.svg';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  // margin-top: 30px;
  @media (max-width: 430px) {
    margin-top: 20px;
  }
`;
const Title = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #212121;
  margin-top: 25px;
`;
const Message = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #212121;
  margin-top: 10px;
`;

interface CongratulationsProps {
  /** message content */
  message: string;
}

const Congratulations = ({ message }: CongratulationsProps): JSX.Element => {
  return (
    <>
      <Wrapper>
        <img src={tick} alt="tick" style={{ width: '50px', height: '50px' }} />
        <Title>Congratulations</Title>
        <Message>{message}</Message>
      </Wrapper>
    </>
  );
};
export default Congratulations;

import { useCallback, useState } from 'react';

export const useToggleAPYCalculator = (): [boolean, (toggle: boolean) => void] => {
  let [toggle, setToggle] = useState<boolean>(false);
  const openAPYCalculator = useCallback((toggle: boolean) => {
    setToggle(toggle);
  }, []);
  return [toggle, openAPYCalculator];
};

export const useAPYCalculatorInputs = () => {
  let [numberOfDays, setNumberOfDays] = useState<number>(30);

  // handle duration
  const changeNumberOfDays = useCallback((numberOfDays: number) => {
    setNumberOfDays(numberOfDays);
  }, []);

  return {
    numberOfDays,
    changeNumberOfDays,
  };
};

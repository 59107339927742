import React, { Fragment } from 'react';
import Snackbar from '@mui/material/Snackbar';
import AlertTitle from '@mui/material/AlertTitle';
import styled from 'styled-components';
import Alert from '@mui/material/Alert';

const Alertitle = styled(AlertTitle)`
  font-size: 18px !important;
  font-weight: 600 !important;
  font-family: 'Inter !important';
  font-style: normal !important;
  text-align: left;
  color: #c62828;
`;
const AleText = styled.div`
  font-size: 14px !important;
  font-weight: 400 !important;
  font-family: 'Inter';
  font-style: normal !important;
  color: #c62828;
`;

interface SnackbarProps {
  open: boolean;
  message: string;
  severity: 'error' | 'info' | 'success' | 'warning';
  handleClose: () => void;
  title?: string;
  border?: string;
}

const MiniSnackBar = ({ open, message, severity, title, border, handleClose }: SnackbarProps) => {
  return (
    <Fragment>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        style={{
          top: 80,
          background: '#FFEAEA',
          display: 'flex',
          justifyContent: 'start',
          border: '1px solid',
          borderColor: border,
        }}
      >
        <Alert severity={severity}>
          <Alertitle>{title}</Alertitle>
          <AleText>{message}</AleText>
        </Alert>
      </Snackbar>
    </Fragment>
  );
};

export default MiniSnackBar;

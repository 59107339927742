import React from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { BackWrapper, TitleText } from './AddLiquidityStyled';

interface IsProps{
    backHandle:()=>void
    title:string
}
const BackStep = ({backHandle,title}:IsProps) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <BackWrapper>
      {
        matches?
        <IconButton onClick={backHandle} style={{color:'#212121'}}>
        <ArrowBackIcon style={{fontSize:32}}/>
      </IconButton>
      :
      <IconButton onClick={backHandle} style={{color:'#212121'}}>
      <ArrowBackIcon style={{fontSize:35}}/>
    </IconButton>
      }
     
      <TitleText>
      {title}
      </TitleText>
    </BackWrapper>
  );
};
export default BackStep;

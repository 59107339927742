import React from 'react';
import IconButton from '@mui/material/IconButton';
import { Button, Skeleton } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { DetailsDiv, LiquidityDiv, NetworksDiv, TokenDiv, TokenIcon, TokenName, ViewDetails } from './LiquidityPoolStyle';
import THT from '../../../assets/V2/Images/tk1.png';
import networksData from 'utilities/getNetworkData';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
// interface networkKey {
//   name: string;
// }
interface Props {
  tokenTicker: string;
  tokenName: string;
  tokenImage: string;
  liquidityValue: string;
  networks: any[];
  HandleChange: (string) => void;
  isLoading: boolean;
}

const LiquidityPool = ({ tokenTicker, tokenName, tokenImage, liquidityValue, networks, HandleChange, isLoading }: Props) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      {isLoading ? (
        <>
          <DetailsDiv>
            <TokenDiv>
              <Skeleton variant="circular" width={25} height={25} />
              <Skeleton variant="text" width={100} />
            </TokenDiv>
            <NetworksDiv>
              <Skeleton variant="circular" width={25} height={25} />
              <Skeleton variant="circular" width={25} height={25} />
              <Skeleton variant="circular" width={25} height={25} />
              <Skeleton variant="circular" width={25} height={25} />
            </NetworksDiv>
            <Skeleton variant="text" width={100} />
            <ViewDetails>
              <IconButton onClick={HandleChange}>
                {/* <ChevronRightIcon style={{ color: '#616161' }} /> */}
                <Button
                  style={{
                    color: 'white',
                    background: '#673AB7',
                    boxShadow: '0px 7px 18px -2px rgba(103, 58, 183, 0.56)',
                    borderRadius: '10px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '8px',
                    width: '100px',
                    textTransform: 'capitalize',
                  }}
                >
                  Add
                </Button>
              </IconButton>
            </ViewDetails>
          </DetailsDiv>
        </>
      ) : (
        <>
          <DetailsDiv>
            <TokenDiv>
              <TokenIcon src={tokenImage} alt="Token" />
              <TokenName>{tokenName}</TokenName>
            </TokenDiv>
            <NetworksDiv>
              {networks?.map((nt, index) => {
                return (
                  <>
                    <Tippy theme="light" placement="top" content={networksData[nt].name} key={index}>
                      <TokenIcon src={networksData[nt].logo} alt="Network" key={index} />
                    </Tippy>
                  </>
                );
              })}
            </NetworksDiv>
            <LiquidityDiv>{liquidityValue}</LiquidityDiv>
            <ViewDetails>
              <IconButton onClick={() => HandleChange(tokenTicker)}>
                {mobile ? (
                  <ChevronRightIcon style={{ color: '#616161' }} />
                ) : (
                  <Button
                    style={{
                      color: 'white',
                      background: '#673AB7',
                      boxShadow: '0px 7px 18px -2px rgba(103, 58, 183, 0.56)',
                      borderRadius: '10px',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '8px',
                      width: '100px',
                      textTransform: 'capitalize',
                    }}
                  >
                    Add
                  </Button>
                )}
                {/*  */}
              </IconButton>
            </ViewDetails>
          </DetailsDiv>
        </>
      )}
    </>
  );
};

export default LiquidityPool;

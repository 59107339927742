import React from 'react';
import { Menu, MenuItem, ProSidebar, SubMenu } from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { Link, useHistory } from 'react-router-dom';
import './SideBar.scss';
import OpacityOutlinedIcon from '@mui/icons-material/OpacityOutlined';
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';
import LaunchIcon from '@mui/icons-material/Launch';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import UnifarmLargeLogo from '../../assets/svg/Ulogo';
import UnifarmSmallLogo from '../../assets/images/brand/Logo.svg';
import styled from 'styled-components';
import { MenuItemsList, SmallFiberManualRecordIcon } from './IpadSidebar';
import Governance from '../../assets/svg/governance';
import Telegrammer from '../../assets/images/telegram1.png';
import Merger from '../../assets/svg/Merger';
import Discorder from '../../assets/images/Discord1.png';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
// import CachedIcon from '@mui/icons-material/Cached';
import { FaLayerGroup } from 'react-icons/fa';
import AssignmentIcon from '@mui/icons-material/Assignment';
// import LP_Farm from '../../assets/V2/Network/LP_Farm.png';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { VersionSideBar } from 'components/VersionSidebar/VersionSidebar';
const FlexBoxFooter = styled.div`
  width: 240px;
  height: 100px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  @media (max-width: 430px) {
    margin-top: 30px;
    margin-left: 2rem;
  }
`;
const Join = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #212121;
  margin-bottom: 1rem;
  padding-left: 1rem;
`;
const Socials = styled.div`
  display: flex;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #212121;
  column-gap: 10px;
  margin-bottom: 15px;
  padding-left: 1rem;
`;
const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
`;
const StyledUnifarmSmallLogo = styled.img`
  cursor: pointer;
  width: 180px;
`;

interface SideBarProps {
  open: boolean;
}

const MenuItems: MenuItemsList[] = [
  {
    fieldName: 'Yield Farming ',
    fieldIcon: <OpacityOutlinedIcon color="action" style={{ fontSize: 20 }} />,
    fieldPath: null,
    subMenus: [
      { fieldName: 'All Yield Farms', fieldPath: '/farms' },
      { fieldName: 'My Stakes', fieldPath: '/stakes' },
    ],
  },
  {
    fieldName: 'Liquidity Farming',
    fieldIcon: <DashboardIcon color="action" style={{ fontSize: 20 }} />,
    fieldPath: null,
    subMenus: [
      { fieldName: 'All LP Farms', fieldPath: '/liquidity-farming' },
      { fieldName: 'My LP Stakes', fieldPath: '/liquidity-farming/stakes?tab=active' },
    ],
  },
  // {
  //   fieldName: 'Trade',
  //   fieldIcon: <SwapHorizIcon color="action" style={{ fontSize: 22 }} />,
  //   fieldPath: null,
  //   subMenus: [
  //     { fieldName: 'Exchange', fieldPath: '/exchange' },
  //     { fieldName: 'Liquidity', fieldPath: '/liquidity' },
  //   ],
  // },
  // {
  //   fieldName: 'Flip',
  //   fieldIcon: <CachedIcon color="action" style={{ fontSize: 20 }} />,
  //   fieldPath: '/flip',
  //   subMenus: null,
  // },
  {
    fieldName: 'Vesting',
    fieldIcon: <AssignmentIcon color="action" style={{ fontSize: 20 }} />,
    fieldPath: '/vesting',
    subMenus: null,
  },
  {
    fieldName: 'ORO-UFARM Merger',
    fieldIcon: <Merger />,
    fieldPath: '/oro-ufarm-merger',
    subMenus: null,
  },
  // {
  //   fieldName: 'Governance',
  //   fieldIcon: <Governance color="action" style={{ fontSize: 22 }} />,
  //   fieldPath: '/governance',
  //   subMenus: null,
  // },
  // {
  //   fieldName: 'Share Some Love',
  //   fieldIcon: <InsertLinkOutlinedIcon color="action" style={{ fontSize: 22 }} />,
  //   fieldPath: '/referral',
  //   subMenus: null,
  // },
  {
    fieldName: 'UniLaunch',
    fieldIcon: <LaunchIcon color="action" style={{ fontSize: 18 }}></LaunchIcon>,
    fieldPath: '/launch',
    subMenus: null,
  },
  // {
  //   fieldName: 'BUY UFARM',
  //   fieldIcon: <ShoppingCartIcon color="action" style={{ fontSize: 18 }}></ShoppingCartIcon>,
  //   fieldPath: '/buy-ufarm',
  //   subMenus: null,
  // },
  {
    fieldName: 'Setu (Bridge)',
    fieldIcon: <FaLayerGroup style={{ fontSize: 18, color: 'gray' }}> </FaLayerGroup>,
    fieldPath: null,
    subMenus: [
      { fieldName: 'Bridge Your Token', fieldPath: '/setu' },
      { fieldName: 'Transactions', fieldPath: '/setu-transaction' },
      { fieldName: 'Liquidity Pool', fieldPath: '/setu-pool' },
    ],
  },
];

const FixedProSideBar = styled(ProSidebar)<{ isOpen: boolean }>`
  position: fixed;
  width: ${(props) => (props.isOpen ? '80px!important' : '240px !important')};
  min-width: ${(props) => (props.isOpen ? '80px!important' : '240px !important')};
`;
const FlexBoxHeader = styled.div<{ isOpen: boolean }>`
  width: ${(props) => (props.isOpen ? '80px' : '230px')};
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

export default function DesktopSideBar({ open }: SideBarProps): JSX.Element {
  const history = useHistory();

  const { pathname } = location;
  const splitLocation = pathname.split('/');

  return (
    <FixedProSideBar collapsed={open} isOpen={open}>
      <FlexBoxHeader isOpen={open}>
        {open ? (
          <div style={{ cursor: 'pointer' }}>
            <UnifarmLargeLogo onClick={() => history.push('/')} />
          </div>
        ) : (
          <StyledUnifarmSmallLogo src={UnifarmSmallLogo} alt="UFARM" onClick={() => history.push('/')} />
        )}
      </FlexBoxHeader>

      <Menu iconShape="square">
        {MenuItems.map((items) => {
          return items.subMenus === null ? (
            <>
              {items.fieldName === 'ORO-UFARM Merger' ? (
                <Tippy theme="light" placement="right" content={'ORO-UFARM Merger'}>
                  <MenuItem
                    icon={items.fieldIcon}
                    style={{ color: 'black' }}
                    className={splitLocation[1] == items.fieldName.toLowerCase() ? 'active' : null}
                  >
                    <Link to={items.fieldPath} style={{ color: 'black' }}>
                      {items.fieldName}
                    </Link>
                  </MenuItem>
                </Tippy>
              ) : (
                <MenuItem
                  icon={items.fieldIcon}
                  style={{ color: 'black' }}
                  className={splitLocation[1] == items.fieldName.toLowerCase() ? 'active' : null}
                >
                  <Link to={items.fieldPath} style={{ color: 'black' }}>
                    {items.fieldName}
                  </Link>
                </MenuItem>
              )}
            </>
          ) : (
            <SubMenu
              title={items.fieldName}
              icon={
                items.fieldIcon
              } /* onClick={() =>{return(openPage(index))}} className={MainMenu == index ? 'active' : null} */
            >
              {items.subMenus.map((subItems, subItemsIndex) => {
                return (
                  <MenuItem
                    key={subItemsIndex}
                    className={splitLocation[2] == items.fieldName.toLowerCase() ? 'active' : null}
                  >
                    <FlexWrapper>
                      <SmallFiberManualRecordIcon /> &nbsp;
                      <Link style={{ color: 'black' }} to={subItems.fieldPath}>
                        {subItems.fieldName}
                      </Link>
                    </FlexWrapper>
                  </MenuItem>
                );
              })}
            </SubMenu>
          );
        })}
      </Menu>

      <FlexBoxFooter>
        {open ? null : <Join>Join:</Join>}
        <Socials>
          <a target="_blank" rel="noreferrer" href="https://telegram.me/unifarm">
            {open ? (
              <Tippy content="Telegram">
                <img src={Telegrammer} width={32} height={32} />
              </Tippy>
            ) : (
              <img src={Telegrammer} width={32} height={32} />
            )}
          </a>
          {!open && <span style={{ marginTop: '3px' }}>Telegram</span>}
        </Socials>
        <Socials>
          <a target="_blank" rel="noreferrer" href="https://discord.com/invite/tTaUr3AckV">
            {open ? (
              <Tippy content="Discord">
                <img src={Discorder} width={32} height={32} />
              </Tippy>
            ) : (
              <img src={Discorder} width={32} height={32} />
            )}
          </a>
          {!open && <span style={{ marginTop: '3px' }}>Discord</span>}
        </Socials>
        <VersionSideBar />
      </FlexBoxFooter>
    </FixedProSideBar>
  );
}

import { makeStyles } from '@material-ui/core';
import Checkbox from '@mui/material/Checkbox';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { ethers, BigNumber } from 'ethers';
import { round } from 'lodash';
import { BoosterConfig } from 'pages/Setu/LiquidityPool/PoolInspect';
import React from 'react';
import { unitFormatter } from 'utilities';
import THT from '../../../assets/V2/Images/tk1.png';
import {
  BoosterWrapper,
  BuyDiv,
  BuyDiv1,
  BuyDiv2,
  EarnDiv,
  EarnText,
  InputDiv,
  InputIcon,
  InputIconLabel,
  InsufficientAmount,
  StyledButton,
  ToggleGroupDiv
} from './AddLiquidityStyled';

const useStyles = makeStyles((theme) => ({
  Root: {
    '&>.MuiDialog-container>.MuiPaper-root.MuiDialog-paperWidthSm ': {
      width: '490px !important',
      borderRadius: '15px !important',
    },
    [theme.breakpoints.down('xs')]: {
      '&>.MuiDialog-container>.MuiPaper-root.MuiDialog-paper': {
        width: '100% !important',
        margin: '15px !important',
      },
    },
  },
  Input: {
    width: '100%',
    '& .css-1480iag-MuiInputBase-root-MuiInput-root:before': {
      borderColor: '#673AB7 !important',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#673AB7',
    },
  },
  formTextInput: {
    fontSize: '14px !important',
  },
  toggle: {
    width: '100%',
    '&>.MuiButtonBase-root': {
      width: '100%',
      height: '40px !important',
      textTransform: 'capitalize !important',
      border: '1px solid #616161 !important',
      color: '#616161 !important',
      borderRadius: 5,
      fontFamily: 'Inter !important',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    '&>.MuiButtonBase-root.Mui-selected': {
      backgroundColor: '#616161 !important',
      color: '#ffffff !important',
    },
  },
  toggle2: {
    width: '100%',
    '&>.MuiButtonBase-root': {
      width: '100%',
      height: '40px !important',
      textTransform: 'capitalize !important',
      border: '1px solid #673AB7 !important',
      color: '#673AB7 !important',
      borderRadius: 5,
      fontFamily: 'Inter !important',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    '&>.MuiButtonBase-root.Mui-selected': {
      backgroundColor: '#673AB7 !important',
      color: '#ffffff !important',
    },
  },
  timelineConnector: { border: '1px dashed #bdbdbd', backgroundColor: 'transparent !important' },
  timeLineDotActive: {
    background: '#673AB7 !important',
    marginTop: '0px !important',
    marginBottom: '0px !important',
  },
  timeLineDotApproved: {
    background: '#009F42 !important',
    marginTop: '0px !important',
    marginBottom: '0px !important',
  },
}));
``;

interface BoosterProps {
  check: boolean;
  handleCheck: () => void;
  transferHandle: () => void;
  boosterConfig: BoosterConfig;
  toggleBoosters: (number) => void;
  balance: boolean;
  amount: string;
}

const Booster = ({ check, handleCheck, transferHandle, boosterConfig, toggleBoosters, balance, amount }: BoosterProps) => {
  const classes = useStyles();
  const [alignment, setAlignment] = React.useState(1);

  const handleChange2 = (event: React.MouseEvent<HTMLElement>, newAlignment: any) => {
    if(newAlignment !== null) {
      setAlignment(newAlignment);
      toggleBoosters(newAlignment);
    }
  };

  const getBoosterTotalPrice = () => {
    // const totalPrice = new BigNumber(boosterConfig?.price).multipliedBy(alignment);
    // return new BigNumber(ethers.utils.formatUnits(totalPrice, 18)).toFormat(3);
    const totalPrice = BigNumber.from(boosterConfig?.price).mul(alignment);
    return round(unitFormatter(totalPrice, 18), 3);
  }

  const themeColor = '#673AB7';
  return (
    <>
      <BoosterWrapper style={{ background: check ? '#F0EBF8' : '#EFEFEF' }}>
        <EarnDiv>
          <Checkbox
            checked={check}
            onClick={handleCheck}
            sx={{
              padding: '0px',
              '&.Mui-checked': {
                color: themeColor,
              },
            }}
          />
          <EarnText>Earn 1.5X Rewards with Booster</EarnText>
        </EarnDiv>
        <ToggleGroupDiv>
          <ToggleButtonGroup
            color="primary"
            value={alignment}
            exclusive
            onChange={handleChange2}
            className={check ? classes.toggle2 : classes.toggle}
          >
            <ToggleButton value={1}>1 Month</ToggleButton>
            <ToggleButton value={3}>3 Months</ToggleButton>
            <ToggleButton value={6}>6 Months</ToggleButton>
          </ToggleButtonGroup>
        </ToggleGroupDiv>
        <InputDiv>
          <InputIcon>
            <InputIconLabel>Pay With</InputIconLabel>
            <TextField
              id="input-with-icon-textfield"
              value={boosterConfig?.tokenTicker}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={boosterConfig?.tokenImageUrl} width={30} alt="imgd" style={{ paddingBottom: '10px', paddingTop: '5px' }} />
                  </InputAdornment>
                ),
              }}
              variant="standard"
              className={classes.Input}
            />
          </InputIcon>
          <BuyDiv>
            <BuyDiv1>Buy For</BuyDiv1>
            <BuyDiv2>{boosterConfig && getBoosterTotalPrice() + " " + boosterConfig?.tokenTicker}</BuyDiv2>
          </BuyDiv>
        </InputDiv>
      </BoosterWrapper>
      {
        (balance && amount && BigNumber.from(balance).lt(ethers.utils.parseUnits(amount.toString(), 18))) ?
        <InsufficientAmount>Insufficient balance </InsufficientAmount>
        :
        <StyledButton onClick={transferHandle} disabled={!amount || ethers.utils.parseUnits(amount, 18).isZero()}>Add Liquidity</StyledButton>
      }
      
      
    </>
  );
};

export default Booster;

import axios from 'axios';
// import { useQuery } from '@apollo/client';
// import { getIDOGraphqlClient } from 'graphql/Ido';
import { Project, SaleStatus } from '../store/ido/reducer';
import { GetIdo, Ido } from 'graphql/Ido/typings';
import { getContract, unitFormatter } from 'utilities';
import { getIDOMulticall } from './multicall';

export const getProjects = async (chainId: number, account: string, library: any): Promise<Project[]> => {
  console.log('chainId', chainId, 'account', account);
  try {
    // let result: any = await axios.get(
    //   `https://api.launch.unifarm.co/v1/launch/projects?chainId=56&account=0x39718fd8ca10e9730a25bfc0efc4781d563e465a`
    // );

    let result: any = await axios.get(`https://api.unilaunch.unifarm.co/ido/${chainId}`);

    console.log('result', result);

    let idoMulticall = await getIDOMulticall('0x1c9311843DD73B22ED6830D36213eE12aeF86fD7', account, chainId);
    console.log(idoMulticall);
    let status: SaleStatus = SaleStatus.CLOSED;

    // if ((Number(idoMulticall.TOTAL_RAISED) / Number(idoMulticall.PURCHASE_CAP)) * 100 > 100) {
    //   status = SaleStatus.FUNDED;
    // } else if (Date.now() < Number(idoMulticall.TOTAL_RAISED)) {
    //   status = SaleStatus.CLOSED;
    // } else if (Number(idoMulticall.START_TIME) > Date.now()) {
    //   status = SaleStatus.UPCOMING;
    // } else {
    //   status = SaleStatus.ACTIVE;
    // }

    let data: any = result.data.data as Ido[];
    let results: Project[] = data.map((item: Ido) => {
      return {
        extra: {
          idoAddress: item?.id,
          eligibility: item?.participationDetails?.eligibilities,
          sellTokenLogo: 'https://raw.githubusercontent.com/InfernalHeir/tokenlist/master/.github/assets/icons/spn.png',
        },
        startTime: Number(idoMulticall.START_TIME), //Math.floor(Number(String(idoMulticall.START_TIME)) / 10),
        endTime: Number(idoMulticall.END_TIME), // Math.floor(Number(String(idoMulticall.END_TIME)) / 10),
        sellToken: {
          name: 'SportzchainToken',
          symbol: 'SPN',
          address: '0xa3C53b53Fe43083aa31c6F32ffe90C4d91b44391',
          icon: 'https://raw.githubusercontent.com/InfernalHeir/tokenlist/master/.github/assets/icons/spn.png',
          decimals: 18,
          price: 0.0024,
          chainId: 137,
          tags: [],
        },
        paymentToken: [
          {
            name: 'Unifarm Token',
            symbol: 'UFARM',
            address: '0xa7305ae84519ff8be02484cda45834c4e7d13dd6',
            icon: 'https://my.oropocket.com/unifarm/assets/icons/ufarm.png',
            decimals: 18,
            price: 0.002546,
            chainId: 137,
            tags: [],
          },
          {
            name: 'usdt',
            symbol: 'USDT',
            address: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
            icon: 'https://my.oropocket.com/unifarm/assets/icons/usdt.png',
            decimals: 6,
            price: 1,
            chainId: 137,
            tags: [],
          },
        ],
        totalRaised: unitFormatter(idoMulticall.TOTAL_RAISED),
        purchaseCap: String(unitFormatter(idoMulticall.PURCHASE_CAP, 6)),
        participantsCount: String(idoMulticall.PARTICIPANTS),
        totalRaisedInPercentage: (Number(idoMulticall.TOTAL_RAISED) / Number(idoMulticall.PURCHASE_CAP)) * 100,
        saleStatus: status,
        totalInsuranceSold: Number(idoMulticall.Total_Insurance_Sold),
        userData: {
          allocateAmount: String(idoMulticall.MAX_ALLOCATION),
          hasBuyed: unitFormatter(idoMulticall.buyedAmount) > 0,
          buyedAmount: unitFormatter(idoMulticall.buyedAmount),
          buyedTokens: unitFormatter(idoMulticall.buyedTokenUsdValue, 6),
          sellTokenAmount: unitFormatter(idoMulticall.sellTokenAmount, 6),
          hasInsuranceBuyed: idoMulticall.hasInsuranceBuyed,
          firstClaimTime: 1663138800,
          secondClaimTime: 1665730800,
          thirdClaimTime: 1668409200,
        },
      };
    });

    // let project = result.data.data;
    return results as Project[];
  } catch (err) {
    if (err instanceof Error) {
      console.log(err.message);
      return;
    }
  }
};

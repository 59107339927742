import React, { Fragment } from 'react';
import Modal from '../../../Modal';
import Box from '@mui/material/Box';
import { makeStyles } from '@material-ui/core/styles';
import { BuyBoosterTransactionStatus } from 'hooks/useBuyBoosterPack';
import ActivateBooster from './ActivateBooster';
import TransactionCompleted from '../Common/TransactionCompleted';
import { useWeb3React } from '@web3-react/core';
import { generateTransactionUrl } from 'utilities/V2';
import SocialShare from 'components/V2/SocialShare';
import { useBoosterModalContext } from 'contexts/V2/BoosterModalContext';
import { ModalScreen } from 'hooks/useV2ModalScreen';
import { getReferralLink } from 'utilities';

const styles = {
  padding: '0px 0px',
  borderRadius: '20px !important',
};

const useStyles = makeStyles(() => ({
  Modal: {
    overflow: 'hidden',
    '&>.MuiDialog-container>.MuiPaper-root': {
      borderRadius: '15px',
      width: '620px',
    },
  },
}));

const BoosterModal = () => {
  let {
    isOpen,
    toggle,
    buyBoosterPack,
    boosterPackDetails,
    stakingFarmDetails,
    switchScreen,
    screen,
    isBuyBoosterTransactionScreen
  } = useBoosterModalContext();

  let { chainId, account } = useWeb3React();

  let classes = useStyles();
  let { buyBoosterTxStaus, transactionHash } = buyBoosterPack;

  // referral link
  let referralLink = getReferralLink(account);

  const getBoosterModalScreen = () => {
    // booster transaction completed
    if (buyBoosterTxStaus === BuyBoosterTransactionStatus.BUYBOOSTER_COMPLETED) {
      if (screen === ModalScreen.SOCIAL_SHARE) {
        return (
          <SocialShare
            title={` :muscle::muscle:Boosted:muscle::muscle: ${stakingFarmDetails?.APY}% APY to ${boosterPackDetails?.boostedAPY}% APY \n
        Support me to further increase my earnings \n
        Start here: ${referralLink}`}
          />
        );
      }
      return (
        <TransactionCompleted
          isStake={false}
          stakingFarmDetails={stakingFarmDetails}
          switchScreen={switchScreen}
          viewMyStakeUrl={'/stakes'}
          // viewMyStakeUrl={isLiquidityFarming ? '/liquidity-farming/stakes?tab=active' : '/stakes'}
          isBuyBoosterTransactionScreen={isBuyBoosterTransactionScreen}
          transactionUrl={generateTransactionUrl(chainId, transactionHash)}
        />
      );
    }
    return <ActivateBooster />;
  };

  return (
    <Fragment>
      <Modal
        open={isOpen}
        title="Boost your APY"
        close={() => toggle(false)}
        className={classes.Modal}
        headerClass="SettingHeader"
      >
        <Box sx={styles}>{getBoosterModalScreen()}</Box>
      </Modal>
    </Fragment>
  );
};

export default BoosterModal;

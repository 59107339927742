import { useCallback, useMemo, useState } from 'react';
import { useNFTManagerContract } from './useContract';
import { useWeb3React } from '@web3-react/core';
import { estimatedGas, gasPrice } from 'utilities';
import { ApprovalResponse, useAllowance, useApproval } from './useApproval';
import { BigNumber } from 'ethers';
import { WRAPPED_TOKENS } from 'constants/V2';
import { chainId } from 'constants/ido';

export enum BuyBoosterTransactionStatus {
  /** loading */
  BUYBOOSTER_LOADING,
  /** completed */
  BUYBOOSTER_COMPLETED,
  /** error */
  BUYBOOSTER_ERORR,
}

export interface BuyBoosterPackTransactionResponse {
  /** buy booster transaction status */
  buyBoosterTxStaus: BuyBoosterTransactionStatus;
  /** buy booster function callback */
  buyBoosterCallback: () => void;
  /** transaction hash */
  transactionHash: string;
}

export const useBuyBoosterPack = (
  /** cohortId */
  cohortId: string,
  /** booster pack id */
  bpid: number,
  /** token Id */
  tokenId: number,
  /** booster payment token */
  paymentToken?: string,
  /** booster pack amount */
  boosterPackAmount?: BigNumber
): BuyBoosterPackTransactionResponse => {
  let [buyBoosterTxStaus, setBuyBoosterTxStaus] = useState<BuyBoosterTransactionStatus>();

  // set the transaction hash
  let [transactionHash, setTransactionHash] = useState<string>();

  const { account, library, chainId } = useWeb3React();

  const manager = useNFTManagerContract();

  const buyBoosterCallback = useCallback(async () => {
    if (!manager || !account) return null;
    try {
      let wrappedToken = WRAPPED_TOKENS[chainId];
      let ethSendValue = paymentToken?.toLowerCase() === wrappedToken?.toLowerCase() ? boosterPackAmount : 0;

      // set loader
      setBuyBoosterTxStaus(BuyBoosterTransactionStatus.BUYBOOSTER_LOADING);

      // estimate gas fees
      let gasLimit = await estimatedGas(manager, 'buyBoosterPackOnUnifarm', [cohortId, bpid, tokenId], account);

      let gas_price = await gasPrice(library);

      const transaction = await manager.connect(library.getSigner()).buyBoosterPackOnUnifarm(cohortId, bpid, tokenId, {
        gasLimit,
        gasPrice: gas_price,
        from: account,
        value: ethSendValue,
      });
      // wait for atleast two confirmation
      await transaction.wait(2);

      // set the status to complete
      setBuyBoosterTxStaus(BuyBoosterTransactionStatus.BUYBOOSTER_COMPLETED);

      // set the transaction  hash
      setTransactionHash(transaction.hash);
    } catch (err) {
      console.log('Buy Booster Pack', err.message);
      // set the transaction status to error
      setBuyBoosterTxStaus(BuyBoosterTransactionStatus.BUYBOOSTER_ERORR);
    }
  }, [account, bpid, cohortId, library, manager, tokenId, boosterPackAmount, paymentToken, chainId]);

  return {
    buyBoosterTxStaus,
    buyBoosterCallback,
    transactionHash,
  };
};

export const useBoosterPackApprovalCallback = (
  /** is booster available in cohort */
  isBoosterAvailable: boolean,
  /** is User wants to buy the booster pack */
  isUserWantsToBuyBoosterPack: boolean,
  /** owner address */
  owner: string,
  /** spender address */
  spender: string,
  /** token */
  token: string,
  /** spending amount */
  spendingAmount: BigNumber,
  /** no call scenrio where Booster payment equal to ETH */
  noCall?: boolean,
  /**chain Id */
  chainId?: number
): [ApprovalResponse] => {
  // to check if user is interested or not
  let userInterestOnBoosterPack = useMemo(() => {
    return isBoosterAvailable && isUserWantsToBuyBoosterPack;
  }, [isBoosterAvailable, isUserWantsToBuyBoosterPack]);

  // check allowance if every thing fine
  let boosterPackAllowance = useAllowance(owner, spender, token, chainId, !userInterestOnBoosterPack || noCall);

  // approval callback for booster pack
  let boosterPackApprovalCallback = useApproval(boosterPackAllowance, spendingAmount, spender, token);

  return [boosterPackApprovalCallback];
};

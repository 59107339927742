import { createStyles, makeStyles } from '@material-ui/core/styles';
import BigNumber from 'bignumber.js';
import { ethers } from 'ethers';
import { round } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { unitFormatter } from 'utilities';
import { LiquidityWrapper } from './NetworkStyled';

const useStyle = makeStyles((theme) =>
  createStyles({
    Root: {
      '&>.MuiDialog-container>.MuiPaper-root.MuiDialog-paperWidthSm ': {
        width: '490px !important',
        borderRadius: '15px !important',
      },
      [theme.breakpoints.down('xs')]: {
        '&>.MuiDialog-container>.MuiPaper-root.MuiDialog-paper': {
          width: '100% !important',
          margin: '15px !important',
        },
      },
    },
    LiquidityText: {
      color: 'rgba(33, 33, 33, 1)',
      fontSize: 12,
      fontWeight: 400,
    },
  })
);

interface isTokeList {
  Liquidity: string;
  isLiquidityShow: boolean;
 
}
const ChooseNetwork = ({
 
  Liquidity,
  isLiquidityShow,
  
}: isTokeList) => {
  const classes = useStyle();

  // const NumberFormate = (value) => {
  //   return value.toLocaleString(undefined, { maximumFractionDigits: 2 });
  // };

  return (
    <>
      <LiquidityWrapper>
        {/* {isLiquidityShow ? <span className={classes.LiquidityText}>Liquidity : {Liquidity && new BigNumber(ethers.utils.formatUnits(Liquidity, 18)).toFormat(3)}</span> : null} */}

        {isLiquidityShow ? <span className={classes.LiquidityText}>Liquidity : {Liquidity ? 
            (Liquidity === "Infinite" ? "Infinite" : round(unitFormatter(Liquidity, 18), 3)) :
            Liquidity
          }
          </span> : 
        null }
      </LiquidityWrapper>
     
    </>
  );
};

export default ChooseNetwork;

import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { ButtonBg, TextColor } from '../../stylevariable';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import './Modal.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    Root: {
      '&>.MuiBackdrop-root': {
        backgroundColor: ButtonBg.myBackground + '!important',
        opacity: '0.5 !important',
      },
    },
    Title: {
      height: '69px',
      padding: '0px 25px',
      borderBottom: '1px solid #80808057',
      color: '#212121',
      [theme.breakpoints.down('xs')]: {
        height: '60px',
      },
    },
    TitleDiv: {
      height: '69px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]:{
        height:58
      }
    },
    MainTitle: {
      fontSize: '18px',
      fontWeight: 700,
      lineHeight: '21px',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      [theme.breakpoints.down('xs')]: {
        fontSize: '13px',
      },
    },
    Content: {
      padding: '0px 25px',
      [theme.breakpoints.down('xs')]: {
        padding: '0px 10px',
      },
    },
  })
);

interface ModalProps {
  title: any;
  open: boolean;
  close: () => void;
  className: string;
  headerClass: string;
  children: React.ReactNode;
  isBack?:boolean;
  backClick?:()=>void
}

const Modal = ({ title, open, close, className, headerClass, children,isBack,backClick }: ModalProps): JSX.Element => {
  const classes = useStyle();

  return (
    <div>
      <Dialog
        open={open}
        onClose={close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={className + ' ' + classes.Root}
      >
        <DialogTitle id="alert-dialog-title" className={headerClass + ' ' + classes.Title}>
          <div className={classes.TitleDiv}>
            
            <div className={classes.MainTitle}>
            {
              isBack?
                <IconButton  onClick={backClick} style={{padding:0,marginRight:10}}><ArrowBackIcon style={{color:'black'}}/></IconButton>
                :null
            }
              {title}
              </div>
            <IconButton onClick={close} style={{ color: TextColor.textColor }}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent className={classes.Content}>{children}</DialogContent>
      </Dialog>
    </div>
  );
};
export default Modal;

// dedicated chain configuration
import EthereumLogo from '../assets/images/others/eth.svg';
import BinanceChainLogo from '../assets/images/others/bn.svg';
import PolygonLogo from '../assets/images/others/polygon.svg';
import KAVA from '../assets/images/others/KAVA.svg';
import AVE from '../assets/images/Ave.png';
import FTMLogo from '../assets/V2/Network/FTM.png';
import ArbitrumLogo from '../assets/images/others/arbitrum.svg';
import OptimismLogo from '../assets/images/others/optimism.svg';

// ETH RPC url
export const ethRpcUrl = process.env.REACT_APP_ETHEREUM_RPC_URL;
// BSC RPC url
export const bscRpcUrl = process.env.REACT_APP_BSC_RPC_URL;

// POLYGON RPC url
export const polygonRpcUrl = process.env.REACT_APP_POLYGON_RPC_URL;
// AVAX RPC URL
export const avaxRpcUrl = process.env.REACT_APP_AVAX_RPC_URL;

export const fantomRpcUrl = String(process.env.REACT_APP_FANTOM_RPC_URL);

export const arbitrumRpcUrl = String(process.env.REACT_APP_ARBITRUM_RPC_URL);

export const optimismRpcUrl = String(process.env.REACT_APP_OPTIMISM_RPC_URL);

if (ethRpcUrl === undefined || bscRpcUrl === undefined || polygonRpcUrl === undefined || avaxRpcUrl === undefined) {
  console.log(ethRpcUrl, bscRpcUrl, polygonRpcUrl, avaxRpcUrl);
  throw new Error(`You may need JSON RPC url to run this app.`);
}

interface RPC {
  [chainId: number]: string;
}

interface Network {
  [chainId: number]: string;
}

interface Explolers {
  [chainId: number]: string;
}

// ETH ChainId
export const ETH_CHAIN = 1;
// BSC ChainId
export const BSC_CHAIN = 56;
// Polygon ChainId
export const POLYGON_CHAIN = 137;
// Avax ChainId
export const AVAX_CHAIN = 43114;
// // Fantom ChainId
export const FANTOM_CHAIN = 250;
// Artbitrum chainId
export const ARBITRUM_CHAIN = 42161;
// Optimism chainId
export const OPTIMISM_CHAIN = 10;

export const rpcUrls: RPC = {
  1: ethRpcUrl,
  56: bscRpcUrl,
  137: polygonRpcUrl,
  250: fantomRpcUrl,
  43114: avaxRpcUrl,
  42161: arbitrumRpcUrl,
  10: optimismRpcUrl,
};

export const NETWORK: Network = {
  1: 'ETHEREUM MAINNET',
  56: 'BSC MAINNET',
  137: 'POLYGON MAINNET',
  250: 'FANTOM MAINNET',
  43114: 'AVALANCHE MAINNET',
  42161: 'ARBITRUM MAINNET',
  10: 'OPTIMISM MAINNET',
};

export const explolers: Explolers = {
  1: 'https://etherscan.io',
  10: 'https://optimistic.etherscan.io/',
  56: 'https://bscscan.com',
  137: 'https://polygonscan.com',
  250: 'https://ftmscan.com',
  43114: 'https://snowtrace.io/',
  42161: 'https://arbiscan.io/',
};

export interface NetworkDetails {
  name: string;
  icon: string;
  chainId: number;
}

export interface Networks {
  [chainId: number]: NetworkDetails;
}

export const blocksMined = {
  /** ethereum takes average 15 secs */
  1: 13.9,
  /** bsc takes average 3.5 secs */
  56: 3,
  /** polygon takes average 2.5 secs */
  137: 2.15,
  // /** fantom takes average 1 secs */
  250: 1,
  /** avalanche takes average 1 sec */
  43114: 1,
  42161: 13,
  10: 13,
};

export const networks: Networks = {
  1: {
    name: 'Ethereum',
    icon: EthereumLogo,
    chainId: 1,
  },
  56: {
    name: 'Binance',
    icon: BinanceChainLogo,
    chainId: 56,
  },
  137: {
    name: 'Polygon',
    icon: PolygonLogo,
    chainId: 137,
  },
  250: {
    name: 'Fantom',
    icon: FTMLogo,
    chainId: 250,
  },
  43114: {
    name: 'Avalanche',
    icon: AVE,
    chainId: 43114,
  },
  42161: {
    name: 'Arbitrum',
    icon: ArbitrumLogo,
    chainId: 42161,
  },
  10: {
    name: 'Optimism',
    icon: OptimismLogo,
    chainId: 10,
  },
  2221: {
    name: 'Kava',
    icon: KAVA,
    chainId: 2221,
  },
};

export const chainConfig = {
  56: [
    {
      chainId: '0x38',
      chainName: 'Binance Smart Chain (Mainnet)',
      nativeCurrency: {
        name: 'BNB',
        symbol: 'BNB',
        decimals: 18,
      },
      rpcUrls: [bscRpcUrl],
      blockExplorerUrls: ['https://bscscan.com/'],
      iconUrls: ['https://unifarm.co/app/assets/icons/bnb.png'],
    },
  ],
  137: [
    {
      chainId: '0x89',
      chainName: 'POLYGON Mainnet',
      nativeCurrency: {
        name: 'MATIC TOKEN',
        symbol: 'MATIC',
        decimals: 18,
      },
      rpcUrls: [polygonRpcUrl],
      blockExplorerUrls: ['https://polygonscan.com/'],
      iconUrls: ['https://raw.githubusercontent.com/InfernalHeir/tokenlist/master/.github/assets/icons/matic.png'],
    },
  ],
  250: [
    {
      chainId: '0xFA',
      chainName: 'Fantom Mainnet',
      nativeCurrency: {
        name: 'Fantom',
        symbol: 'FTM',
        decimals: 18,
      },
      rpcUrls: [fantomRpcUrl],
      blockExplorerUrls: ['https://ftmscan.com/'],
      iconUrls: [FTMLogo],
    },
  ],
  42161: [
    {
      chainId: '0xA4B1',
      chainName: 'Arbitrum Mainnet',
      nativeCurrency: {
        name: 'Arbitrum',
        symbol: 'ARB',
        decimals: 18,
      },
      rpcUrls: [arbitrumRpcUrl],
      blockExplorerUrls: ['https://arbiscan.io/'],
      iconUrls: ['https://raw.githubusercontent.com/InfernalHeir/tokenlist/mainnet-01/.github/assets/icons/arb.png'],
    },
  ],
  10: [
    {
      chainId: '0xA',
      chainName: 'Optimism Mainnet',
      nativeCurrency: {
        name: 'Optimism',
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrls: [optimismRpcUrl],
      blockExplorerUrls: ['https://optimistic.etherscan.io/'],
      iconUrls: ['https://raw.githubusercontent.com/InfernalHeir/tokenlist/mainnet-01/.github/assets/icons/opt.png'],
    },
  ],
  43114: [
    {
      chainId: '0xA86A',
      chainName: 'Avalanche Mainnet C-Chain',
      nativeCurrency: {
        name: 'Avalanche',
        symbol: 'AVAX',
        decimals: 18,
      },
      rpcUrls: [avaxRpcUrl],
      blockExplorerUrls: ['https://snowtrace.io/'],
      iconUrls: ['https://raw.githubusercontent.com/InfernalHeir/tokenlist/mainnet-01/.github/assets/icons/avax.png'],
    },
  ],
};

export const legacyAppUrls = {
  1: 'https://eth.unifarm.co/',
  56: 'https://bsc.unifarm.co/',
  137: 'https://polygon.unifarm.co/',
  43114: 'https://avax.unifarm.co/',
};

export const multicallAddress = {
  1: '0xeefba1e63905ef1d7acba5a8513c70307c1ce441',
  56: '0x41263cba59eb80dc200f3e2544eda4ed6a90e76c',
  137: '0x11ce4B23bD875D7F5C6a31084f55fDe1e9A87507',
  43114: '0xed386Fe855C1EFf2f843B910923Dd8846E45C5A4',
};

export const ChainId = {
  // MAINNET: 1,
  ROPSTEN: 3,
  RINKEBY: 4,
  GÖRLI: 5,
  KOVAN: 42,
  DEVNET: 444800,
  AUTONITY: 444900,
  PARASTATE: 123,
  GANCHE: 1337,
  KAVA: 2221,
};

export const routerAddress = new Map();
routerAddress.set(ChainId.ROPSTEN, '0x58eB3693BB442be24185Da274eB60B3E057F7025');
routerAddress.set(ChainId.RINKEBY, '0xbA09E5754556f30eC5C9cf4c1b2A79F5D9058497');
routerAddress.set(ChainId.GÖRLI, '0xbA09E5754556f30eC5C9cf4c1b2A79F5D9058497');
routerAddress.set(ChainId.KOVAN, '0xbA09E5754556f30eC5C9cf4c1b2A79F5D9058497');
routerAddress.set(ChainId.DEVNET, '0x04e555283D37aE85F6eB311fe2578F3B3f3dFc52');
routerAddress.set(ChainId.AUTONITY, '0x04e555283D37aE85F6eB311fe2578F3B3f3dFc52');
routerAddress.set(ChainId.PARASTATE, '0x07a1905D44feeA439ceFAabd11a63bEf684abE11');
routerAddress.set(ChainId.GANCHE, '0x0F44AC51198D8F99847db6C431448dBC673428f1');
// 0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D
export const factoryAddress = new Map();
factoryAddress.set(ChainId.ROPSTEN, '0x700276ca62916e831576b735F119D725BFe6Ca0d');
factoryAddress.set(ChainId.RINKEBY, '0x5858f63A06B048F2C07411bb7d620B34107cC5fd');
factoryAddress.set(ChainId.GÖRLI, '0x5858f63A06B048F2C07411bb7d620B34107cC5fd');
factoryAddress.set(ChainId.KOVAN, '0x5858f63A06B048F2C07411bb7d620B34107cC5fd');

export const ETH_MAINNET_CHAIN = 1;

export const NETWORK_NAME: { [chainId: number]: string } = {
  1: 'Ethereum',
  56: 'BSC',
  137: 'Polygon',
};

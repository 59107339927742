import { Reward } from '../types';

export enum Page {
  /** All farm page */
  ALLFARMS,
  /** My Stake page */
  MYSTAKES,
  /** My Claims page */
  MYCLAIMS,
}

export interface ApyProps {
  /** Annual Percentage yield  */
  Apy: number;
}
export interface NetworkProps {
  /** Annual Percentage yield  */
  chainId: number;
}
export interface PoolFilledProps {
  /** pool filled percentage */
  poolFilledPercentage: number;
}

export interface RewardsProps {
  /** rewards */
  rewards: Reward[][];
  content: string
}

export interface StakedAmountProps {
  /** staked Amount */
  stakedAmount: number;
}

export interface StakedOnProps {
  /** staked Date */
  stakedDate: string;
}

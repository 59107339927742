import React from 'react'
import styled from 'styled-components'
const SetuWrapper=styled.div`
margin-top:6rem;
@media(max-width:480px){
    margin-top:1.5rem;
}
`
interface isProps{
    children:React.ReactNode
}
const SetuContainer=({children}:isProps)=>
{
    return(
     <SetuWrapper>
       {children}
     </SetuWrapper>
    )
}
export default SetuContainer
import React from 'react';
import styled from 'styled-components';
const WidgetWrapper = styled.div`
  height: 66vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const FrameWrapper = styled.iframe`
  height: 68vh;
  border: none;
  overflow: hidden;
  width: 440px;
  @media (max-width: 430px) {
    height: 78vh;
    border: none;
    overflow: hidden;
    width: 322px;
  }
`;
interface isProps {
  widgetUrl: string;
}
const UfarmMetaFrame = ({ widgetUrl }: isProps) => {
  return (
    <WidgetWrapper>
      <FrameWrapper src={widgetUrl} allow="clipboard-write" />
    </WidgetWrapper>
  );
};
export default UfarmMetaFrame;

import React, { useEffect, useState } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Button, InputAdornment, TextField } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import BNB from '../../../assets/V2/Images/bnb.png';
import Unifarm from '../../../assets/V2/Images/unifarm.png';
import BoosterImage from '../../../assets/V2/Images/Rocket_New.png';
import ClipLoader from 'react-spinners/ClipLoader';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Confetti from 'react-confetti';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  BoosterPayWrapper,
  BoosterWrapper,
  ButttonWrapper,
  BuyForWrapper,
  EarnDiv,
  EarnText,
  ErrorDiv,
  InputIcon,
  InputIcon2,
  InputIconLabel,
  LiquidityWrapper,
  NetworkWrapper,
  StyledButton,
  StyledDoneButton,
  SubToken,
  ToggleGroupDiv,
  TokenIcon2,
  TokenInfoWrapper,
  TokenName,
  TokenTextDiv,
  TokenTextDiv1,
  TokenTitle,
} from './LiquidityDetailStyle';
import { BigNumber, ethers } from 'ethers';
import { bridgeAddress } from 'constants/bridge';
import { useWeb3React } from '@web3-react/core';
import bridgeABI from '../../../constants/ABI/bridge/Bridge.json';
import tokenABI from '../../../constants/ABI/bridge/Token.json';
import networksData from 'utilities/getNetworkData';
import { unitFormatter } from 'utilities';
import { round } from 'lodash';

const useStyles = makeStyles((theme) => ({
  toggle2: {
    width: '100%',
    '&>.MuiButtonBase-root': {
      width: '100%',
      height: '40px !important',
      textTransform: 'capitalize !important',
      border: '1px solid #673AB7 !important',
      color: '#673AB7 !important',
      borderRadius: 5,
      fontFamily: 'Inter !important',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    '&>.MuiButtonBase-root.Mui-selected': {
      backgroundColor: '#673AB7 !important',
      color: '#ffffff !important',
    },
  },
  Input: {
    width: '100%',
    '& .css-1480iag-MuiInputBase-root-MuiInput-root:before': {
      borderColor: '#673AB7 !important',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#673AB7',
    },
  },
  formTextInput: {
    fontSize: '14px !important',
  },
}));
interface IsBoosterContact {
  isLoading: boolean;
  handleBoost: (tokenTicker: string, index: string, noOfBoosters: number) => void;
  isBoosterTwo: boolean;
  handleDone: () => void;
  errorWallet: boolean;
  isConfetti: boolean;
  tokenTicker: string;
  lpIndex: string;
  isTokenApprove: boolean;
  handleApproveToken: (tokenAddress: string, price: string, alignment: number) => void;
}

interface BoosterConfig {
  tokenTicker: string;
  tokenName: string;
  tokenAddress: string;
  price: string;
  tokenImageUrl: string;
  // isTokenApprove: boolean;
}

const BoosterContent = ({
  isLoading,
  handleBoost,
  isBoosterTwo,
  handleDone,
  errorWallet,
  isConfetti,
  tokenTicker,
  lpIndex,
  isTokenApprove,
  handleApproveToken
}: IsBoosterContact) => {
  const [alignment, setAlignment] = React.useState(1);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();
  const handleChange2 = (event: React.MouseEvent<HTMLElement>, newAlignment: any) => {
    if(newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };
  const { chainId, library, account } = useWeb3React();
  const [boosterConfig, setBoosterConfig] = useState<BoosterConfig>();
  const [allowance, setAllowance] = useState<BigNumber>();

  useEffect(() => {
    async function getBoosterInfo() {
      try {
        let bridgeInstance = new ethers.Contract(bridgeAddress(chainId), bridgeABI.abi, library);
        // let bridgeInstanceWithSigner = await bridgeInstance.connect(library.getSigner(account));

        let boosterConfig = await bridgeInstance.boosterConfig();
        // console.log('BoosterConfig: ', ethers.utils.formatUnits(boosterConfig.price, 'wei'));

        let tokenInstance = new ethers.Contract(boosterConfig.tokenAddress, tokenABI.abi, library);
        let tokenName = await tokenInstance.name();
        let tokenTicker = await tokenInstance.symbol();
        // console.log('TokenName: ', tokenName);

        let config = {
          tokenTicker,
          tokenName,
          tokenAddress: boosterConfig.tokenAddress,
          price: boosterConfig.price.toString(),
          tokenImageUrl: boosterConfig.imageUrl
        };
        setBoosterConfig(config);

        const totalPrice = boosterConfig.price.mul(alignment);
        const allowance = await tokenInstance.allowance(account, bridgeAddress(chainId));
        setAllowance(allowance);
        // console.log("Total Price: ", totalPrice.toString(), allowance.toString(), allowance.lt(totalPrice));
        // console.log("allowance: ", allowance.toString(), allowance >= totalPrice);
        // if(allowance < totalPrice) 
      } catch (error) {
        console.log('Error in getBoosterInfo(): ', error);
      }
    }

    getBoosterInfo();
  }, []);

  const getBoosterTotalPrice = () => {
    if(boosterConfig?.price) {
      const totalPrice = ethers.BigNumber.from(boosterConfig?.price).mul(alignment);
      return round(unitFormatter(totalPrice, 18), 3);
    }
    else 
    return 0;
  }

  return (
    <>
      <Confetti width={mobile ? 335 : 530} height={400} run={isConfetti} recycle={false} numberOfPieces={400} />

      <TokenInfoWrapper>
        <SubToken>
          <TokenIcon2 src={boosterConfig?.tokenImageUrl} />
          <TokenTextDiv1>
            <TokenTitle>Reward Token</TokenTitle>
            <TokenName>{boosterConfig?.tokenName}</TokenName>
          </TokenTextDiv1>
        </SubToken>
        <TokenTextDiv>
          <TokenTitle>Network</TokenTitle>
          <TokenName>{networksData[chainId].name}</TokenName>
        </TokenTextDiv>
      </TokenInfoWrapper>

      <LiquidityWrapper>
        <NetworkWrapper>
          <BoosterWrapper style={{ background: '#F0EBF8' }}>
            <EarnDiv>
              <EarnText>Earn 1.5X Rewards with Booster</EarnText>
            </EarnDiv>
            <ToggleGroupDiv>
              <ToggleButtonGroup
                color="primary"
                value={alignment}
                exclusive
                onChange={handleChange2}
                className={classes.toggle2}
              >
                <ToggleButton value={1}>1 Month</ToggleButton>
                <ToggleButton value={3}>3 Months</ToggleButton>
                <ToggleButton value={6}>6 Months</ToggleButton>
              </ToggleButtonGroup>
            </ToggleGroupDiv>
            <BoosterPayWrapper>
              <InputIcon>
                <InputIconLabel>Pay With</InputIconLabel>
                <TextField
                  id="input-with-icon-textfield"
                  value={boosterConfig?.tokenTicker}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" style={{ borderRadius: 20 }}>
                        <img src={boosterConfig?.tokenImageUrl} width={30} alt="imgd" style={{ paddingBottom: '10px', paddingTop: '5px' }} />
                      </InputAdornment>
                    ),
                  }}
                  variant="standard"
                  className={classes.Input}
                />
              </InputIcon>
              <InputIcon2>
                <InputIconLabel>Buy for</InputIconLabel>
                <BuyForWrapper>
                  <img src={BoosterImage} />
                  {boosterConfig && getBoosterTotalPrice() + ' ' + boosterConfig?.tokenTicker}
                </BuyForWrapper>
              </InputIcon2>
            </BoosterPayWrapper>
          </BoosterWrapper>
          {errorWallet ? (
            <ErrorDiv>
              <ErrorOutlineIcon style={{ color: '#C62828' }} />
              Please approve the transaction from your wallet
            </ErrorDiv>
          ) : null}

          <ButttonWrapper>
            {isLoading ? (
              <StyledButton>
                <ClipLoader color="white" size={30} />
                {
                  isTokenApprove && (allowance && allowance.lt(BigNumber.from(boosterConfig.price).mul(alignment))) ?"Approve":"Buy Booster"
                }
                
              </StyledButton>
            ) : allowance && allowance.lt(BigNumber.from(boosterConfig.price).mul(alignment)) ? (
              <StyledButton onClick={() => handleApproveToken(boosterConfig.tokenAddress, boosterConfig.price, alignment)}>Approve</StyledButton>
            ) : isBoosterTwo === true ? (
              <StyledDoneButton onClick={handleDone}>Done</StyledDoneButton>
            ) : (
              <StyledButton onClick={() => handleBoost(tokenTicker, lpIndex, alignment)}>
                Buy Booster
              </StyledButton>
            )}
          </ButttonWrapper>
        </NetworkWrapper>
      </LiquidityWrapper>
    </>
  );
};
export default BoosterContent;

import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import styled from 'styled-components';

const Alertitle = styled(AlertTitle)`
  font-size: 18px !important;
  font-weight: 600 !important;
  font-family: 'Inter !important';
  font-style: normal !important;
  text-align: left;
  color: #C62828;
`;
const AleText = styled.div`
  font-size: 14px !important;
  font-weight: 400 !important;
  font-family: 'Inter';
  font-style: normal !important;
  color: #C62828;
  display: flex;
  justify-content: start;
`;

interface SnackbarProps {
  open: boolean;
  title: string;
  message: string;
  severity: 'error' | 'info' | 'success' | 'warning';
  handleClose: () => void;
}
const ErrorSnackbar = ({ open, severity, title, message, handleClose }: SnackbarProps) => {
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        autoHideDuration={500}
        onClose={handleClose}
        style={{
          top: 80,
          display: 'flex',
          justifyContent: 'start',
          border: '1px solid #C62828',
          borderRadius: '5px',
        }}
      >
        <Alert severity={severity}>
          <Alertitle>{title}</Alertitle>
          <AleText>{message}</AleText>
        </Alert>
      </Snackbar>
    </>
  );
};

export default ErrorSnackbar;

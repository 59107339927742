import { useCallback, useState } from 'react';

export enum ModalScreen {
  /** activate booster */
  ACTIVATE_BOOSTER,
  /** social share */
  SOCIAL_SHARE,
}

export const useModalState = (): [boolean, (isOpen: boolean) => void] => {
  // toggle the any modal state
  let [isOpen, setOpen] = useState<boolean>(false);
  // toggle modal state
  let toggleModal = useCallback((isOpen: boolean) => {
    setOpen(isOpen);
  }, []);
  return [isOpen, toggleModal];
};

export const useV2ModalScreen = (): [ModalScreen, (screen: ModalScreen) => void] => {
  // current screen
  let [screen, setScreen] = useState<ModalScreen>();
  // switch screne call back
  const switchScreen = useCallback((screen: ModalScreen) => {
    setScreen(screen);
  }, []);

  return [screen, switchScreen];
};

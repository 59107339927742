import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import TextField from '@mui/material/TextField';

const useStyle = makeStyles((theme) =>
  createStyles({
    root2: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      marginTop: '20px',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    text: {
      fontFamily: 'Inter',
      color: '#212121',
    },
    root: {
      marginTop: '5px',
      '&>.MuiButtonBase-root': {
        textTransform: 'capitalize !important',
        border: '1px solid #673AB7 !important',
        color: '#673AB7 !important',
        padding: '7px !important',
        borderRadius: 7,
        width: '100%',
        [theme.breakpoints.down('xs')]: {
          width: '100%',
        },
      },
      '&>.MuiButtonBase-root.Mui-selected': {
        backgroundColor: '#F5F0FF !important',
        color: '#673AB7 !important',
      },
    },
    Input: {
      width: '80%',
      '& .css-1480iag-MuiInputBase-root-MuiInput-root:before': {
        borderColor: '#673AB7 !important',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#673AB7',
      },
    },
  })
);

interface DurationProps {
  /** current duration */
  numberOfDays: number;
  /** change duration */
  changeNumberOfDays: (numberOfDays: number | '') => void;
}

const Duration = ({ numberOfDays, changeNumberOfDays }: DurationProps) => {
  // const [alignment, setAlignment] = React.useState('web');
  const classes = useStyle();
  const [inputDays, setInputDays] = React.useState<number | ''>(1);

  const handleInputDays = (days: string) => {
    // validate input only number and less than 365
    if (days.match(/^[0-9]*$/)) {
      setInputDays(Number(days));
      console.log(days);
      changeNumberOfDays(Number(days));
    } else {
      return;
    }
  };

  React.useEffect(() => {
    if (Number.isInteger(inputDays)) {
      changeNumberOfDays(inputDays);
    }
  }, [inputDays, changeNumberOfDays]);

  const handleChangeDaysValue = React.useCallback((newValue) => {
    if (newValue) {
      changeNumberOfDays(newValue);
    }
  }, []);

  return (
    <div className={classes.root2}>
      <span className={classes.text}>Stake For</span>
      <ToggleButtonGroup
        color="primary"
        value={numberOfDays}
        exclusive
        onChange={(event, newValue) => handleChangeDaysValue(newValue)}
        className={classes.root}
      >
        {/** toggles */}
        <ToggleButton value="30">30 Days</ToggleButton>
        <ToggleButton value="60">60 Days</ToggleButton>
        <ToggleButton value="90">90 Days</ToggleButton>

        {/** we take user input as well for custom number of days */}
        <ToggleButton value={inputDays}>
          <TextField
            id="standard-basic"
            placeholder="Enter Value"
            variant="standard"
            inputProps={{
              sx: {
                color: '#673AB7',
                '&::placeholder': {
                  color: '#673AB7 !important',
                  fontSize: '15px',
                },
              },
            }}
            value={inputDays}
            onChange={(e) => handleInputDays(e.target.value)}
            className={classes.Input}
          />
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};
export default Duration;

import { Button } from '@mui/material';
import React from 'react';
import { ClipLoader } from 'react-spinners';
import { DetailWrapper, MainWrapper, StyledButton2, TitleWrapper, Wrapper } from './AddLiquidityStyled';

interface IsProps {
  tokenIcone: string;
  permissionText: string;
  metamaskText: string;
  confirmTokenHandle: () => void;
  isAddLiquidity: boolean;
  isLoading: boolean;
}
const ConfirmToken = ({
  tokenIcone,
  permissionText,
  metamaskText,
  confirmTokenHandle,
  isAddLiquidity,
  isLoading,
}: IsProps) => {
  return (
    <MainWrapper>
      {tokenIcone && metamaskText ? (
        <Wrapper>
          <img src={tokenIcone} width={70} />
          <TitleWrapper>{permissionText}</TitleWrapper>
          <DetailWrapper>{metamaskText}</DetailWrapper>
        </Wrapper>
      ) : (
        'fisrt select your token'
      )}
      <>
        {isAddLiquidity ? (
          <>
            {isLoading ? (
              <StyledButton2 onClick={confirmTokenHandle}>
                <ClipLoader color="white" size={28} />
                Add Liquidity{' '}
              </StyledButton2>
            ) : (
              <StyledButton2 onClick={confirmTokenHandle}> Add Liquidity </StyledButton2>
            )}
          </>
        ) : (
          <>
            {isLoading ? (
              <StyledButton2 onClick={confirmTokenHandle}>
                <ClipLoader color="white" size={28} />
                Approve{' '}
              </StyledButton2>
            ) : (
              <StyledButton2 onClick={confirmTokenHandle}>Approve</StyledButton2>
            )}
          </>
        )}
      </>
    </MainWrapper>
  );
};
export default ConfirmToken;

import { ICall, IKeysValues } from '@makerdao/multicall';
import { COHORT_REGISTRYS, MAGIC_VALUES } from 'constants/V2';
import { BigNumber, ethers } from 'ethers';
import { add, concat, multiply, subtract, isEmpty } from 'lodash';
import { TokenListV2, TokenMetaDataV2 } from 'store/lists/reducer';
import { Farm, FarmData, FarmDetails, UserFarmData } from 'store/V2/farms/reducer';
import { roundValue, unitFormatter } from 'utilities';
import { getYF2GraphqlClient } from '../../graphql/V2';
import { getVestingGraphqlClient } from '../../graphql/Vesting';
import { v2FarmQuery } from '../../graphql/V2/queries';
import { vestingQuery } from '../../graphql/Vesting/queries';
import { Token, ReleaseHistory } from '../../graphql/V2/typings';
import { createCalls } from './multicall';
import { computeApy, decodeRewardTokens, getBlockDiffrence, getRewardTokensMetaData } from './reward';
import { multicall } from 'utilities/multicall';
import { orderBy } from 'lodash';
import { getIDOCLAIMGraphqlClient } from 'graphql/IdoClaim';
import { ClaimIDO } from 'graphql/IdoClaim/typings';
import { idoClaims } from 'graphql/IdoClaim/quries';

// per block time to be mined
const BLOCK_TIME: { [chainId: number]: number } = {
  1: 15,
  56: 3,
  137: 2.15,
};

export const getVestingDetails = async (chainId: number): Promise<ReleaseHistory[] | undefined> => {
  const client = getVestingGraphqlClient(chainId);
  if (!client) return null;
  const response = await client.query<{ history: ReleaseHistory[] }>({
    query: vestingQuery,
  });
  if (response.data) {
    return response.data.history as ReleaseHistory[];
  }
};

export const getYF2FarmDetails = async (chainId: number): Promise<Token[] | undefined> => {
  const client = getYF2GraphqlClient(chainId);
  if (!client) return null;
  const response = await client.query<{ tokens: Token[] }>({
    query: v2FarmQuery,
  });

  if (response.data) {
    const responseWithChainId = response.data.tokens.map((item) => {
      return {
        ...item,
        chainId,
      };
    });

    return responseWithChainId as Token[];
  }
};

export const getIdoClaimedData = async (chainId: number): Promise<ClaimIDO[] | undefined> => {
  const client = getIDOCLAIMGraphqlClient(chainId);
  if (!client) return null;
  const response = await client.query<{ claimedEntities: ClaimIDO[] }>({
    query: idoClaims,
  });

  if (response.data) {
    return response.data.claimedEntities as ClaimIDO[];
  }
};

export const formatFarmDetails = (
  /** farm token address */
  farmToken: string,
  /** tokenlist */
  tokenlist: TokenListV2,
  /** rewards */
  rewards: string,
  /** is boosters availbale */
  isBoosterAvailable: boolean,
  /** has liquidity mining */
  hasLiquidityMining: boolean,
  /** cohort version */
  cohortVersion: string,
  /** chain Id */
  chainId: number
): FarmDetails => {
  let farmName: string;
  let farmSymbol: string;
  let farmIcon: string[];
  let farmTokenPrice: number;
  let token0: TokenMetaDataV2;
  let token1: TokenMetaDataV2;

  // derive tokens
  let { tokens, lpTokens } = tokenlist || {};

  let dexAddLiquidityPage: string = '';
  if (hasLiquidityMining) {
    // farm token details
    let farmTokenDetails = lpTokens?.filter((e) => e.lpToken.toLowerCase() === farmToken.toLowerCase());
    if (!isEmpty(farmTokenDetails)) {
      let farmTokenMetaData = farmTokenDetails[0];
      let liquidityPoolTokens = farmTokenMetaData.tokens;
      let lpTokenMetaData = liquidityPoolTokens?.map((liquidityPoolToken) => {
        let lpToken = tokens?.filter((e) => e.address.toLowerCase() === liquidityPoolToken.toLowerCase());
        if (!isEmpty(lpToken)) {
          return lpToken[0];
        }
      });

      farmName = `${lpTokenMetaData?.[0]?.symbol}-${lpTokenMetaData?.[1]?.symbol}`;
      farmSymbol = farmName;
      farmIcon = lpTokenMetaData?.map((token) => token?.icon);
      farmTokenPrice = farmTokenMetaData?.pricePoints?.[cohortVersion];
      dexAddLiquidityPage = farmTokenDetails?.[0]?.dexAddLiquidityPage;
      // two additional fields
      token0 = lpTokenMetaData?.[0];
      token1 = lpTokenMetaData?.[1];
    }
  } // for normal one
  else {
    let farmTokenDetails = tokens?.filter((e) => e.address.toLowerCase() === farmToken.toLowerCase());
    let { name, icon, symbol, pricePoints } = farmTokenDetails?.[0] || {};
    farmName = name;
    farmSymbol = symbol;
    farmIcon = [icon];
    farmTokenPrice = pricePoints?.[cohortVersion];
  }

  // decode reward tokens
  let [rewardTokenAddress, pbrs] = decodeRewardTokens(rewards);

  let [rewardTokensMetaData, parsedPbrs] = getRewardTokensMetaData(tokens, rewardTokenAddress, pbrs);

  return {
    farmName,
    farmSymbol,
    farmIcon,
    farmTokenPrice,
    rewards: rewardTokensMetaData,
    rewardTokenAddress,
    perBlockRewards: parsedPbrs,
    isBoosterAvailable,
    chainId,
    token0,
    token1,
    dexAddLiquidityPage,
  };
};

export const computeCohortSalt = (cohortId: string, fid: number) => {
  return ethers.utils.defaultAbiCoder.encode(['address', 'uint256'], [cohortId, fid]).slice(0, 66);
};

export const createCallsForFarmData = (farms: Farm[], chainId: number) => {
  // collect calls
  let calls = [] as ICall[];

  for (var k = 0; k < farms.length; k++) {
    let { cohort, token, farmData, farmDetails } = farms[k];
    if (farmData !== undefined || farmDetails === undefined) {
      break;
    }
    let { startBlock, endBlock, epochBlocks } = cohort || {};

    if (isEmpty(farmData)) {
      // create calls for total staking
      calls.push(
        createCalls(cohort.id, 'totalStaking(uint32)(uint256)', [token.fid.toString()], [[concat(`TOTALSTAKING`, token.id)]])
      );
      // create calls for prior epoch tvls
      let endCheckpoint = roundValue(subtract(endBlock, startBlock) / epochBlocks, 0);

      // create calls for priorEpochATVL
      let k = 0;
      while (k < endCheckpoint) {
        calls.push(
          createCalls(
            cohort.id,
            'priorEpochATVL(uint32,uint256)(uint256)',
            [token.fid.toString(), k.toString()],
            [[concat(`PRIOR_EPOCH_ATVL`, token.fid.toString(), k.toString())]]
          )
        );
        k++;
      }

      // create calls for whole cohort lock
      calls.push(
        createCalls(
          COHORT_REGISTRYS[chainId],
          'wholeCohortLock(address)(bool)',
          [cohort.id],
          [[concat(`WHOLE_COHORT_LOCK`, cohort.id)]]
        )
      );

      // create calls for token lock status
      let m = 0;
      let salt = computeCohortSalt(cohort.id, token.fid);

      while (m < MAGIC_VALUES.length) {
        // create calls for locked cohort status if there any action which has been paused
        calls.push(
          createCalls(
            COHORT_REGISTRYS[chainId],
            'lockCohort(address,bytes4)(bool)',
            [cohort.id, MAGIC_VALUES[m]],
            [[concat(`LOCK_COHORT_`, cohort.id, MAGIC_VALUES[m])]]
          )
        );

        // create calls for token lock status
        calls.push(
          createCalls(
            COHORT_REGISTRYS[chainId],
            'tokenLockedStatus(bytes32,bytes4)(bool)',
            [salt, MAGIC_VALUES[m]],
            [[concat(`TOKEN_LOCK_STATUS`, salt, MAGIC_VALUES[m])]]
          )
        );
        m++;
      }
    }
  }
  return calls;
};

export const getTotalStakingUsd = (
  /** farm total staking  */
  totalStaking: number,
  /** farm token price */
  farmTokenPrice: number
): number => {
  return multiply(totalStaking, farmTokenPrice);
};

export const getPoolFilled = (
  /** farm active staking */
  activeStaking: number,
  /** farm total stake limit */
  farmTotalStakeLimit: number
) => {
  return roundValue(multiply(activeStaking / farmTotalStakeLimit, 100), 2);
};

export const hotFarmCreteria = (
  /** active total staking */
  activeTotalStaking: number
): boolean => {
  return activeTotalStaking > 40000;
};

export const getFarmEndTime = (
  /** deployAt */
  deployAt: number,
  /** endBlock */
  endBlock: number,
  /** start block */
  startBlock: number,
  /**chain Id  */
  chainId: number
): number => {
  if (!deployAt || !endBlock || !startBlock) return null;
  let blockDiffrence = getBlockDiffrence(endBlock, startBlock);
  let duration = multiply(blockDiffrence, BLOCK_TIME[chainId]);
  return add(deployAt, duration) as number;
};

export const formatFarmData = (
  /** farm data */
  farms: Farm[],
  /** block number when fetched */
  blockNumber: number,
  /** call results */
  callResults: IKeysValues,
  /** chain Id */
  chainId: number
): { farmData: FarmData }[] => {
  // push all the required data into this
  let farmsData = [] as { farmData: FarmData }[];

  // iterate loop here
  for (var k = 0; k < farms.length; k++) {
    let { token, cohort, farmDetails } = farms[k];

    let { endBlock, startBlock, epochBlocks, id, deployedAt } = cohort;
    let { fid, decimals, totalStakeLimit } = token;

    let decimal = parseInt(decimals);
    // format data
    let activeStaking: BigNumber = callResults[concat(`TOTALSTAKING,${token.id}`)];

    let farmActiveStaking = unitFormatter(activeStaking, decimal);

    let priorEpochTvls = [] as number[];

    let endCheckpoint = roundValue(subtract(endBlock, startBlock) / epochBlocks, 0);
    let p = 0;

    // loop though checkpoints
    while (p < endCheckpoint) {
      let priorEpochATVL = callResults[concat(`PRIOR_EPOCH_ATVL`, fid.toString(), p.toString())];
      priorEpochTvls.push(unitFormatter(priorEpochATVL, decimal));
      p++;
    }

    const salt = computeCohortSalt(id, fid);
    let [STAKE_MAGIC_VALUE, UNSTAKE_MAGIC_VALUE] = MAGIC_VALUES;

    // i know this is very core
    // for stake lock
    let wholeCohortLock = callResults[concat(`WHOLE_COHORT_LOCK`, cohort.id)] as boolean;
    let stakeLockStatus = callResults[concat(`LOCK_COHORT_`, cohort.id, STAKE_MAGIC_VALUE)] as boolean;
    let tokenStakeLockStatus = callResults[concat(`TOKEN_LOCK_STATUS`, salt, STAKE_MAGIC_VALUE)] as boolean;

    // unstake lock
    let unStakeLockStatus = callResults[concat(`LOCK_COHORT_`, cohort.id, UNSTAKE_MAGIC_VALUE)] as boolean;
    let tokenUnStakeLockStatus = callResults[concat(`TOKEN_LOCK_STATUS`, salt, UNSTAKE_MAGIC_VALUE)] as boolean;

    // caluclate farm end time
    let farmEndTime = getFarmEndTime(deployedAt, endBlock, startBlock, chainId);
    let blockDifference = getBlockDiffrence(cohort?.endBlock, cohort?.startBlock);
    let pbrs = farmDetails?.perBlockRewards;

    let farmTokenPrice = farmDetails.farmTokenPrice;
    let stakeLimitInUSD = token?.totalStakeLimit * farmTokenPrice;

    // active total staking
    let usdTotalStaking = getTotalStakingUsd(farmActiveStaking, farmTokenPrice);

    // reward
    let reward = computeApy(
      farmDetails?.rewards,
      pbrs,
      blockDifference,
      stakeLimitInUSD,
      usdTotalStaking,
      BLOCK_TIME[chainId],
      cohort?.cohortVersion
    );

    farmsData.push({
      farmData: {
        farmId: token?.id,
        activeStaking: farmActiveStaking,
        priorEpochTvls,
        usdTotalStaking,
        poolFilled: getPoolFilled(farmActiveStaking, totalStakeLimit),
        totalStakeLimit,
        isHotFarm: undefined,
        farmEndTime,
        isFarmEnds: blockNumber > endBlock,
        hasStakeLocked: wholeCohortLock || stakeLockStatus || tokenStakeLockStatus,
        hasUnstakeLocked: wholeCohortLock || unStakeLockStatus || tokenUnStakeLockStatus,
        fetchedAtBlockNumber: blockNumber,
        apy: roundValue(reward?.apy, 2),
        boostUptoAPY: roundValue(reward?.boostedAPY, 2),
        hasLiquidityMiningAvailable: cohort?.hasLiquidityMining,
      },
    });
  }

  let yieldFarmsOnly = farmsData?.filter((e) => e.farmData.hasLiquidityMiningAvailable === false);
  let lpFarmsOnly = farmsData?.filter((e) => e.farmData.hasLiquidityMiningAvailable === true);

  // for yield farms
  let top3TvlYieldFarms = orderBy(yieldFarmsOnly, ['farmData.poolFilled'], ['desc']) as {
    farmData: FarmData;
  }[];
  let top3MostYieldApyFarms = orderBy(yieldFarmsOnly, ['farmData.apy'], ['desc'])?.slice(0, 3) as {
    farmData: FarmData;
  }[];

  top3TvlYieldFarms = top3TvlYieldFarms?.filter((e) => e.farmData.poolFilled > 60)?.slice(0, 3);

  // for lp farms
  let top3TvlLiquidityFarms = orderBy(lpFarmsOnly, ['farmData.poolFilled'], ['desc']) as {
    farmData: FarmData;
  }[];
  let top3MostLiquidityApyFarms = orderBy(lpFarmsOnly, ['farmData.apy'], ['desc'])?.slice(0, 3) as {
    farmData: FarmData;
  }[];

  top3TvlLiquidityFarms = top3TvlLiquidityFarms?.filter((e) => e.farmData.poolFilled > 60)?.slice(0, 3);

  let hotFarms = top3TvlYieldFarms
    ?.concat(top3MostYieldApyFarms)
    ?.concat(top3TvlLiquidityFarms)
    ?.concat(top3MostLiquidityApyFarms);

  return farmsData?.map(({ farmData }) => {
    // filter hot farm by farm end time
    let { farmId } = farmData;
    // hot farm
    let isHotFarm = hotFarms?.filter((e) => e.farmData.farmId.toLowerCase() === farmId.toLowerCase());

    return {
      farmData: {
        ...farmData,
        isHotFarm: !isEmpty(isHotFarm) ? true : false,
      },
    };
  });
};

export const getUserFarmPublicData = async (farms: Farm[], account: string, chainId: number): Promise<UserFarmData[]> => {
  // user farm data
  let userFarmPublicData = [] as UserFarmData[];

  if (!isEmpty(farms)) {
    let calls = [] as ICall[];
    // create calls
    for (let k = 0; k < farms.length; k++) {
      let { token, cohort, userFarmData } = farms[k];
      if (!isEmpty(userFarmData)) {
        return null;
      }
      // calls for balance Of
      calls.push(
        createCalls(
          token.farmToken,
          'balanceOf(address)(uint256)',
          [account],
          [[`balanceOf_${token?.farmToken.toLowerCase()}`]]
        )
      );
      calls.push(
        createCalls(
          cohort?.id,
          'userTotalStaking(address,uint256)(uint256)',
          [account, token.fid.toString()],
          [[`${token.fid}_userTotalStaking`]]
        )
      );
    }
    let callResults = await multicall(chainId, calls);
    let originalResults = callResults.results.original;
    // loop again
    for (let m = 0; m < farms.length; m++) {
      let { token } = farms[m];
      let userFarmTokenBalance = originalResults[`balanceOf_${token?.farmToken?.toLowerCase()}`];
      let userFarmTotalStaking = originalResults[`${token.fid}_userTotalStaking`];
      userFarmPublicData.push({
        userFarmTokenBalance: unitFormatter(userFarmTokenBalance, parseInt(token?.decimals)),
        userFarmTotalStaking: unitFormatter(userFarmTotalStaking, parseInt(token?.decimals)),
      });
    }
  }
  return userFarmPublicData;
};

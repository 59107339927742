import { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { OffCanvas, OffCanvasMenu, OffCanvasBody } from 'react-offcanvas';

const CanvasMenu = styled.div`
  height: 100vh;
  width: 350px;
  background-color: #f7f7f7;
  overflow-y: auto;
  box-shadow: 4px 4px 25px #e0e0e0;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Version = styled.p`
  font-size: 16px;
  margin: 0px;
  color: black;
  cursor: pointer;
  padding-left: 1rem;
  @media (max-width: 768px) {
    font-size: 14px;
    padding: 0;
    margin-bottom: '10px';
  }
`;

const VersionCircle = styled.div`
  width: 45px;
  height: 45px;
  margin-top: 4px;
  border-radius: 50px;
  background: #673ab7;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 5px;
`;

const FeedBackButton = styled.a`
  width: 100%;
  height: 38px;
  border: 1px solid #673ab7;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  line-height: 15px;
  margin-bottom: 10px;
  color: #673ab7;
  text-decoration: none;
  text-transform: capitalize;
  background: #ffffff;
  &: hover {
    background: #6338bc;
    color: #ffffff;
  }
`;
interface SideBarProps {
  SidebarToggle?: boolean;
}

export const VersionSideBar = ({ SidebarToggle }: SideBarProps) => {
  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const [releaseNotes, setReleaseNotes] = useState<number[]>([]);
  const getData = async () => {
    const response = await axios({
      method: 'GET',
      url: 'https://dev.my.oropocket.com/releasenotesapi/retrieve.php',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      },
    });

    console.log({ response });

    let res = response.data as any;
    console.log({ res });

    let getReverseData = res.data.reverse();
    console.log(getReverseData);

    // setReleaseNotes(getReverseData);
  };

  // useEffect(() => {
  //   getData();
  //   try {
  //     fetch('https://dev.my.oropocket.com/releasenotesapi/retrieve.php', { mode: 'cors' })
  //       .then((res) => res.json())
  //       .then((res) => setReleaseNotes(res.data?.reverse()));
  //   } catch (err) {
  //     console.log(err);
  //   }
  //   console.log('update APi live--------------------------------');
  // }, []);

  return (
    <>
      <OffCanvas width={350} transitionDuration={300} effect={'parallax'} isMenuOpened={isMenuOpened} position={'right'}>
        <OffCanvasBody>
          <Version
            style={{ position: 'fixed', bottom: '-15px' }}
            onClick={() => {
              setIsMenuOpened(!isMenuOpened);
            }}
          >
            {SidebarToggle ? <span>V2.0</span> : <span>Version 2.0</span>}
          </Version>
        </OffCanvasBody>

        <OffCanvasMenu>
          <CanvasMenu>
            <div style={{ padding: '10px 20px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', color: 'black' }}>
                <div>
                  <p style={{ fontWeight: 'bold' }}>Version History</p>
                </div>
                <div>
                  <p style={{ cursor: 'pointer', fontWeight: 'bold' }} onClick={() => setIsMenuOpened(!isMenuOpened)}>
                    X
                  </p>
                </div>
              </div>

              {/* <div>
                <VersionCircle>
                  <span>v 2.0</span>
                </VersionCircle>

                <div>
                  <p style={{ color: 'black' }}>Version Number</p>
                </div>
              </div> */}

              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div>
                  <p style={{ color: 'black' }}>Release Notes:</p>
                </div>

                <div style={{ whiteSpace: 'normal', color: '#616161' }}>
                  {releaseNotes?.map((notes, ind) => {
                    let numeric = ind + 1;
                    return (
                      <div key={ind}>
                        <VersionCircle>
                          <span>v.{notes['version']}</span>
                        </VersionCircle>
                        {/* <div> */}

                        <div style={{ display: 'flex', justifyContent: 'start', alignItems: 'baseline', margin: '10px 0' }}>
                          <div dangerouslySetInnerHTML={{ __html: notes['notes'] }} />
                        </div>
                        {/* <div style={{marginTop:'5px',marginBottom:'5px'}}> */}
                        <FeedBackButton data-nolt="button" target="_blank" href="https://unifarm.nolt.io/">
                          Let us know your thoughts
                        </FeedBackButton>
                        {/* </div> */}
                      </div>
                    );
                  })}
                </div>
              </div>

              {/* <span style={{ marginRight: '8px' }}>{numeric}.</span> */}
              {/* <div>
                <FeedBackButton data-nolt="button" href="https://unifarm.nolt.io/">
                  Let us know your thoughts
                </FeedBackButton>
              </div> */}
            </div>
          </CanvasMenu>
        </OffCanvasMenu>
      </OffCanvas>
    </>
  );
};

import { binance, injected, walletconnect, coinbaseWallet } from '../connectors';
import { AbstractConnector } from '@web3-react/abstract-connector';
import MetaMaskIcon from '../assets/images/connectors/meta2.svg';
import WalletConnectLogo from '../assets/images/connectors/wallet2.svg';
import BitkeepLogo from '../assets/images/connectors/bitkeep.png';
import BSCWalletLogo from '../assets/images/connectors/binance-wallet.jpg';
import WalletLinkLogo from '../assets/images/connectors/coinbasewallet.png';

interface Wallet {
  [connetorName: string]: {
    name: string;
    logoUri: string;
    connector: AbstractConnector;
    isMobileSupported: boolean;
    depiction: string;
  };
}

interface Hotpool {
  chainId: number;
  tokenId: string;
  cohortId: string;
}

export const wallets: Wallet = {
  METAMASK: {
    name: 'Metamask',
    logoUri: MetaMaskIcon,
    connector: injected,
    isMobileSupported: false,
    depiction: 'Chrome Extension Trusted By 10 Million Users.',
  },
  WALLETCONNECT: {
    name: 'Wallet Connect',
    logoUri: WalletConnectLogo,
    connector: walletconnect,
    isMobileSupported: true,
    depiction: 'Using Trust Wallet & Rainbow Wallet',
  },
  COINBASE: {
    name: 'Coinbase Wallet',
    logoUri: WalletLinkLogo,
    connector: coinbaseWallet,
    isMobileSupported: true,
    depiction: 'Using Trust Wallet & Rainbow Wallet',
  },
  // BITKEEP: {
  //   name: 'Bitkeep',
  //   logoUri: BitkeepLogo,
  //   connector: injected,
  //   isMobileSupported: true,
  //   depiction: 'Using Trust Wallet & Rainbow Wallet',
  // },
  BSC: {
    name: 'Binance Wallet',
    logoUri: BSCWalletLogo,
    connector: binance,
    isMobileSupported: false,
    depiction: 'Using Binance Wallet',
  },
};

export const COINGECOKO_BASE_URI: string = 'https://api.coingecko.com/api/v3/simple/token_price/ethereum';

export const unifarmAddress = {
  1: '0x40986a85b4cfcdb054a6cbfb1210194fee51af88',
  56: '0x0A356f512f6fCE740111ee04Ab1699017a908680',
  137: '0xa7305ae84519ff8be02484cda45834c4e7d13dd6',
  43114: '0xd60effed653f3f1b69047f2d2dc4e808a548767b',
};

export const POOL_FETCHED_LIMIT = 30;

export const Theme: string = 'red';

export const ButtonColor: string = 'Blue';

export const UNIFARM_MAINNET: string = '0x40986a85b4cfcdb054a6cbfb1210194fee51af88';

export const DEFAULT_APP_CHAIN: number = 1;

export const DEFAULT_STAKE_USD_AMOUNT: number = 100;

export const STARTFI_COHORT: string = '0x0000000000000000000000000000000000000000';

export const DEFAULT_REFFERAL_ADDRESS: string = '0x0000000000000000000000000000000000000000';

export const ZERO_ADDRESS: string = '0x0000000000000000000000000000000000000000';

export const GAS_MARGIN = 20;

export const YEAR = 360;

export const V1: string = '0xd426037bCf28fc4eA3730525Ff8B3d2DE412257d';
export const V2: string = '0x207C678457617bC8c8Ab06F9088EFC1DCD45887c';
export const V3: string = '0x349d55f12fb166a926214ca0195a07a16fa4ccb1';
export const LAUNCH: string = '0xef3dB88CdaE6627Ef706962c7a83C1A29be52711';

export const blockedPools: string[] = [
  '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
  '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
  '0xe9e7cea3dedca5984780bafc599bd69add087d56',
  '0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6',
  '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  '0xa0f5505dC06eBE8Ee8CbdC2059eaDE0b9F35cbC2',
  '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
];

export const hotpools: Hotpool[] = [
  {
    chainId: 1,
    tokenId: '0x40986a85B4cFCDb054A6CBFB1210194fee51af88',
    cohortId: '0xE7058bF41230df7211a28958d517060d7d56BAF5',
  },
  {
    chainId: 1,
    tokenId: '0xa0f5505dC06eBE8Ee8CbdC2059eaDE0b9F35cbC2',
    cohortId: '0xE7058bF41230df7211a28958d517060d7d56BAF5',
  },
  {
    chainId: 1,
    tokenId: '0x6bd361e10c1afed0d95259e7C0115f3A60e4ea99',
    cohortId: '0xE7058bF41230df7211a28958d517060d7d56BAF5',
  },
  {
    chainId: 1,
    tokenId: '0x3873965e73d9A21F88e645ce40B7db187FDE4931',
    cohortId: '0xE7058bF41230df7211a28958d517060d7d56BAF5',
  },
  {
    chainId: 56,
    tokenId: '0x0A356f512f6fCE740111ee04Ab1699017a908680',
    cohortId: '0x02c57D740836A0706d48bF0EfD9Af214dFbB3d46',
  },
  {
    chainId: 56,
    tokenId: '0xdBB66Eb9f4D737B49aE5CD4De25E6C8da8B034f9',
    cohortId: '0x02c57D740836A0706d48bF0EfD9Af214dFbB3d46',
  },
  {
    chainId: 56,
    tokenId: '0x73afC23510b40dcbEABc25fFBc8C2976eD9f950c',
    cohortId: '0x02c57D740836A0706d48bF0EfD9Af214dFbB3d46',
  },
  {
    chainId: 56,
    tokenId: '0x9108c36DC1DCbf08187D4f4D4579D72e6A35d979',
    cohortId: '0x02c57D740836A0706d48bF0EfD9Af214dFbB3d46',
  },
];

interface LaunchPad {
  [chainId: number]: string[];
}

export const launchpads: LaunchPad = {
  56: ['0xE23dD304C325Cae4A0FB3bA368363d2c620C0de7'],
};

export const DEFAULT_TXN_DEADLINE = 30;

export const MatchUnifarmAddress = {
  //Staging Address

  3: '0xf7745d2e7fde51c542568f718457d983f761e8c3',
  4: '0xf7745d2e7fde51c542568f718457d983f761e8c3',
  97: '0x19e90b5888c44f0bf50f19b2842a8937c78c9688',
  80001: '0xa1C5949Ec64b4d81b2be69356Beafe529f774fe5',
  43113: '0x4eBf6Ed5dacfa34Bee0db8f99dd9bbee62597e5a',

  //Production Address

  1: '0x40986a85b4cfcdb054a6cbfb1210194fee51af88',
  56: '0x0a356f512f6fce740111ee04ab1699017a908680',
  137: '0xa7305ae84519ff8be02484cda45834c4e7d13dd6',
  43114: '0xd60effed653f3f1b69047f2d2dc4e808a548767b',
};

export const claimRewardContracts: { [chainId: number]: string } = {
  56: '0x5aa5e0c7731e7d086d4272e2e73606a0f92e74d2',
};

export const chainIdToCoin: { [chainId: number]: string } = {
  1: 'ETH',
  56: 'BNB',
  137: 'MATIC',
  43114: 'AVAX',
};

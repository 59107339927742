import React from 'react';
import styled from 'styled-components';
import IconButton from '@mui/material/IconButton';
import Boost from '../../assets/V2/Images/Rocket_New.png';
import { Button } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import {
  ButtonDiv,
  DetailsDiv,
  LiquidityButton,
  LiquidityDiv,
  NetworksDiv,
  TokenDiv,
  TokenIcon,
  TokenName,
  ViewDetails,
} from './SetuPoolStyle';
import { ethers } from 'ethers';
import BigNumber from 'bignumber.js';
import { unitFormatter } from 'utilities';
import { round } from 'lodash';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import { useWeb3React } from '@web3-react/core';
import { useChangeAppChainId } from 'store/user/hooks';
import networksData from 'utilities/getNetworkData';
interface NetworkKey {
  name: string;
}
interface Props {
  icon: string;
  tokenTicker: string;
  tokenImage: string;
  index: string;
  rewards: string;
  liquidityValue: string;
  decimals: number;
  HandleChange: () => void;
  buttonvalue: (ticker: string, index: string) => void;
  isBoostIcon: boolean;
  network: number;
  isLoading: boolean;
  address: string;
  isActive: boolean;
}

const MyTokenLiquidity = ({
  icon,
  tokenTicker,
  tokenImage,
  index,
  liquidityValue,
  decimals,
  HandleChange,
  buttonvalue,
  isBoostIcon,
  network,
  rewards,
  isLoading,
  address,
  isActive,
}: Props) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { chainId } = useWeb3React();
  const changeAppNetwork = useChangeAppChainId();

  const onBuyBooster = async () => {
    if (network !== chainId) await changeAppNetwork(network);
    else HandleChange();
  };

  const onViewDetails = async () => {
    if (network !== chainId) await changeAppNetwork(network);
    else buttonvalue(tokenTicker, index);
  };

  return (
    <>
      {isLoading ? (
        <DetailsDiv isActive={!isActive}>
          <TokenDiv>
            <Skeleton variant="circular" width={25} height={25} />
            <TokenName>
              <Skeleton variant="text" width={50} />

              <Skeleton variant="text" width={50} />
            </TokenName>
          </TokenDiv>
          <NetworksDiv>
            <Skeleton variant="circular" width={25} height={25} />
          </NetworksDiv>
          <LiquidityDiv>
            <Skeleton variant="text" width={100} />
          </LiquidityDiv>
          <ViewDetails>
            <IconButton onClick={HandleChange}>
              <ButtonDiv isActive={!isActive}>Buy Booster</ButtonDiv>
            </IconButton>
          </ViewDetails>
          <LiquidityButton onClick={() => buttonvalue(tokenTicker, index)}>View Details</LiquidityButton>
        </DetailsDiv>
      ) : (
        <DetailsDiv isActive={!isActive}>
          <TokenDiv>
            <TokenIcon src={tokenImage} alt="Token" />
            <TokenName>
              {round(unitFormatter(liquidityValue, 18), 3)}
              <br />
              {tokenTicker}
            </TokenName>
          </TokenDiv>
          <NetworksDiv>
            <Tippy theme="light" placement="top" content={networksData[network].name} key={index}>
              <TokenIcon src={icon} alt="Token" />
            </Tippy>
          </NetworksDiv>
          <LiquidityDiv>
            {round(rewards, 3)}
            {isBoostIcon ? <img src={Boost} /> : null}
          </LiquidityDiv>
          <ViewDetails>
            <ButtonDiv disabled={!isActive} onClick={onBuyBooster} style={{ padding: 0 }} isActive={!isActive}>
              Buy Booster
            </ButtonDiv>
          </ViewDetails>
          {mobile ? (
            <IconButton onClick={onViewDetails}>
              <ChevronRightIcon />
            </IconButton>
          ) : (
            <LiquidityButton onClick={onViewDetails}>View Details</LiquidityButton>
          )}
        </DetailsDiv>
      )}
    </>
  );
};

export default MyTokenLiquidity;

import { Button } from '@material-ui/core';
import styled from 'styled-components';

export const OnBoardRoot = styled.div``;
export const CloseRoot = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  color: #616161;
  column-gap: 3px;
  &:hover {
    cursor: pointer;
  }
`;
export const MetamaskRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const HeadingText = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 1.5rem;
`;
export const DetailRoot = styled.div`
  margin-top: 4rem;
`;
export const MetamaskCardRoot = styled.div`
  width: 700px;
  height: 140px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  display: flex;
  align-items: center;
  column-gap: 40px;
  padding-left: 2.5rem;
  margin-bottom: 1rem;
  &:hover {
    cursor: pointer;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    box-shadow: 4px 4px 25px #e0e0e0;
    border-radius: 10px;
  }
  @media(max-width:430px){
    width:100%;
    height:auto;
    flex-direction: column;
    padding-top:1.5rem;
    padding-bottom:1.5rem;
  }
`;
export const MetamskImageWrap = styled.div``;
export const MetaskContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  row-gap: 23px;
  @media(max-width:430px){
    row-gap:9px;
  }
`;
export const MetamaskTitle = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #616161;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  @media(max-width:430px){
    justify-content: center;
  }
`;
export const MetamaskContent = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #616161;
`;
export const StepscreenRoot = styled.div`
  height: 29rem;
  display: flex;
  align-items: center;
  @media(max-width:430px){
    height:auto;
  }
`;
export const BackButton = styled(Button)`
  border: 1px solid #673ab7;
  border-radius: 10px;
  width: 200px;
  height: 55px;
  color: #673ab7;
  text-transform: capitalize;
  font-weight: 600;
`;
export const StepButton = styled(Button)`
  background: #673ab7;
  box-shadow: 0px 7px 18px -2px rgba(103, 58, 183, 0.56);
  border-radius: 10px;
  width: 200px;
  height: 55px;
  color: white;
  text-transform: capitalize;
  font-weight: 600;
  &:hover {
    background: #673ab7;
  }
`;
export const StepButtonRoot = styled.div`
display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left:5.5rem;
    padding-right:5.5rem;
    @media(max-width:430px){
      flex-direction: column;
    padding: 0;
    row-gap:15px;
    }
}
`;
export const StepContentRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 14px;
  justify-content: center;
  width: 100%;
  @media(max-width:430px){
    margin-top:1.5rem;
    margin-bottom:1.5rem;
  }
`;
export const ContentHead = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 19px;
  color: #212121;
  @media(max-width:430px){
    font-size:17px;
  }
`;
export const Content = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #212121;
  width: 868px;
  @media(max-width:430px){
    width:100%;
  }
`;
export const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 25px;
`;
export const ImageArrow=styled.img`
@media(max-width:430px){
  display:none;
}
`
import React from 'react';
import {
  SubTokenDetail,
  TokenDetailIcon,
  TokenDetailInfoWrapper,
  TokenDetailName,
  TokenDetailTextDiv1,
  TokenDetilTitle,
} from './AddLiquidityStyled';

interface Props {
  icon: string;
  tokenName: string;
  ticker: string;
}

const TokenDetails = ({ icon, tokenName, ticker }: Props) => {
  return (
    <>
      <TokenDetailInfoWrapper>
        <SubTokenDetail>
          <TokenDetailIcon src={icon} />
          <TokenDetailTextDiv1>
            <TokenDetilTitle>{tokenName}</TokenDetilTitle>
            <TokenDetailName>{ticker}</TokenDetailName>
          </TokenDetailTextDiv1>
        </SubTokenDetail>
      </TokenDetailInfoWrapper>
    </>
  );
};

export default TokenDetails;

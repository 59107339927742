import { useMemo } from 'react';
import { Contract } from '@ethersproject/contracts';
import CohortABI from '../constants/ABI/cohorts/Cohort.json';
import TokenABI from '../constants/ABI/ERC20.json';
import RouterABI from '../constants/ABI/UNIFARMROUTER.json';
import { useApplicationUserState } from '../store/user/hooks';
import { unifarmAddress } from '../constants';
import { NFT_MANAGER_ADDRESS } from '../constants/V2';
import UNIFARM_CONTRACT_ABI from '../constants/ABI/UNIFARM.json';
import IDO_ABI from '../constants/ABI/IDO.json';
import NFTMANAGER_ABI from '../constants/ABI/UNIFARMNFTMANAGER.json';
import claimABI from '../constants/ABI/CLAIM.json';
import ENS_ABI from '../constants/ABI/ens-registrar.json';
import ENS_PUBLIC_RESOLVER_ABI from '../constants/ABI/ens-public-resolver.json';
import Vesting_ABI from '../constants/ABI/Vesting.json';
import beneBook_ABI from '../constants/ABI/BeneBook.json';

import { getContract } from 'utilities';
import { useWeb3React } from '@web3-react/core';

export const useContract = (ABI, address: string) => {
  const { library } = useWeb3React();
  const { appChainId } = useApplicationUserState();
  return useMemo(() => {
    if (!address || !library || !appChainId) return null;
    return new Contract(address, ABI, library);
  }, [ABI, address, library, appChainId]);
};

export const useUnifarmTokenContract = () => {
  const { appChainId } = useApplicationUserState();
  const unifarm = unifarmAddress[appChainId];
  return useContract(UNIFARM_CONTRACT_ABI, unifarm);
};

export const useCohortContract = (cohortId: string) => {
  return useContract(CohortABI, cohortId);
};

export const useTokenContract = (address: string) => {
  return useContract(TokenABI, address);
};

export const useRouterContract = (address: string) => {
  return useContract(RouterABI, address);
};

export const useIdoContract = (address: string) => {
  return useContract(IDO_ABI, address);
};

export const useFlipContract = (flipContract: string) => {
  return useContract(claimABI, flipContract);
};

export const useVestingContract = (address: string) => {
  return useContract(Vesting_ABI, address);
};

export const useBeneficiaryBookContract = (address: string) => {
  return useContract(beneBook_ABI, address);
};

export const useNFTManagerContract = () => {
  const { appChainId } = useApplicationUserState();
  const unifarmNftManager = NFT_MANAGER_ADDRESS[appChainId];
  return useContract(NFTMANAGER_ABI, unifarmNftManager);
};

export function useENSRegistrarContract(): Contract | null {
  const { chainId } = useWeb3React();
  if (chainId !== 1) return null;
  let address: string | undefined;
  if (chainId) {
    switch (chainId) {
      case 1:
        address = '0x314159265dd8dbb310642f98f50c066173c1259b';
        break;
      // case 5:
      // case 3:
      // case 4:
      //   address = '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e';
      //   break;
    }
  }
  return useContract(ENS_ABI, address);
}

export function useENSResolverContract(address: string | undefined): Contract | null {
  return useContract(ENS_PUBLIC_RESOLVER_ABI, address);
}

// returns null on errors
export function useMultipleContracts(
  addresses: string[] | undefined,
  ABI: any,
  withSignerIfPossible = true
): {
  [key: string]: Contract;
} | null {
  const { library, account } = useWeb3React();

  return useMemo(() => {
    if (!addresses || !Array.isArray(addresses) || addresses.length === 0 || !ABI || !library) return null;

    const result: {
      [key: string]: Contract;
    } = {};

    try {
      addresses.forEach((address) => {
        if (address) {
          result[address] = getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined);
        }
      });

      if (Object.keys(result).length > 0) {
        return result;
      }

      return null;
    } catch (error) {
      console.error('Failed to get contract', error);

      return null;
    }
  }, [addresses, ABI, library, withSignerIfPossible, account]);
}

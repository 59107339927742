import { ApolloClient, gql, InMemoryCache, useQuery } from '@apollo/client';

export const graphSupportedChains = [1, 10, 56, 137, 250, 43114];

const getSetuGraphBaseUri = (chainId: number) => {
    switch (chainId) {
        case 1:
            return "https://api.thegraph.com/subgraphs/name/rajatlko13/bridge-mainnet";
        case 10:
            return "https://api.thegraph.com/subgraphs/name/rajatlko13/bridge-optimism";
        case 56:
            return "https://api.thegraph.com/subgraphs/name/rajatlko13/bridge-bsc";
        case 97:
            return "";
        case 137:
            return "https://api.thegraph.com/subgraphs/name/rajatlko13/bridge-polygon";
        case 250:
            return "https://api.thegraph.com/subgraphs/name/rajatlko13/bridge-fantom";
        case 42161:
            return "https://api.thegraph.com/subgraphs/name/rajatlko13/bridge-arbitrum";
        case 43114:
            return "https://api.thegraph.com/subgraphs/name/rajatlko13/bridge-avalanche";
        case 80001:
            return "https://api.thegraph.com/subgraphs/name/rajatlko13/bridgetest";
        default:
            return null;
    }
}

export const getGraphqlClient = (chainId: number) => {
    const client = new ApolloClient({
        uri: getSetuGraphBaseUri(chainId),
        cache: new InMemoryCache(),
    });
    return client;
}

export const LP_POSITIONS = gql`
    query Positions($account: Bytes!) {
        liquidityPositions(where: { account: $account }) {
            id
            index
            account
            tokenTicker
            noOfTokens
            status
            addTimestamp
            removeTimestamp
        }
    }
`;

export const ACTIVE_LP_POSITIONS = gql`
    query Positions($account: Bytes!) {
        liquidityPositions(where: { 
            account: $account,
            status: 1
        }) {
            id
            index
            account
            tokenTicker
            noOfTokens
            status
            addTimestamp
            removeTimestamp
        }
    }
`;

export const USER_LP_POSITION = gql`
    query Positions($account: Bytes!, $tokenTicker: String!, $index: BigInt!) {
        liquidityPositions(where: { 
            account: $account,
            tokenTicker: $tokenTicker,
            index: $index
        }) {
            id
            index
            account
            tokenTicker
            noOfTokens
            status
            addTimestamp
            removeTimestamp
        }
    }
`;

export const POSITION_REWARD_HISTORY = gql`
    query RewardHistory($account: Bytes!, $tokenTicker: String!, $index: BigInt!) {
        claimedRewards(where: { 
            account: $account,
            tokenTicker: $tokenTicker,
            index: $index
        }) {
            index
            account
            tokenTicker
            noOfTokens
            claimTimestamp
            txHash
        }
    }
`;
import styled from 'styled-components';
import Button from '@mui/material/Button';
const TransferButton = styled(Button)`
  width: 100%;
  height: 55px;
  display: flex !important;
  align-items: center !important;
  box-shadow: 0px 7px 18px -2px rgba(103, 58, 183, 0.56);
  color: #ffffff !important;
  border-radius: 10px !important;
  font-weight: 600 !important;
  font-size: 15px !important;
  line-height: 26px !important;
  text-transform: none !important;
  padding: 0px !important;
  margin-top: 15px !important;
`;

const RootCard = styled.div`
  padding-top: 25px;
  padding-bottom: 25px;
  @media (max-width: 430px) {
    padding-top: 0.5rem;
    padding-bottom: 1rem;
  }
`;
const IconImage = styled.img`
  width: 53px;
  height: 53px;
  @media (max-width: 430px) {
    width: 40px;
    height: 40px;
  }
`;
const TokenDetails = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
  @media (max-width: 430px) {
    margin-top: 0;
  }
`;
const TokenValue = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #212121;
  line-height: 19px;
  color: #212121;
  margin-top: 1rem;
  @media (max-width: 430px) {
    font-size: 14px;
    margin-top: 1rem;
  }
`;
const TokenLeftValue = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #212121;
  line-height: 19px;
  color: #212121;
  margin-top: 1rem;

  @media (max-width: 430px) {
    font-size: 14px;
    margin-top: 1rem;
  }
`;
const TransferProcess = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 30px;
  @media (max-width: 430px) {
    margin-top: 1rem;
    padding-left: 0px;
    padding-right: 0px;
  }
`;
const TokenProcessDiv = styled.div`
  width: 185px;
  height: 50px;
  display: flex;
  align-items: center;
  background: #efededdb;
  border-radius: 5px;
  padding-left: 10px;
  gap: 10px;
  @media (max-width: 430px) {
    width: 125px;
    height: 50px;
    gap:8px;
  }
`;
const TokenProcessedDiv = styled.div`
  width: 185px;
  height: 50px;
  display: flex;
  align-items: center;
  background: rgba(240, 235, 248, 1);
  border-radius: 5px;
  padding-left: 10px;
  gap: 10px;
  @media (max-width: 430px) {
    width: 125px;
    height: 50px;
    gap:8px;
  }
`;
const ProcessTokenIcon = styled.img`
  width: 25px;
  height: 25px;
  @media (max-width: 430px) {
    width: 1rem;
    height: 1rem;
  }
`;
const ProcessTokenName = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #673ab7;
  @media (max-width: 430px) {
    font-size: 11px;
  }
`;
const ProcessDetails = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #212121;
  margin-top: 30px;
  padding-left: 150px;
  padding-right: 150px;
  @media (max-width: 430px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;
const newLocal = styled(Button)`
  width: 75%;
  height: 70px;
  display: flex !important;
  align-items: center !important;
  border-radius: 10px !important;
  font-weight: 600 !important;
  font-size: 17px !important;
  line-height: 24px !important;
  text-transform: none !important;
  padding: 0px !important;
  margin-top: 25px !important;
  gap: 10px !important;
  @media (max-width: 430px) {
    height: 45px;
    font-size: 14px !important;
  }
`;
const WalletChangeText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #212121;
  margin-top: 30px;
  @media (max-width: 430px) {
    margin-top: 1rem;
    font-size: 14px;
    text-align: center;
  }
`;
const CurrentWallet = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #212121;
  margin-top: 10px;
`;
const ConfirmButton = newLocal;
const ConfirmButtonDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const FeesDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  @media (max-width: 430px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;
const FeesName = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #212121;
`;
const CongratulationsDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 30px;
  row-gap: 20px;
  @media (max-width: 430px) {
    margin-top: 1rem;
  }
`;
const CongratulationsText = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #212121;
  @media (max-width: 430px) {
    font-size: 14px;
  }
`;
const SucessDetails = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin-top: 10px;
  padding-left: 150px;
  padding-right: 150px;
  text-align: center;
  @media (max-width: 430px) {
    padding-left: 15px;
    padding-right: 15px;
  }
`;
const ErrorDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #c62828;
  margin-top: 1rem;
  gap: 1rem;
`;
const ExplorerWrap = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  &:hover {
    cursor: pointer;
  }
`;
const ExplorerShareWrap = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;
const InfoLeft = styled.div`
  width: 478px;
  /* justify-content: center; */
  /* display: flex; */
  margin: 0px auto;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 19px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #ffeaea;
  border-radius: 10px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #c62828;
  @media (max-width: 430px) {
    width: 100%;
    font-size: 10px;
  }
`;
export {
  TransferButton,
  RootCard,
  IconImage,
  TokenDetails,
  TokenValue,
  TokenLeftValue,
  TransferProcess,
  TokenProcessDiv,
  TokenProcessedDiv,
  ProcessTokenIcon,
  ProcessTokenName,
  WalletChangeText,
  ProcessDetails,
  CurrentWallet,
  ConfirmButton,
  ConfirmButtonDiv,
  FeesDiv,
  FeesName,
  CongratulationsDiv,
  CongratulationsText,
  SucessDetails,
  ErrorDiv,
  ExplorerWrap,
  ExplorerShareWrap,
  InfoLeft
};

import { createReducer } from "@reduxjs/toolkit";
import { setBlockNumber } from "./action";

export const blockState = {
  blockNumber: 0
}

const blockUpdate = createReducer<{blockNumber: number}>(blockState, (builder) => {
  builder.addCase(setBlockNumber, (state, {payload: { blockNumber}}) => {
    return {
      ...state,
      blockNumber
    }
  })
})

export default blockUpdate;
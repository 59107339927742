import React from 'react';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { DetailsSubDiv, NetworkIcon, TokenDiv } from './Style';
import networksData from 'utilities/getNetworkData';
import Tippy from '@tippyjs/react';


interface Props {
  network1: string;
  network2: string;
}

const ChangeNetwork = ({ network1, network2 }: Props) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  // console.log("Network1: ", network1);
  // console.log("Network2: ", network2);
  return (
    <>
      <DetailsSubDiv>
        {mobile ? (
          <TokenDiv>
            <Tippy theme="light" placement="top" content={networksData[Number(network1)].name}>
              <NetworkIcon src={network1 && networksData[Number(network1)].logo} />
            </Tippy>
            <ArrowForwardIosIcon style={{fontSize:10}}/>
            <Tippy theme="light" placement="top" content={networksData[Number(network2)].name}>
              <NetworkIcon src={network2 && networksData[Number(network2)].logo} />
            </Tippy>
          </TokenDiv>
        ) : (
          <TokenDiv>
            From
            <Tippy theme="light" placement="top" content={networksData[Number(network1)].name}>
              <NetworkIcon src={network1 && networksData[Number(network1)].logo} />
            </Tippy>
            to
            <Tippy theme="light" placement="top" content={networksData[Number(network2)].name}>
              <NetworkIcon src={network2 && networksData[Number(network2)].logo} />
            </Tippy>
          </TokenDiv>
        )}
      </DetailsSubDiv>
    </>
  );
};

export default ChangeNetwork;

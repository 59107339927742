import React from 'react';
import { IconButton } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useHistory } from 'react-router-dom';
import { IconeWrapper, TextWrapper } from './AddLiquidityStyled';


const AddLiquidity = () => {
  const history = useHistory();
  return (
    <>
      <IconeWrapper>
        <IconButton>
          <ArrowBackIosIcon onClick={() => history.push('/setu-pool')} />
        </IconButton>
        <TextWrapper>Add Liquidity</TextWrapper>
      </IconeWrapper>
    </>
  );
};

export default AddLiquidity;

import { Button } from '@mui/material';
import styled from 'styled-components';
const LiquidityWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 4px;
  @media (max-width: 430px) {
    width: 100%;
  }
`;
const Networks = styled(Button)`
  width: 100%;
  height: 70px;
  background: #ffffff;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: flex-start;
  justify-content: start !important;
  text-align:left;
  cursor: pointer;
  border-radius:0px !important;
  &:hover {
    background: #f7f7f7;
  }
  :disabled { 
    opacity:0.5;
    cursor: not-allowed !important;
    pointer-events: all !important;
   }
  
  }
  @media (max-width: 430px) {
    height: 55px;
  }
`;
const IconDiv = styled.div`
  width: 50px;
  height: 50px;
  background: #fdfdfd;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 430px) {
    width: 35px;
    height: 35px;
    margin-left: 0.5rem;
  }
`;
const NetworkIcon = styled.img`
  width: 30px;
  height: 30px;
  @media (max-width: 430px) {
    width: 35px;
    height: 35px;
    margin-left: 0.5rem;
  }
`;
const NetworkText = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #616161;
  margin-left: 1rem;
  @media (max-width: 430px) {
    font-size: 14px;
  }
`;
export {LiquidityWrapper,Networks,IconDiv,NetworkIcon,NetworkText}
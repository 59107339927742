import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import PageHeader from 'components/PageHeader';
import SetuContainer from 'components/Setu/Container/SetuContainer';
import ErrorSnackbar from 'components/Snackbar/ErrorSnackbar';
import { useState } from 'react';
import styled from 'styled-components';
import AVL from '../../assets/V2/Network/AVL.png';
import BNB from '../../assets/V2/Network/BNB.png';
import ETH from '../../assets/V2/Network/ETH.png';
import FTM from '../../assets/V2/Network/FTM.png';
import PLY from '../../assets/V2/Network/PLY.png';
import KAVA from '../../assets/V2/Network/KAVA.png';
import ChooseNetwork from '../../components/Setu/Network/ChooseNetwork';
import ChooseToken from '../../components/Setu/Token/ChooseToken';
import TransferBtn from '../../components/Setu/Transfer/TransferBtn';
import Network from 'components/Setu/Network/Network';
import Modal from 'components/Modal';
import TokenItem from 'components/Setu/Token/TokenItem';
import BridgeContext from '../../components/Setu/Context/BridgeContext';
import axios from 'axios';
import { useEffect } from 'react';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import ReactiveButton from 'reactive-button';
import LinearProgress from '@mui/material/LinearProgress';
import DownloadIcon from '@mui/icons-material/Download';
import {
  ApproveButton,
  BalanceDiv,
  BalanceText,
  BalanceValue,
  CardWrapper2,
  ChangeWrapper,
  ChooseText,
  ConnectWallete,
  FromDiv,
  FromText,
  IconDiv,
  IconImg,
  IconName,
  IconWrapper,
  InputWrapper,
  InsufficientAmount,
  NetworkButton,
  NetworkSelectDiv,
  NetworkSelectDivTitle,
  NetworkWrapper,
  SelectErrorDiv,
  TransferDiv,
} from './Style';
import setuHttp from '../../utilities/http';
import networksData from '../../utilities/getNetworkData';
import { ethers } from 'ethers';
import { bridgeAddress, bridgeRegistry, bridgeUtilsAddress } from 'constants/bridge';
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core';

import bridgeABI from '../../constants/ABI/bridge/Bridge.json';
import bridgeUtilsABI from '../../constants/ABI/bridge/BridgeUtilsUpgradeable.json';
import bridgeRegistryABI from '../../constants/ABI/bridge/BridgeRegistryAbi.json';
import tokenABI from '../../constants/ABI/bridge/Token.json';
import { getContract } from 'utilities/getContract';
import { getJsonProvider } from 'utilities/provider';
import { getSetuContract } from 'utilities/getSetuContract';
import { useChangeAppChainId } from 'store/user/hooks';
import { useOpenNetworkPopUp, useOpenWalletPopUp } from 'store/application/hooks';
import { formatWithoutRounding, unitFormatter } from 'utilities';
import { round } from 'lodash';
import { FadeLoader } from 'react-spinners';
import { formatUnits } from '@ethersproject/units';
import BigNumber from 'bignumber.js';
import { isEmpty } from 'lodash';

const useStyle = makeStyles((theme) =>
  createStyles({
    Input: {
      width: '100%',
      marginTop: '25px !important',
      '& .css-1480iag-MuiInputBase-root-MuiInput-root:before': {
        borderColor: '#673AB7 !important',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#673AB7',
      },
    },
    Root: {
      '&>.MuiDialog-container>.MuiPaper-root.MuiDialog-paperWidthSm ': {
        width: '490px !important',
        borderRadius: '15px !important',
      },
      [theme.breakpoints.down('xs')]: {
        '&>.MuiDialog-container>.MuiPaper-root.MuiDialog-paper': {
          width: '100% !important',
          margin: '15px !important',
        },
      },
    },
    Arrow: {
      [theme.breakpoints.down('xs')]: {
        transform: 'rotateZ(90deg)',
      },
    },
  })
);
const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 425px) {
    flex-direction: column;
  }
`;

const SpanWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #673ab7;
  font-weight: 600;
  cursor: pointer;
  @media (max-width: 425px) {
    justify-content: center;
  }
`;
const DownloadWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  @media (max-width: 425px) {
    justify-content: center;
  }
`;

const Bridge = () => {
  const classes = useStyle();
  const openNetworkPop = useOpenNetworkPopUp();
  const isConnect = false;
  const isApprove = false;
  const isbalnce = false;
  const TransectionReject = false;
  const data = useContext(BridgeContext);
  const [TokenListData, setTokenListData] = useState([]);
  const [amount, setAmount] = useState<number>();
  const [fromToken, setFromToken] = useState<number>();
  const [toToken, setToToken] = useState<number>();
  const [fromTokenLiquidity, setFromTokenLiquidity] = useState('');
  const [toTokenLiquidity, setToTokenLiquidity] = useState('');
  const [balance, setBalance] = useState('0');
  const [tokenNetworks, setTokenNetworks] = useState<number[]>();
  const [open, setOpen] = useState(false);
  const [isShow, setShow] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [isError, setError] = useState(false);
  const [isTokenSelect, setTokenSelect] = useState(false);
  const [isToTokenSelect, setToTokenSelect] = useState(false);

  const open3 = useOpenWalletPopUp();
  const { account, chainId, library, active, error, connector } = useWeb3React();
  const changeAppNetwork = useChangeAppChainId();

  const handleDownload = () => {
    fetch('Setu_Audit_Report.pdf').then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        const alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = 'Setu_Audit_Report.pdf';
        alink.click();
      });
    });
  };

  // console.log("AMOUNT: ", amount && ethers.utils.formatUnits(amount, 0));
  const handleOpen = () => {
    if (!tokenTicker) {
      alert('Select token first');
      return;
    }
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const handleOpen2 = () => {
    if (!tokenTicker) {
      alert('Select token first');
      return;
    }
    setOpen2(true);
  };
  const handleClose2 = () => setOpen2(false);
  // const [tokenName, setTokenName] = useState();
  // const [tokenImage, setTokenImage] = useState();
  const [tokenTicker, setTokenTicker] = useState();

  // console.log('tokenName', tokenName, tokenImage);
  const TokenData = [
    { logo: ETH, name: 'ETHEREUM', chainId: 7, networkId: 1 },
    { logo: PLY, name: 'POLYGON', chainId: 1, networkId: 137 },
    { logo: BNB, name: 'BINANCE SMART CHAIN', chainId: 2, networkId: 56 },
    { logo: AVL, name: 'AVALANCHE', chainId: 3, networkId: 43114 },
    { logo: FTM, name: 'FANTOM', chainId: 4, networkId: 250 },
    { log0: KAVA, name: 'KAVA', chainId: 8, networkId: 2221 },
  ];

  console.log({ fromToken, TokenListData });

  useEffect(() => {
    if (!chainId || !account) return;
    const getLiqiuidity = async () => {
      try {
        const provider = getJsonProvider(chainId);
        let bridgeUtilsInstance = getContract(
          bridgeUtilsAddress(chainId),
          bridgeUtilsABI.abi,
          getJsonProvider(chainId),
          account
        );
        let bridgeInstance = getContract(bridgeAddress(chainId), bridgeABI.abi, provider, account);
        const totalLiquidity = await bridgeInstance.totalLiquidity(tokenTicker);
        const bridgeType = await bridgeUtilsInstance.getBridgeType(tokenTicker);
        if (bridgeType == 1) setFromTokenLiquidity('Infinite');
        else setFromTokenLiquidity(totalLiquidity.toString());
      } catch (err) {
        console.log(err);
      }
    };

    getLiqiuidity();
  }, [chainId, fromToken]);

  // select From network
  const SelectFromToken = async (index, newValue) => {
    console.log({ index });
    if (chainId !== newValue) {
      const res = await changeAppNetwork(newValue);
      if (!res) return;
    }
    setTokenSelect(true);

    setFromToken(index);
    console.log('from chainID: ', newValue);
    data.setFromNetwork(networksData[newValue].name);
    data.setFromImage(networksData[newValue].logo);
    setError(false);
    handleClose();

    try {
      const provider = getJsonProvider(newValue);
      let bridgeUtilsInstance = getContract(
        bridgeUtilsAddress(newValue),
        bridgeUtilsABI.abi,
        getJsonProvider(newValue),
        account
      );
      let bridgeInstance = getContract(bridgeAddress(newValue), bridgeABI.abi, provider, account);
      const totalLiquidity = await bridgeInstance.totalLiquidity(tokenTicker);
      const bridgeType = await bridgeUtilsInstance.getBridgeType(tokenTicker);
      if (bridgeType == 1) setFromTokenLiquidity('Infinite');
      else setFromTokenLiquidity(totalLiquidity.toString());

      let bridgeRegistryInstance = getContract(bridgeRegistry(newValue), bridgeRegistryABI.abi, provider);
      const tokenMetadata = await bridgeRegistryInstance.bridgeTokenMetadata(tokenTicker);

      let tokenInstance = getContract(tokenMetadata.tokenAddress, tokenABI.abi, provider);
      const tokenBalance = await tokenInstance.balanceOf(account);
      setBalance(tokenBalance.toString());
    } catch (error) {
      console.log('Error in SelectFromToken(): ', error);
    }
  };

  const SelectToToken = async (index, newValue) => {
    setToToken(index);
    setToTokenSelect(true);
    console.log('to chainId: ', newValue);
    data.setToNetwork(networksData[newValue].name);
    data.setToImage(networksData[newValue].logo);
    handleClose2();
    setError(false);

    try {
      let bridgeUtilsInstance = getContract(
        bridgeUtilsAddress(newValue),
        bridgeUtilsABI.abi,
        getJsonProvider(newValue),
        account
      );
      let bridgeInstance = getContract(bridgeAddress(newValue), bridgeABI.abi, getJsonProvider(newValue), account);

      const totalLiquidity = await bridgeInstance.totalLiquidity(tokenTicker);
      const bridgeType = await bridgeUtilsInstance.getBridgeType(tokenTicker);
      if (bridgeType == 1) setToTokenLiquidity('Infinite');
      else setToTokenLiquidity(totalLiquidity.toString());
    } catch (error) {
      console.log('Error in SelectToToken(): ', error);
    }
  };

  const switchNetwork = async () => {
    if (toToken === null || fromToken === null) return;
    const toTokenIndex = toToken;
    const fromTokenIndex = fromToken;

    const res = await changeAppNetwork(tokenNetworks[toTokenIndex]);
    if (!res) return;

    setFromToken(toTokenIndex);
    data.setFromNetwork(networksData[tokenNetworks[toTokenIndex]].name);
    data.setFromImage(networksData[tokenNetworks[toTokenIndex]].logo);
    setFromTokenLiquidity(toTokenLiquidity);

    setToToken(fromTokenIndex);
    data.setToNetwork(networksData[tokenNetworks[fromTokenIndex]].name);
    data.setToImage(networksData[tokenNetworks[fromTokenIndex]].logo);
    setToTokenLiquidity(fromTokenLiquidity);

    const provider = getJsonProvider(tokenNetworks[toTokenIndex]);
    let bridgeRegistryInstance = getContract(bridgeRegistry(tokenNetworks[toTokenIndex]), bridgeRegistryABI.abi, provider);
    const tokenMetadata = await bridgeRegistryInstance.bridgeTokenMetadata(tokenTicker);

    let tokenInstance = getContract(tokenMetadata.tokenAddress, tokenABI.abi, provider);
    const tokenBalance = await tokenInstance.balanceOf(account);
    setBalance(tokenBalance.toString());
  };

  const TransferErrorButton = () => {
    if (!data.tokenName) {
      setError(true);
    } else if (!data.fromNetworkName && !data.toNetworkName) {
      setError(true);
    } else if (!data.fromNetworkName) {
      setError(true);
    } else if (!data.toNetworkName) {
      setError(true);
    } else if (!amount) {
      setError(true);
    } else {
      setError(false);
    }
  };
  useEffect(() => {
    // data.setName(null);
    // data.setImage(null);

    async function getTokenData() {
      try {
        const res = await setuHttp.get('/api/tokens');
        console.log('token list: ', res.data);
        setTokenListData(res.data);
      } catch (error) {
        console.log('Error in getTokenData(): ', error);
      }
    }

    getTokenData();
  }, []);

  useEffect(() => {
    // setFromToken(null);
    setToToken(null);
    setFromTokenLiquidity('');
    setToTokenLiquidity('');
    data.setFromNetwork(null);
    data.setFromImage(null);
    data.setToNetwork(null);
    data.setToImage(null);
  }, [tokenTicker]);

  const ConnectWallte = () => {
    console.log('Connect wallet button click');
  };
  const Approve = () => {
    console.log('Approve Button Click');
  };
  const BalanceErrorClick = () => {
    console.log('Insufficient Amount Click');
  };
  const formatInput = (e) => {
    // Prevent characters that are not numbers ("e", ".", "+" & "-") ✨
    let checkIfNum;
    if (e.key !== undefined) {
      // Check if it's a "e", ".", "+" or "-"
      checkIfNum = e.key === '+' || e.key === '-';
    }

    return checkIfNum && e.preventDefault();
  };
  const OnchangeAmount = (e) => {
    if (e.target.value < 0) {
      setAmount(0);
    } else {
      let str: string = e.target.value.toString();
      if (str.indexOf('.') >= 0 && str.length - str.indexOf('.') > 6) return;
      setAmount(e.target.value);
      // console.log(new BigNumber(formatUnits(balance, 18)).toString(), balance);
    }
    // const value = e.target.value.replace(/[^\d]/, "");

    // if (+value !== 0) {
    //   setAmount(value);
    // }
    // else
    // {
    //   setAmount(null);
    // }
  };

  React.useEffect(() => {
    if (isEmpty(TokenListData)) return null;

    // setTokenName(TokenListData[0].token_name);
    // setTokenImage(TokenListData[0].token_image);
    data.setName(TokenListData[0].token_name);
    data.setImage(TokenListData[0].token_image);
    data.setPrice(TokenListData[0].token_price);
    setTokenTicker(TokenListData[0].token_ticker);
    setTokenNetworks(TokenListData[0]['networks']);

    TokenListData[0]['networks'].map((i: number, index: number) => {
      console.log({ i, fromToken, chainId, index });
      if (Number(i) === chainId) {
        setFromToken(Number(index));
        data.setFromNetwork(networksData[chainId].name);
        data.setFromImage(networksData[chainId].logo);
      }
    });
  }, [TokenListData, chainId]);

  console.log({ fromToken });

  const SelectToken = (index, image, name, price, ticker) => {
    // setTokenName(name);
    // setTokenImage(image);
    setShow(false);
    data.setName(name);
    data.setImage(image);
    data.setPrice(price);
    setTokenTicker(ticker);
    setError(false);
    setTokenNetworks(TokenListData[index]['networks']);
  };

  const ClickOpen = () => {
    setShow(true);
  };

  return (
    <>
      <Wrapper>
        <PageHeader
          title="Setu"
          content={
            <>
              Bridge your tokens between <br />
              any two chains with ease
            </>
          }
          hasShowSwitch={false}
        />

        <DownloadWrapper>
          <SpanWrapper onClick={handleDownload}>
            Setu Audit Report
            <DownloadIcon />
          </SpanWrapper>
        </DownloadWrapper>
      </Wrapper>

      {console.log('data.tokenName', data.tokenName)}
      <SetuContainer>
        <CardWrapper2>
          <ChooseToken
            isShowList={isShow}
            tokenName={data.tokenName ? data.tokenName : TokenListData[0]?.token_name}
            tokenImage={data.tokenImage ? data.tokenImage : TokenListData[0]?.token_image}
            close={() => setShow(false)}
            selectClick={ClickOpen}
          >
            {TokenListData.map((tk, index) => {
              return (
                <TokenItem
                  key={index}
                  icon={tk.token_image}
                  title={tk.token_ticker}
                  description={tk.token_name}
                  amount={tk.token_price}
                  handleClick={() => SelectToken(index, tk.token_image, tk.token_name, tk.token_price, tk.token_ticker)}
                />
              );
            })}
          </ChooseToken>

          <NetworkSelectDivTitle>
            <FromDiv>
              <FromText>From</FromText>
              {isTokenSelect ? null : <ChooseText>Choose Network</ChooseText>}
              {/* <ChooseText>Choose Network</ChooseText> */}
            </FromDiv>
            <FromDiv>
              <FromText>To</FromText>
              {/* <ChooseText>Choose Network</ChooseText> */}
              {isToTokenSelect ? null : <ChooseText>Choose Network</ChooseText>}
            </FromDiv>
          </NetworkSelectDivTitle>

          <NetworkSelectDiv>
            <ChangeWrapper>
              <InputWrapper onClick={handleOpen}>
                <IconWrapper>
                  {data.fromNetworkImage && data.fromNetworkName ? (
                    <>
                      <IconDiv>
                        <IconImg src={data.fromNetworkImage} />
                      </IconDiv>
                      <IconName>{data.fromNetworkName}</IconName>
                    </>
                  ) : (
                    'Select From Network'
                  )}
                </IconWrapper>
                <ArrowDropDownIcon style={{ color: '#000000' }} />
              </InputWrapper>
              <ChooseNetwork Liquidity={fromTokenLiquidity} isLiquidityShow={true} />
            </ChangeWrapper>

            <TransferDiv onClick={switchNetwork}>
              <ArrowForwardIcon className={classes.Arrow} style={{ color: '#ffffff' }} />
            </TransferDiv>

            <ChangeWrapper>
              <InputWrapper onClick={handleOpen2}>
                <IconWrapper>
                  {data.toNetworkImage && data.toNetworkName ? (
                    <>
                      <IconDiv>
                        {/* <IconImg src={data.toNetworkImage} /> */}
                        <IconImg src={data.toNetworkImage} />
                      </IconDiv>
                      <IconName>{data.toNetworkName}</IconName>
                    </>
                  ) : (
                    'Select To Network'
                  )}
                </IconWrapper>
                <ArrowDropDownIcon style={{ color: '#000000' }} />
              </InputWrapper>
              <ChooseNetwork Liquidity={toTokenLiquidity} isLiquidityShow={true} />
            </ChangeWrapper>
          </NetworkSelectDiv>

          <TextField
            variant="standard"
            id="number"
            placeholder="0.21514515"
            value={amount}
            onChange={OnchangeAmount}
            onKeyDown={formatInput}
            type="number"
            InputProps={{
              inputProps: {
                style: { textAlign: 'left' },
              },
            }}
            className={classes.Input}
          />

          <BalanceDiv>
            <BalanceText>
              Balance:{' '}
              <BalanceValue
                onClick={() => setAmount(formatWithoutRounding(new BigNumber(formatUnits(balance, 18)).toString(), 3))}
              >
                {round(unitFormatter(balance, 18), 3)}
              </BalanceValue>
            </BalanceText>
          </BalanceDiv>
          {/* <BalanceDiv>
          <MaxButton>MAX</MaxButton>
        </BalanceDiv> */}
          {isConnect ? (
            <ConnectWallete onClick={ConnectWallte}>Connect Wallet</ConnectWallete>
          ) : isApprove ? (
            <ApproveButton onClick={Approve}>Approve</ApproveButton>
          ) : balance && amount && ethers.BigNumber.from(balance).lt(ethers.utils.parseUnits(amount.toString(), 18)) ? (
            <InsufficientAmount onClick={BalanceErrorClick}>Insufficient Amount</InsufficientAmount>
          ) : (
            <>
              {isError ? (
                <>
                  {!data.tokenName ? (
                    <SelectErrorDiv>Please select token to proceed further</SelectErrorDiv>
                  ) : !data.fromNetworkName && !data.toNetworkName ? (
                    <SelectErrorDiv>Please select From and To network to proceed further</SelectErrorDiv>
                  ) : !data.fromNetworkName ? (
                    <SelectErrorDiv>Please select From Network to proceed further</SelectErrorDiv>
                  ) : !data.toNetworkName ? (
                    <SelectErrorDiv>Please select To Network to proceed further</SelectErrorDiv>
                  ) : !amount ? (
                    <SelectErrorDiv>Please enter the Amount to proceed further</SelectErrorDiv>
                  ) : null}
                </>
              ) : null}

              {active && account ? (
                <div onClick={TransferErrorButton}>
                  <TransferBtn
                    amount={amount}
                    from={tokenNetworks && tokenNetworks[fromToken]}
                    to={tokenNetworks && tokenNetworks[toToken]}
                    ticker={tokenTicker}
                  />
                </div>
              ) : error instanceof UnsupportedChainIdError ? (
                <NetworkButton
                  onClick={() => {
                    if (connector instanceof WalletConnectConnector) return null;
                    openNetworkPop();
                  }}
                  disabled={connector instanceof WalletConnectConnector}
                >
                  Wrong Network
                </NetworkButton>
              ) : (
                <ConnectWallete onClick={() => open3()}>Connect Wallet</ConnectWallete>
              )}
            </>
          )}
          <ErrorSnackbar
            handleClose={() => {}}
            message="View on Explorer"
            severity="error"
            title="Transaction Rejected"
            open={TransectionReject}
          />
        </CardWrapper2>
      </SetuContainer>
      <Modal open={open} close={handleClose} title="Choose Network" className={classes.Root} headerClass="SettingHeader">
        <NetworkWrapper>
          {tokenNetworks &&
            tokenNetworks.map((item, index) => (
              <Network
                key={index}
                id={index}
                icon={networksData[item].logo}
                title={networksData[item].name}
                choosen={fromToken}
                networkDisable={toToken}
                handleClick={() => SelectFromToken(index, item)}
              />
            ))}
        </NetworkWrapper>
      </Modal>

      <Modal open={open2} close={handleClose2} title="Choose Network" className={classes.Root} headerClass="SettingHeader">
        <NetworkWrapper>
          {tokenNetworks &&
            tokenNetworks.map((item, index) => (
              <Network
                key={index}
                id={index}
                icon={networksData[item].logo}
                title={networksData[item].name}
                choosen={toToken}
                networkDisable={fromToken}
                handleClick={() => SelectToToken(index, item)}
              />
            ))}
        </NetworkWrapper>
      </Modal>
    </>
  );
};

export default Bridge;

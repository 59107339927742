import React from 'react';
import styled from 'styled-components';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ShareIcon from '@mui/icons-material/Share';
import { ModalScreen } from 'hooks/useV2ModalScreen';
import { ViewStakeButton } from '../styled';
import { useHistory } from 'react-router-dom';

const Links = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 25px;
  gap: 20px;
`;

const Link = styled.a`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #616161;
  text-decoration: none;
  gap: 0.5rem;
`;

interface TransactionViewerProps {
  /** transaction url */
  transactionUrl: string;
  /** switch screen */
  switchScreen: (screen: ModalScreen) => void;
  isStake?: boolean;
}

const TransactionViewer = ({ transactionUrl, switchScreen, isStake }: TransactionViewerProps) => {
  const history = useHistory();
  return (
    <Links>
      <Link href={transactionUrl} target="_blank" rel="noreferrer">
        <OpenInNewIcon style={{ color: '#616161', fontSize: '15px' }} />
        View on Explorer
      </Link>
      <Link onClick={() => switchScreen(ModalScreen.SOCIAL_SHARE)}>
        <ShareIcon style={{ color: '#616161', fontSize: '15px' }} />
        Share
      </Link>
    </Links>
  );
};

export default TransactionViewer;

import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CircularProgress, { circularProgressClasses, CircularProgressProps } from '@mui/material/CircularProgress';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 16,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 10,
    backgroundColor: theme.palette.mode === 'light' ? '#009F42' : '#009F42',
  },
}));

// Inspired by the former Facebook spinners.
interface isProps{
    value:any
}
const CustomizedProgressBars = ({value}:isProps) => {
  return (
    <Box sx={{ flexGrow: 1 }} style={{position:'relative',}}>
        <span style={{position:'absolute',zIndex:1}}>{value}%</span>
      <BorderLinearProgress variant="determinate" value={value} />
    </Box>
  );
};
export default CustomizedProgressBars;

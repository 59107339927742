import React, { useEffect } from 'react';
import styled from 'styled-components';
import stakeCoins from '../../../assets/V2/Images/stakeImg.png';
import NewDollar from '../../../assets/V2/Images/NewDollar.png';
import ClockImg from '../../../assets/V2/Images/clock.svg';
// import NewRocket3 from '../../../assets/V2/Images/NewRocket3.png';
import NewRocket4 from '../../../assets/V2/Images/NewRocket4.png';
import { usdCurrencyFormat } from 'utilities';
// import NewRocket from '../../../assets/images/Rocket.png';
import { useGetDate } from 'hooks/useGetDate';
import Moment from 'moment';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
`;

const CurrentAPYdiv = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
`;
const CurrentAPYdetails = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
`;
const IconImage = styled.img`
  height: 40px;
  @media (max-width: 430px) {
    width: 30px;
    height: 30px;
  }
`;
const CurrentAPYTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin-left: 10px;
  @media (max-width: 430px) {
    font-size: 12px;
  }
`;
const CurrentAPYValue = styled.span`
  font-size: 16px;
  font-weight: 700;
  color: #616161;
  // margin-left: 5px;
`;
const BoosterWrapper = styled.div`
  width: 100%;
  height: 340px;
  @media (max-width: 430px) {
    height: 260px;
  }
`;
const BoosterDiv = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  flex-direction: column;
  padding: 0px 20px;
  @media (max-width: 430px) {
    height: 250px !important;
  }
`;
const BoosterTitle = styled.span`
  font-size: 15px;
  font-weight: 500;
  @media (max-width: 430px) {
    font-size: 12px;
  }
`;
const BoosterValue = styled.span`
  font-size: 18px;
  font-weight: 600;
  coloe: black;
  margin-left: 5px;
  @media (max-width: 430px) {
    font-size: 15px;
  }
`;
const InfoTitle = styled.h1`
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  @media (max-width: 430px) {
    font-size: 15px;
    margin: 0;
  }
`;
const InfoText = styled.div`
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #616161;
  @media (max-width: 430px) {
    font-size: 12px;
  }
`;

const BoostImage = styled.img`
  position: absolute;
  @media (max-width: 430px) {
    width: 100%;
    height: 43%;
    object-fit: cover;
  }
`;

interface BoosterAPYDetailsProps {
  /** aggregate reward booster */
  aggregatedRewardBooster: number;
  /** aggregated reward without booster */
  aggregatedRewardWithoutBooster: number;
  /** APY */
  APY: number;
  /** boosted APY  */
  boostedAPY: number;
  /** boosted show */
  isBoosterAvailable: boolean;
  /* farm end timestamp */
  farmEnd: number;
}

const BoosterPack = ({
  aggregatedRewardBooster,
  aggregatedRewardWithoutBooster,
  APY,
  boostedAPY,
  isBoosterAvailable,
  farmEnd,
}: BoosterAPYDetailsProps) => {
  const endDays = new Date(Moment(farmEnd * 1000).format('MM/DD/YYYY'));
  const RemainingDay = useGetDate(endDays, farmEnd);

  // let days = useCountDown(farmEnd);

  const date = new Date(Moment(farmEnd * 1000).format('MM/DD/YYYY'));
  const time = farmEnd * 1000;
  const GetDate = useGetDate(date, time);

  const BoostCount = () => {
    if (isBoosterAvailable) {
      return (
        <BoosterWrapper>
          <BoostImage src={NewRocket4} />
          <BoosterDiv>
            <CurrentAPYdiv>
              <CurrentAPYdetails>
                <BoosterTitle>Boosted APY :</BoosterTitle>
                <BoosterValue>{boostedAPY > 400 ? '400+' : boostedAPY}%</BoosterValue>
              </CurrentAPYdetails>
              <CurrentAPYdetails>
                <BoosterTitle>Value :</BoosterTitle>
                <BoosterValue>{usdCurrencyFormat(aggregatedRewardBooster)}</BoosterValue>
              </CurrentAPYdetails>
            </CurrentAPYdiv>
            <InfoTitle>What is booster pack ?</InfoTitle>
            <InfoText>
              Booster pack help you earn significantly higher rewards. You can purchase the booster pack while staking in
              this pool.
            </InfoText>
          </BoosterDiv>
        </BoosterWrapper>
      );
    }
  };

  return (
    <>
      <Wrapper>
        <CurrentAPYdiv>
          <CurrentAPYdetails>
            <IconImage src={ClockImg} />

            <CurrentAPYTitle>
              Remaining
              <br />
              <CurrentAPYValue>{GetDate.days} days</CurrentAPYValue>
            </CurrentAPYTitle>
          </CurrentAPYdetails>
          <CurrentAPYdetails>
            <IconImage src={stakeCoins} />
            <CurrentAPYTitle>
              Current APY
              <br />
              <CurrentAPYValue>{APY}%</CurrentAPYValue>
            </CurrentAPYTitle>
          </CurrentAPYdetails>
          <CurrentAPYdetails>
            <IconImage src={NewDollar} />
            <CurrentAPYTitle>
              Value
              <br />
              <CurrentAPYValue>{usdCurrencyFormat(aggregatedRewardWithoutBooster)}</CurrentAPYValue>
            </CurrentAPYTitle>
          </CurrentAPYdetails>
        </CurrentAPYdiv>
      </Wrapper>
      {BoostCount()}
    </>
  );
};

export default BoosterPack;

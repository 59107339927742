import React from 'react';
import styled from 'styled-components';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { IconDiv, NetworkIcon, Networks, NetworkText } from './NetworkStyled';


interface Props {
  id: number;
  icon: string;
  title: string;
  choosen: number;
  networkDisable:number;
  handleClick: (choosen: number) => void;
}

const Network = ({ id, icon, title, choosen, handleClick ,networkDisable}: Props) => {
  return (
    <>
      <Networks onClick={() => handleClick && handleClick(id)} disabled={networkDisable == id}>
        <IconDiv>
          <NetworkIcon src={icon} alt="NetworkIcon" />
        </IconDiv>
        <NetworkText>{title}</NetworkText>
        {choosen == id ? (
          <CheckCircleIcon
            style={{ width: '15px', height: '15px', color: '#673AB7', marginLeft: '0.5rem' }}
          />
        ) : (
          ''
        )}
      </Networks>
    </>
  );
};

export default Network;

import { createAction } from '@reduxjs/toolkit';
import { CohortYF2, TokenMetaDataYF2 } from '../../../utilities/V2/types';
import { FarmData, FarmDetails, UserFarmData } from './reducer';

// set Farm token public data
export const setFarmTokenPublicData =
  createAction<{ cohort: CohortYF2; token: TokenMetaDataYF2; farmDetails: FarmDetails }[]>(
    '/v2/farms/setFarmToken'
  );

// set farm data
export const setFarmData = createAction<{ farmData: FarmData }[]>('/v2/farms/setFarmData');

// set user farm public data
export const setUserFarmPublicData = createAction<{ userFarmPublicData: UserFarmData[] }>(
  '/v2/farms/setUserFarmPublicData'
);
import React from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { DetailsSubDivView, TokenDivView, ViewText } from './Style';



const ViewInfo = ({txHash}) => {
  return (
    <>
      <DetailsSubDivView>
        <TokenDivView>
          <ViewText href="#" onClick={() => window.open(txHash, '_blank')}>View on explorer</ViewText>
          <OpenInNewIcon style={{ fontSize: 15, color: '#616161' }} />
        </TokenDivView>
      </DetailsSubDivView>
    </>
  );
};

export default ViewInfo;

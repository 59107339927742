import { Button, Skeleton } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import DoneIcon from '@mui/icons-material/Done';
import BridgeContext from '../../../components/Setu/Context/BridgeContext';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import {
  DetailsDiv,
  LiquidityDiv,
  MobileDiv,
  MobileTokenDiv,
  MobilHash,
  NetworksDiv,
  SuccessfullWrap,
  TokenDiv,
  TokenName,
  ViewDetails,
} from './TransectionTableStyle';
interface Props {
  index: number;
  tokenName: React.ReactNode;
  status: React.ReactNode;
  network: React.ReactNode;
  claim: React.ReactNode;
  transactionHash: React.ReactNode;
  isLoading: boolean;
  mobileRowClick: (index: number) => void;
}

const TransectionTable = ({ tokenName, status, claim, network, transactionHash, isLoading, mobileRowClick, index }: Props) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const data1 = React.useContext(BridgeContext);
  // console.log("status: ", status['props'].stepNumber, data1.count, index);
  return (
    <>
      {isLoading ? (
        <>
          <DetailsDiv>
            <TokenDiv>
              <Skeleton variant="circular" width={25} height={25} />
              <Skeleton variant="text" width={100} />
            </TokenDiv>
            <NetworksDiv>
              <Skeleton variant="circular" width={25} height={25} />
              <Skeleton variant="circular" width={25} height={25} />
              <Skeleton variant="circular" width={25} height={25} />
              <Skeleton variant="circular" width={25} height={25} />
            </NetworksDiv>
            <Skeleton variant="text" width={100} />
            <ViewDetails>
              <IconButton>
                {/* <ChevronRightIcon style={{ color: '#616161' }} /> */}
                <Button
                  style={{
                    color: 'white',
                    background: '#673AB7',
                    boxShadow: '0px 7px 18px -2px rgba(103, 58, 183, 0.56)',
                    borderRadius: '10px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '8px',
                    width: '100px',
                    textTransform: 'capitalize',
                  }}
                >
                  Add
                </Button>
              </IconButton>
            </ViewDetails>
          </DetailsDiv>
        </>
      ) : (
        <>
          <DetailsDiv onClick={() => mobileRowClick(index)}>
            {mobile ? (
              <MobileTokenDiv>
                {tokenName}
                <div style={{ marginLeft: 42 }}>{network}</div>
              </MobileTokenDiv>
            ) : (
              <TokenDiv>
                <TokenName>{tokenName}</TokenName>
              </TokenDiv>
            )}

            {mobile ? (
              <MobileDiv>
                <>{status['props'].stepNumber === 3 ? <SuccessfullWrap>Successful</SuccessfullWrap> : <>{status}</>}</>

                <MobilHash>
                  {status['props'].stepNumber === 3 ? <DoneAllIcon style={{ color: ' #009F42' }} /> : <DoneIcon />}

                  {transactionHash}
                </MobilHash>
              </MobileDiv>
            ) : (
              <TokenDiv>
                <TokenName>{status}</TokenName>
              </TokenDiv>
            )}

            <NetworksDiv>{network}</NetworksDiv>
            <LiquidityDiv>{claim}</LiquidityDiv>
            <ViewDetails>{transactionHash}</ViewDetails>
          </DetailsDiv>
        </>
      )}
    </>
  );
};

export default TransectionTable;

import PageHeader from 'components/PageHeader';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Frame1 from '../../../assets/V2/Images/Frame1.png';
import Frame2 from '../../../assets/V2/Images/Frame2.png';
import Frame3 from '../../../assets/V2/Images/Frame3.png';
import Frame4 from '../../../assets/V2/Images/Frame4.png';
import Icone1 from '../../../assets/V2/Images/icon1.png';
// import Icone2 from '../../../assets/V2/Images/icon2.png';
// import Icone3 from '../../../assets/V2/Images/icon3.png';
// import Icone4 from '../../../assets/V2/Images/icon4.png';
// import THT from '../../../assets/V2/Images/tk1.png';
import LiquidityPool from '../../../components/SetuPool/LiquidityPool/LiquidityPool';
import MyTokenLiquidity from '../../../components/SetuPool/MyTokenLiquidity';
// import TokenDetails from '../../components/BridgePool/TokenDetails/TokenDetails';
import { IconButton, makeStyles, Snackbar } from '@material-ui/core';
import { Alert, Box } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import BoosterContent from 'components/SetuPool/LiquidityDetail/BoosterContent';
import TabGroup from 'components/V2/TabGroup';
import Modal from '../../../components/Modal/index';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  HeaderDiv,
  LiquidityList,
  NetworkTabList,
  NotFoundText,
  ScrollWrapper,
  ScrollWrapper2,
  Searchdiv,
  Spandivs,
  TableList,
  TableTitleDiv,
  Text,
  TextDiv,
  TextDiv2,
  TextHeader,
  TokenIcon,
} from './Style';
import setuHttp from '../../../utilities/http';
import { ApolloClient, gql, InMemoryCache, useQuery } from '@apollo/client';
import axios from 'axios';
import { useWeb3React } from '@web3-react/core';
import bridgeABI from '../../../constants/ABI/bridge/Bridge.json';
import feePoolABI from '../../../constants/ABI/bridge/FeePool.json';
import { bridgeAddress, feePoolAddress, MAX_APPROVAL_VALUE, tokenImageUrl } from 'constants/bridge';
import { BigNumber, ethers } from 'ethers';
import tokenABI from '../../../constants/ABI/bridge/Token.json';
import { ACTIVE_LP_POSITIONS, getGraphqlClient, graphSupportedChains, LP_POSITIONS } from 'utilities/setuGraph';
import networksData from 'utilities/getNetworkData';
import Close from '@material-ui/icons/Close';
import { getJsonProvider } from 'utilities/provider';
import { getContract } from 'utilities/getContract';
import { unitFormatter } from 'utilities';
import { round } from 'lodash';
import { add } from 'lodash';
import feePoolAbi from '../../../constants/ABI/bridge/FeePool.json';

const useStyles = makeStyles((theme) => ({
  Root: {
    '&>.MuiDialog-container>.MuiPaper-root.MuiDialog-paperWidthSm ': {
      width: '530px !important',
      borderRadius: '15px !important',
    },
    [theme.breakpoints.down('xs')]: {
      '&>.MuiDialog-container>.MuiPaper-root.MuiDialog-paper': {
        width: '100% !important',
        margin: '15px !important',
      },
    },
  },
  Input: {
    width: '100%',
    '& .css-1480iag-MuiInputBase-root-MuiInput-root:before': {
      borderColor: '#673AB7 !important',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#673AB7',
    },
  },
  formTextInput: {
    fontSize: '14px !important',
  },
  toggle: {
    width: '100%',
    '&>.MuiButtonBase-root': {
      width: '100%',
      height: '40px !important',
      textTransform: 'capitalize !important',
      border: '1px solid #616161 !important',
      color: '#616161 !important',
      borderRadius: 5,
      fontFamily: 'Inter !important',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    '&>.MuiButtonBase-root.Mui-selected': {
      backgroundColor: '#616161 !important',
      color: '#ffffff !important',
    },
  },
  toggle2: {
    width: '100%',
    '&>.MuiButtonBase-root': {
      width: '100%',
      height: '40px !important',
      textTransform: 'capitalize !important',
      border: '1px solid #673AB7 !important',
      color: '#673AB7 !important',
      borderRadius: 5,
      fontFamily: 'Inter !important',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    '&>.MuiButtonBase-root.Mui-selected': {
      backgroundColor: '#673AB7 !important',
      color: '#ffffff !important',
    },
  },
  timelineConnector: { border: '1px dashed #bdbdbd', backgroundColor: 'transparent !important' },
  timeLineDotActive: {
    background: '#673AB7 !important',
    marginTop: '0px !important',
    marginBottom: '0px !important',
  },
  timeLineDotApproved: {
    background: '#009F42 !important',
    marginTop: '0px !important',
    marginBottom: '0px !important',
  },
  TabRootMyToken: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {},
    '&>div .MuiBox-root .MuiBox-root .MuiTabs-root .MuiTabs-scroller': {
      border: '0.5px solid #673AB7!important',
      borderRadius: '6px !important',
    },
    '&>div .MuiBox-root .MuiBox-root .MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer .MuiButtonBase-root.Mui-selected':
      {
        background: '#673AB7 !important',
        color: 'white !important',
        width: '190px !important',
        textTransform: 'capitalize',
      },
    '&>div .MuiBox-root .MuiBox-root .MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer .MuiButtonBase-root': {
      width: '190px !important',
      textTransform: 'capitalize',
    },
    '&>div .MuiBox-root .MuiBox-root .MuiTabs-root .MuiTabs-indicator': {
      display: 'none !important',
    },
  },
  AutocompleteRoot: {
    '&>.MuiFormControl-root .MuiInput-root .MuiAutocomplete-endAdornment': {
      display: 'none !important',
    },
  },
}));

const BridgePool = () => {
  const history = useHistory();
  const [lpIndex, setLpIndex] = useState();
  const [tokenTicker, setTokenTicker] = useState();
  let HandleChange = (tokenTicker) => history.push(`/SetuPool-inspect?ticker=${tokenTicker}`);
  let HandleChange2 = (ticker, index) => {
    console.log('index: ', ticker, index);
    setTokenTicker(ticker);
    setLpIndex(index);
    setOpen(true);
  };
  let HandleChange3 = (ticker, index) => {
    console.log(ticker, index);
    history.push(`/SetuPool-Details?ticker=${ticker}&index=${index}`);
  };
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  // const [value, setValue] = useState('');
  const [Searchvalue, setSearchvalue] = React.useState<any | null>();
  const [open, setOpen] = useState(false);
  const [isLoadin, setIsloading] = React.useState(false);
  const [isBooster, setBooster] = React.useState(false);
  const [isAvailableBoost, setAvailable] = React.useState(false);
  const [isConfetti, setConfetti] = React.useState(false);
  const [tokenList, setTokenList] = useState([]);
  const [allTokenList, setAllTokenList] = useState([]);
  const [userPositions, setUserPositions] = useState([]);
  const [allUserPositions, setAllUserPositions] = useState([]);
  const { account, chainId, library } = useWeb3React();
  const [isTokenApprove, setApprove] = React.useState(true);
  const [tabValue, setTabValue] = useState<number>(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarError, setSnackbarError] = useState('');
  const [disableSearchText, setDisableSearchText] = useState(false);
  const [disablePopularSearchText, setDisablePopularSearchText] = useState(false);
  const [unconfirmedRewards, setUnconfirmedRewards] = useState('0');

  // useEffect(() => {
  //   if(!account)
  //     return;
  //   const currentTab = new URLSearchParams(location.search).get("tab");

  //   async function getGraphData() {
  //     try {
  //       const graphChains = graphSupportedChains;
  //       let positions = [];
  //       for (let i = 0; i < graphChains.length; i++) {
  //         const chainId = graphChains[i];
  //         const client = getGraphqlClient(chainId);

  //         const {data, loading, networkStatus} = await client.query({
  //           query: currentTab === "alltransactions" ? LP_POSITIONS : ACTIVE_LP_POSITIONS,
  //           variables: {
  //             account: account
  //           }
  //         });

  //         const provider = getJsonProvider(chainId);
  //         const feePoolInstance = getContract(feePoolAddress(chainId), feePoolABI.abi, provider);
  //         let bridgeInstance = getContract(bridgeAddress(chainId), bridgeABI.abi, provider);

  //         for (let index = 0; index < data.liquidityPositions.length; index++) {
  //           let position = data.liquidityPositions[index];
  //           let confirmedRewards = "0", isBoosted = false;
  //           if(position.status == 1) {
  //             confirmedRewards = await feePoolInstance.getUserConfirmedRewards(position.tokenTicker, account, position.index);

  //             // console.log("Info: ", chainId, position.tokenTicker, account, position.index);
  //             // let lpDetails = await bridgeInstance.liquidityPosition(position.tokenTicker, account, position.index);
  //             let currentEpoch = await bridgeInstance.getEpochsLength(position.tokenTicker);
  //             isBoosted = await bridgeInstance.hasBooster(position.tokenTicker, account, position.index, currentEpoch.add(1));
  //             // let boosterEndEpoch = lpDetails['boosterEndEpochIndex'];
  //             // isBoosted = boosterEndEpoch.gte(currentEpoch.add("1"));
  //             // console.log("boosterEndEpoch: ", currentEpoch, isBoosted);
  //           }
  //           position = {
  //             ...position,
  //             'tokenImage': tokenImageUrl(position.tokenTicker),
  //             'icon': networksData[chainId].logo,
  //             'networkId': chainId,
  //             'confirmedRewards': confirmedRewards,
  //             'isBoosted': isBoosted,
  //             'label': position.tokenTicker
  //           };
  //           positions.push(position);
  //         }
  //       }

  //       setUserPositions(positions);
  //       console.log("GRAPH: ", positions);
  //     } catch (error) {
  //       console.log("Error in getGraphData(): ", error);
  //     }
  //   }

  //   getGraphData();
  // }, [tabValue, account])

  useEffect(() => {
    if (!account) return;
    setSearchvalue(null);
    const currentTab = new URLSearchParams(location.search).get('tab');

    async function getGraphData() {
      try {
        const graphChains = graphSupportedChains;
        let positions = [],
          graphPromises = [],
          graphChainIds = [];
        for (let i = 0; i < graphChains.length; i++) {
          // console.log("HERE: ", i);
          const chainId = graphChains[i];
          const client = getGraphqlClient(chainId);

          const res = client.query({
            query: currentTab === 'alltransactions' ? LP_POSITIONS : ACTIVE_LP_POSITIONS,
            variables: {
              account: account,
            },
          });
          graphPromises.push(res);
          graphChainIds.push(chainId);
        }

        const resolvedGraphData = await Promise.all(graphPromises);

        for (let index = 0; index < resolvedGraphData.length; index++) {
          const chainId = graphChainIds[index];
          const liquidityPositions = resolvedGraphData[index].data.liquidityPositions;

          const provider = getJsonProvider(chainId);
          const feePoolInstance = getContract(feePoolAddress(chainId), feePoolABI.abi, provider);
          let bridgeInstance = getContract(bridgeAddress(chainId), bridgeABI.abi, provider);

          let rewardsPromises = [],
            isBoostedPromises = [];
          for (let i = 0; i < liquidityPositions.length; i++) {
            let position = liquidityPositions[i];
            // console.log("position: ", position);
            let confirmedRewards = '0',
              isBoosted = false;
            if (position.status == 1) {
              confirmedRewards = feePoolInstance.getUserConfirmedRewards(position.tokenTicker, account, position.index);
              rewardsPromises.push(confirmedRewards);

              bridgeInstance.getEpochsLength(position.tokenTicker).then((currentEpoch) => {
                isBoosted = bridgeInstance.hasBooster(position.tokenTicker, account, position.index, currentEpoch.add(1));
                isBoostedPromises.push(isBoosted);
              });
            } else {
              rewardsPromises.push(new Promise((resolve, reject) => resolve('0')));
              isBoostedPromises.push(new Promise((resolve, reject) => resolve(false)));
            }
          }

          const resolvedRewardsData = await Promise.all(rewardsPromises);
          const resolvedIsBoostedData = await Promise.all(isBoostedPromises);
          // console.log("resolvedRewardsData: ", resolvedRewardsData);
          // console.log("resolvedIsBoostedData: ", chainId, resolvedIsBoostedData);

          for (let i = 0; i < resolvedRewardsData.length; i++) {
            let position = liquidityPositions[i];
            // console.log("position2: ", position);
            position = {
              ...position,
              tokenImage: tokenImageUrl(position.tokenTicker),
              icon: networksData[chainId].logo,
              networkId: chainId,
              confirmedRewards: resolvedRewardsData[i],
              isBoosted: resolvedIsBoostedData[i],
              label: position.tokenTicker,
            };
            positions.push(position);
          }

          // sort in desc order based on the time liq. was added
          positions.sort(function (a, b) {
            let keyA = a.addTimestamp,
              keyB = b.addTimestamp;
            // Compare the 2 dates
            if (keyA > keyB) return -1;
            if (keyA < keyB) return 1;
            return 0;
          });
        }

        setUserPositions(positions);
        setAllUserPositions(positions);
        console.log('GRAPH: ', positions);
      } catch (error) {
        console.log('Error in getGraphData(): ', error);
      }
    }

    getGraphData();
  }, [tabValue, account]);

  // useEffect(() => {
  //   async function getUnconfirmedRewards() {
  //     let feePoolInstance = new ethers.Contract(feePoolAddress(chainId), feePoolAbi.abi, library);
  //     let feePoolInstanceWithSigner = await feePoolInstance.connect(library.getSigner(account));
  //     // fetching unconfirmed rewards
  //     let unconfirmedRewards = await feePoolInstanceWithSigner.getUserUnconfirmedRewards(tokenTicker, account, lpIndex);
  //     setUnconfirmedRewards(unconfirmedRewards.toString());
  //   }

  //   getUnconfirmedRewards();
  // });

  useEffect(() => {
    if (!account) return;

    const currentTab = new URLSearchParams(location.search).get('tab');
    if (currentTab === 'alltransactions') setTabValue(1);
    async function getTokenData() {
      try {
        const res: any = await setuHttp.get('/api/tokens/' + chainId);
        let list = [];
        for (let i = 0; i < res.data.length; i++) {
          const token = res.data[i];
          let totalLastEpochUserRewards = BigNumber.from(0);
          let promises = [];
          for (let j = 0; j < token.networks.length; j++) {
            const networkId = token.networks[j];
            const provider = getJsonProvider(networkId);
            const feePoolInstance = getContract(feePoolAddress(networkId), feePoolABI.abi, provider);
            // const lastEpochUserRewards = await feePoolInstance.getLastEpochLpFees(token.token_ticker);
            // totalLastEpochUserRewards = totalLastEpochUserRewards.add(lastEpochUserRewards);
            const lastEpochUserRewards = feePoolInstance.getLastEpochLpFees(token.token_ticker);
            promises.push(lastEpochUserRewards);
          }
          const resolvedData = await Promise.all(promises);
          resolvedData.forEach((data) => {
            // console.log("data: ", data);
            totalLastEpochUserRewards = totalLastEpochUserRewards.add(data);
          });
          list.push({
            ...token,
            label: token.token_ticker,
            liquidity: round(unitFormatter(totalLastEpochUserRewards, 18), 3),
          });
        }
        console.log('data: ', list);
        setTokenList(list);
        setAllTokenList(list);
      } catch (error) {
        console.log('Error in getTokenData(): ', error);
      }
    }

    getTokenData();
  }, [account, chainId]);

  const BoostClick = async (tokenTicker, index, noOfBoosters) => {
    console.log('boosters: ', tokenTicker, index, noOfBoosters);
    setIsloading(true);
    try {
      let bridgeInstance = new ethers.Contract(bridgeAddress(chainId), bridgeABI.abi, library);
      let bridgeInstanceWithSigner = await bridgeInstance.connect(library.getSigner(account));

      let tx = await bridgeInstanceWithSigner.buyBoosterPacks(tokenTicker, index, noOfBoosters);
      await tx.wait();

      setBooster(true);
      setConfetti(true);
    } catch (error) {
      console.log('Error in BoostClick(): ', error);
      if (error?.code === 4001 || error?.code === 'ACTION_REJECTED') setSnackbarError('User denied the transaction');
      else setSnackbarError('Some error occurred');
      setSnackbarOpen(true);
    }
    setIsloading(false);

    // setTimeout(() => {
    //   setIsloading(false);
    //   setBooster(true);
    //   setConfetti(true);
    // }, 1000);
  };
  const handleApproveToken = async (tokenAddress, price, noOfBoosters) => {
    // console.log("handleApproveToken: ", tokenAddress, price, noOfBoosters);
    const totalPrice = BigInt(price) * BigInt(noOfBoosters);
    setIsloading(true);

    try {
      let tokenInstance = new ethers.Contract(tokenAddress, tokenABI.abi, library);
      let tokenInstanceWithSigner = tokenInstance.connect(library.getSigner(account));
      const allowance = await tokenInstanceWithSigner.allowance(account, bridgeAddress(chainId));
      // console.log("allowance: ", allowance.toString(), allowance >= totalPrice);
      if (allowance < totalPrice) {
        let tx = await tokenInstanceWithSigner.approve(bridgeAddress(chainId), MAX_APPROVAL_VALUE);
        await tx.wait();
      }

      setApprove(false);
    } catch (error) {
      console.log('Error in handleApproveToken(): ', error);
      if (error?.code === 4001 || error?.code === 'ACTION_REJECTED') setSnackbarError('User denied the transaction');
      else setSnackbarError('Some error occurred');
      setSnackbarOpen(true);
    }
    setIsloading(false);

    // setTimeout(() => {
    //   setIsloading(false);
    //   setApprove(false)
    // }, 1000);
  };

  const DoneBooster = () => {
    setOpen(false);
    setAvailable(true);
  };
  // const Data = [
  //   {
  //     icon: Icone1,
  //     name: 'UFARM',
  //     address: '0x8353227b5AA01a51d3C330886f8a79bCD4b915BA',
  //     value: '400,000.00',
  //     token_ticker:"ufamr",
  //     network: [80001],
  //   },
  // ];

  // const MyToken = [
  //   {
  //     icon: THT,
  //     name: 'UFARM',
  //     address: '0x8353227b5AA01a51d3C330886f8a79bCD4b915BA',
  //     TokenValue: 0.2451,
  //     value: '400,000.00',
  //     network: [{ name: THT }],
  //   },
  // ];
  // const TokenaData = [{ label: 'UFARM', logo: Icone1 }];

  const HandleMyTokenSearch = (event, newValue) => {
    console.log(newValue);
    // console.log("HandleMyTokenSearch: ", event, newValue);
    setSearchvalue(newValue);
    if (newValue) {
      const positions = allUserPositions.filter((position) => position.tokenTicker === newValue.token_ticker);
      setUserPositions(positions);
      setDisableSearchText(true);
    } else {
      setUserPositions(allUserPositions);
      setDisableSearchText(false);
    }
  };

  const HandleSearch = (event, newValue) => {
    // console.log("handleSearch: ", event, newValue);
    setSearchvalue(newValue);
    if (newValue) {
      setTokenList([newValue]);
      setDisablePopularSearchText(true);
    } else {
      setTokenList(allTokenList);
      setDisablePopularSearchText(false);
    }
  };

  const snackbarCancelButton = (
    <React.Fragment>
      <IconButton size="small" aria-label="close" color="inherit" onClick={() => setSnackbarOpen(false)}>
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  console.log({ userPositions });

  return (
    <>
      <PageHeader
        title="Liquidity Pool"
        content={
          <>
            Add liquidity to Setu and start
            <br /> earning fees in native tokens
          </>
        }
        hasShowSwitch={false}
      />
      {allUserPositions.length > 0 ? (
        <>
          <div className={classes.TabRootMyToken}>
            <TextDiv2>My Tokens</TextDiv2>
            {mobile ? null : (
              <TabGroup tabs={['Active', 'All Transactions']} tabValue={tabValue} setTabValue={setTabValue} />
            )}
          </div>

          <Searchdiv>
            {disableSearchText ? null : <Text>Search Token</Text>}

            {/* <TextField
              id="standard-basic"
              variant="standard"
              value={value}
              onChange={handleChange}
              style={{ width: '250px' }}
            /> */}
            <Autocomplete
              disablePortal
              className={classes.AutocompleteRoot}
              id="combo-box-demo"
              options={allTokenList}
              value={Searchvalue}
              onChange={HandleMyTokenSearch}
              sx={{ width: mobile ? '100%' : 300 }}
              renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  <img src={option.token_image} style={{ height: '20px', width: '20px', marginRight: '10px' }} />
                  {option.label}
                </Box>
              )}
              renderInput={(params) => <TextField {...params} variant="standard" />}
            />
          </Searchdiv>
          <TableTitleDiv>
            <TableList isMyToken={true}>TOKEN</TableList>
            <NetworkTabList isMyToken={true}>NETWORKS</NetworkTabList>
            <LiquidityList isMyToken={true}>REWARDS</LiquidityList>
            <LiquidityList isMyToken={true}>BOOSTER</LiquidityList>
            <LiquidityList isMyToken={true}>DETAILS</LiquidityList>
          </TableTitleDiv>

          <ScrollWrapper2 scroll={userPositions.length > 5 ? true : false}>
            {userPositions.map((item, index) => (
              <MyTokenLiquidity
                key={index}
                icon={item?.icon}
                tokenTicker={item?.tokenTicker}
                index={item?.index}
                tokenImage={item?.tokenImage}
                rewards={add(
                  unitFormatter(item?.confirmedRewards, 18),
                  unitFormatter(item?.unconfirmedRewards, 18),
                  unitFormatter(item?.noOfToken, 18)
                )}
                address={item?.account}
                liquidityValue={item?.noOfTokens}
                decimals={18}
                buttonvalue={HandleChange3}
                HandleChange={() => HandleChange2(item?.tokenTicker, item?.index)}
                isBoostIcon={item?.isBoosted}
                network={item?.networkId}
                isLoading={false}
                isActive={item?.status === 1}
              />
            ))}
          </ScrollWrapper2>
        </>
      ) : (
        <HeaderDiv style={{ marginTop: '20px' }}>
          <Spandivs>
            <div style={{ padding: '25px' }}>
              <div style={{ marginBottom: '10px' }}>
                <TextHeader>
                  <TokenIcon src={Frame1} alt="Liquidity Pool"></TokenIcon>
                </TextHeader>
              </div>
              <div>
                Add Liquidity
                <br />
                in Network
              </div>
            </div>
          </Spandivs>
          <Spandivs>
            <div style={{ padding: '25px' }}>
              <div style={{ marginBottom: '10px' }}>
                <TextHeader>
                  <TokenIcon src={Frame2} alt="Liquidity Pool"></TokenIcon>
                </TextHeader>
              </div>
              <div>
                Principle Completely
                <br />
                Protected
              </div>
            </div>
          </Spandivs>
          <Spandivs>
            <div style={{ padding: '25px' }}>
              <div style={{ marginBottom: '10px' }}>
                <TextHeader>
                  <TokenIcon src={Frame3} alt="Liquidity Pool"></TokenIcon>
                </TextHeader>
              </div>
              <div>
                Earn Rewards
                <br />
                with Liquidity
              </div>
            </div>
          </Spandivs>
          <Spandivs>
            <div style={{ padding: '25px' }}>
              <div style={{ marginBottom: '10px' }}>
                <TextHeader>
                  <TokenIcon src={Frame4} alt="Liquidity Pool"></TokenIcon>
                </TextHeader>
              </div>
              <div>
                No locking Period
                <br />
                on Liquidity
              </div>
            </div>
          </Spandivs>
        </HeaderDiv>
      )}

      <TextDiv>Popular tokens</TextDiv>
      <Searchdiv>
        {disablePopularSearchText ? null : <Text>Search Token</Text>}

        {/* <TextField id="standard-basic" variant="standard" value={value} onChange={handleChange} style={{ width: '250px' }} /> */}
        <Autocomplete
          disablePortal
          className={classes.AutocompleteRoot}
          id="combo-box-demo"
          options={allTokenList}
          value={Searchvalue}
          onChange={HandleSearch}
          sx={{ width: mobile ? '100%' : 300 }}
          renderOption={(props, option) => (
            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
              <img src={option.token_image} style={{ height: '20px', width: '20px', marginRight: '10px' }} />
              {option.token_ticker}
            </Box>
          )}
          renderInput={(params) => <TextField {...params} variant="standard" />}
        />
      </Searchdiv>
      <TableTitleDiv>
        <TableList isMyToken={false}>TOKEN</TableList>
        <NetworkTabList isMyToken={false}>NETWORKS</NetworkTabList>
        <LiquidityList isMyToken={false}>EARNING LAST MONTH</LiquidityList>
        <LiquidityList isMyToken={false}>ADD LIQUIDITY</LiquidityList>
      </TableTitleDiv>
      <ScrollWrapper scroll={tokenList.length > 10 ? true : false}>
        {tokenList.length > 0 ? (
          tokenList.map((item, index) => (
            <LiquidityPool
              key={index}
              tokenTicker={item.token_ticker}
              tokenName={item.token_name}
              tokenImage={item.token_image}
              liquidityValue={item.liquidity}
              networks={item.networks}
              HandleChange={HandleChange}
              isLoading={false}
            />
          ))
        ) : (
          <NotFoundText>Token Not Listed</NotFoundText>
        )}
      </ScrollWrapper>
      <Modal open={open} close={() => setOpen(false)} title="BOOSTER" className={classes.Root} headerClass="SettingHeader">
        <BoosterContent
          isLoading={isLoadin}
          handleBoost={BoostClick}
          isBoosterTwo={isBooster}
          handleDone={DoneBooster}
          errorWallet={false}
          isConfetti={isConfetti}
          tokenTicker={tokenTicker}
          lpIndex={lpIndex}
          isTokenApprove={isTokenApprove}
          handleApproveToken={handleApproveToken}
        />
      </Modal>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        // message={snackbarError}
        action={snackbarCancelButton}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="error" sx={{ width: '100%' }}>
          {snackbarError}
        </Alert>
      </Snackbar>
    </>
  );
};

export default BridgePool;

import { AppState, AppDispatch } from "store";
import { useSelector, useDispatch} from "react-redux";
import { useWeb3React } from "@web3-react/core";
import { setBlockNumber } from "./action";
import { Web3Provider } from '@ethersproject/providers';
import { useEffect } from 'react';

export const useFetchBlockNumber = () => {
  // grab library
  let { library } = useWeb3React() as { library: Web3Provider };
  // dispatch action
  let dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (!library) return null;
    // block number updater
    library.on('block', (blockNumber: number) => {
      dispatch(setBlockNumber({ blockNumber }));
    });
  }, [library, dispatch]);
};

export const useBlockNumber = () => {
  return useSelector((state: AppState) => state.blockUpdate.blockNumber);
};
import ETH from '../assets/V2/Network/ETH.png';
import AST from '../assets/V2/Network/AST.png';
import BNB from '../assets/V2/Network/BNB.png';
import AVL from '../assets/V2/Network/AVL.png';
import FTM from '../assets/V2/Network/FTM.png';
import PLY from '../assets/V2/Network/PLY.png';
import ARB from '../assets/V2/Network/Arbitrum.png';
import OPT from '../assets/V2/Network/Optimism.png';

const networksData = {
  1: { logo: ETH, name: 'Ethereum', token: 'ETH', explorerUrl: '' },
  4: { logo: ETH, name: 'Rinkeby', token: 'ETH', explorerUrl: '' },
  137: { logo: PLY, name: 'Polygon', token: 'MATIC', explorerUrl: 'https://polygonscan.com/tx/', chainId: 1 },
  80001: { logo: PLY, name: 'Polygon Mumbai', token: 'MATIC', explorerUrl: 'https://mumbai.polygonscan.com/tx/' },
  56: { logo: BNB, name: 'Binance Smart Chain', token: 'BNB', explorerUrl: 'https://bscscan.com/tx/', chainId: 2 },
  97: { logo: BNB, name: 'Binance Testnet', token: 'BNB', explorerUrl: '' },
  43114: { logo: AVL, name: 'Avalanche', token: 'AVAX', explorerUrl: 'https://snowtrace.io/tx/' },
  250: { logo: FTM, name: 'Fantom', token: 'FTM', explorerUrl: 'https://ftmscan.com/tx/', chainId: 4 },
  42161: { logo: ARB, name: 'Arbitrum', token: 'ETH', explorerUrl: 'https://arbiscan.io/tx/' },
  10: { logo: OPT, name: 'Optimism', token: 'ETH', explorerUrl: 'https://optimistic.etherscan.io/tx/' },
  592: { logo: AST, name: 'Astar', token: 'AST', explorerUrl: '' },
};

export default networksData;

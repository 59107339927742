import { ApolloClient, InMemoryCache, NormalizedCacheObject } from '@apollo/client';

interface GraphUrl {
  [chainId: number]: string;
}

const endpoints: GraphUrl = {
  137: 'https://api.thegraph.com/subgraphs/name/ramverma-oro/ido-graph',
};

export const getIDOCLAIMGraphqlClient = (chainId: number): ApolloClient<NormalizedCacheObject> | null => {
  const endpoint = endpoints[chainId];
  if (!endpoint) return null;
  return new ApolloClient({
    uri: endpoint,
    cache: new InMemoryCache({
      addTypename: false,
      typePolicies: {
        Query: {
          fields: {
            CLAIM_DETAILS: {
              keyArgs: false,
              merge: (existing = [], incoming) => {
                return [...existing, ...incoming];
              },
            },
          },
        },
      },
    }),
  });
};

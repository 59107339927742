import React, { Fragment, useEffect, useMemo, useState } from 'react';
import Empty from '../../components/Launch/Empty';
import OrderPlaced from '../../components/Launch/OrderPlaced';
import ProjectDetail from '../../components/Launch/ProjectDetail';
import Purchase from '../../components/Launch/Purchase';
import SaleClosed from '../../components/Launch/SaleClosed';
import SoldOut from '../../components/Launch/SoldOut';
import PageHeader from '../../components/PageHeader';
import { ApprovalTransactionStatus, useAllowance, useApproval } from '../../hooks/useApproval';
import { useBuyToken } from '../../hooks/useBuyToken';
import { IDO_SALE_STATUS, useIDOSaleState } from '../../hooks/useIDOSaleState';
import { useObtainIDOProjects, useUpdateIdoData } from '../../store/ido/hooks';
import { getDate, getNftOwner, unitFormatter, unitParser } from '../../utilities';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import ContentLoader from '../../components/ContentLoader';
import { useUserTokenBalance } from '../../hooks/useMiscellaneous';
import { useRouteMatch } from 'react-router-dom';
import { useValidateSolanaAddress } from 'hooks/useValidateSolanaAddress';
import { isEmpty, round } from 'lodash';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useHistory } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
import { useV2UserNftsContext } from 'contexts/V2/FarmContext';
import { useFetchUserNFtsForChainID } from '../../hooks/useFetchNFTwithChain';

const IconWrapperFlexBox = styled.div`
  display: flex;
  // margin-top: -1.5rem;
`;

export enum Discount {
  FULL,
  SEMI,
  NONE,
}

const Launch = () => {
  useObtainIDOProjects();
  // const [dollerValue, setDollerVale] = React.useState<number>();
  const { params } = useRouteMatch<{ projectId: string }>();
  const { priceProtectionChecked, priceProtectionHandler, idoTokenTickerHandler, idoTokenTicker } =
    useValidateSolanaAddress();
  const history = useHistory();
  const projects = useObtainIDOProjects();
  const [eligible, setEligible] = React.useState<Discount>();
  const [selectedIndex, setSelectedIndex] = React.useState<number>(0);

  let project = useMemo(() => {
    if (isEmpty(projects)) return null;
    return projects.filter((e) => e.extra.idoAddress.toLowerCase() === params?.projectId?.toLowerCase())[0];
  }, [projects, params?.projectId]);

  const tokenIndexHandler = (index: number) => {
    setSelectedIndex(index);
  };

  const buyedToken = useMemo(() => {
    if (isEmpty(project)) return 0;

    return Number(project?.userData?.buyedTokens);
  }, [project]);

  const allocateTokens = useMemo(() => {
    if (isEmpty(project)) return 0;

    return project.userData.allocateAmount;
  }, []);

  const saleStatus = useIDOSaleState(project?.totalRaisedInPercentage, project?.endTime, buyedToken > 0);

  let { account } = useWeb3React();

  let allowance0 = useAllowance(account, project?.extra?.idoAddress, project?.paymentToken[0]?.address);

  let allowance1 = useAllowance(account, project?.extra?.idoAddress, project?.paymentToken[1]?.address);

  const token = useMemo(() => project?.paymentToken[selectedIndex]?.address, [selectedIndex]);

  const { approvalTxStatus, isApprovalRequired, triggeredApproval } = useApproval(
    allowance1,
    unitParser(idoTokenTicker),
    project?.extra?.idoAddress,
    token
  );

  // let allowanceForPriceProtection = useAllowance(account, project?.extra?.idoAddress, project?.paymentToken[0]?.address);

  // approval for price protection
  const priceProtectionUfarmApproval = useApproval(
    allowance0,
    unitParser(100000000000),
    project?.extra?.idoAddress,
    project?.paymentToken[0]?.address
  );

  const dollerValue = useMemo(() => {
    console.log('selectedIndex', selectedIndex);
    return project?.paymentToken[selectedIndex]?.price * Number(idoTokenTicker);
  }, [project, idoTokenTicker, selectedIndex]);

  useEffect(() => {
    const fetchEligibility = async () => {
      const eligibility: Discount = await getNftOwner(account);
      setEligible(eligibility);
    };

    if (account) {
      fetchEligibility();
    }
  }, [account]);

  const balance = useUserTokenBalance(
    project?.paymentToken[selectedIndex]?.address,
    project?.paymentToken[selectedIndex]?.decimals
  );

  const { transactionStatus, buyToken } = useBuyToken(
    selectedIndex,
    project?.extra?.idoAddress,
    Number(idoTokenTicker),
    eligible,
    priceProtectionChecked
  );

  const sellTokenIcon = useMemo(() => {
    if (isEmpty(project)) return null;
    return project.sellToken.price;
  }, [project]);

  const getIDOSaleScreen = () => {
    if (saleStatus === IDO_SALE_STATUS.WALLET_NOT_CONNECTED) {
      return <Empty />;
    } else if (saleStatus === IDO_SALE_STATUS.SOLD_OUT) {
      return <SoldOut sellTokenName={project?.sellToken?.name} />;
    } else if (saleStatus === IDO_SALE_STATUS.SALE_CLOSED) {
      return <SaleClosed />;
    } else if (saleStatus === IDO_SALE_STATUS.USER_BUYED) {
      return (
        <OrderPlaced
          sellTokenName={project?.sellToken?.name}
          sellTokenLogo={project?.extra?.sellTokenLogo}
          allocateTokens={buyedToken}
        />
      );
    } else {
      return project !== undefined ? (
        <Fragment>
          {mobile ? (
            <PageHeader
              title="UniLaunch"
              content={
                <div>
                  Participate in the Sportzchain IDO by staking in any Yield Farming V2 Farms or Liquidity Farms. To know
                  more details about the project, click on{' '}
                  <a href="https://medium.com/@UniFarm_/poget-whitelisted-for-sportzchains-ido-at-unilaunch-f668d7367686">
                    How to Participate
                  </a>
                </div>
              }
              hasShowSwitch={false}
            />
          ) : (
            <>
              <PageHeader
                title="UniLaunch"
                content={
                  <div>
                    Participate in the Sportzchain IDO by staking in any Yield Farming V2 Farms or Liquidity Farms. To know
                    more details about the project, click on{' '}
                    <a href="https://medium.com/@UniFarm_/poget-whitelisted-for-sportzchains-ido-at-unilaunch-f668d7367686">
                      How to Participate
                    </a>
                  </div>
                }
                hasShowSwitch={false}
              />
            </>
          )}

          <Purchase
            approvalLoading={
              approvalTxStatus === ApprovalTransactionStatus.APPROVAL_LOADING ||
              priceProtectionUfarmApproval.approvalTxStatus === ApprovalTransactionStatus.APPROVAL_LOADING
            }
            approvalStatus={selectedIndex == 1 ? !isApprovalRequired : !priceProtectionUfarmApproval.isApprovalRequired}
            priceProtectionApprovalStatus={!priceProtectionUfarmApproval.isApprovalRequired}
            balance={balance}
            approveCallback={() =>
              selectedIndex == 0
                ? priceProtectionUfarmApproval.triggeredApproval(1000000000000)
                : triggeredApproval(100000000000)
            }
            approveCallbackForPriceProtction={() => priceProtectionUfarmApproval.triggeredApproval(1000000000000)}
            buyStatus={transactionStatus}
            buyCallback={() => buyToken()}
            idoTokenIcon={project?.paymentToken[selectedIndex]?.icon}
            idoTokenTicker={idoTokenTicker}
            sellTokenTicker={project?.sellToken?.symbol}
            sellTokenIcon={project?.sellToken?.icon}
            notEligible={false}
            allocateTokens={1000}
            eligible={eligible}
            priceProtectionHandler={() => priceProtectionHandler()}
            priceProtectionChecked={priceProtectionChecked}
            idoTokenTickerHandler={idoTokenTickerHandler}
            dollarValue={round(dollerValue, 3)}
            priceOfToken={String(sellTokenIcon)}
            additionalToken={2}
            noOfToken={10000}
            priceProtectionValue={'10000 UFARM'}
            paymentToken={project?.paymentToken}
            selectedTokenIndex={selectedIndex}
            tokenSelectHandler={tokenIndexHandler}
            totalInsuranceSold={project?.totalInsuranceSold}
          />

          {allocateTokens > 0 && (
            <ProjectDetail
              startTime={String(getDate(project?.startTime, true))}
              endTime={String(getDate(project?.endTime, true))}
              totalInvestors={project?.participantsCount}
              purchaseCap={project?.purchaseCap}
              // totalRaised={project?.totalRaised}
              totalRaisedPercentage={project?.totalRaisedInPercentage}
              // idoTokenTicker={idoTokenTicker}
            />
          )}
        </Fragment>
      ) : (
        <ContentLoader color="#222" message="Fetching the project details" />
      );
    }
  };

  const Theme = useTheme();
  const mobile = useMediaQuery(Theme.breakpoints.down('xs'));

  return (
    <>
      {mobile ? (
        <IconWrapperFlexBox>
          <IconButton onClick={() => history.push('/launch')}>
            <ArrowBackIosIcon style={{ fontSize: 18, color: 'black' }} />
          </IconButton>
        </IconWrapperFlexBox>
      ) : (
        <>
          <IconWrapperFlexBox>
            <IconButton onClick={() => history.push('/launch')}>
              <ArrowBackIosIcon style={{ fontSize: 18, color: 'black' }} />
            </IconButton>
          </IconWrapperFlexBox>
        </>
      )}

      {getIDOSaleScreen()}
    </>
  );
};

export default Launch;

import { useWeb3React } from '@web3-react/core';
import { useCallback, useState } from 'react';
import { POLYGON_CHAIN } from '../constants/chain';
import { useApplicationUserState } from '../store/user/hooks';
import { estimatedGas, gasPrice, roundValue, unitParser } from '../utilities';
import { useIdoContract } from './useContract';
import _ from 'lodash';
import { GAS_MARGIN } from '../constants';
import { useDispatchRefetch } from '../store/ido/hooks';
import { useFirebaseDb } from './useFirebase';
import { Discount } from 'pages/Launch';

export enum BUY_STATE {
  LOADING,
  COMPLETED,
  ERROR,
}

export const useBuyToken = (token: number, IDO: string, amount: number, discount: Discount, insurance: boolean) => {
  const setUserTransaction = useFirebaseDb();

  const { appChainId } = useApplicationUserState();
  const { account, library, chainId } = useWeb3React();
  const [transactionStatus, setTransactionStatus] = useState<BUY_STATE>();

  const ido = useIdoContract(IDO);
  const refetch = useDispatchRefetch();

  console.log('token', Number(token) == 0 ? 0 : 1, unitParser(amount), !discount ? 2 : 0, insurance);

  const buyToken = useCallback(async () => {
    if (chainId !== POLYGON_CHAIN || !ido) return null;

    try {
      setTransactionStatus(BUY_STATE.LOADING);

      const gasLimitExpected = await estimatedGas(
        ido,
        'buy',
        [Number(token), token == 0 ? unitParser(amount) : unitParser(amount, 6), !discount ? 2 : 0, insurance],
        account
      );

      console.log('gasLimitExpected', Number(token), amount, !discount ? 2 : 0, insurance);

      // set the gas limit margin
      const gasLimit = roundValue(_.add(gasLimitExpected, _.divide(_.multiply(gasLimitExpected, GAS_MARGIN), 100)), 0);

      const gas_price = await gasPrice(library);

      const transaction = await ido
        .connect(library.getSigner())
        .buy(Number(token), token == 0 ? unitParser(amount) : unitParser(amount, 6), !discount ? 2 : 0, insurance, {
          from: account,
          gasLimit,
          gasPrice: gas_price,
        });

      await transaction.wait(2);

      // set this transaction to firebase
      setUserTransaction(transaction.hash, account);

      // refetch again
      refetch();

      // set transaction status
      setTransactionStatus(BUY_STATE.COMPLETED);
    } catch (err) {
      setTransactionStatus(BUY_STATE.ERROR);
    }
  }, [appChainId, library, account, ido, refetch, setUserTransaction, amount, insurance, discount]);

  return { transactionStatus, buyToken };
};

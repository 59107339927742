import { Button } from '@mui/material';
import styled from 'styled-components';
const TokenInfoWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff;
  // border: 1px solid #e0e0e0;
  // border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  column-gap: 20px;
  margin-top: 25px;
  @media (max-width: 430px) {
    column-gap: 0px;
    width: 100%;
  }
`;
const SubToken = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  @media (max-width: 430px) {
    padding-left: 1rem;
    width: 100%;

    justify-content: flex-start;
    align-items: center;
  }
`;
const TokenIcon2 = styled.img`
  width: 60px;
  height: 60px;
  @media (max-width: 430px) {
    width: 50px;
    height: 50px;
  }
`;

const TokenTextDiv1 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 0.5rem;
  flex-direction: column;
`;
const TokenTextDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 0.5rem;
  flex-direction: column;
  @media (max-width: 430px) {
    width: 100%;
    align-items: center;
  }
`;
const TokenTitle = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #616161;
  @media (max-width: 430px) {
    font-size: 12px;
  }
`;
const TokenName = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #212121;
  @media (max-width: 430px) {
    font-size: 14px;
  }
`;

const LiquidityWrapper = styled.div`
  width: 100%;
  margin: 0px auto;
  display: flex;
  background: #ffffff;
  // border: 1px solid #e0e0e0;
  // border-radius: 15px;
  margin-top: 1rem;
  @media (max-width: 430px) {
    flex-direction: column;
    width: 100%;
    margin-top: 0rem;
  }
`;
const NetworkWrapper = styled.div`
  width: 100%;
  padding: 15px;
  padding-bottom: 25px;
  @media (max-width: 430px) {
    width: 100%;
    padding: 0;
  }
`;
const ButttonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BoosterWrapper = styled.div`
  border-radius: 10px;
  padding: 25px 15px;
  // margin-top: 25px;
  @media (max-width: 430px) {
    margin-top: 1rem;
  }
`;
const EarnDiv = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
`;
const EarnText = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  @media (max-width: 430px) {
    font-size: 14px;
  }
`;
const ToggleGroupDiv = styled.div`
  margin-top: 1rem;
`;
const InputIcon = styled.div`
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  margin-top: 1rem;
  width: 180px;
`;
const InputIcon2 = styled.div`
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  margin-top: 1rem;
`;
const InputIconLabel = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #616161;
  margin-bottom: 10px;
`;
const StyledButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 25px !important;
  padding: 0px;
  gap: 8px;
  color: white !important;
  width: 100%;
  text-transform: capitalize !important;
  height: 55px;
  background: #673ab7 !important;
  box-shadow: 0px 7px 18px -2px rgba(103, 58, 183, 0.56) !important;
  border-radius: 10px !important;
  margin-left: 10px !important;
  @media (max-width: 430px) {
    margin-bottom: 1rem !important;
  }
`;
const StyledDoneButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 25px !important;
  padding: 0px;
  gap: 8px;
  color: white !important;
  width: 100%;
  text-transform: capitalize !important;
  height: 55px;
  background: #009f42 !important;
  box-shadow: 0px 7px 18px -2px #00c85380 !important;

  border-radius: 10px !important;
  margin-left: 10px !important;
  @media (max-width: 430px) {
    margin-bottom: 1rem !important;
  }
`;
const BoosterPayWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const BuyForWrapper = styled.div`
  color: #212121;
  font-weight: 600;
  font-size: 18px;
  @media (max-width: 430px) {
    font-size: 13px;
  }
`;
const ErrorDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #c62828;
  margin-top: 1rem;
  gap: 1rem;
`;
const MyRewardErrorDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #c62828;
  margin-top: 1.5rem;
  gap: 1rem;
  margin-bottom: 1.5rem;
`;
const TextDiv = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #212121;
  padding: 30px;
`;

const LineDiv = styled.div`
  border: 1px solid #e0e0e0;
`;

const StyleDiv = styled.div`
  padding: 30px;
  display: flex;
  column-gap: 15px;
  justify-content:start;
  @media (max-width: 430px) {
    flex-direction: column;
    flex-flow: wrap;
    row-gap: 7px;
}
  }
`;

const TitleDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
  @media (max-width: 430px) {
    display: flex;
  }
`;

const BoosterButton = styled(Button)`
  margin-left: 20px !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;
  color: white !important;
  width: 140px !important ;
  text-transform: capitalize !important;
  height: 55px;
  background: #673ab7 !important;
  box-shadow: 0px 7px 18px -2px rgba(103, 58, 183, 0.56) !important;
  border-radius: 10px !important;
  @media (max-width: 430px) {
    margin-left: 0px !important;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
`;
const BoosterDisableButton = styled(Button)`
  margin-left: 20px !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;
  color: #616161 !important;
  width: 140px !important ;
  text-transform: capitalize !important;
  height: 55px;
  background: #efefef !important;
  border-radius: 10px !important;
  @media (max-width: 430px) {
    margin-left: 0px !important;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
`;
const RewardDay = styled.div`
text-align: left;
    padding-left: 32px;
    margin-top: -12px;
    margin-bottom: 20px;
    color:#616161;
    font-weight: 600;
    font-size: 15px;
}
`;
const ModalContentWrapp = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;
const MyRewardTokenInfoWrapper = styled.div`
  width: 100%;
  // height: 125px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 20px;
  align-items: center;
  @media (max-width: 430px) {
    flex-wrap: wrap;
    height: 150px;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
  }
`;
const MyRewardSubToken = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  @media (max-width: 430px) {
    padding-left: 1rem;
  }
`;
const MyRewardTokenIcon = styled.img`
  width: 60px;
  height: 60px;
  @media (max-width: 430px) {
    width: 30px;
    height: 30px;
  }
`;

const MyRewardTokenTextDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 0.5rem;
  flex-direction: column;
  @media (max-width: 430px) {
    width: 50%;
    align-items: center;
  }
`;

const RemoveTokenInfoWrapper = styled.div`
  width: 100%;
  border-radius: 10px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  align-items: flex-start;
  column-gap: 15px;
  padding-left: 25px;
  padding-right: 25px;
  margin-top: 25px;
  @media (max-width: 430px) {
    display: flex;
    column-gap: 0px;
  }
`;
const RemoveSubToken = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 105px;
  gap: 0.5rem;
  @media (max-width: 430px) {
    padding-left: 1rem;
    width: 50px;
  }
`;
const RemoveTokenIcon = styled.img`
  width: 60px;
  height: 60px;
  @media (max-width: 430px) {
    width: 30px;
    height: 30px;
  }
`;
const RemoveTokenTextDiv1 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 0.5rem;
  flex-direction: column;
`;
const RemoveTokenTextDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 0.5rem;
  flex-direction: column;
  width: 105px;
  @media (max-width: 430px) {
    width: 26%;
    align-items: center;
  }
`;
const RemoveTokenTitle = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #616161;
  @media (max-width: 430px) {
    font-size: 12px;
  }
`;
const RemoveTokenName = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #212121;
  @media (max-width: 430px) {
    font-size: 14px;
  }
`;

const RemoveContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-flow: wrap;
  row-gap: 12px;
  @media (max-width: 430px) {
    column-gap: 32px;
  }
`;
const RemoveSelectWrapper = styled.div`
  @media (max-width: 430px) {
    margin-left: 67px;
  }
`;
const RemoveStyledButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 25px !important;
  padding: 0px;
  gap: 8px;
  color: #616161 !important;
  width: 100%;
  text-transform: capitalize !important;
  height: 55px;
  background: #efefef !important;
  border-radius: 10px !important;
  margin-left: 0px !important;

  margin-left: 0px !important;
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
`;

export const RemoveStyledButton2 = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 25px !important;
  padding: 0px;
  gap: 8px;
  color: white !important;
  width: 100%;
  text-transform: capitalize !important;
  height: 55px;
  background: #673ab7 !important;
  box-shadow: 0px 3px 18px -2px rgba(103, 58, 183, 0.56) !important;
  margin-left: 0px !important;
  border-radius: 10px !important;

  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
`;
export const RemoveTextMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;
  color: #212121;
  font-size: 16px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
`;
export const RemoveTextDetail = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  display: flex;
  align-items: center;
  text-align: center;
  color: #212121;
  margin-top: 1rem;
`;
export const RemoveTextErrorMessage = styled.div`
  background: #f0ebf8;
  border-radius: 10px;
  height: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 8px 24px;
  gap: 24px;
  color: #212121;
  font-weight: 400;
  font-size: 14px;
  margin-top: 1rem;
`;
export const RemoveErrorDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #c62828;
  margin-top: 1rem;
  gap: 1rem;
`;
export const RemoveIconeWrapper = styled.div``;

export const RemoveContentTokenInfoWrapper = styled.div`
  width: 100%;
  border-radius: 10px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  align-items: flex-start;
  column-gap: 15px;
  margin-top: 25px;
  @media (max-width: 430px) {
    display: FLEX;
    column-gap: 0px;
  }
`;
export const RemoveContentSubToken = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 30%;
  gap: 0.5rem;
  @media (max-width: 430px) {
    padding-left: 1rem;
    width:27%;
  }
`;
export const RemoveContentTokenIcon = styled.img`
  width: 60px;
  height: 60px;
  @media (max-width: 430px) {
    width: 30px;
    height: 30px;
  }
`;
export const RemoveContentTokenTextDiv1 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 0.5rem;
  flex-direction: column;
`;
export const RemoveContentDivNoToken = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 0.5rem;
  flex-direction: column;
  width: 30%;
  @media (max-width: 430px) {
    width: 39%;
    align-items: flex-start;
  }
`;
export const RemoveContentTokenTextDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 0.5rem;
  flex-direction: column;
  width: 42%;
  @media (max-width: 430px) {
    width: 39%;
    align-items: flex-start;
  }
`;
export const RemoveContentConfirmReward=styled.div`
display: flex;
justify-content: space-between;
align-items: start;
gap: 0.5rem;
flex-direction: column;
width: 45%;
@media (max-width: 430px) {
  width: 45%;
  align-items: flex-start;
}
`;

export const RemoveContentNetworkDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 0.5rem;
  flex-direction: column;
  width: 150px;
  @media (max-width: 430px) {
    width: 34%;
    align-items: flex-start;
  }
`;
export const RemoveContentRewradDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 0.5rem;
  flex-direction: column;
  width: 30%;
  @media (max-width: 430px) {
    width: 29%;
    align-items: flex-start;
    padding-left:1rem;
  }
`;
export const RemoveContentTokenTitle = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #616161;
  @media (max-width: 430px) {
    font-size: 12px;
  }
`;
export const RemoveContentTokenName = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #212121;
  @media (max-width: 430px) {
    font-size: 14px;
  }
`;

export const RemoveContentContentWrapper = styled.div`
display:flex;
align-items:center;
flex-flow:wrap;
row-gap:12px;
}
`;
export const RemoveContentStyledButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 25px !important;
  padding: 0px;
  gap: 8px;
  color: white !important;
  margin-left: 0px !important;
  width: 100%;
  text-transform: capitalize !important;
  height: 55px;
  background: #673ab7 !important;
  box-shadow: 0px 7px 18px -2px rgba(103, 58, 183, 0.56) !important;
  border-radius: 10px !important;

  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
`;
export const RemoveContentErrorDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #c62828;
  margin-top: 1rem;
  gap: 1rem;
`;
export const RemoveContentIconeWrapper = styled.div``;

export const TokenDetailInfoWrapper = styled.div`
  width: 100%;
  height: 125px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  align-items: center;
  padding-left: 25px;
  padding-right: 25px;
  margin-top: 25px;
  box-shadow: 4px 4px 25px 0px #e0e0e0;
  @media (max-width: 430px) {
    height: 300px;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
    flex-direction: column;
  }
`;
export const SubTokenDetail = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  @media (max-width: 430px) {
    padding-left: 1rem;
  }
`;
export const TokenDetailIcon = styled.img`
  width: 60px;
  height: 60px;
  @media (max-width: 430px) {
    width: 30px;
    height: 30px;
  }
`;
export const TokenDetailTextDiv1 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 0.5rem;
  flex-direction: column;
`;
export const TokenDetailTextDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 0.5rem;
  flex-direction: column;
  @media (max-width: 430px) {
    width: 38%;
    align-items: flex-start;
  }
`;
export const NetworkDivDetail = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 0.5rem;
  flex-direction: column;
  @media (max-width: 430px) {
    width: 38%;
    align-items: flex-start;
    padding-left:3.4rem;
  }
`;
export const TokenDetailTitle = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #616161;
  @media (max-width: 430px) {
    font-size: 12px;
  }
`;
export const TokenDetailName = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #212121;
  @media (max-width: 430px) {
    font-size: 14px;
  }
`;
export const DetailStyledButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;
  color: #673ab7 !important;
  width: 21%;
  text-transform: capitalize !important;
  height: 55px;
  background: transparent !important;
  border: 1px solid #673ab7 !important;
  border-radius: 10px !important;
  font-family: Inter;
  font-weight: 600 !important;
  @media (max-width: 430px) {
    width: 100%;
    margin-bottom: 1rem !important;
  }
`;

export const RemoveButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;
  color: #e0e0e0 !important;
  width: 21%;
  text-transform: capitalize !important;
  height: 55px;
  background: transparent !important;
  border: 1px solid #e5e5e5 !important;
  border-radius: 10px !important;
  font-family: Inter;
  font-weight: 600 !important;
  @media (max-width: 430px) {
    width: 100% !important;
    margin-bottom: 1rem !important;
  }
`;
export const MobileButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 8px;
  color: white !important;
  width: 21%;
  text-transform: capitalize !important;
  height: 55px;
  background: #673ab7 !important;
  border: 1px solid #673ab7 !important;
  border-radius: 10px !important;
  font-family: Inter;
  font-weight: 600 !important;
  @media (max-width: 430px) {
    width: 100% !important;
    margin-bottom: 1rem !important;
    margin-top: 1rem !important;
  }
`;
export const ContentWrapper = styled.div`
display:flex;
align-items:center;
width: 77%;
justify-content: space-between;
@media (max-width: 430px) {
  flex-flow: wrap;
    width: 100%;
    row-gap: 16px;
    margin-top:12px;
}
}
`;

export {
  TokenInfoWrapper,
  SubToken,
  TokenIcon2,
  TokenTextDiv1,
  TokenTextDiv,
  TokenTitle,
  TokenName,
  ErrorDiv,
  BuyForWrapper,
  BoosterPayWrapper,
  StyledDoneButton,
  StyledButton,
  InputIconLabel,
  InputIcon2,
  InputIcon,
  ToggleGroupDiv,
  EarnText,
  EarnDiv,
  LiquidityWrapper,
  NetworkWrapper,
  ButttonWrapper,
  BoosterWrapper,
  MyRewardErrorDiv,
  TextDiv,
  LineDiv,
  StyleDiv,
  TitleDiv,
  BoosterButton,
  BoosterDisableButton,
  RewardDay,
  ModalContentWrapp,
  MyRewardTokenInfoWrapper,
  MyRewardSubToken,
  MyRewardTokenIcon,
  MyRewardTokenTextDiv,
  RemoveTokenInfoWrapper,
  RemoveSubToken,
  RemoveTokenIcon,
  RemoveTokenTextDiv1,
  RemoveTokenTextDiv,
  RemoveTokenTitle,
  RemoveTokenName,
  RemoveContentWrapper,
  RemoveSelectWrapper,
  RemoveStyledButton,
};

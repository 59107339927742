import React from 'react';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  DetailsSubDivDetail,
  MobileDivWrapper,
  TokenDivDetail,
  TokenIcon,
  TokenInfoDetail,
  TokenNameDetail,
  TokenValueDetail,
} from './Style';
import BigNumber from 'bignumber.js';
import { ethers } from 'ethers';
import { unitFormatter } from 'utilities';
import { round } from 'lodash';

interface Props {
  icon: string;
  name: string;
  ticker: string;
  value: string;
}

const TokenDetails = ({ icon, name, ticker, value }: Props) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <DetailsSubDivDetail>
        <TokenDivDetail>
          <TokenIcon src={icon} />
          {mobile ? (
            <MobileDivWrapper>
              <TokenValueDetail>{value}</TokenValueDetail>
              <TokenNameDetail>{round(unitFormatter(value, 18), 3) + " " + ticker}</TokenNameDetail>
            </MobileDivWrapper>
          ) : (
            <TokenInfoDetail>
              <TokenNameDetail>{name}</TokenNameDetail>
              <TokenValueDetail>{round(unitFormatter(value, 18), 3) + ' ' + ticker}</TokenValueDetail>
            </TokenInfoDetail>
          )}
        </TokenDivDetail>
      </DetailsSubDivDetail>
    </>
  );
};

export default TokenDetails;

import { YEAR } from '../../constants';
import { BigNumber, ethers } from 'ethers';
import { isEmpty, divide, multiply, subtract, sum, add } from 'lodash';
import { roundValue, unitFormatter } from '..';
import { TokenMetaDataV2 } from '../../store/lists/reducer';
import { Farm } from 'store/V2/farms/reducer';
import { StakeV2 } from 'store/V2/mints/reducer';

export type ComputV2Reward = {
  /** maxAPY */
  maxAPY: number;
  /** max boosted APY */
  maxBoostedAPY: number;
  /** normal rewards in USD */
  normalRewardsInUSD: number;
  /** boosted rewards in USD */
  boostedRewardsInUSD: number;
  /** rewardTokenMetaData */
  rewardTokenMetaData: V2Reward[];
};

export const computeV2FarmRewards = (
  /** farm */
  farm: Farm,
  /** current block */
  currentBlock: number,
  /** block time */
  blockTime: number,
  /** staked amount */
  stakedAmount?: number
): ComputV2Reward => {
  if (isEmpty(farm)) return null;
  // farm destruct
  let { cohort, farmDetails, token, farmData } = farm || {};

  // cohort destruct
  let { startBlock, epochBlocks, endBlock } = cohort || {};

  let considerableEndBlock = currentBlock >= endBlock ? endBlock : currentBlock;

  // start checkpoint
  let startCheckpoint = getStartCheckpoint(startBlock, considerableEndBlock, epochBlocks);

  // end checkpoint
  let currentCheckpoint = getEndCheckpoint(startBlock, endBlock, epochBlocks, endBlock);

  let considerableStakeAmount = stakedAmount >= 0 ? stakedAmount : token?.userMaxStake;

  // v2 rewards calculation
  let v2RewardTokens = getV2Rewards({
    rewards: farmDetails?.rewards,
    pbr: farmDetails?.perBlockRewards,
    epochBlocks,
    userStakedBlock: subtract(currentBlock, startBlock),
    startCheckpoint,
    currentCheckpoint,
    stakedAmount: considerableStakeAmount,
    totalStakeLimit: token?.totalStakeLimit,
    priorCheckpointTVLs: farmData?.priorEpochTvls,
    cohortVersion: cohort?.cohortVersion,
    deriveBothAPY: true,
    totalStaking: farmData?.activeStaking,
    endBlock,
  });

  let farmTokenPrice = farmDetails?.farmTokenPrice;
  let stakedAmountInUSD = multiply(considerableStakeAmount, farmTokenPrice);

  let evaluationDays = (subtract(endBlock, considerableEndBlock) * blockTime) / 86400;

  // apy
  let maxAPY = getV2Apy(v2RewardTokens?.totalEarnedValueNormalInUsd, stakedAmountInUSD, evaluationDays);
  let maxBoostedAPY = getV2Apy(v2RewardTokens?.totalEarnedValueBoostedInUsd, stakedAmountInUSD, evaluationDays);

  return {
    maxAPY: roundValue(maxAPY, 2),
    maxBoostedAPY: roundValue(maxBoostedAPY, 2),
    normalRewardsInUSD: v2RewardTokens?.totalEarnedValueNormalInUsd,
    boostedRewardsInUSD: v2RewardTokens?.totalEarnedValueBoostedInUsd,
    rewardTokenMetaData: v2RewardTokens?.rewardsTokenMetaData,
  };
};

export const computeV2FarmRewardsBeforeStake = (
  /** farm */
  farm: Farm,
  // /**block time */
  // blockTime: number,
  /** current block */
  currentBlock: number,
  /** staked amount */
  stakedAmount?: number,
  /**user stake limit */
  userStakeLimit?: number
): ComputV2Reward => {
  if (isEmpty(farm)) return null;
  // farm destruct
  let { cohort, farmDetails, token, farmData } = farm || {};

  // cohort destruct
  let { startBlock, epochBlocks, endBlock } = cohort || {};

  let considerableEndBlock = currentBlock >= endBlock ? endBlock : currentBlock;

  // start checkpoint
  let startCheckpoint = getStartCheckpoint(startBlock, considerableEndBlock, epochBlocks);

  // end checkpoint
  let currentCheckpoint = getEndCheckpoint(startBlock, endBlock, epochBlocks, endBlock);

  // let considerableStakeAmount = stakedAmount ? stakedAmount : token?.userMaxStake;
  // v2 rewards calculation
  let v2RewardTokens = getV2RewardsBeforeStake({
    rewards: farmDetails?.rewards,
    pbr: farmDetails?.perBlockRewards,
    epochBlocks,
    userStakedBlock: subtract(currentBlock, startBlock),
    startCheckpoint,
    currentCheckpoint,
    stakedAmount,
    totalStakeLimit: token?.totalStakeLimit,
    priorCheckpointTVLs: farmData?.priorEpochTvls,
    cohortVersion: cohort?.cohortVersion,
    deriveBothAPY: true,
    totalStaking: farmData?.activeStaking,
    currentBlock,
    endBlock,
    userStakeLimit,
  });

  let farmTokenPrice = farmDetails?.farmTokenPrice;
  let stakedAmountInUSD = multiply(stakedAmount, farmTokenPrice);

  let evaluationDays = (subtract(endBlock, considerableEndBlock) * 2) / 86400;

  // apy
  let maxAPY = getV2Apy(v2RewardTokens?.totalEarnedValueNormalInUsd, stakedAmountInUSD, evaluationDays);
  let maxBoostedAPY = getV2Apy(v2RewardTokens?.totalEarnedValueBoostedInUsd, stakedAmountInUSD, evaluationDays);

  return {
    maxAPY: roundValue(maxAPY, 2),
    maxBoostedAPY: roundValue(maxBoostedAPY, 2),
    normalRewardsInUSD: v2RewardTokens?.totalEarnedValueNormalInUsd,
    boostedRewardsInUSD: v2RewardTokens?.totalEarnedValueBoostedInUsd,
    rewardTokenMetaData: v2RewardTokens?.rewardsTokenMetaData,
  };
};

export const computeV2FarmRewardsForApyCalculator = (
  /** farm */
  farm: Farm,
  /** current block */
  currentBlock: number,
  /** end block */
  stakingForBlocks: number,
  /** staked amount */
  stakedAmount?: number
): ComputV2Reward => {
  if (isEmpty(farm)) return null;
  // farm destruct
  let { cohort, farmDetails, token, farmData } = farm || {};

  // cohort destruct
  let { startBlock, epochBlocks, endBlock } = cohort || {};

  let considerableEndBlock = currentBlock >= endBlock ? endBlock : currentBlock;

  // start checkpoint
  let startCheckpoint = getStartCheckpoint(startBlock, considerableEndBlock, epochBlocks);

  // end checkpoint
  let currentCheckpoint = getEndCheckpoint(startBlock, endBlock, epochBlocks, endBlock);

  let considerableStakeAmount = stakedAmount ? stakedAmount : token?.userMaxStake;

  // v2 rewards calculation
  let v2RewardTokens = getV2RewardsForApyCalculator({
    rewards: farmDetails?.rewards,
    pbr: farmDetails?.perBlockRewards,
    epochBlocks,
    userStakedBlock: subtract(currentBlock, startBlock),
    startCheckpoint,
    currentCheckpoint,
    stakedAmount: considerableStakeAmount,
    totalStakeLimit: token?.totalStakeLimit,
    priorCheckpointTVLs: farmData?.priorEpochTvls,
    cohortVersion: cohort?.cohortVersion,
    deriveBothAPY: true,
    totalStaking: farmData?.activeStaking,
    stakingForBlock: stakingForBlocks,
    endBlock,
  });

  return {
    maxAPY: roundValue(farm?.farmData?.apy, 2),
    maxBoostedAPY: roundValue(farm?.farmData?.boostUptoAPY, 2),
    normalRewardsInUSD: v2RewardTokens?.totalEarnedValueNormalInUsd,
    boostedRewardsInUSD: v2RewardTokens?.totalEarnedValueBoostedInUsd,
    rewardTokenMetaData: v2RewardTokens?.rewardsTokenMetaData,
  };
};

export const getBlockDiffrence = (
  /** to block */
  to: number,
  /** from block */
  from: number
): number => {
  return subtract(to, from);
};

export const getStartCheckpoint = (
  /** cohort start block */
  startBlock: number,
  /** cohort end block */
  endBlock: number,
  /** blocks in single checkpoint  */
  checkpointBlocks: number
): number => {
  return Math.trunc(divide(getBlockDiffrence(endBlock, startBlock), checkpointBlocks));
};

export const getEndCheckpoint = (
  /** user start checkpoint */
  startBlock: number,
  /** user end block */
  endBlock: number,
  /** blocks in single checkpoint */
  checkpointBlocks: number,
  /** cohort end block */
  cEndBlock: number
): number => {
  if (endBlock > cEndBlock) {
    endBlock = cEndBlock;
  }
  return Math.trunc(divide(getBlockDiffrence(endBlock, startBlock), checkpointBlocks));
};

export interface V2Options {
  /** rewards in that particular farm */
  rewards: TokenMetaDataV2[];
  /** per block rewards */
  pbr: number[];
  /** blocks in single epoch */
  epochBlocks: number;
  /** user staked block */
  userStakedBlock: number;
  /** user start checkpoint */
  startCheckpoint: number;
  /** user current checkpoint */
  currentCheckpoint: number;
  /** user staked amount */
  stakedAmount: number;
  /** total stake limit */
  totalStakeLimit: number;
  /** prior epoch tvls */
  priorCheckpointTVLs: number[];
  /** cohort version */
  cohortVersion: string;
  /** cohort end block */
  endBlock?: number;
  /** current block */
  currentBlock?: number;
  /** total staking */
  totalStaking?: number;
  /** derive both APY  */
  deriveBothAPY?: boolean;
  /** is boosted */
  isBoosted?: boolean;
  /** staking for block */
  stakingForBlock?: number;
  /** user staake limit */
  userStakeLimit?: number;
}

export interface V2Reward extends TokenMetaDataV2 {
  /** reward value */
  rewardValue: any;
  /** boosted reward value */
  boostedRewardValue?: number;
}

type V2RewardResponse = {
  /** rewards */
  rewardsTokenMetaData: V2Reward[];
  /** total earned value normal */
  totalEarnedValueNormalInUsd: number;
  /** total earned value when booster purchase */
  totalEarnedValueBoostedInUsd: number;
};

export const getV2Rewards = (options: V2Options): V2RewardResponse => {
  if (isEmpty(options)) return null;
  // destruct all properties
  let {
    rewards,
    pbr,
    epochBlocks,
    userStakedBlock,
    startCheckpoint,
    currentCheckpoint,
    stakedAmount,
    totalStakeLimit,
    priorCheckpointTVLs,
    deriveBothAPY,
    isBoosted,
    totalStaking,
    cohortVersion,
  } = options || {};

  if (isEmpty(priorCheckpointTVLs)) return null;
  // validate
  if (rewards.length !== pbr.length) return null;

  let rewardsTokenMetaData = [] as V2Reward[];
  // start checkpoint
  let k = startCheckpoint;

  // push all the r values
  let rValues = [] as number[];

  if (deriveBothAPY) {
    // this is for boosted reward on other side
    var rRvalues = [] as number[];
  }
  // when its premature we need to fetch both rewards
  // when its after staking we need to fetch only single way either a staking will boosted or not

  // calculate aggregated reward value
  // console.log('stakedAmount', stakedAmount);
  if (currentCheckpoint !== startCheckpoint) {
    while (k < currentCheckpoint) {
      let eligibleBlocks: number = epochBlocks;
      const nextCheckpointBlocks = multiply(add(startCheckpoint, 1), epochBlocks);
      if (userStakedBlock > startCheckpoint * epochBlocks) {
        eligibleBlocks = subtract(nextCheckpointBlocks, userStakedBlock);
      }
      // check if using both
      let tStaking = totalStaking === 0 ? stakedAmount : totalStaking;
      let priorATvl = priorCheckpointTVLs[k] === 0 ? tStaking : priorCheckpointTVLs[k];
      console.log('priorATvl', priorATvl);
      if (deriveBothAPY) {
        rValues.push(divide(multiply(stakedAmount, eligibleBlocks), totalStakeLimit));
        rRvalues.push(divide(multiply(stakedAmount, eligibleBlocks), priorATvl));
      } else {
        // this is single side
        if (isBoosted) {
          rValues.push(
            divide(
              multiply(stakedAmount, eligibleBlocks),
              // totalStaking
              priorCheckpointTVLs[k] === 0 ? totalStaking : priorCheckpointTVLs[k]
            )
          );
        } else {
          rValues.push(divide(multiply(stakedAmount, eligibleBlocks), totalStakeLimit));
        }
      }
      k++;
    }
  }

  let rValue = sum(rValues);

  if (currentCheckpoint === startCheckpoint) {
    rValue = 0;
  }

  if (deriveBothAPY) {
    var rRvalue = sum(rRvalues);
  }

  // sum up the reward token
  let usdAmounts = [] as number[];
  let boostedUsdAmounts = [] as number[];

  for (var t = 0; t < rewards.length; t++) {
    // reward for non boosted staking
    let rAmount = multiply(rValue, pbr[t]) as number;
    // reward for boosted staking
    let rRAmount = multiply(rRvalue, pbr[t]) as number;

    // push all the reward token meta data
    let token = rewards[t];
    rewardsTokenMetaData.push({ ...token, rewardValue: rAmount, boostedRewardValue: rRAmount });

    // calculate usd amounts
    usdAmounts.push(multiply(rAmount, token?.pricePoints[cohortVersion]));
    boostedUsdAmounts.push(multiply(rRAmount, token?.pricePoints[cohortVersion]));
  }

  return {
    rewardsTokenMetaData,
    totalEarnedValueNormalInUsd: sum(usdAmounts),
    totalEarnedValueBoostedInUsd: sum(boostedUsdAmounts),
  };
};

export const getV2RewardsBeforeStake = (options: V2Options): V2RewardResponse => {
  if (isEmpty(options)) return null;
  // destruct all properties
  let { rewards, pbr, stakedAmount, totalStakeLimit, totalStaking, cohortVersion, endBlock, currentBlock, userStakeLimit } =
    options || {};

  let rewardsTokenMetaData = [] as V2Reward[];

  const eligibleBlocks = getBlockDiffrence(endBlock, currentBlock);

  const sAmount = stakedAmount > userStakeLimit ? userStakeLimit : stakedAmount;

  const rValue = divide(multiply(sAmount, eligibleBlocks), totalStakeLimit);
  const rRvalue = divide(multiply(sAmount, eligibleBlocks), Number(totalStaking) + Number(sAmount));

  // sum up the reward token
  let usdAmounts = [] as number[];
  let boostedUsdAmounts = [] as number[];

  for (var t = 0; t < rewards.length; t++) {
    // reward for non boosted staking
    let rAmount = multiply(rValue, pbr[t]) as number;
    // reward for boosted staking
    let rRAmount = multiply(rRvalue, pbr[t]) as number;

    // push all the reward token meta data
    let token = rewards[t];
    rewardsTokenMetaData.push({ ...token, rewardValue: rAmount, boostedRewardValue: rRAmount });

    // calculate usd amounts
    usdAmounts.push(multiply(rAmount, token?.pricePoints[cohortVersion]));
    boostedUsdAmounts.push(multiply(rRAmount, token?.pricePoints[cohortVersion]));
  }

  return {
    rewardsTokenMetaData,
    totalEarnedValueNormalInUsd: sum(usdAmounts),
    totalEarnedValueBoostedInUsd: sum(boostedUsdAmounts),
  };
};

export const getV2RewardsForApyCalculator = (options: V2Options): V2RewardResponse => {
  if (isEmpty(options)) return null;
  // destruct all properties
  let { rewards, pbr, stakedAmount, totalStakeLimit, priorCheckpointTVLs, totalStaking, cohortVersion, stakingForBlock } =
    options || {};

  if (isEmpty(priorCheckpointTVLs)) return null;
  // validate
  if (rewards.length !== pbr.length) return null;

  let rewardsTokenMetaData = [] as V2Reward[];

  const rValue = divide(multiply(stakedAmount, stakingForBlock), totalStakeLimit);
  const rRvalue = divide(multiply(stakedAmount, stakingForBlock), totalStaking + stakedAmount);
  console.log('rRvalue', rRvalue);
  // sum up the reward token
  let usdAmounts = [] as number[];
  let boostedUsdAmounts = [] as number[];

  for (var t = 0; t < rewards.length; t++) {
    // reward for non boosted staking
    let rAmount = multiply(rValue, pbr[t]) as number;
    // reward for boosted staking
    let rRAmount = multiply(rRvalue, pbr[t]) as number;

    // push all the reward token meta data
    let token = rewards[t];
    rewardsTokenMetaData.push({ ...token, rewardValue: rAmount, boostedRewardValue: rRAmount });

    // calculate usd amounts
    usdAmounts.push(multiply(rAmount, token?.pricePoints[cohortVersion]));
    boostedUsdAmounts.push(multiply(rRAmount, token?.pricePoints[cohortVersion]));
  }
  return {
    rewardsTokenMetaData,
    totalEarnedValueNormalInUsd: sum(usdAmounts),
    totalEarnedValueBoostedInUsd: sum(boostedUsdAmounts),
  };
};

export interface V2UnConfirmedRewardOptions {
  /** rewards in that particular farm */
  rewards: TokenMetaDataV2[];
  /** active staking */
  activeStaking: number;
  /** per block rewards */
  pbr: number[];
  /** next checkpoint */
  nextCheckpoint: number;
  /** for blocks */
  minedBlocks: number;
  /** user staked amount */
  stakedAmount: number;
  /** total stake limit */
  totalStakeLimit: number;
  /** prior epoch tvls */
  priorCheckpointTVLs: number[];
  /** is boosted */
  isBoosted?: boolean;
}

export const getV2UnConfirmedRewards = (options: V2UnConfirmedRewardOptions): { unconfirmedRewards: V2Reward[] } => {
  if (isEmpty(options)) return null;
  // destruct all properties
  let {
    rewards,
    activeStaking,
    pbr,
    // nextCheckpoint,
    minedBlocks,
    stakedAmount,
    totalStakeLimit,
    isBoosted,
  } = options || {};

  // validate
  if (rewards.length !== pbr.length) return null;

  // rewards token meta data
  let unconfirmedRewards = [] as V2Reward[];

  // unchecked blocks
  let rValue = 0;

  // sum up the reward token
  if (isBoosted) {
    rValue = multiply(
      divide(stakedAmount, activeStaking),
      // divide(stakedAmount, priorCheckpointTVLs[nextCheckpoint] === 0 ? activeStaking : priorCheckpointTVLs[nextCheckpoint]),
      minedBlocks
    );
  } else {
    rValue = multiply(divide(stakedAmount, totalStakeLimit), minedBlocks);
  }

  for (var t = 0; t < rewards.length; t++) {
    // reward for non boosted staking
    let rAmount = multiply(rValue, pbr[t]) as number;
    // push all the reward token meta data
    let token = rewards[t];
    unconfirmedRewards.push({ ...token, rewardValue: rAmount });
  }

  return {
    unconfirmedRewards,
  };
};

export const computeApy = (
  /** list of rewards */
  rewards: TokenMetaDataV2[],
  /** per block rewards */
  pbrs: number[],
  /** number of blocks */
  nBlocks: number,
  /** stake limit in USD */
  stakeLimitInUSD: number,
  /** active staking in USD */
  activeStakingInUSD: number,
  /** Block Time */
  blockTime: number,
  /** cohortVersion */
  cohortVersion: string
): { apy: number; boostedAPY: number; earningRewardInUSD: number } => {
  // reward values to store reward IN USD
  let rewardsValue = [] as number[];
  // loop the rewards
  for (let k = 0; k < rewards.length; k++) {
    let { pricePoints } = rewards[k] || {};
    let earningReward = pbrs[k] * nBlocks;
    let earningRewardInUSD = earningReward * pricePoints?.[cohortVersion];
    rewardsValue.push(earningRewardInUSD);
  }

  let totalReward = sum(rewardsValue) as number;
  // compute evaluation days
  let evaluationDays = (nBlocks * blockTime) / 86400;
  // apy computation

  let apy = getV2Apy(totalReward, stakeLimitInUSD, evaluationDays);
  let boostedAPY = getV2Apy(totalReward, activeStakingInUSD, evaluationDays);

  // cap to the 400%
  if (activeStakingInUSD === 0) {
    boostedAPY = 400;
  }

  // booster apy limiter
  let boosterAPYLimit = getBoosterPackApyLimit(cohortVersion);
  if (boostedAPY > boosterAPYLimit) {
    boostedAPY = boosterAPYLimit;
  }
  // returns the apy and computed total reward in USD
  return {
    apy,
    boostedAPY,
    earningRewardInUSD: totalReward,
  };
};

export const getV2Apy = (
  /** earned reward value in USD */
  earnedRewardValueInUsd: number,
  /** stake Amount in USD */
  stakeAmountInUsd: number,
  /** evaluation days - for how many days ??  */
  evaluationDays: number
): number => {
  if (evaluationDays === 0) return 0;
  // fractional days
  let fractionalDays = YEAR / evaluationDays;
  // apy calculation
  return multiply(multiply(divide(earnedRewardValueInUsd, stakeAmountInUsd), fractionalDays), 100);
};

export const decodeRewardTokens = (
  /** reward bytes */
  rewards: string
): [string[], BigNumber[]] => {
  return ethers.utils.defaultAbiCoder.decode(['address[]', 'uint256[]'], rewards) as [string[], BigNumber[]];
};

export const getRewardTokensMetaData = (
  /** tokenlist basically tokens meta data */
  tokenlist: TokenMetaDataV2[],
  /** reward token addresses */
  rewards: string[],
  /** pbrs - per block rewards */
  pbrs: BigNumber[]
): [TokenMetaDataV2[], number[]] => {
  // empty arrays
  let rewardTokens = [] as TokenMetaDataV2[];
  let parsedPbrs = [] as number[];

  for (var q = 0; q < rewards.length; q++) {
    const filterTokens = tokenlist?.filter((e) => {
      return e.address.toLowerCase() === rewards[q].toLowerCase();
    });
    rewardTokens.push(filterTokens?.[0]);
    parsedPbrs.push(unitFormatter(pbrs[q], filterTokens?.[0]?.decimals));
  }

  return [rewardTokens, parsedPbrs];
};

export const computeStakingConfirmedRewards = (
  /** particular farm */
  farm: Farm,
  /** staking object */
  stake: StakeV2,
  /** current blocknumber */
  blockNumber: number
): V2Reward[] => {
  if (isEmpty(farm) || isEmpty(stake)) return null;

  let { cohort, farmDetails, farmData, token } = farm || {};

  // start check point
  let startCheckpoint = getStartCheckpoint(cohort?.startBlock, stake?.startBlock, cohort?.epochBlocks);

  // current check point
  let currentCheckpoint = getEndCheckpoint(cohort?.startBlock, blockNumber, cohort?.epochBlocks, cohort?.endBlock);

  let stakedAmount = unitFormatter(stake?.stakedAmount, parseInt(token?.decimals));

  // deriving stable rewards
  let stableRewards = getV2Rewards({
    rewards: farmDetails?.rewards,
    pbr: farmDetails?.perBlockRewards,
    epochBlocks: cohort?.epochBlocks,
    userStakedBlock: stake?.startBlock - cohort?.startBlock,
    startCheckpoint,
    currentCheckpoint,
    stakedAmount,
    totalStakeLimit: token?.totalStakeLimit,
    priorCheckpointTVLs: farmData?.priorEpochTvls,
    cohortVersion: cohort?.cohortVersion,
    deriveBothAPY: false,
    isBoosted: stake?.hasBoosterBuyed,
    endBlock: cohort?.endBlock,
  });

  console.log('stableRewards', stableRewards);

  return stableRewards?.rewardsTokenMetaData;
};

// using claimed rewards
export const getClaimedRewards = (
  /** reward tokens */
  rewardTokens: TokenMetaDataV2[],
  /** per block reward */
  pbrs: number[],
  /** combined aggregated R value */
  combinedRValue: BigNumber[],
  /** cohort version */
  cohortVersion: string
): V2Reward[] => {
  // v2 rewards
  let claimedRewards = [] as V2Reward[];
  let earnedRewardsInUsd = [] as number[];

  if (!isEmpty(rewardTokens)) {
    let rFactor = BigNumber.from(1e12);
    let rValue = 0;
    let b = 0;
    while (b < combinedRValue?.length) {
      rValue += combinedRValue[b].div(rFactor).toNumber();
      b++;
    }

    // loop through all rewards values
    for (var k = 0; k < rewardTokens.length; k++) {
      // per block reward
      let perBlockReward = pbrs[k];
      let rewardValue = rValue * perBlockReward;

      // push this on claimed rewards array
      claimedRewards.push({
        ...rewardTokens[k],
        rewardValue,
      });

      // push
      earnedRewardsInUsd.push(rewardValue * rewardTokens[k]?.pricePoints[cohortVersion]);
    }
    // EOL
  }
  // eslint-disable-next-line
  let earnedRewardInUSD = sum(earnedRewardsInUsd) as number;

  return claimedRewards;
};

export const getBoosterPackApyLimit = (cohortVersion: string): number => {
  if (cohortVersion === 'SF/V39') {
    return 1200;
  }
  return 400;
};

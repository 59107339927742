import React from 'react';
import { Amount, InfoDiv, TokenDetails, TokenDiscription, TokenItemIcon, TokenTitle, TokenWrapper } from './TokenStyled';



interface Props {
  icon: string;
  title: string;
  description: string;
  amount: number;
  handleClick:()=>void
}

function TokenItem({ icon, title, description, amount,handleClick }: Props) {
  return (
    <TokenWrapper onClick={handleClick}>
      <InfoDiv>
        <TokenItemIcon src={icon} alt="token" />
        <TokenDetails>
          <TokenTitle>{title}</TokenTitle>
          <TokenDiscription>{description}</TokenDiscription>
        </TokenDetails>
      </InfoDiv>
      <Amount>{amount}</Amount>
    </TokenWrapper>
  );
}

export default TokenItem;

import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Button } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
// import styled from 'styled-components';
import BoosterIcon from '../../../assets/V2/Images/boost2.png';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import InfoIcon from '@material-ui/icons/Info';
import { IconButton } from '@mui/material';
import Ufarm from '../../../assets/V2/Images/unifarm.png';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  BoosterWrapper,
  BuyDiv,
  BuyDiv1,
  BuyDiv2,
  ContentWrapper,
  InputDiv,
  InputIcon,
  InputIconLabel,
  StyledButton3,
  SubToken,
  TipsContentWrapper,
  TipsWrapper,
  ToggleGroupDiv,
  TokenInfoWrapper,
  TokenName,
  TokenTextDiv,
  TokenTextDiv1,
  TokenTextDivBooster1,
  TokenTitle,
  WithoutBoosterWrapper,
  TokenTextDivBuyFor
} from './AddLiquidityStyled';
import { ClipLoader } from 'react-spinners';
import networksData from 'utilities/getNetworkData';
import BigNumber from 'bignumber.js';
import { ethers } from 'ethers';
import { unitFormatter } from 'utilities';
import { round } from 'lodash';
// const TokenInfoWrapper = styled.div`
//   width: 100%;
//   border-radius: 10px;
//   height: 326px;
//   margin-left: auto;
//   margin-right: auto;
//   margin-top: 0;
//   align-items: flex-start;
//   column-gap: 15px;
//   padding-left: 25px;
//   padding-right: 25px;
//   margin-top: 25px;
//   @media (max-width: 430px) {
//     padding-left: 15px;
//     padding-right: 15px;
//     margin-top: 0px;
//   }
// `;
// const SubToken = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;

//   gap: 0.5rem;
//   @media (max-width: 430px) {
//   }
// `;

// const TokenTextDiv1 = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: start;
//   gap: 0.5rem;
//   flex-direction: column;

// `;
// const TokenTextDivBooster1 = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: start;
//   gap: 0.5rem;
//   flex-direction: column;
//   margin-left:7px;

// `;
// const TokenTextDiv = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: start;
//   gap: 0.5rem;
//   flex-direction: column;

//   @media (max-width: 430px) {
//     width: 28%;
//     align-items: center;
//   }
// `;
// const TokenTitle = styled.span`
//   font-family: 'Inter';
//   font-style: normal;
//   font-weight: 400;
//   font-size: 14px;
//   line-height: 24px;
//   color: #616161;
//   @media (max-width: 430px) {
//     font-size: 12px;
//   }
// `;
// const TokenName = styled.span`
//   font-family: 'Inter';
//   font-style: normal;
//   font-weight: 600;
//   font-size: 18px;
//   line-height: 22px;
//   color: #212121;
//   @media (max-width: 430px) {
//     font-size: 14px;
//   }
// `;

// const ContentWrapper = styled.div`
//   display: flex;
//   align-items: center;
//   flex-flow: wrap;
//   row-gap: 14px;
//   column-gap: 120px;
//   @media (max-width: 430px) {
//     column-gap: 26px;
//     display: flex;
//     column-gap: 15px;
//   }
// `;
// const StyledButton = styled(Button)`
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   align-items: center;
//   margin-top: 25px !important;
//   padding: 0px;
//   gap: 8px;
//   color: white !important;
//   margin-left: 0px !important;
//   width: 100%;
//   text-transform: capitalize !important;
//   height: 55px;
//   background: #673ab7 !important;
//   box-shadow: 0px 7px 18px -2px rgba(103, 58, 183, 0.56) !important;
//   border-radius: 10px !important;
//   margin-top: 1.5rem !important;
//   margin-bottom: 1.5rem !important;
//   font-weight: 600 !important;
//   font-size: 15px !important;
//   width: 92% !important;
// `;

// const BoosterWrapper = styled.div`
//   border-radius: 10px;
//   padding: 25px 15px;
//   margin-top: 25px;
//   @media (max-width: 430px) {
//     margin-top: 1rem;
//   }
// `;

// const ToggleGroupDiv = styled.div`
//   margin-top: 1rem;
// `;

// const InputDiv = styled.div`
//   display: flex;
//   justify-content: start;
//   align-items: start;
// `;
// const BuyDiv = styled.div`
//   display: flex;
//   justify-content: start;
//   align-items: start;
//   flex-direction: column;
//   margin-top: 1rem;
//   margin-left: 2rem;
//   width: 40%;
//   @media (max-width: 430px) {
//     margin-top: 0.5rem;
//     margin-left: 1rem;
//   }
// `;
// const BuyDiv1 = styled.div`
//   display: flex;
//   justify-content: start;
//   align-items: start;
//   flex-direction: column;
//   font-family: 'Inter';
//   font-style: normal;
//   font-weight: 400;
//   font-size: 14px;
//   line-height: 24px;
//   color: #616161;
//   @media (max-width: 430px) {
//     font-size: 10px;
//   }
// `;

// const BuyDiv2 = styled.div`
//   display: flex;
//   justify-content: start;
//   align-items: start;
//   flex-direction: column;
//   font-family: 'Inter';
//   font-style: normal;
//   font-weight: 600;
//   font-size: 18px;
//   line-height: 22px;
//   color: #212121;
//   margin-top: 10px;
//   @media (max-width: 430px) {
//     font-size: 14px;
//   }
// `;

// const InputIcon = styled.div`
//   display: flex;
//   justify-content: start;
//   align-items: start;
//   flex-direction: column;
//   margin-top: 1rem;
//   width: 60%;
// `;
// const InputIconLabel = styled.span`
//   font-family: 'Inter';
//   font-style: normal;
//   font-weight: 400;
//   font-size: 12px;
//   line-height: 15px;
//   color: #616161;
//   margin-bottom: 10px;
// `;
// const WithoutBoosterWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;
//   row-gap: 8px;
//   padding-left: 20px;
// `;
// const TipsWrapper = styled.div`
//   font-weight: 400;
//   font-size: 14px;
//   color: #616161;
// `;
// const TipsContentWrapper = styled.div`
//   font-family: 'Inter';
//   font-style: normal;
//   font-weight: 600;
//   font-size: 16px;
//   line-height: 19px;
//   display: flex;
//   align-items: center;
//   color: #212121;
//   column-gap: 3px;
//   @media(max-width:430px){
//     font-size: 12px;
//     text-align: left;
//     column-gap: 11px;
//   }
// `;
const useStyles = makeStyles((theme) => ({
  Root: {
    '&>.MuiDialog-container>.MuiPaper-root.MuiDialog-paperWidthSm ': {
      width: '490px !important',
      borderRadius: '15px !important',
    },
    [theme.breakpoints.down('xs')]: {
      '&>.MuiDialog-container>.MuiPaper-root.MuiDialog-paper': {
        width: '100% !important',
        margin: '15px !important',
      },
    },
  },
  Input: {
    width: '100%',
    '& .css-1480iag-MuiInputBase-root-MuiInput-root:before': {
      borderColor: '#673AB7 !important',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#673AB7',
    },
  },
  formTextInput: {
    fontSize: '14px !important',
  },
  toggle: {
    width: '100%',
    '&>.MuiButtonBase-root': {
      width: '100%',
      height: '40px !important',
      textTransform: 'capitalize !important',
      border: '1px solid #616161 !important',
      color: '#616161 !important',
      borderRadius: 5,
      fontFamily: 'Inter !important',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    '&>.MuiButtonBase-root.Mui-selected': {
      backgroundColor: '#616161 !important',
      color: '#ffffff !important',
    },
  },
  toggle2: {
    width: '100%',
    '&>.MuiButtonBase-root': {
      width: '100%',
      height: '40px !important',
      textTransform: 'capitalize !important',
      border: '1px solid #673AB7 !important',
      color: '#673AB7 !important',
      borderRadius: 5,
      fontFamily: 'Inter !important',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    '&>.MuiButtonBase-root.Mui-selected': {
      backgroundColor: '#673AB7 !important',
      color: '#ffffff !important',
    },
  },
  timelineConnector: { border: '1px dashed #bdbdbd', backgroundColor: 'transparent !important' },
  timeLineDotActive: {
    background: '#673AB7 !important',
    marginTop: '0px !important',
    marginBottom: '0px !important',
  },
  timeLineDotApproved: {
    background: '#009F42 !important',
    marginTop: '0px !important',
    marginBottom: '0px !important',
  },
}));
``;
interface Props {
  icon: any;
  tokenTicker: string;
  tokenName: string;
  noOfToken: string;
  network: any;
  booster: any;
  boosterIcon: string;
  buyFor: any;
  handleApprveBooster: () => void;
  isBooster: boolean;
  isLoading: boolean;
  isAddliquidity: boolean;
  alignment: number;
  toggleBoosters: (number) => void;
  isStepShow?:number;
}

const IsBoosterStep = ({
  icon,
  tokenTicker,
  tokenName,
  noOfToken,
  network,
  booster,
  boosterIcon,
  buyFor,
  isBooster,
  handleApprveBooster,
  isLoading,
  isAddliquidity,
  alignment,
  toggleBoosters,
  isStepShow
}: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const getBoosterTotalPrice = () => {
    const totalPrice = ethers.BigNumber.from(buyFor).mul(alignment);
    return round(unitFormatter(totalPrice, 18), 3);
  }

  return (
    <>
      <TokenInfoWrapper>
        <ContentWrapper>
          <SubToken>
            <img src={icon} width={50} />
            <TokenTextDiv1>
              <TokenTitle>Token</TokenTitle>
              <TokenName>
                {tokenName.slice(0, 5)}
                {tokenName.length > 5 ? '...' : null}
                {tokenName.length > 5 ? (
                  <Tippy theme="light" placement="top" content={tokenName}>
                    <IconButton style={{ padding: 4 }}>
                      <InfoIcon
                        style={{
                          fontSize: '12px',
                          color: '#C4C4C4',
                        }}
                      />
                    </IconButton>
                  </Tippy>
                ) : null}
              </TokenName>
            </TokenTextDiv1>
          </SubToken>
          <TokenTextDiv>
            <TokenTitle>No. of Tokens</TokenTitle>
            <TokenName>{new BigNumber(noOfToken).toFormat(3) + ' ' + tokenTicker}</TokenName>
          </TokenTextDiv>
          <TokenTextDiv>
            <TokenTitle>Network</TokenTitle>
            {mobile ? (
              <TokenName>
                {networksData[network]?.name.length > 5 ? networksData[network]?.name.slice(0, 5) + ' ' + '...' : null}
                {networksData[network]?.name.length > 5 ? (
                  <Tippy theme="light" placement="top" content={networksData[network]?.name}>
                    <IconButton style={{ padding: 4 }}>
                      <InfoIcon
                        style={{
                          fontSize: '12px',
                          color: '#C4C4C4',
                        }}
                      />
                    </IconButton>
                  </Tippy>
                ) : null}
              </TokenName>
            ) : (
              <TokenName>
                {networksData[network]?.name.length > 15
                  ? networksData[network]?.name.slice(0, 10) + ' ' + '...'
                  : networksData[network]?.name.slice(0, 15)}
                {networksData[network]?.name.length > 15 ? (
                  <Tippy theme="light" placement="top" content={networksData[network]?.name}>
                    <IconButton style={{ padding: 4 }}>
                      <InfoIcon
                        style={{
                          fontSize: '12px',
                          color: '#C4C4C4',
                        }}
                      />
                    </IconButton>
                  </Tippy>
                ) : null}
              </TokenName>
            )}
          </TokenTextDiv>
          <SubToken>
            <img src={BoosterIcon} width={43} />
            <TokenTextDivBooster1>
              <TokenTitle>Booster</TokenTitle>
              <TokenName style={{ color: isBooster === false && '#E0E0E0' }}>
                {booster.slice(0, 5)}
                {booster.length > 5 ? '...' : null}
                {booster.length > 5 ? (
                  <Tippy theme="light" placement="top" content={booster}>
                    <IconButton style={{ padding: 4 }}>
                      <InfoIcon
                        style={{
                          fontSize: '12px',
                          color: '#C4C4C4',
                        }}
                      />
                    </IconButton>
                  </Tippy>
                ) : null}
              </TokenName>
            </TokenTextDivBooster1>
          </SubToken>
          <TokenTextDivBuyFor>
            <TokenTitle>Buy for</TokenTitle>
            <TokenName style={{ color: isBooster === false && '#E0E0E0' }}>{buyFor && getBoosterTotalPrice() + " " + booster}</TokenName>
          </TokenTextDivBuyFor>
        </ContentWrapper>
        <BoosterWrapper style={{ background: '#F0EBF8',display:isStepShow==3?'none':null }}>
        {isBooster ? (
            <>
              <ToggleGroupDiv>
                <ToggleButtonGroup
                  color="primary"
                  value={alignment}
                  exclusive
                  onChange={() => toggleBoosters(alignment)}
                  className={classes.toggle2}
                >
                  <ToggleButton value={1}>1 Month</ToggleButton>
                  <ToggleButton value={3}>3 Months</ToggleButton>
                  <ToggleButton value={6}>6 Months</ToggleButton>
                </ToggleButtonGroup>
              </ToggleGroupDiv>
              <InputDiv>
                <InputIcon>
                  <InputIconLabel>Pay With</InputIconLabel>
                  <TextField
                    id="input-with-icon-textfield"
                    value={booster}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={boosterIcon} width={30} alt="imgd" style={{ paddingBottom: '10px', paddingTop: '5px' }} />
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                    className={classes.Input}
                  />
                </InputIcon>
                <BuyDiv>
                  <BuyDiv1>Buy For</BuyDiv1>
                  <BuyDiv2>{buyFor && getBoosterTotalPrice() + " " + booster}</BuyDiv2>
                </BuyDiv>
              </InputDiv>
            </>
          ) : (
            <WithoutBoosterWrapper>
              <TipsWrapper>Tips:</TipsWrapper>
              <TipsContentWrapper>
                <img src={BoosterIcon} width={20} />
                Add Booster will increase the rewards by 1.5x or more
              </TipsContentWrapper>
            </WithoutBoosterWrapper>
          )}
          
          
        </BoosterWrapper>
      </TokenInfoWrapper>
      {isAddliquidity ? (
        <>
          <StyledButton3 onClick={handleApprveBooster}>
            {isLoading ? (
              <>
                <ClipLoader color="white" size={28} />
                Add Liquidity For {tokenName}
              </>
            ) : (
              `Add Liquidity For ${tokenName}`
            )}
          </StyledButton3>
        </>
      ) : (
        <StyledButton3 onClick={handleApprveBooster}>
          {isLoading ? (
            <>
              <ClipLoader color="white" size={28} />
              Approve {tokenName}
            </>
          ) : (
            `Approve ${tokenName}`
          )}
        </StyledButton3>
      )}
    </>
  );
};

export default IsBoosterStep;

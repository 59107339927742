import React from 'react';
import { Button } from '@material-ui/core';
import { getProviderLogo, miniWalletAddress } from '../../utilities';
import { useWeb3React } from '@web3-react/core';
import styled from 'styled-components';
import { useOpenWalletPopUp } from '../../store/application/hooks';
import { useEnsName } from 'contexts/EnsContext';
import WarningSnackBar from '../Snackbar/WarningSnackBar';
import SuccessSnackBar from '../Snackbar/SuccessSnackBar';
import { useUnstoppable} from 'contexts/UnsContext'

const ConnectWalletFlexWrapper = styled.div`
  display: flex;
  align-items: center;
  text-transform: lowercase
`;

const StyledButton = styled(Button)<{ account: string; active: boolean }>`
  background: ${(props) => (props.active && props.account ? '#F8F8F9' : '#6338BC')};
  color: ${(props) => (props.active && props.account ? '#000000' : '#ffffff')};
  border: ${(props) => (props.active && props.account ? '1px solid transparent' : 'none')};
  border-radius: 5px;
  margin-left: 0.5rem;
  padding: 6px 16px;
  text-transform: capitalize;
  box-shadow: ${(props) =>
    props.active && props.account ? '1px solid transparent' : 'rgb(103 58 183 / 56%) 0px 7px 18px -2px'};
  &: hover {
    background: ${(props) => (props.active && props.account ? '#F8F8F9' : '#6338BC')};
  }
`;

export default function ConnectWallet(): JSX.Element {
  const { active, account, connector } = useWeb3React();
  const activeProviderLogo = getProviderLogo(connector);
  const open = useOpenWalletPopUp();
  const ens = useEnsName();
  const ensName = typeof ens === 'string' ? ens.toLowerCase() : '';
  const UnstoppableDomain = useUnstoppable();
  console.log(UnstoppableDomain,"Domain-------------------")
  const unsDomain = typeof UnstoppableDomain === 'string'? UnstoppableDomain : '';

  const [open2, setOpen2] = React.useState(active);
  const handleClose = () => {
    setOpen2(true);
  };

  return (
    <>
      <StyledButton onClick={() => open()} account={account} active={active}>
        {active && account ? (
          <ConnectWalletFlexWrapper>
            <img src={activeProviderLogo} />{' '}
            <span>{unsDomain === '' ? ensName === '' ? miniWalletAddress(account) : ensName.toLowerCase() : unsDomain.toLowerCase()}</span>
          </ConnectWalletFlexWrapper>
        ) : (
          <span>Connect Wallet</span>
        )}
      </StyledButton>
      {active && account ? (
        <SuccessSnackBar
          open={!open2}
          title="Connected"
          message="You have successfully connected to your wallet"
          severity="success"
          handleClose={handleClose}
        />
      ) : (
        <WarningSnackBar
          open={true}
          title="Wallet is not connected"
          message="Connect Wallet"
          severity="warning"
          handleClose={() => null}
          handleModal={() => open()}
        />
      )}
      
    </>
  );
}

import React from 'react';
import styled from 'styled-components';
import { usdCurrencyFormat } from 'utilities';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import InfoIcon from '@material-ui/icons/Info';

const CohortCont = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
  gap: 10px;
  @media (max-width: 430px) {
    margin-top: 20px;
  }
`;

const Icondiv = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border-radius: 50%;
  :nth-child(2) {
    margin-left: -15px;
  }
  @media (max-width: 430px) {
    display: none;
  }
`;
const IconImg = styled.img`
  width: 32px;
  height: 32px;
`;

const CohortInnerCont = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const TvlWrapper = styled.div`
  height: 55px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  gap: 0.5rem;
`;
const TokenWrapper = styled.div`
  width: 100%;
  height: 25px;
  display: flex;
  align-items: flex-start;
`;
const TokenName = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  font-family: 'Inter';
  font-style: normal;
  color: #616161;
`;
const TokenPrice = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  font-family: 'Inter';
  font-style: normal;
  color: #212121;
  @media (max-width: 430px) {
    font-size: 14px;
  }
`;

interface UserStakingDetailsProps {
  /** farm icon  */
  farmIcon: string[];
  /** farm name */
  farmName: string;
  /** farm token price */
  farmTokenPrice: number;
  /** cohort version */
  cohortVersion: string;
  /** APY */
  APY: number;
  /** number of tokens stake */
  noOfTokensStake: number | string;
  /** number of token stake usd */
  noOfTokensStakeUSD: string;
}

const UserStakingDetails = ({
  farmIcon,
  farmName,
  farmTokenPrice,
  APY,
  noOfTokensStake,
  noOfTokensStakeUSD,
}: UserStakingDetailsProps) => {
  return (
    <CohortCont>
      <div style={{ display: 'flex' }}>
        {farmIcon?.map((icon, index) => (
          <Icondiv key={index}>
            <IconImg src={icon} />
          </Icondiv>
        ))}
      </div>
      <CohortInnerCont>
        {/** farm token price */}
        <TvlWrapper>
          <TokenWrapper>
            <TokenName>
              {farmName?.toString()?.slice(0, 7)}
              {farmName?.toString()?.length > 7 ? (
                <>
                  ..
                  <Tippy placement="top" theme="light" content={farmName}>
                    <InfoIcon style={{ width: '12px', color: '#c4c4c4' }} />
                  </Tippy>
                </>
              ) : null}
            </TokenName>
          </TokenWrapper>
          <TokenPrice>
            {usdCurrencyFormat(farmTokenPrice)?.toString()?.slice(0, 7)}
            {usdCurrencyFormat(farmTokenPrice)?.toString()?.length > 7 ? (
              <>
                ..
                <Tippy placement="top" theme="light" content={usdCurrencyFormat(farmTokenPrice)}>
                  <InfoIcon style={{ width: '12px', color: '#c4c4c4' }} />
                </Tippy>
              </>
            ) : null}
          </TokenPrice>
        </TvlWrapper>

        {/** normal apy */}
        <TvlWrapper>
          <TokenWrapper>
            <TokenName>APY</TokenName>
          </TokenWrapper>
          <TokenPrice>{APY}%</TokenPrice>
        </TvlWrapper>

        {/** number of tokens to stake */}
        <TvlWrapper>
          <TokenWrapper>
            <TokenName># of Token</TokenName>
          </TokenWrapper>
          <TokenPrice>
            {noOfTokensStake?.toString()?.slice(0, 7)}
            {noOfTokensStake?.toString()?.length > 7 ? (
              <>
                ..
                <Tippy placement="top" theme="light" content={noOfTokensStake}>
                  <InfoIcon style={{ width: '12px', color: '#c4c4c4' }} />
                </Tippy>
              </>
            ) : null}
          </TokenPrice>
        </TvlWrapper>

        {/** number of tokens to staked USD */}
        <TvlWrapper>
          <TokenWrapper>
            <TokenName>$ of Token</TokenName>
          </TokenWrapper>
          <TokenPrice>
            {noOfTokensStakeUSD?.toString()?.slice(0, 7)}
            {noOfTokensStakeUSD?.toString()?.length > 7 ? (
              <>
                ..
                <Tippy placement="top" theme="light" content={noOfTokensStakeUSD}>
                  <InfoIcon style={{ width: '12px', color: '#c4c4c4' }} />
                </Tippy>
              </>
            ) : null}
          </TokenPrice>
        </TvlWrapper>
      </CohortInnerCont>
    </CohortCont>
  );
};
export default UserStakingDetails;

import React, { useContext } from 'react';
import {
  StakeModalBoosterPackDetails,
  StakingFarmDetails,
} from 'components/V2/Modals/Common/types';
import { ApprovalResponse } from 'hooks/useApproval';
import { BuyBoosterPackTransactionResponse } from 'hooks/useBuyBoosterPack';
import { ModalScreen } from 'hooks/useV2ModalScreen';

export type BoosterModalContextData = {
  /** current opening status */
  isOpen: boolean;
  /**  toggle modal */
  toggle: (isOpen: boolean) => void;
  /** staking details */
  stakingFarmDetails: StakingFarmDetails;
  /** booster pack details */
  boosterPackDetails: StakeModalBoosterPackDetails;
  /** booster approval */
  boosterApproval: ApprovalResponse;
  /** buy booster pack */
  buyBoosterPack: BuyBoosterPackTransactionResponse;
  /** view my stake url */
  viewMyStakeUrl: string;
  /** transaction url  */
  transactionUrl: string;
  /** userWantsToBuyBooster */
  isBuyBoosterTransactionScreen: boolean;
  /** screen */
  screen: ModalScreen;
  /** switch screen */
  switchScreen: (screen: ModalScreen) => void;
};

let BoosterPackContext = React.createContext<BoosterModalContextData>(null);

// use booster pack modal context
export let useBoosterModalContext = (): BoosterModalContextData => {
  return useContext(BoosterPackContext);
};

const BoosterModalContext = ({
  ctx,
  children,
}: {
  ctx: BoosterModalContextData;
  children: React.ReactNode;
}): JSX.Element => {
  return (
    <BoosterPackContext.Provider value={ctx}>
      {children}
    </BoosterPackContext.Provider>
  );
};

export default BoosterModalContext;

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from 'store';
import {
  createCallsForFarmData,
  formatFarmData,
  formatFarmDetails,
  getUserFarmPublicData,
  getYF2FarmDetails,
} from 'utilities/V2/farms';
import { Farm, FarmData, FarmDetails, UserFarmData } from 'store/V2/farms/reducer';
import { useApplicationUserState } from 'store/user/hooks';
import { setFarmData, setFarmTokenPublicData, setUserFarmPublicData } from 'store/V2/farms/action';
import { isEmpty, concat } from 'lodash';
import { useAllV2TokenList, useV2Tokenlist } from 'store/lists/hooks';
import { unitFormatter } from 'utilities';
import { formatFarmTokenDetails } from 'utilities/V2';
import { Booster, CohortYF2, TokenMetaDataYF2 } from 'utilities/V2/types';
import { multicall } from 'utilities/multicall';
import { useWeb3React } from '@web3-react/core';
import { blocksMined, BSC_CHAIN } from 'constants/chain';
import { ETH_CHAIN, POLYGON_CHAIN } from 'constants/chain';
import { Token } from 'graphql/V2/typings';

let log = console.log;

export const useFetchV2Farms = (): void => {
  // for dispatch
  const dispatch = useDispatch<AppDispatch>();
  // using app chainId
  const { appChainId } = useApplicationUserState();
  // tokenlist
  const tokenlist = useAllV2TokenList();

  useEffect(() => {
    // only ethereum chain supported
    async function fetchV2Farms() {
      try {
        if (!isEmpty(tokenlist)) {
          let farmPublicData = [] as {
            cohort: CohortYF2;
            token: TokenMetaDataYF2;
            farmDetails: FarmDetails;
          }[];

          const [farmForEthereum, farmForBSC, farmForPolygon] = await Promise.all([
            getYF2FarmDetails(ETH_CHAIN),
            getYF2FarmDetails(BSC_CHAIN),
            getYF2FarmDetails(POLYGON_CHAIN),
          ]);

          // let farms = await getYF2FarmDetails(appChainId);

          let farms: Token[] = concat(farmForEthereum, farmForBSC, farmForPolygon);

          // if (farmForEthereum) {
          //   for (let i = 0; i < farmForEthereum.length; i++) {
          //     allFarm.push(farmForEthereum[i]);
          //   }
          // }

          // if (farmForBSC) {
          //   for (let i = 0; i < farmForBSC.length; i++) {
          //     allFarm.push(farmForBSC[i]);
          //   }
          // }

          // if (farmForPolygon) {
          //   for (let i = 0; i < farmForPolygon.length; i++) {
          //     allFarm.push(farmForPolygon[i]);
          //   }
          // }

          // console.log('allfarm', farms);

          // let allFarm: Token[] = [];

          // update boosters
          if (!isEmpty(farms)) {
            for (var k = 0; k < farms.length; k++) {
              let farmItems = farms[k];
              let farm = formatFarmTokenDetails(farmItems);
              let { cohort, token } = farm;
              let boosters = [] as Booster[];
              // check if boosters available in that cohort
              if (!isEmpty(cohort?.boosters)) {
                // update the booster object
                let t = 0;
                while (t < cohort.boosters.length) {
                  // filtereing token in tokenlist
                  let { paymentToken, boosterPackAmount } = cohort.boosters[t];
                  let boosterToken = tokenlist[farmItems?.chainId]?.tokens?.filter(
                    (e) => e.address.toLowerCase() === paymentToken.toLowerCase()
                  )?.[0];
                  if (!isEmpty(boosterToken)) {
                    // then update this
                    boosters.push({
                      ...cohort.boosters[t],
                      formattedBoosterAmount: unitFormatter(boosterPackAmount, boosterToken?.decimals),
                      name: boosterToken?.symbol,
                      decimals: boosterToken?.decimals,
                      icon: boosterToken?.icon,
                    });
                  }
                  t++;
                }
              }

              cohort.boosters = boosters;

              // collect farmDetails data as well
              let { farmToken } = token;

              // format farm token
              let farmDetails = formatFarmDetails(
                farmToken,
                tokenlist[farmItems?.chainId],
                cohort.rewards,
                !isEmpty(cohort?.boosters),
                cohort?.hasLiquidityMining,
                cohort?.cohortVersion,
                farmItems?.chainId
              );

              // after update push the farm
              farmPublicData.push({ ...farm, farmDetails });
            }
            // dispatch
            dispatch(setFarmTokenPublicData(farmPublicData));
          } else {
            dispatch(setFarmTokenPublicData([]));
          }
        }
      } catch (err) {
        if (err instanceof Error) {
          log(`V2 Farms derivation failed`, err.stack);
          dispatch(setFarmTokenPublicData([]));
        }
      }
    }
    // call function
    fetchV2Farms();
  }, [appChainId]);
};

export const useFetchFarmData = (): void => {
  const farms = useV2Farms();

  const { appChainId } = useApplicationUserState();

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    async function fetchFarmPublicData() {
      try {
        if (!isEmpty(farms)) {
          // aggregate calls
          const ethereumFarms = farms.filter((e) => e.farmDetails.chainId == ETH_CHAIN);
          const bscFarms = farms.filter((e) => e.farmDetails.chainId == BSC_CHAIN);
          const polygonFarms = farms.filter((e) => e.farmDetails.chainId == POLYGON_CHAIN);
          let ethereumCalls = createCallsForFarmData(ethereumFarms, ETH_CHAIN);
          let bscCalls = createCallsForFarmData(bscFarms, BSC_CHAIN);
          let polygonCalls = createCallsForFarmData(polygonFarms, POLYGON_CHAIN);
          if (isEmpty(ethereumCalls) && isEmpty(bscCalls) && isEmpty(polygonCalls)) return null;
          let [ethereumCallResults, bscCallsResults, polygonCallResults] = await Promise.all([
            multicall(ETH_CHAIN, ethereumCalls),
            multicall(BSC_CHAIN, bscCalls),
            multicall(POLYGON_CHAIN, polygonCalls),
          ]);

          let ethereumBlockNumber = ethereumCallResults.results.blockNumber;
          let ethereumOriginal = ethereumCallResults.results.original;

          let bscBlockNumber = bscCallsResults.results.blockNumber;
          let bscOriginal = bscCallsResults.results.original;

          let polygonBlockNumber = polygonCallResults.results.blockNumber;
          let polygonOriginal = polygonCallResults.results.original;

          let ethereumFarmData = formatFarmData(ethereumFarms, ethereumBlockNumber.toNumber(), ethereumOriginal, ETH_CHAIN);
          let bscFarmData = formatFarmData(bscFarms, bscBlockNumber.toNumber(), bscOriginal, BSC_CHAIN);
          let polygonFarmData = formatFarmData(polygonFarms, polygonBlockNumber.toNumber(), polygonOriginal, POLYGON_CHAIN);

          let farmData: { farmData: FarmData }[] = concat(ethereumFarmData, bscFarmData, polygonFarmData);

          // if (!isEmpty(ethereumFarmData)) {
          //   for (let i = 0; i < ethereumFarmData.length; i++) {
          //     farmData.push(ethereumFarmData[i]);
          //   }
          // }

          // if (!isEmpty(bscFarmData)) {
          //   for (let i = 0; i < bscFarmData.length; i++) {
          //     farmData.push(bscFarmData[i]);
          //   }
          // }

          // if (!isEmpty(polygonFarmData)) {
          //   for (let i = 0; i < polygonFarmData.length; i++) {
          //     farmData.push(polygonFarmData[i]);
          //   }
          // }

          if (!isEmpty(farmData)) {
            dispatch(setFarmData(farmData));
          }
        }
      } catch (err) {
        console.log('err', err.stack);
      }
    }
    // call fetch farm public data
    fetchFarmPublicData();
  }, [farms]);
};

export const useFetchUserPublicFarmData = () => {
  // farms
  let farms = useV2Farms();
  let { chainId, account } = useWeb3React();
  // const account = '0x7707Bfcd5C7524d372dCCD747c6bC941566eCA5A';
  // dispatch
  let dispatch = useDispatch<AppDispatch>();

  console.log({ farms, account });

  useEffect(() => {
    async function fetchUserFarmPublicData() {
      const ethFarm = farms.filter((farm) => farm.farmDetails.chainId === ETH_CHAIN);
      const bscFarm = farms.filter((farm) => farm.farmDetails.chainId === BSC_CHAIN);
      const polygonFarm = farms.filter((farm) => farm.farmDetails.chainId === POLYGON_CHAIN);
      let userFarmPublicDataEthereum = await getUserFarmPublicData(ethFarm, account, ETH_CHAIN);
      let userFarmPublicDataBSC = await getUserFarmPublicData(bscFarm, account, BSC_CHAIN);
      let userFarmPublicDataPolygon = await getUserFarmPublicData(polygonFarm, account, POLYGON_CHAIN);
      let userFarmPublicData: UserFarmData[] = [];

      if (!isEmpty(userFarmPublicDataEthereum)) {
        for (let i = 0; i < userFarmPublicDataEthereum.length; i++) {
          userFarmPublicData.push(userFarmPublicDataEthereum[i]);
        }
      }

      if (!isEmpty(userFarmPublicDataBSC)) {
        for (let i = 0; i < userFarmPublicDataBSC.length; i++) {
          userFarmPublicData.push(userFarmPublicDataBSC[i]);
        }
      }

      if (!isEmpty(userFarmPublicDataPolygon)) {
        for (let i = 0; i < userFarmPublicDataPolygon.length; i++) {
          userFarmPublicData.push(userFarmPublicDataPolygon[i]);
        }
      }

      if (!isEmpty(userFarmPublicData)) {
        dispatch(setUserFarmPublicData({ userFarmPublicData }));
      }
    }
    // fetch farm public data
    if (account && chainId && !isEmpty(farms)) {
      fetchUserFarmPublicData();
    }
    // eslint-disable-next-line
  }, [account, farms]);
};

export const useV2Farms = (): Farm[] => {
  return useSelector((state: AppState) => state.v2Farms.farms);
};

import React, { Fragment, useState } from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { makeStyles } from '@material-ui/core/styles';
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';
import { ClipLoader } from 'react-spinners';
import { ApprovalResponse, ApprovalTransactionStatus } from 'hooks/useApproval';
import { ethers } from 'ethers';
// import { MintGaslessTransactionResponse, MintGaslessTransactionStatus } from 'hooks/useMintGasless';
import { MintTransactionResponse, MintTransactionStatus } from 'hooks/useMint';
import { BoosterStakeButton, DotWrapper, VersionWrapper, ApproveError, ErrorText } from 'components/V2/Modals/Common/styled';
import { Booster } from 'utilities/V2/types';
import { useApplicationUserState } from 'store/user/hooks';
import { WRAPPED_TOKENS } from 'constants/V2';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ErrorSnackbar from 'components/V2/Snackbar/ErrorSnackbar';
import SuccessSnackBar from '../../../../../Snackbar/SuccessSnackBar';
import styled from 'styled-components';

const IconDiv = styled.div`
  width: 35px;
  height: 35px;
  display: flex;
  justidy-content: center;
  align-items: center;
  background: #ffffff;
`;
const IconImg = styled.img`
  width: 28px;
  height: 28px;
`;
const useStyles = makeStyles((theme) => ({
  timelineItem: {
    minHeight: '50px !important',
    '&:before': {
      display: 'none',
    },
  },
  activeTimelineContent: {
    height: '80px',
    margin: '5px 0px !important',
    border: '1px solid #E0E0E0',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      height: '100%',
    },
  },
  approvedTimelineContent: {
    height: '44px',
    margin: '5px 0px !important',
    border: '1px solid #009F42',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  timelineContent: {
    height: '44px',
    margin: '5px 0px !important',
    justifyContent: 'space-between',
    border: '1px solid #E0E0E0',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  timelineConnector: {
    borderBottom: '2px dashed #bdbdbd',
    backgroundColor: 'transparent !important',
  },
  timeLine: {
    padding: '0 !important',
    margin: '0px',
    marginBottom: '0.5rem',
  },
  timeLineSeparator: {
    paddingRight: '5px',
  },
  timeLineDot: {
    background: '#FFF !important',
    marginTop: '0px !important',
    marginBottom: '0px !important',
  },
  timeLineDotActive: {
    background: 'grey !important',
    marginTop: '0px !important',
    marginBottom: '0px !important',
  },
  timeLineDotApproved: {
    background: '#009F42 !important',
    marginTop: '0px !important',
    marginBottom: '0px !important',
  },
}));

export function TimeLineComponent({ children }: { children: React.ReactNode }): JSX.Element {
  let classes = useStyles();
  return (
    <Timeline className={classes.timeLine}>
      <TimelineItem className={classes.timelineItem}>
        <TimelineContent className={classes.activeTimelineContent}>{children}</TimelineContent>
      </TimelineItem>
    </Timeline>
  );
}

type TransactionError = {
  /** error code */
  errorCode: number;
  /** error message */
  errorMessage: string;
};

interface StakeActionProps {
  /** is booster pack available in particular cohort */
  isBoosterPackAvailable: boolean;
  /** isUserWantToBuyBoosterPack */
  isUserWantToBuyBoosterPack: boolean;
  /** farmTokenIcon */
  farmTokenIcon: string[];
  /** farmTokenName */
  farmTokenName: string;
  /** approvalCallback */
  approvalCallBackWithStatus: ApprovalResponse;
  /** approval callback for booster token if any */
  approvalCallBackForBoosterWithStatus: ApprovalResponse;
  /** mint without booster */
  mint: MintTransactionResponse;
  /** transaction error */
  transactionErrors: TransactionError;
  /** choosen booster */
  choosenBooster: Booster;
}

function StakeAction({
  isBoosterPackAvailable,
  isUserWantToBuyBoosterPack,
  farmTokenIcon,
  farmTokenName,
  approvalCallBackForBoosterWithStatus,
  approvalCallBackWithStatus,
  mint,
  choosenBooster,
}: StakeActionProps) {
  const classes = useStyles();

  //mint transaction approval
  let { approvalTxStatus, isApprovalRequired, triggeredApproval } = approvalCallBackWithStatus;

  console.log('-----------------------------------------------------------------');

  console.log('approvalTxStatus', approvalTxStatus);
  console.log('approvalCallBackWithStatus', approvalCallBackWithStatus);
  console.log('approvalCallBackForBoosterWithStatus', approvalCallBackForBoosterWithStatus);
  console.log('-----------------------------------------------------------------');

  // app chainId
  let { appChainId } = useApplicationUserState();

  // wrap token
  let wrappedToken = WRAPPED_TOKENS[appChainId];

  const [walleterror] = useState(false);
  const [boost, setBoost] = useState(false);
  const [success, setSuccess] = useState(false);
  const [open, setOpen] = useState(false);

  if (isBoosterPackAvailable && isUserWantToBuyBoosterPack) {
    return (
      // first step when user not approved
      isApprovalRequired ? (
        <Fragment>
          <DotWrapper>
            <TimelineDot className={classes.timeLineDotActive} />
            <TimelineConnector className={classes.timelineConnector} />
            <TimelineDot className={classes.timeLineDot} />
            <TimelineConnector className={classes.timelineConnector} />
            <TimelineDot className={classes.timeLineDot} />
          </DotWrapper>
          {walleterror ? (
            <ApproveError>
              <ErrorOutlineIcon style={{ width: '18px', height: '18px', color: '#c62828' }} />
              <ErrorText>Please approve the transaction from your wallet</ErrorText>
            </ApproveError>
          ) : (
            ''
          )}
          <TimeLineComponent>
            {farmTokenIcon?.map((icon, index) => (
              <IconDiv key={index}>
                <IconImg src={icon} alt={farmTokenName} />
              </IconDiv>
            ))}
            <VersionWrapper>Please approve the {farmTokenName}</VersionWrapper>
            <BoosterStakeButton onClick={() => triggeredApproval(ethers.constants.MaxUint256)}>
              {approvalTxStatus === ApprovalTransactionStatus.APPROVAL_LOADING ? (
                <>
                  <ClipLoader color="white" size={20} />
                  &nbsp; Approve {farmTokenName}
                </>
              ) : (
                `Approve ${farmTokenName}`
              )}
            </BoosterStakeButton>
          </TimeLineComponent>
          <ErrorSnackbar
            open={open}
            title="Staking unsuccessful"
            message="Transaction failed"
            severity="error"
            handleClose={() => setOpen(false)}
          />
        </Fragment>
      ) : approvalCallBackForBoosterWithStatus?.isApprovalRequired &&
        choosenBooster?.paymentToken?.toLowerCase() !== wrappedToken?.toLowerCase() ? (
        // second step for booster approval
        <Fragment>
          <DotWrapper>
            <TimelineDot className={classes.timeLineDotApproved} />
            <TimelineConnector className={classes.timelineConnector} />
            <TimelineDot className={classes.timeLineDotActive} />
            <TimelineConnector className={classes.timelineConnector} />
            <TimelineDot className={classes.timeLineDot} />
          </DotWrapper>
          {walleterror ? (
            <ApproveError>
              <ErrorOutlineIcon style={{ width: '18px', height: '18px', color: '#c62828' }} />
              <ErrorText>Please approve the transaction from your wallet</ErrorText>
            </ApproveError>
          ) : (
            ''
          )}
          <TimeLineComponent>
            <img src={choosenBooster?.icon} width={40} alt={choosenBooster?.name} />
            <VersionWrapper>Approve {choosenBooster?.name} Tokens</VersionWrapper>
            <BoosterStakeButton
              onClick={() => approvalCallBackForBoosterWithStatus?.triggeredApproval(ethers.constants.MaxUint256)}
            >
              {approvalCallBackForBoosterWithStatus?.approvalTxStatus === ApprovalTransactionStatus.APPROVAL_LOADING ? (
                <>
                  <ClipLoader color="white" size={20} />
                  &nbsp;Approve {choosenBooster?.name}
                </>
              ) : (
                <>
                  <ClipLoader color="white" size={20} />
                  &nbsp;Approve {choosenBooster?.name}
                </>
                // ` Approve ${choosenBooster?.name}`
              )}
            </BoosterStakeButton>
          </TimeLineComponent>
          <ErrorSnackbar
            open={open}
            title="Staking unsuccessful"
            message="Transaction failed"
            severity="error"
            handleClose={() => setOpen(false)}
          />
        </Fragment>
      ) : (
        // last step for minting NFT
        <Fragment>
          <DotWrapper>
            <TimelineDot className={classes.timeLineDotApproved} />
            <TimelineConnector className={classes.timelineConnector} />
            <TimelineDot className={classes.timeLineDotApproved} />
            <TimelineConnector className={classes.timelineConnector} />
            <TimelineDot className={classes.timeLineDotActive} />
          </DotWrapper>
          {walleterror ? (
            <ApproveError>
              <ErrorOutlineIcon style={{ width: '18px', height: '18px', color: '#c62828' }} />
              <ErrorText>Please approve the transaction from your wallet</ErrorText>
            </ApproveError>
          ) : (
            ''
          )}
          <TimeLineComponent>
            <VersionWrapper>Stake with Booster Package</VersionWrapper>
            <BoosterStakeButton onClick={() => mint.mintCallback()}>
              {mint.mintTransactionStatus === MintTransactionStatus.MINT_LOADING ? (
                <>
                  <ClipLoader color="white" size={20} />
                  &nbsp; Stake Now
                  <RocketLaunchOutlinedIcon style={{ marginLeft: '5px' }} />
                </>
              ) : (
                <>
                  Stake Now
                  <RocketLaunchOutlinedIcon style={{ marginLeft: '5px' }} />
                </>
              )}
            </BoosterStakeButton>
          </TimeLineComponent>
          <SuccessSnackBar
            open={success}
            title="Successfully boosted"
            message="Transaction boosted successfully"
            severity="success"
            handleClose={() => setSuccess(false)}
          />
          <ErrorSnackbar
            open={boost}
            title="Boosting failed"
            message="Error occured while boosting the transaction"
            severity="error"
            handleClose={() => setBoost(false)}
          />
        </Fragment>
      )
    ); // return end here
  } else {
    // non booster condition
    return isApprovalRequired ? (
      <Fragment>
        <DotWrapper>
          <TimelineDot className={classes.timeLineDotActive} />
          <TimelineConnector className={classes.timelineConnector} />
          <TimelineDot className={classes.timeLineDot} />
        </DotWrapper>
        {walleterror ? (
          <ApproveError>
            <ErrorOutlineIcon style={{ width: '18px', height: '18px', color: '#c62828' }} />
            <ErrorText>Please approve the transaction from your wallet</ErrorText>
          </ApproveError>
        ) : (
          ''
        )}
        <TimeLineComponent>
          {farmTokenIcon?.map((icon, index) => (
            <IconDiv key={index}>
              <IconImg src={icon} alt={farmTokenName} />
            </IconDiv>
          ))}
          <VersionWrapper>Please approve the {farmTokenName} tokens</VersionWrapper>
          <BoosterStakeButton onClick={() => triggeredApproval(ethers.constants.MaxUint256)}>
            {approvalTxStatus === ApprovalTransactionStatus.APPROVAL_LOADING ? (
              <>
                <ClipLoader color="white" size={18} />
                &nbsp;Approve {farmTokenName}
              </>
            ) : (
              `Approve ${farmTokenName}`
            )}
          </BoosterStakeButton>
        </TimeLineComponent>
      </Fragment>
    ) : (
      <Fragment>
        <DotWrapper>
          <TimelineDot className={classes.timeLineDotApproved} />
          <TimelineConnector className={classes.timelineConnector} />
          <TimelineDot className={classes.timeLineDotActive} />
        </DotWrapper>
        {walleterror ? (
          <ApproveError>
            <ErrorOutlineIcon style={{ width: '18px', height: '18px', color: '#c62828' }} />
            <ErrorText>Please approve the transaction from your wallet</ErrorText>
          </ApproveError>
        ) : (
          ''
        )}
        <TimeLineComponent>
          <VersionWrapper>Stake the token without booster pack</VersionWrapper>
          <BoosterStakeButton onClick={() => mint.mintCallback()}>
            {mint.mintTransactionStatus === MintTransactionStatus.MINT_LOADING ? (
              <>
                <ClipLoader color="white" size={20} />
                &nbsp; Stake
              </>
            ) : (
              ` Stake`
            )}
          </BoosterStakeButton>
        </TimeLineComponent>
        <SuccessSnackBar
          open={success}
          title="Successfully boosted"
          message="Transaction boosted successfully"
          severity="success"
          handleClose={() => setSuccess(false)}
        />
      </Fragment>
    );
  }
}

export default StakeAction;

import styled from 'styled-components';
export const HeaderDiv = styled.div`
  height: 180px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 30px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  @media only screen and (max-width: 600px) {
    flex-wrap: wrap;
    height: 375px;
    display: flex;
  }
`;

export const Spandivs = styled.div`
  height: 180px;
  width: 100%;
  color: black;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 13px;
  cursor: pointer;
  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    height: 200px;
  }
  @media (max-width: 430px) {
    width: 47%;
  }
`;

export const TokenIcon = styled.img`
  width: 38px;
  height: 50px;
  // @media (max-width: 430px) {
  //   width: 18px;
  //   height: 18px;
  // }
`;

export const TextHeader = styled.div`
  border-radius: 50px;
  background: #f0ebf8;
  border: 1px solid #a28ede;
  height: 80px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px auto;
`;

export const TableTitleDiv = styled.div`
  height: 50px;
  background: #f0ebf8;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px 5px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 2rem;
`;
interface LiquidityTableProps {
  isMyToken: boolean;
}
export const TableList = styled.div<LiquidityTableProps>`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #673ab7;
  display: flex;
  justify-content: start;
  width: ${(props: LiquidityTableProps) => (props.isMyToken ? '20%' : '25%')};
`;
export const NetworkTabList = styled.div<LiquidityTableProps>`
  width: ${(props: LiquidityTableProps) => (props.isMyToken ? '20%' : '25%')};
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #673ab7;
  display: flex;
  justify-content: start;
  @media(max-width:430px){
    width:50%;
    justify-content:${(props: LiquidityTableProps) => (props.isMyToken ? 'center' : 'start')};;
  }

`;
export const LiquidityList = styled.div<LiquidityTableProps>`
  width: ${(props: LiquidityTableProps) => (props.isMyToken ? '20%' : '25%')};
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #673ab7;
  display: flex;
  justify-content: start;
  @media (max-width: 430px) {
    display: none;
  }
`;
interface isPropsScrollWrapper {
  scroll: boolean;
}
export const ScrollWrapper = styled.div<isPropsScrollWrapper>`
  height: ${(props: isPropsScrollWrapper) => (props.scroll ? '550px' : null)};
  overflow-y: ${(props: isPropsScrollWrapper2) => (props.scroll ? 'scroll' : null)};
  overflow-x: hidden;
  width: 100%;
  margin-bottom: 3rem;
  @media (max-width: 430px) {
    width: 100%;
  }
`;
interface isPropsScrollWrapper2 {
  scroll: boolean;
}
export const ScrollWrapper2 = styled.div<isPropsScrollWrapper2>`
  height: ${(props: isPropsScrollWrapper2) => (props.scroll ? '400px' : null)};
  overflow-y: ${(props: isPropsScrollWrapper2) => (props.scroll ? 'scroll' : null)};
  overflow-x: hidden;
  margin-bottom: 3rem;
  @media (max-width: 430px) {
    width: 100%;
  }
`;
export const TextDiv = styled.div`
  text-align: left;
  color: #673ab7;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
`;
export const TextDiv2 = styled.div`
  text-align: left;
  margin-top: 30px;
  color: #673ab7;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
`;

export const Searchdiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 26px;
`;

export const Text = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #673ab7;
  text-align: left;
`;
export const NotFoundText = styled.h3`
  height: 179px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #80808033;
  /* margin-bottom: 3rem; */
  padding-bottom: 2rem;
`;

export const MainDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 24px;
  @media (max-width: 430px) {
    flex-wrap: wrap;

    width: 100%;
  }
`;
export const RemoveDiv = styled.div`
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-shadow: 4px 4px 25px #e0e0e0;
  border-radius: 10px;
  width:40%;
  margin-right: 12px;
  @media (max-width: 430px) {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 12px;
  }
  @media (width:1366px) {
    width:46%;
  }
`;
export const RewardDiv = styled.div`
  width: 60%;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-shadow: 4px 4px 25px #e0e0e0;
  border-radius: 10px;
  margin-left: 12px;
  height: 24.4rem;
  @media (max-width: 430px) {
    width: 100%;
    margin-left: 0px;
    margin-top: 12px;
  }
  @media (width:1366px) {
    width:54%;
  }
`;

export const LiquidityWrapper = styled.div`
  // width: 600px;
  margin: 0px auto;
  display: flex;
  background: #ffffff;
  // border: 1px solid #e0e0e0;
  border-radius: 15px;
  // margin-top: 1rem;
  @media (max-width: 430px) {
    flex-direction: column;
    width: 100%;
  }
`;
export const NetworkWrapper = styled.div`
  width: 100%;
  padding: 15px;
  @media (max-width: 430px) {
    width: 100%;
  }
`;

export const CardView = styled.div`
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-shadow: 4px 4px 25px #e0e0e0;
  border-radius: 10px;
  width: 600px;
  margin: 0px auto;
  margin-top: 1rem;
  height: 560px;
  @media (max-width: 430px) {
    width: 100%;
    height: auto;
  }
`;

export const BalanceDiv = styled.div`
  display: flex;
  justify-content: end;
  align-items: end;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #616161;
  //   margin-top: 25px;
  @media (max-width: 430px) {
    font-size: 12px;
    // margin-top: 1rem;
  }
`;

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 25px;
  @media (max-width: 430px) {
    gap: 1rem;
  }
`;
export const StepperDiv = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 430px) {
    margin-right: 7rem;
  }
  //
`;
export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const WarningIcon = styled.img`
  width: 35px;
  height: 35px;
  margin-top: 30px;
`;

export const InfoText = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #212121;
  text-align: center;
  margin-top: 25px;
  @media (max-width: 430px) {
    margin-top: 20px;
    font-size: 13px;
  }
`;

export const ButtonDiv = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 30px;
  margin-top: 25px;
  margin-bottom: 25px;
`;
export const YesBtn = styled.button`
  width: 200px;
  height: 50px;
  border: 1px solid #673ab7;
  border-radius: 10px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 26px;
  color: #673ab7;
  background: transparent;
  cursor: pointer;
  @media (max-width: 430px) {
    width: 130px;
    font-size: 14px;
  }
`;
export const BoostBtn = styled.button`
  width: 200px;
  height: 50px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 26px;
  color: #ffffff;
  background: #673ab7;
  box-shadow: 0px 7px 18px -2px rgba(103, 58, 183, 0.56);
  border: 1px solid #673ab7;
  border-radius: 10px;
  cursor: pointer;
  @media (max-width: 430px) {
    width: 130px;
    font-size: 14px;
  }
`;
export const MessagenWrapper = styled.div`
  padding-left: 25px;
  font-weight: 700;
  font-size: 20px;
  color: #212121;
  width: 100%;
  text-align: left;
  @media (max-width: 430px) {
    font-size: 12px;
  }
`;

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import 'antd/dist/antd.css';
import TransectionTable from 'components/SetuPool/Transaction/TransectionTable';
import TabGroup from 'components/V2/TabGroup';
import useQuery from 'hooks/useQuery';
import React, { useEffect, useState } from 'react';
// import THT from '../../../assets/V2/Images/tk1.png';
import BNB from '../../../assets/V2/Images/BnbNew.png';
import ETH from '../../../assets/V2/Images/EthNew.png';
// import PLY from '../../../assets/V2/Images/poly2.png';
import TransferModal from 'components/Setu/Transfer/TransferModal';
import BridgeContext from '../../../components/Setu/Context/BridgeContext';
import WithdrawButton from '../../../components/SetuTransaction/WithdrawButton';
import ChangeNetwork from './ChangeNetwork';
import ProcessInfo from './ProcessInfo';
import TokenDetails from './TokenDetails';
import ViewInfo from './ViewInfo';
import {
  BadgeStyle,
  BadgeWrapper,
  LiquidityList,
  NotFoundImage,
  NotFoundWrapper,
  ScrollWrapper,
  TableList,
  TableTitleDiv,
  Title,
} from './Style';
import PageHeader from 'components/PageHeader';
import NotFound from '../../../assets/V2/Images/Frame.png';
import setuHttp from 'utilities/http';
import { useWeb3React } from '@web3-react/core';
import networksData from 'utilities/getNetworkData';
// import { ethers } from 'ethers';
// import axios from "axios";
// import { sign } from 'crypto';


const BridgeTransaction = () => {
  const query = useQuery();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  console.log(mobile);
  // const tab = query.get('tab') as string;
  const [tabValue, setTabValue] = useState<number>();
  const [step, setStep] = useState(1);
  const [done, setDone] = useState(false);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState<number>();
  const [idArrary] = useState([]);
  const [transactionsData, setTransactionsData] = useState([]);
  const data1 = React.useContext(BridgeContext);
  const { account, chainId, library } = useWeb3React();

  const [transferObj, setTransferObj] = useState({
    amount: "",
    from: null,
    to: null,
    ticker: "",
    transferIndex: undefined,
    transactionHash: ""
  });

  // console.log(setStep);
  // console.log(done);
  const handleDone = () => {
    if (step == 3) {
      setDone(true);
    }
  };

  useEffect(() => {
    handleDone();
  }, []);
  
  const MobileClick = (index) => {
    if (mobile) {
      // console.log("index: ", index);
      setOpen(true);

      const obj = {
        amount: transactionsData[index]['no_of_tokens'],
        from: transactionsData[index]['from_chain'],
        to: transactionsData[index]['to_chain'],
        ticker: transactionsData[index]['token_ticker'],
        transferIndex: transactionsData[index]['transfer_index'],
        transactionHash: getTransactionHash(transactionsData[index])
      };
      setTransferObj(obj);
      console.log(obj)

      data1.setPrice(obj.amount);
      data1.setFromNetwork(networksData[obj.from].name);
      data1.setToNetwork(networksData[obj.to].name);
      data1.setFromImage(networksData[obj.from].logo);
      data1.setToImage(networksData[obj.to].logo);
      data1.setName(obj.ticker);
      data1.setImage(transactionsData[index]['token_image']);

      const status = transactionsData[index]['status'];
      if (!status || !chainId) return;

      if (status == 1 || (status == 2 && chainId !== obj.to)) {
        data1.setCount(status);
      } else {
        data1.setCount(status + 1);
      }
    }
  };

  console.log('transaction', data1.count);

  const stepnum = data1.count;
  const selectTokenName = data1.tokenName;
  const selectTokenImage = data1.tokenImage;
  const selectTokenPrice = data1.tokenPrice;
  // let url = "http://localhost:3001/api/transactions/"

  // let chainToNetwork = {
  //   1: 137,
  //   2: 56,
  //   3: 43114,
  //   4: 250,
  //   7: 1,
  //   5: 42161
  // }

  // const getImgChain = (num) => {
  //   if(num == 137) {
  //     return PLY;
  //   }
  //   if(num == 56) {
  //     return BNB;
  //   }
  //   if(num == 1) {
  //     return ETH;
  //   }
  // }

  useEffect(() => {
    if (!account) return;

    async function getTransactions() {
      try {
        let res;
        if (new URLSearchParams(location.search).get('tab') === 'pending')
          res = await setuHttp.get(`/api/pendingTransactions/${account}`);
        else res = await setuHttp.get(`/api/transactions/${account}`);
        console.log('transactions: ', res);
        setTransactionsData(res.data);
      } catch (error) { 
        console.log('Error in getTransactions(): ', error);
      }
    }

    getTransactions();
  }, [account, chainId, tabValue]);

  const [data] = useState([
    // {
    //   key: '1',
    //   Token: <TokenDetails icon={selectTokenImage} name={selectTokenName} ticker={selectTokenName} value={selectTokenPrice} />,
    //   Status: <ProcessInfo stepNumber={data1.count} />,
    //   Network: <ChangeNetwork network1={data1.fromNetworkImage} network2={data1.toNetworkImage} />,
    //   Claim: <WithdrawButton status={1} ticker={'ticker'} tokenImage={""} amount={10} from={137} to={2} transferIndex={0} />,
    //   Transaction: <ViewInfo txHash={""} />,
    // },
  ]);
  const useUniqId = (index) => {
    setId(index);
    data1.globalArray.push({
      index: index,
    });
  };
  const getTransactionHash = (item) => {
    if (item['status'] === 1) 
      return networksData[item['from_chain']].explorerUrl + item['tx_hash'];
    else 
      return networksData[item['to_chain']].explorerUrl + item['tx_hash'];
  };

  return (
    <>
    {/* {console.log('data1.globalArray',transactionsData)} */}
      <PageHeader title="Transaction" content={''} hasShowSwitch={false} />

      <div style={{ marginTop: '30px' }}>
        <div style={{ width: '100%', position: 'relative' }}>
          {mobile ? null : (
            <BadgeWrapper>
              <BadgeStyle badgeContent={data1.count < 3 ? data.length : 0} color="primary" />
            </BadgeWrapper>
          )}

          <TabGroup tabs={['All Transaction', 'Pending']} tabValue={0} setTabValue={setTabValue} />
        </div>
        <TableTitleDiv>
          {mobile ? (
            <TableList style={{ width: '200px' }}>Token Amount</TableList>
          ) : (
            <TableList style={{ width: '200px' }}>Token Name / Amount</TableList>
          )}

          <TableList style={{ width: mobile ? '191px' : '200px' }}>Status</TableList>
          <LiquidityList>Network</LiquidityList>
          <LiquidityList>Claim</LiquidityList>
          <LiquidityList style={{ width: '150px' }}>Transaction Hash</LiquidityList>
        </TableTitleDiv>
        <>
          {/* {console.log('transactionsData', id)} */}
          <ScrollWrapper>
            {transactionsData.length > 0 ? (
              transactionsData.map((item, index) => {
                // console.log('test', idArrary[id]?.index);
                return (
                  <TransectionTable
                    tokenName={
                      <TokenDetails
                        icon={item['token_image']}
                        name={item['token_name']}
                        ticker={item['token_ticker']}
                        value={item['no_of_tokens']}
                      />
                    }
                    status={<ProcessInfo stepNumber={item['status']} />}
                    network={<ChangeNetwork network1={item['from_chain']} network2={item['to_chain']} />}
                    claim={
                      <WithdrawButton
                        status={item['status']}
                        ticker={item['token_ticker']}
                        tokenImage={item['token_image']}
                        amount={item['no_of_tokens']}
                        from={item['from_chain']}
                        to={item['to_chain']}
                        transferIndex={item['transfer_index']}
                        isProgress={index == data1.globalArray[index]?.index}
                        clickWithdraw={() => useUniqId(index)}
                        transactionHash={getTransactionHash(item)}
                      />
                    }
                    transactionHash={<ViewInfo txHash={getTransactionHash(item)} />}
                    isLoading={false}
                    key={index}
                    index={index}
                    mobileRowClick={MobileClick}
                  />
                );
              })
            ) : (
              <NotFoundWrapper>
                <NotFoundImage src={NotFound} />
                <span>No Pending Transaction</span>
              </NotFoundWrapper>
            )}
          </ScrollWrapper>
        </>
        <TransferModal
          open={open}
          close={() => setOpen(false)}
          amount={transferObj.amount}
          from={transferObj.from}
          to={transferObj.to}
          ticker={transferObj.ticker}
          transferIndex={transferObj.transferIndex}
          transactionHash={transferObj.transactionHash}
        />
      </div>
    </>
  );
};

export default BridgeTransaction;

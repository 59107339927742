//import { parseBytes32String } from 'ethers/lib/utils';
import { Interface, LogDescription } from 'ethers/lib/utils';
import { isEmpty, unitFormatter } from '..';
import { Cohort, ProtocolConfiguration, Token, Booster } from '../../graphql/V2/typings';
import { Booster as BoosterYF2, TokenMetaDataYF2, CohortYF2, ProtocolConfig as ProtocolConfigYf2 } from './types';
import MANAGER_ABI from '../../constants/ABI/UNIFARMNFTMANAGER.json';
import CLAIM_ABI from '../../constants/ABI/CLAIMANDTRANSFER.json';
import { Log } from '@ethersproject/providers';
import { explolers } from 'constants/chain';
import { BigNumber } from 'ethers';
import { TokenMetaDataV2 } from 'store/lists/reducer';
import { Page } from 'components/V2/Views/shared/types';
import BoostIcon from '../../assets/V2/Images/boost2.png';
import CalculatorIcon from '../../assets/V2/Images/calc.png';

// max reward show
let MAX_REWARD_SHOW = 3;

/**
 * Get NFT Manager Interface
 * @returns
 */

export const getManagerInterface = () => {
  return new Interface(MANAGER_ABI);
};

/**
 * Get Claim Interface
 */
export const getClaimInterface = () => {
  return new Interface(CLAIM_ABI);
};

/**
 * format protocol configuration
 * @param protocolConfig
 * @returns
 */

const formatProtocolConfig = (protocolConfig: ProtocolConfiguration): ProtocolConfigYf2 => {
  if (isEmpty(protocolConfig)) return null;
  return {
    ...protocolConfig,
    refPercentage: parseFloat(protocolConfig.referralPercentage),
  };
};

/**
 * format boosters in particular cohort
 * @param boosters
 * @returns
 */

const formatBoosters = (boosters: Booster[]): BoosterYF2[] => {
  if (isEmpty(boosters)) return null;
  return boosters.map((items) => {
    return {
      id: items.id,
      bpid: parseFloat(items.bpid),
      paymentToken: items.paymentToken,
      boosterPackAmount: BigNumber.from(items.boosterPackAmount),
      numberOfBoostedUser: parseFloat(items.numberOfBoostedUser),
      boosterSell: items.boosterSell,
    };
  });
};

/**
 * format cohort details
 * @param cohort
 * @returns
 */

const formatCohortDetails = (cohort: Cohort): CohortYF2 => {
  if (isEmpty(cohort)) return null;
  return {
    id: cohort.id,
    protocolConfig: formatProtocolConfig(cohort.protocolConfig),
    cohortVersion: cohort.cohortVersion,
    startBlock: parseFloat(cohort.startBlock),
    endBlock: parseFloat(cohort.endBlock),
    epochBlocks: parseFloat(cohort.epochBlocks),
    hasLiquidityMining: cohort.hasLiquidityMining,
    hasContainsWrappedToken: cohort.hasContainsWrappedToken,
    hasCohortLockinAvaliable: cohort.hasCohortLockinAvaliable,
    numberOfFarms: parseFloat(cohort.numberOfFarms),
    numberOfBoostedUsers: parseFloat(cohort.numberOfBoostedUsers),
    rewards: cohort.rewards,
    boosters: formatBoosters(cohort.boosters),
    deployedAt: parseFloat(cohort.deployedAt),
  };
};

/**
 * format farm token metadata
 * @param token
 * @returns
 */

const formatFarmTokenMetaDataDetails = (token: Token): TokenMetaDataYF2 => {
  if (isEmpty(token)) return null;
  const decimals = parseFloat(token.decimals);
  return {
    id: token.id,
    fid: parseFloat(token.fid),
    farmToken: token.farmToken,
    userMinStake: unitFormatter(token.userMinStake, decimals),
    userMaxStake: unitFormatter(token.userMaxStake, decimals),
    totalStakeLimit: unitFormatter(token.totalStakeLimit, decimals),
    decimals: decimals.toString(),
    skip: token.skip,
  };
};

/**
 * format farm token details
 * @param token
 * @returns
 */

export const formatFarmTokenDetails = (token: Token): { cohort: CohortYF2; token: TokenMetaDataYF2 } => {
  if (isEmpty(token)) return null;
  return {
    cohort: formatCohortDetails(token.cohort),
    token: formatFarmTokenMetaDataDetails(token),
  };
};

/**
 * get NFT Manager Encode Data
 * @param iface
 * @param methodName
 * @param values
 * @returns
 */
export const getManagerEncodeCallData = (iface: Interface, methodName: string, values?: any[]): string => {
  return iface.encodeFunctionData(methodName, values);
};

/**
 * get Claim Encode Data
 */
export const getClaimEncodeCallData = (iface: Interface, methodName: string, values?: any[]): string => {
  return iface.encodeFunctionData(methodName, values);
};

/**
 * Get Event parsed Logs
 * @param iface
 * @param logs
 * @returns
 */

export const getParsedLogs = (iface: Interface, logs: Log[]): LogDescription[] => {
  let events = [] as LogDescription[];
  for (var k = 0; k < logs.length; k++) {
    events.push(iface.parseLog(logs[k]));
  }
  return events;
};

/**
 * generate Transaction URL
 * @param chainId
 * @param transactionHash
 * @returns
 */

export const generateTransactionUrl = (chainId: number, transactionHash: string) => {
  if (!chainId) return null;
  return `${explolers[chainId]}/tx/${transactionHash}`;
};

/**
 * Returns flatten rewards
 * @param rewards
 * @returns
 */

type Reward = {
  name: string;
  icon: string;
};

export const flatRewards = (rewards: TokenMetaDataV2[]): [Reward[], Reward[]] => {
  // construct reward tokens
  let rewardTokens = rewards?.map((rewardToken) => {
    return {
      name: rewardToken?.name,
      icon: rewardToken?.icon,
    };
  });

  return [rewardTokens?.slice(0, MAX_REWARD_SHOW), rewardTokens?.slice(MAX_REWARD_SHOW, rewardTokens.length)];
};

export const computeBoosterButtonContent = (
  /** page */
  page: Page,
  /** is booster available in cohort */
  isBoosterAvailable: boolean,
  /** has booster buyed by the user */
  hasBoosterBuyed?: boolean,
  /** booster apy */
  boostedAPY?: number
): string => {
  if (boostedAPY === undefined) return undefined;
  // when page is all farms and booster is not avaliable simply showing APY calculator
  if (page === Page.ALLFARMS && !isBoosterAvailable) {
    return 'Apy Calculator';
  }
  // when page is all farms and booster is available
  else if ((page === Page.ALLFARMS && isBoosterAvailable) || (Page.MYSTAKES && isBoosterAvailable && !hasBoosterBuyed)) {
    return `Boost upto ${boostedAPY}%`;
  }

  // when page is my stakes and booster is not available
  else if (page === Page.MYSTAKES && !isBoosterAvailable) {
    return null;
  }

  // when page is my stakes and booster is available, booster has buyed by the user
  else if (page === Page.MYSTAKES && isBoosterAvailable && hasBoosterBuyed) {
    return 'Boosted';
  }

  // for any thing
  else {
    return null;
  }
};

export const computeBoosterButtonIcon = (
  /** page */
  page: Page,
  /** is booster available in cohort */
  isBoosterAvailable: boolean
) => {
  if (page === Page.MYSTAKES && isBoosterAvailable) {
    return BoostIcon;
  } else {
    return CalculatorIcon;
  }
};

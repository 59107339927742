// V1 Proxies
export const V1PROXY: string = "0xe8369CA802f89b16A968ccb24431A7A412F41f27";
export const V1REPROXY: string = "0x0862eD7f6B2bc350508B29542511249b7E11A0a0";

// V2 Proxies
export const V2PROXY: string = "0xdb6cf8acca5031eb42e283fa441a4c9ada5cc8d9";
export const V2REPROXY: string = "0x152A8d34c5C4540645443A63Bd8C1d395543BdC2";
export const V2REPROXY_UPGRADE: string = "0x652862b61C71C8D955cCBd973d2830C68e46CE78";

// V3 PROXIES
export const V3PROXY: string = "0x559CD5B11Ca882CEDda823Ac06275558A92b7064";
export const V3REPROXY: string = "0xeae5ce18fe34d1ee46c67f326c4a7f2038e6bd78";
export const V3REPROXY_UPGRADE: string = "0xd04dde065fe872adb6517b4bb0c11eaf49ce26a5";

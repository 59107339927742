import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Modal from '../../Modal/index';
import Network from '../../Setu/Network/Network';
import { IconDiv, IconImg, IconName, IconWrapper, InputWrapper, NetworkButton, NetworkWrapper } from './AddLiquidityStyled';
import networksData from '../../../utilities/getNetworkData';

const useStyle = makeStyles((theme) =>
  createStyles({
    Root: {
      '&>.MuiDialog-container>.MuiPaper-root.MuiDialog-paperWidthSm ': {
        width: '490px !important',
        borderRadius: '15px !important',
      },
      [theme.breakpoints.down('xs')]: {
        '&>.MuiDialog-container>.MuiPaper-root.MuiDialog-paper': {
          width: '100% !important',
          margin: '15px !important',
        },
      },
    },
  })
);

interface Props {
  chosenNetwork?: any;
  networks?: number[];
  handledoor: (newValue: any) => void;
}

const ChooseNetwork = ({ chosenNetwork, networks, handledoor }: Props) => {
  const classes = useStyle();
  const [open, setOpen] = useState(false);
  // const [choosenNetwork, setChoosenNetwork] = useState(1);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // useEffect(() => {
  //   if(networks)
  //     setChoosenNetwork(networks[0]);
  // }, [networks])

  const handleChange = (newValue: number) => {
    // setChoosenNetwork(newValue);
    handledoor(newValue);
    handleClose();
  };

  // const Data = [
  //   { logo: ETH, name: 'ETHEREUM' },
  //   { logo: PLY, name: 'POLYGON' },
  //   { logo: BNB, name: 'BINANCE SMART CHAIN' },
  //   { logo: AVL, name: 'AVALANCHE' },
  //   { logo: FTM, name: 'FANTOM' },
  // ];

  return (
    <>
      <NetworkButton onClick={handleOpen}>
        <InputWrapper>
          <IconWrapper>
            <IconDiv>
              <IconImg src={networksData[chosenNetwork].logo} />
            </IconDiv>
            <IconName>{networksData[chosenNetwork].name}</IconName>
          </IconWrapper>
          <ArrowDropDownIcon style={{ color: '#000000' }} />
        </InputWrapper>
      </NetworkButton>
      <Modal open={open} close={handleClose} title="Choose Network" className={classes.Root} headerClass="SettingHeader">
        <NetworkWrapper>
          {networks &&
            networks.map((item, index) => (
              <Network
                key={index}
                id={item}
                icon={networksData[item].logo}
                title={networksData[item].name}
                choosen={chosenNetwork}
                handleClick={handleChange}
                networkDisable={chosenNetwork}
              />
            ))}
        </NetworkWrapper>
      </Modal>
    </>
  );
};

export default ChooseNetwork;

import { IconButton, LinearProgress, Snackbar, Step, StepLabel, Stepper } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ShareIcon from '@material-ui/icons/Share';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import SocialShare from 'components/V2/SocialShare';
import React, { useEffect, useState } from 'react';
import { ClipLoader } from 'react-spinners';
import styled from 'styled-components';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
// import Eth from '../../../assets/V2/Images/Eth.png';
// import Ply from '../../../assets/V2/Images/poly2.png';
// import THT from '../../../assets/V2/Images/tk1.png';
import Modal from '../../Modal/index';
import BridgeContext from '../Context/BridgeContext';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Confetti from 'react-confetti';
// import { ethers } from 'ethers';
// import BridgeRegistry from '../interfaces/BridgeRegistryAbi.json';
// import Bridge from '../interfaces/Bridge.json';
import ConfirmToken from '../../SetuPool/AddLiquidity/ConfirmToken';
import {
  ConfirmButton,
  ConfirmButtonDiv,
  CongratulationsDiv,
  CongratulationsText,
  CurrentWallet,
  ErrorDiv,
  ExplorerShareWrap,
  ExplorerWrap,
  FeesDiv,
  FeesName,
  IconImage,
  InfoLeft,
  ProcessDetails,
  ProcessTokenIcon,
  ProcessTokenName,
  RootCard,
  SucessDetails,
  TokenDetails,
  TokenLeftValue,
  TokenProcessDiv,
  TokenProcessedDiv,
  TokenValue,
  TransferProcess,
  WalletChangeText,
} from './TransferStyled';
import { getContract } from 'utilities/getContract';
import { bridgeAddress, bridgeRegistry, bridgeUtilsAddress, feePoolAddress, mapNetworkIdToChainId } from 'constants/bridge';
import bridgeRegistryABI from '../../../constants/ABI/bridge/BridgeRegistryAbi.json';
import bridgeUtilsABI from '../../../constants/ABI/bridge/BridgeUtilsUpgradeable.json';
import bridgeABI from '../../../constants/ABI/bridge/Bridge.json';
import tokenABI from '../../../constants/ABI/bridge/Token.json';
import { getJsonProvider } from 'utilities/provider';
import networksData from 'utilities/getNetworkData';
import { useWeb3React } from '@web3-react/core';
import { useChangeAppChainId } from 'store/user/hooks';
import { ethers } from 'ethers';
import BigNumber from 'bignumber.js';
import setuHttp from 'utilities/http';
import { unitFormatter } from 'utilities';
import { round } from 'lodash';
import { Alert } from '@mui/material';
import Close from '@material-ui/icons/Close';
import ReactiveButton from 'reactive-button';
import { connectFirestoreEmulator } from 'firebase/firestore';

// import ArrowAnimation from './ArrowAnimation';

// import SuccessSnackBar from 'components/Snackbar/SuccessSnackBar';

interface IsTransferModal {
  open: boolean;
  close: () => void;
  amount: any;
  from: number;
  to: number;
  ticker: string;
  transferIndex?: any;
  transactionHash?: any;
}

const TransferModal = ({ open, close, amount, from, to, ticker, transferIndex, transactionHash }: IsTransferModal) => {
  //left over state ----->
  const [isInfoLeft, setisInfoLeft] = useState(false);
  const [isLeftToken, setIsleftToken] = useState(false);
  const [isLeftToken2, setIsleftToken2] = useState(false);
  const [isLeftTransefer, setTransfer] = useState(false);
  const [isActiveLeft, setActiveLeft] = useState(false);
  const [state, setState] = useState('idle');
  //end left over state ---->
  const data1 = React.useContext(BridgeContext);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const useStyle = makeStyles((theme) =>
    createStyles({
      Root: {
        '&>.MuiDialog-container>.MuiPaper-root.MuiDialog-paperWidthSm ': {
          width: '620px !important',
          borderRadius: '15px !important',
          maxWidth: '620px !important',
        },
        [theme.breakpoints.down('xs')]: {
          '&>.MuiDialog-container>.MuiPaper-root.MuiDialog-paper': {
            width: '100% !important',
            margin: '15px !important',
          },
        },
      },
      Step: {
        '& .MuiStepIcon-active': { color: isActiveLeft && data1.count === 2 ? 'red' : '#673AB7' },
        '& .MuiStepIcon-completed': { color: 'rgba(0, 159, 66, 1)' },
        '& .Mui-disabled .MuiStepIcon-root': { color: '#616161' },
        [theme.breakpoints.down('xs')]: {
          paddingLeft: '0px',
          paddingRight: '0px',
          '& .MuiStepIcon-completed': { height: 17 },
          '& .MuiStepLabel-iconContainer': { paddingRight: 0 },
          '& .MuiStepIcon-active': { height: 17 },
          '& .Mui-disabled .MuiStepIcon-root': { height: 17 },
          '& .MuiStepLabel-label.MuiStepLabel-active': { fontSize: 11, fontWeight: 600 },
          '& .MuiStepLabel-label.MuiStepLabel-completed': { fontSize: 11, fontWeight: 600 },
          '& .MuiStepLabel-label': { fontSize: 11, fontWeight: 600 },
        },
      },
      MainStep: {
        [theme.breakpoints.down('xs')]: {
          paddingLeft: '0px',
          paddingRight: '0px',
        },
      },
      ProgresBar: {
        '&>svg circle': {
          color: '#673ab7',
        },
      },
      disableButton: {
        '&:hover': {
          cursor: 'pointer !important',
        },
      },
    })
  );
  const classes = useStyle();

  const [activeStep, setActiveStep] = useState(data1.count);
  const [loading, setLoding] = useState(false);
  const [error, setError] = useState(false);

  const [IsSocialShare, setSocialShare] = useState(false);
  const [progress, setProgress] = React.useState(0);
  const [networkIndex, setNetworkIndex] = React.useState(0);
  const [feeType, setFeeType] = useState(0);
  const [depositFees, setDepositFees] = useState('');
  const [depositFeesToken, setDepositFeesToken] = useState('');
  const [isRouterConfirmed, setIsRouterConfirmed] = useState(true);
  const [loadingbutton] = useState(false);

  const { account, chainId, library } = useWeb3React();

  const changeAppNetwork = useChangeAppChainId();
  const [txHash, setTxHash] = useState(transactionHash);

  const [supportedNetworks, setSupportedNetworks] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectNetworkName, setNetworkName] = useState();
  const [selectImageNetwork, setImageNetwork] = useState();
  const [snackbarError, setSnackbarError] = useState('');

  useEffect(() => {
    if (!ticker) return;

    async function getNetworksData() {
      try {
        const res: any = await setuHttp.get(`/api/token/${ticker}`);
        // console.log("RES: ", res);
        const list = res.data.networks.filter((network) => network != to && network != from);
        setSupportedNetworks(list);
        setTxHash(transactionHash)
      } catch (error) {
        console.log('Error in getNetworksData(): ', error);
      }
    }

    getNetworksData();
  }, [ticker]);

  useEffect(() => {
    if (!to || !amount || !ticker || !open) return;
    setActiveStep(data1.count);
    console.log('COUNT: ', data1.count);
    async function getTransferFees() {
      try {
        console.log('TO_NETWORK: ', to);
        let bridgeInstance = getContract(bridgeAddress(to), bridgeABI.abi, getJsonProvider(to));
        let maxBips = await bridgeInstance.maxBips();
        console.log('maxBips: ', maxBips.toString());

        let bridgeUtilsInstance = getContract(bridgeUtilsAddress(to), bridgeUtilsABI.abi, getJsonProvider(to));
        let feeConfig = await bridgeUtilsInstance.getFeeTypeAndFeeInBips(ticker);
        console.log('FeeType: ', feeConfig[0]);
        console.log('feeInBips: ', feeConfig[1].toString());
        setFeeType(feeConfig[0]);

        if (feeConfig[0] == 0) {
          setDepositFees(feeConfig[1].toString());
          setDepositFeesToken(networksData[to].token);
        } else {
          // let fees = BigInt(ethers.utils.parseUnits(amount.toString(), 18).toString()) * BigInt(feeConfig[1].toString()) / BigInt(maxBips.toString());
          let fees = (BigInt(amount) * BigInt(feeConfig[1].toString())) / BigInt(maxBips.toString());
          setDepositFees(fees.toString());
          setDepositFeesToken(ticker);
        }
      } catch (error) {
        console.log('Error in getTransferFees(): ', error);
      }
    }

    getTransferFees();
  }, [to, amount, ticker, open, data1]);

  const CircularProgressWithLabel = (props: CircularProgressProps & { value: number }) => {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" {...props} className={classes.ProgresBar} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  };
  const onClickHandler = () => {
    setState('loading');
    setTimeout(() => {
      setState('success');
    }, 2000);
  };

  const handleChange = (event) => {
    console.log('handleChange(): ', event.target.value);
    setNetworkName(networksData[event.target.value].name);
    setImageNetwork(networksData[event.target.value].logo);
    setNetworkIndex(event.target.value);
    // setIsleftToken2(true);
    setIsleftToken(false);
    // data1.setCount(1);
  };
  // console.log(setError);

  const nextStep = async () => {
    console.log('Click confirm transfer button');
    setIsRouterConfirmed(false)
    data1.setRouterProtocol(false)
    setLoding(true);

    try {
      const provider = getJsonProvider(isLeftTransefer ? networkIndex : to);
      let destGasPrice = await provider.getGasPrice();
      console.log('Gas Price: ', destGasPrice.toString());

      let bridgeInstance;
      if (isLeftTransefer) {
        console.log('In nextStep() ', networkIndex, mapNetworkIdToChainId(networkIndex), amount.toString());
        bridgeInstance = new ethers.Contract(bridgeAddress(chainId), bridgeABI.abi, library);
        let bridgeInstanceWithSigner = await bridgeInstance.connect(library.getSigner(account));

        const tx = await bridgeInstanceWithSigner.crossChainTransferOut(
          ticker,
          amount,
          mapNetworkIdToChainId(networkIndex),
          destGasPrice
        );
        await tx.wait();
      } else {
        bridgeInstance = getContract(bridgeAddress(chainId), bridgeABI.abi, library, account);
        console.log('Amount: ', amount, round(unitFormatter(amount, 18), 3), chainId, to, ticker, destGasPrice.toString());
        let tx = await bridgeInstance.transferIn(ticker, amount, mapNetworkIdToChainId(to), destGasPrice);
        await tx.wait(2);
        // console.log("Tx: ", tx);
        // console.log("Receipt: ", await getJsonProvider(chainId).getTransactionReceipt(tx.hash));
      }

      let data = data1.update();
      setActiveStep(data);

      let bridgeUtilsInstance = getContract(bridgeUtilsAddress(from), bridgeUtilsABI.abi, getJsonProvider(from));
      let hash = await bridgeUtilsInstance.getCurrentTransferIndexHash(
        ticker,
        account,
        mapNetworkIdToChainId(from),
        mapNetworkIdToChainId(to)
      );
      let currentTransferIndex = (await bridgeInstance.currentTransferIndex(hash)) - 1;
      console.log('Transfer Index: ', currentTransferIndex);

      const getRouterConfirmation = setInterval(async () => {
        const obj = {
          ticker,
          account,
          from,
          to,
          currentTransferIndex,
        };
        const res = await setuHttp.post('/api/checkTransaction/', obj);
        console.log(obj, res.data);
        if (res.data) {
          console.log('res.data', res.data);
          setIsRouterConfirmed(true);
          data1.setRouterProtocol(true)
          clearInterval(getRouterConfirmation);
        } 
      }, 5000);
    } catch (error) {
      console.log('Error in nextStep(): ', error);
      if (error?.code === 4001 || error?.code === "ACTION_REJECTED") setSnackbarError('User denied the transaction');
      else setSnackbarError('Some error occurred');
      setSnackbarOpen(true);
    }
    setLoding(false);

    // let data = data1.update();
    // setActiveStep(data);
    // let i = 0;
    // const getRouterConfirmation = setInterval(async () => {
    //   const obj = {
    //     ticker,
    //     account,
    //     from,
    //     to
    //   }
    //   ++i;
    //   const res = await setuHttp.post("/api/checkTransaction/", obj);
    //   console.log(res, obj, i);
    //   if(i==4)
    //     clearInterval(getRouterConfirmation);
    // }, 5000);

    // setTimeout(() => {
    //   let data = data1.update();
    //   setActiveStep(data);
    //   setLoding(false);
    // }, 1000);
    // console.log('----------transfer confirm  Props -------');
    // console.log('amount get by confirm button props:=>', amount);
    // console.log('from get by confirm button props:=>', from);
    // console.log('to get by confirm button porps:=>', to);
    // console.log('ticker get by confirm button props:=>', ticker);
  };
  React.useEffect(() => {
    if (data1.isWidthdraw) {
      //check for condition after 2 min 90% stop and get true so fill button
      //  setTimeout(() => {
      //   setIsRouterConfirmed(true)
      //  }, 500);
      // const clear = setInterval(
      //   () =>
      //     data1.setProgress((prev) => {
      //       console.log('in', isRouterConfirmed);
      //       if (prev == 90) {
      //         clearInterval(clear);
      //       }

      //       return prev + 1;
      //     }),
      //   100
      // );
    }
  }, [data1.isWidthdraw]);
  const nextStep2 = async () => {
    console.log('Click Switch network button');
    setLoding(true);
    const clear = setInterval(
      () =>
        data1.setProgress((prev) => {
          if (prev === 90) {
            clearInterval(clear);
            return 90
          }

          return prev + 1;
        }),
      5000
    );
    // if (isInfoLeft) {
    //   setTimeout(() => {
    //     setTransfer(true);
    //     setLoding(false);
    //   }, 1000);
    // } else {
    //   await changeAppNetwork(to);

    //   let data = data1.update();
    //   setActiveStep(data);
    //   setLoding(false);
    // }

    let res;
    if (isLeftTransefer) res = await changeAppNetwork(networkIndex);
    else res = await changeAppNetwork(to);

    if (res) {
      let data = data1.update();
      setActiveStep(data);
      // setLoding(false);
      data1.setWidthDraw(false);
      data1.setWidthDraw(true);
      setProgress(0);
    }
    setLoding(false);

    // setTimeout(() => {
    //   let data = data1.update();
    //   setActiveStep(data);
    //   setLoding(false);
    // }, 1000);
  };

  const nextStep3 = async () => {
    console.log('Click withdraw token Button, ', amount);
    if (isInfoLeft) {
      setIsleftToken(true);
      setIsleftToken2(true);
      setisInfoLeft(false);
    } else {
      setLoding(true);

      console.log('TRANSFER_INDEX: ', transferIndex);

      try {
        // to get initial setu token balance
        let bridgeRegistryInstance = new ethers.Contract(bridgeRegistry(chainId), bridgeRegistryABI.abi, library);
        let setuTokenMetadata = await bridgeRegistryInstance.bridgeTokenMetadata('setu' + ticker);
        const tokenInstance = getContract(setuTokenMetadata['tokenAddress'], tokenABI.abi, library, account);
        let initialBalance = await tokenInstance.balanceOf(account);
        // let initialBalance = ethers.BigNumber.from('0');

        // instance for the source chain

        let bridgeInstance, currentTransferIndex;
        if (transferIndex === undefined) {
          bridgeInstance = getContract(bridgeAddress(from), bridgeABI.abi, getJsonProvider(from));
          let bridgeUtilsInstance = getContract(bridgeUtilsAddress(from), bridgeUtilsABI.abi, getJsonProvider(from));
          // console.log("FROM: ", from, ticker, account, mapNetworkIdToChainId(to), bridgeInstance);
          let hash = await bridgeUtilsInstance.getCurrentTransferIndexHash(
            ticker,
            account,
            mapNetworkIdToChainId(from),
            mapNetworkIdToChainId(to)
          );
          currentTransferIndex = (await bridgeInstance.currentTransferIndex(hash)) - 1;
        } else {
          currentTransferIndex = transferIndex;
        }
        console.log('currentTransferIndex: ', currentTransferIndex);

        // instance for the destination chain (currently active)
        bridgeInstance = getContract(bridgeAddress(chainId), bridgeABI.abi, library, account);
        let tx = await bridgeInstance.crossChainTransferIn(
          amount,
          ticker,
          currentTransferIndex,
          mapNetworkIdToChainId(from),
          {
            value: feeType == 0 ? depositFees : 0,
          }
        );
        // setTxHash(tx.hash);
        setTxHash(networksData[chainId].explorerUrl + txHash);
        await tx.wait(3);

        // to check the setu token balance after crossChainTransferIn
        let finalBalance = await tokenInstance.balanceOf(account);
        console.log('setu' + ticker, ' Final Balance: ', finalBalance);

        // condition based on remaining setu balance
        if (finalBalance.gt(initialBalance) && finalBalance.sub(initialBalance).gt(0)) {
          // if(true) {
          amount = finalBalance.sub(initialBalance);
          setisInfoLeft(true);
        } else {
          let data = data1.update();
          setActiveStep(data);
          console.log('last', activeStep);
        }
      } catch (error) {
        console.log('Error in nextStep3(): ', error);
        if (error?.code === 4001 || error?.code === "ACTION_REJECTED") setSnackbarError('User denied the transaction');
        else setSnackbarError('Some error occurred');
        setSnackbarOpen(true);
      }

      setLoding(false);

      // setTimeout(() => {
      //   let data = data1.update();
      //   setActiveStep(data);
      //   console.log('last', activeStep);
      //   setLoding(false);
      // }, 1000);
    }
  };

  const LeftTransfer = () => {
    setLoding(true);
    setTimeout(() => {
      let data = data1.update();
      setActiveStep(data);
      console.log('last', activeStep);
      setTransfer(false);
      setActiveLeft(true);
      setLoding(false);
    }, 1000);
  };
  const Procced = () => {
    // setIsleftToken(false);
    setIsleftToken2(false);

    from = to;
    data1.setFromNetwork(networksData[from].name);
    data1.setFromImage(networksData[from].logo);
    to = networkIndex;
    console.log('from, to, networkIndex: ', from, to, networkIndex);
    data1.setToNetwork(networksData[to].name);
    data1.setToImage(networksData[to].logo);

    // setActiveStep(data1.count);
    setActiveStep(1);
    data1.setCount(1);
    setLoding(false);
    setTransfer(true);
  };
  const ApproveToken = async () => {
    setLoding(true);
    // console.log('----------Aprove Props -------');
    // console.log('amount get by Approve button props:=>', amount);
    // console.log('from get by Approve button props:=>', from);
    // console.log('to get by Approve button porps:=>', to);
    // console.log('ticker get by Approve button props:=>', ticker);

    try {
      // const tokenInstance = getContract(
      //   tokenAddress,
      //   tokenABI.abi,
      //   library,
      //   account
      // );
      // let allowance = await tokenInstance.allowance(account, bridgeAddress(chainId));
      // if(allowance < BigInt(amount)) {
      //   let tx = await tokenInstance.approve(bridgeAddress(chainId), amount);
      //   await tx.wait();
      // }

      let data = data1.update();
      setActiveStep(data);
    } catch (error) {
      console.log('Error in ApproveToken(): ', error);
    }
    setLoding(false);

    // setTimeout(() => {
    //   let data = data1.update();
    //   setActiveStep(data);
    //   setLoding(false);
    // }, 1000);
  };
  function showStep(activeStep) {
    switch (activeStep) {
      case 0:
        return (
          <ConfirmButtonDiv>
            <ConfirmButton
              style={{
                background: loading ? '#EFEFEF' : '#673ab7',
                boxShadow: loading ? 'none' : '0px 7px 18px -2px rgba(103, 58, 183, 0.56)',
                color: loading ? '#616161' : '#ffffff',
              }}
              onClick={nextStep}
            >
              {loading ? <ClipLoader size={20} color={'#616161'} /> : ''}
              Confirm Transfer
            </ConfirmButton>
          </ConfirmButtonDiv>
        );
      case 1:
        return (
          <>
            <ProcessDetails>
              Moving funds from {data1.fromNetworkName} to {data1.toNetworkName} Deposit of funds takes ~7-8 minutes
            </ProcessDetails>

            {error ? (
              <>
                <ErrorDiv>
                  <ErrorOutlineIcon style={{ color: '#C62828' }} />
                  Please approve the transaction from your wallet
                </ErrorDiv>
              </>
            ) : (
              ''
            )}
            <ConfirmButtonDiv>
              <ConfirmButton
                style={{
                  background: loading ? '#EFEFEF' : '#673ab7',
                  boxShadow: loading ? 'none' : '0px 7px 18px -2px rgba(103, 58, 183, 0.56)',
                  color: loading ? '#616161' : '#ffffff',
                }}
                onClick={nextStep}
              >
                {loading ? <ClipLoader size={20} color={'#616161'} /> : ''}
                Confirm Transfer
              </ConfirmButton>
            </ConfirmButtonDiv>
          </>
        );
      case 2:
        return (
          <>
            {isLeftTransefer ? (
              <ProcessDetails>
                Moving funds from {data1.fromNetworkName} to {data1.toNetworkName} Deposit of funds takes ~7-8 minutes
              </ProcessDetails>
            ) : (
              <>
                <WalletChangeText>Allow us to switch your network on to {data1.toNetworkName}</WalletChangeText>
                <CurrentWallet>Currently on {data1.fromNetworkName}</CurrentWallet>
              </>
            )}

            {error ? (
              <>
                <ErrorDiv>
                  <ErrorOutlineIcon style={{ color: '#C62828' }} />
                  Please approve the transaction from your wallet
                </ErrorDiv>
              </>
            ) : (
              ''
            )}
            <ConfirmButtonDiv>
              {/* {isLeftTransefer ? (
                <ConfirmButton
                  style={{
                    background: loading ? '#EFEFEF' : '#673ab7',
                    boxShadow: loading ? 'none' : '0px 7px 18px -2px rgba(103, 58, 183, 0.56)',
                    color: loading ? '#616161' : '#ffffff',
                  }}
                  onClick={LeftTransfer}
                >
                  {loading ? <ClipLoader size={20} color={'#616161'} /> : ''}
                  Confirm Transfer
                </ConfirmButton>
              ) : (
                <ConfirmButton
                  style={{
                    background: loading ? '#EFEFEF' : '#673ab7',
                    boxShadow: loading ? 'none' : '0px 7px 18px -2px rgba(103, 58, 183, 0.56)',
                    color: loading ? '#616161' : '#ffffff',
                  }}
                  onClick={nextStep2}
                >
                  {loading ? <ClipLoader size={20} color={'#616161'} /> : ''}
                  Switch Network
                </ConfirmButton>
              )} */}
              <ConfirmButton
                style={{
                  background: loading ? '#EFEFEF' : '#673ab7',
                  boxShadow: loading ? 'none' : '0px 7px 18px -2px rgba(103, 58, 183, 0.56)',
                  color: loading ? '#616161' : '#ffffff',
                }}
                onClick={nextStep2}
              >
                {loading ? <ClipLoader size={20} color={'#616161'} /> : ''}
                Switch Network
              </ConfirmButton>
            </ConfirmButtonDiv>
          </>
        );
      case 3:
        return (
          <>
            {isInfoLeft ? (
              <InfoLeft>
                Liquidity is not available in Polygon Chain. Please Select network with available liquidity to transfer left
                over token.
              </InfoLeft>
            ) : isLeftToken == true || isLeftToken2 == true ? null : (
              <ProcessDetails>
                Moving funds from {data1.fromNetworkName} to {data1.toNetworkName} Deposit of funds takes ~7-8 minutes
              </ProcessDetails>
            )}
            {error ? (
              <>
                <ErrorDiv>
                  <ErrorOutlineIcon style={{ color: '#C62828' }} />
                  Please approve the transaction from your wallet
                </ErrorDiv>
              </>
            ) : (
              ''
            )}
            <ConfirmButtonDiv>
              {isLeftToken ? (
                <ConfirmButton
                  style={{
                    background: isLeftToken ? '#EFEFEF' : '#673ab7',
                    color: isLeftToken ? '#616161' : '#ffffff',
                  }}
                  disabled
                >
                  Proceed
                </ConfirmButton>
              ) : isLeftToken2 == true ? (
                <ConfirmButton
                  style={{
                    background: '#673ab7',
                    color: '#ffffff',
                  }}
                  onClick={Procced}
                >
                  Proceed
                </ConfirmButton>
              ) : (
                <>
                  {isRouterConfirmed ? (
                    <ConfirmButton
                      style={{
                        background: loading ? '#EFEFEF' : '#673ab7',
                        boxShadow: loading ? 'none' : '0px 7px 18px -2px rgba(103, 58, 183, 0.56)',
                        color: loading ? '#616161' : '#ffffff',
                      }}
                      onClick={nextStep3}
                    >
                      {loading ? <ClipLoader size={20} color={'#616161'} /> : ''}
                      {isInfoLeft ? 'Proceed' : 'Withdraw Token'}
                    </ConfirmButton>
                  ) : (
                    <>
                      <div style={{ width: '75%', position: 'relative', marginTop: '25px' }}>
                        <span
                          style={{
                            color: 'white',
                            position: 'absolute',
                            zIndex: 1,
                            fontSize: '17PX',
                            fontWeight: 700,
                            left: mobile ? '97px' : '165px',
                            top: mobile ? '9px' : '20px',
                          }}
                        >
                          Loading....
                        </span>
                        <LinearProgress
                          variant="determinate"
                          className="progress-bar-linear"
                          value={data1.progress}
                          style={{ height: mobile ? 45 : 70, borderRadius: 10 }}
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </ConfirmButtonDiv>
          </>
        );
      case 4:
        return (
          <>
            {IsSocialShare ? (
              <SocialShare title="Check this out 👀 I have just bridged my tokens on @unifarm_ using their Setu bridge.I can seamlessly transfer tokens between chains now! Thanks @unifarm_.😁" />
            ) : (
              <CongratulationsDiv>
                <CongratulationsText>Congratulations!</CongratulationsText>
                <SucessDetails>
                  You have successfully bridged tokens from {data1.fromNetworkName} to {data1.toNetworkName}
                </SucessDetails>
                <ExplorerShareWrap>
                  <ExplorerWrap onClick={() => window.open(txHash, '_blank')}>
                    <OpenInNewIcon style={{ color: 'rgba(97, 97, 97, 1)', fontSize: 18 }} />
                    <span>View on Explorer</span>
                  </ExplorerWrap>
                  <ExplorerWrap onClick={() => setSocialShare(true)}>
                    <ShareIcon style={{ color: 'rgba(97, 97, 97, 1)', fontSize: 18 }} />
                    <span>Share</span>
                  </ExplorerWrap>
                </ExplorerShareWrap>
              </CongratulationsDiv>
            )}
          </>
        );
    }
  }
  const TokenData = [
    { name: 'ETHEREUM', chainId: 7, networkId: 1 },
    { name: 'POLYGON', chainId: 1, networkId: 137 },
    { name: 'BINANCE SMART CHAIN', chainId: 2, networkId: 56 },
    { name: 'AVALANCHE', chainId: 3, networkId: 43114 },
    { name: 'FANTOM', chainId: 4, networkId: 250 },
    { name: 'KAVA', chainId: 8, networkId: 2221 },
  ];

  const snackbarCancelButton = (
    <React.Fragment>
      <IconButton size="small" aria-label="close" color="inherit" onClick={() => setSnackbarOpen(false)}>
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <>
      <Modal
        open={open}
        close={close}
        title={
          IsSocialShare === true ? 'Share' : activeStep === 4 ? 'Your transaction' : 'Your transaction progress on Setu'
        }
        className={classes.Root}
        headerClass="SettingHeader"
      >
        <RootCard>
          {activeStep == 4 ? (
            <Confetti
              width={mobile ? 345 : 614}
              height={mobile ? 450 : 455}
              run={true}
              recycle={false}
              numberOfPieces={400}
            />
          ) : null}
          {IsSocialShare ? null : (
            <>
              <Stepper className={classes.Step} activeStep={activeStep - 1}>
                {/* <Step className={classes.MainStep}>
                  <StepLabel>{activeStep != 0 ? 'Confirmed' : 'Confirm'}</StepLabel>
                </Step> */}
                <Step className={classes.MainStep}>
                  <StepLabel>Transfer</StepLabel>
                </Step>
                <Step className={classes.MainStep}>
                  <StepLabel>Checkpoint</StepLabel>
                </Step>
                <Step className={classes.MainStep}>
                  <StepLabel>Complete</StepLabel>
                </Step>
              </Stepper>
              <TokenDetails>
                <IconImage src={data1.tokenImage} alt="tokenImage" />
                <TokenValue>{amount && round(unitFormatter(amount, 18), 3) + ' ' + ticker}</TokenValue>

                {isLeftToken ? <TokenLeftValue>LEFT OVER : {amount + ' ' + ticker}</TokenLeftValue> : null}
              </TokenDetails>
            </>
          )}
          <>
            {activeStep === 4 ? null : (
              <div style={{ textAlign: 'center' }}>
                <TransferProcess>
                  <TokenProcessDiv>
                    {data1.fromNetworkImage && data1.fromNetworkName ? (
                      <>
                        <ProcessTokenIcon src={data1.fromNetworkImage} />
                        <ProcessTokenName>{data1.fromNetworkName}</ProcessTokenName>
                      </>
                    ) : (
                      'Select From Network'
                    )}
                  </TokenProcessDiv>
                  {mobile ? (
                    <div>
                      <ChevronRightIcon style={{ color: '#F0EBF8', fontSize: '1.2rem' }} />
                      <ChevronRightIcon style={{ color: '#A28EDE', fontSize: '1.2rem' }} />
                      <ChevronRightIcon style={{ color: '#846DCA', fontSize: '1.2rem' }} />
                      {/* <ArrowAnimation/> */}
                    </div>
                  ) : (
                    <div>
                      <ChevronRightIcon style={{ color: '#F0EBF8' }} />
                      <ChevronRightIcon style={{ color: '#A28EDE' }} />
                      <ChevronRightIcon style={{ color: '#846DCA' }} />
                      {/* <ArrowAnimation/> */}
                    </div>
                  )}

                  {isLeftToken || isLeftToken2 ? (
                    <>
                      <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                        <TokenValue style={{ textAlign: 'left', margin: '0px' }}>Choose Network</TokenValue>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          // value={networkIndex}
                          onChange={handleChange}
                          style={{ width: mobile ? 120 : 190, textAlign: 'left' }}
                        >
                          {supportedNetworks.map((network, index) => {
                            return (
                              <MenuItem
                                className="menu-item"
                                value={network}
                                key={index}
                                // disabled={data1.fromNetworkName == data.name}
                              >
                                {networksData[network].name}
                              </MenuItem>
                            );
                          })}
                          {/* {TokenData.map((data, index) => {
                            return (
                              <MenuItem
                                className="menu-item"
                                value={data.chainId}
                                key={index}
                                disabled={data1.fromNetworkName == data.name}
                              >
                                {data.name}
                              </MenuItem>
                            );
                          })} */}
                          {/* <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={10}>Binance Smart Chain</MenuItem>
                      <MenuItem value={20}>Polygon Network</MenuItem>
                      <MenuItem value={30}>Avalanche Network</MenuItem>
                      <MenuItem value={40}>FTM Network</MenuItem> */}
                        </Select>
                      </FormControl>
                    </>
                  ) : (
                    <TokenProcessedDiv>
                      {/* {selectNetworkName && selectImageNetwork ? (
                        <>
                          <ProcessTokenIcon src={selectImageNetwork} />
                          <ProcessTokenName>{selectNetworkName}</ProcessTokenName>
                        </> */}
                      {data1.toNetworkImage && data1.toNetworkName ? (
                        <>
                          <ProcessTokenIcon src={data1.toNetworkImage} />
                          <ProcessTokenName>{data1.toNetworkName}</ProcessTokenName>
                        </>
                      ) : (
                        'Select To Network'
                      )}
                    </TokenProcessedDiv>
                  )}
                </TransferProcess>
                {isLeftToken || isLeftToken2 ? (
                  <>
                    <br />
                    <TokenLeftValue>Choose Network to add left over token</TokenLeftValue>
                  </>
                ) : null}
              </div>
            )}
          </>

          {showStep(activeStep)}
          {activeStep == 0 || activeStep == 1 ? (
            <>
              <FeesDiv>
                <FeesName>Bridge Fees</FeesName>
                <FeesName>
                  {depositFees &&
                    new BigNumber(ethers.utils.formatUnits(depositFees, 18)).toFormat(3) + ' ' + depositFeesToken}
                </FeesName>
              </FeesDiv>
              {/* <FeesDiv>
                <FeesName>Gas Fees</FeesName>
                <FeesName>100 UFARM ($40.22)</FeesName>
              </FeesDiv> */}
            </>
          ) : null}
        </RootCard>
      </Modal>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        // message={snackbarError}
        action={snackbarCancelButton}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="error" sx={{ width: '100%' }}>
          {snackbarError}
        </Alert>
      </Snackbar>
    </>
  );
};
export default TransferModal;

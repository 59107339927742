/// for infinite approval
export const MAX_APPROVAL_VALUE = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';

export const bridgeRegistry = (networkID: number)=> {
    // eth
    if(networkID == 1) {
        return "0xf7C422bC3Cce4F757cbF8080EE6f8eA9B1562De2";
    }
    // Polygon
    if(networkID == 137) {
        return "0xA47f27b2D20b4c2Fe09CBb83AF875BFe31489847";
    }
    // mumbai
    if(networkID == 80001) {
        return "0xe6774ea9eC37B29CcCd93B118718B9C9E1D0210C";
    }
    // bsc
    if(networkID == 56) {
        return "0xfBF451f7DC68be361183d60608912acf9F1D94f9";
    }
    // fantom
    if(networkID == 250) {
        return "0xa68783EEF7fAdFb4d5a9776F45aF78b4305b145e";
    }
    // avalanche
    if(networkID == 43114) {
        return "0x4A9C32920c2211Edd521049803b6Dd7496dDB04D";
    }
    // arbitrum
    if(networkID == 42161) {
        return "";
    }
    // optimism
    if(networkID == 10) {
        return "0xf7C422bC3Cce4F757cbF8080EE6f8eA9B1562De2";
    }
};


export const bridgeAddress = (networkID: number)=> {
    // eth
    if(networkID == 1) {
        return "0xD13dF6B426358C2471bC6dea75167c3c106Ef881";
    }
    // Polygon
    if(networkID == 137) {
        return "0x62CEfDaDd37C8169034b2efD4401bc770B7B92D3";
    }
    // Polygon Mumbai
    if(networkID == 80001) {
        return "0x20c02F330475F79871c2D7B1d0c855328b840d18";
    }
    // BSC
    if(networkID == 56) {
        return "0x7423Af05D11e7363cF5Ea5Ef2eA55c7E7AEA3f7a";
    }
    // avalanche
    if(networkID == 43114) {
        return "0x2bbf37b4c98b393085E7155B62962990cBef1e42";
    }
    // fantom
    if(networkID == 250) {
        return "0x4535C4EE8984581DEbe904D5eE5c261e42E20378";
    }
    // arbitrum
    if(networkID == 42161) {
        return "";
    }
    // optimism
    if(networkID == 10) {
        return "0xD13dF6B426358C2471bC6dea75167c3c106Ef881";
    }
};

export const feePoolAddress = (networkID: number)=> {
    // eth
    if(networkID == 1) {
        return "0x04f3dD4f010620fcAF0188046C346B5FE9D2d26b";
    }
    // Polygon
    if(networkID == 137) {
        return "0x8ee9E4977C40bd0827000A782649fb6a8F03e10a";
    }
    // Polygon Mumbai
    if(networkID == 80001) {
        return "0x76C3885817Fb82a98a308C19EF06471b51Ba9FcD";
    }
    // BSC
    if(networkID == 56) {
        return "0x6F1Fb547A80EAe6543eadF4EC39fc0F07c898cf2";
    }
    // avalanche
    if(networkID == 43114) {
        return "0x56403d9E38572e00a5651002aFc0336F8284E4d0";
    }
    // fantom
    if(networkID == 250) {
        return "0x7D8df9B57Ed4445819ac1D511b852d8596B744A7";
    }
    // arbitrum
    if(networkID == 42161) {
        return "";
    }
    // optimism
    if(networkID == 10) {
        return "0x04f3dD4f010620fcAF0188046C346B5FE9D2d26b";
    }
};

export const bridgeUtilsAddress = (networkID: number)=> {
    // eth
    if(networkID == 1) {
        return "0x731A3D979ACe72504D51819E12358066082C74cA";
    }
    // Polygon
    if(networkID == 137) {
        return "0x5C004dCffefEd0d76004C832Bece690af2e7EB11";
    }
    // Polygon Mumbai
    if(networkID == 80001) {
        return "0x76C3885817Fb82a98a308C19EF06471b51Ba9FcD";
    }
    // BSC
    if(networkID == 56) {
        return "0x9b56484747664335B7eC14642C638fFA3141b916";
    }
    // avalanche
    if(networkID == 43114) {
        return "0xFe4FFeD8E508F1242b34e97705aA18CBA91046A1";
    }
    // fantom
    if(networkID == 250) {
        return "0x56403d9E38572e00a5651002aFc0336F8284E4d0";
    }
    // arbitrum
    if(networkID == 42161) {
        return "";
    }
    // optimism
    if(networkID == 10) {
        return "0x387B8d3268abf6183cb0DAA0A861c161621DF3E7";
    }
};

export const mapNetworkIdToChainId = (networkID: number) => {
    // eth
    if(networkID == 1) {
        return 7;
    }
    // ply
    if(networkID == 137) {
        return 1;
    }
    // bnb
    if(networkID == 56) {
        return 2;
    }
    // avalanche
    if(networkID == 43114) {
        return 3;
    }
    // fantom
    if(networkID == 250) {
        return 4;
    }
    // arbitrum
    if(networkID == 42161) {
        return 5;
    }
    // optimism
    if(networkID == 10) {
        return 6;
    }
}
  
export const mapchainIdToNetworkId = (chainID: number) => {
    // eth
    if(chainID == 7) {
        return 1;
    }
    // ply
    if(chainID == 1) {
        return 137;
    }
    // bnb
    if(chainID == 2) {
        return 56;
    }
    // avalanche
    if(chainID == 3) {
        return 43114;
    }  
    // fantom
    if(chainID == 4) {
        return 250;
    }
    // arbitrum
    if(chainID == 5) {
        return 42161;
    }
    // optimism
    if(chainID == 6) {
        return 10;
    } 
}

export const tokenImageUrl = (tokenTicker: string) => {
    if(tokenTicker === "DOGE")
        return "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTrUQxEki-l35LtZseebog3DtvLSYenpywrt3GfZDXyBrcYt17sXXOdQNqNzVKNJi98GA&amp;amp;usqp=CAU";
    
    else if(tokenTicker === "ABC")
        return "https://assets.coingecko.com/coins/images/7968/large/alpha-token.jpg?1552883009";
    
    else if(tokenTicker === "UFARM")
        return "https://api.unifarm.co/ufarm.png";
    
    else if(tokenTicker === "ORO")
        return "https://bscscan.com/token/images/oropocket_32.png";
    else
        return "";
}
import React, { useContext, useEffect, useState } from 'react';
import TransferModal from './TransferModal';
import { BigNumber, ethers, utils } from 'ethers';
import { useTransferIn } from 'hooks/useBridgeTransferIn';
import BridgeContext from '../../Setu/Context/BridgeContext';
import { TransferButton } from './TransferStyled';
import { ClipLoader } from 'react-spinners';
import { getContract } from 'utilities/getContract';
import { bridgeAddress, bridgeRegistry, MAX_APPROVAL_VALUE } from 'constants/bridge';
import { getJsonProvider } from 'utilities/provider';
import bridgeRegistryABI from '../../../constants/ABI/bridge/BridgeRegistryAbi.json';
import tokenABI from '../../../constants/ABI/bridge/Token.json';
import { useWeb3React } from '@web3-react/core';

interface isProps {
  amount: number;
  from: number;
  to: number;
  ticker: string;
}
const TransferBtn = ({ amount, from, to, ticker }: isProps) => {
  // console.log(amount, from, to, ticker);
  // const [amount, setAmount]= useState(null);

  const data = useContext(BridgeContext);
  const [open, setOpen] = useState(false);
  const [balance, setBalance] = useState(false);
  const [isApproved, setApproved] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [tokenAddress, setTokenAddress] = useState('');
  const { account, chainId, library } = useWeb3React();

  // console.log(setBalance);

  useEffect(() => {
    data.setCount(1);

    async function getTokenAddress() {
      if(!from)
        return;
      try {
        let bridgeRegistryInstance = getContract(bridgeRegistry(from), bridgeRegistryABI.abi, getJsonProvider(from));
        const tokenMetadata = await bridgeRegistryInstance.bridgeTokenMetadata(ticker);
        console.log("TokenMetadata: ", tokenMetadata);
        setTokenAddress(tokenMetadata.tokenAddress);
      } catch (error) {
        console.log('Error in getTokenAddress(): ', error);
      }
    }

    getTokenAddress();
  }, [data.tokenName, data.fromNetworkName, data.toNetworkName]);

  useEffect(() => {
    // if (amount) {
    //   setApproved(true);
    // }
    // else {
    //   setApproved(false);
    // }
    if(!amount || !tokenAddress)
      return;

    async function checkAllowance() {
      try {
        const tokenInstance = getContract(tokenAddress, tokenABI.abi, library, account);
        let allowance = await tokenInstance.allowance(account, bridgeAddress(chainId));
        // console.log('ALLOWANCE: ', allowance.toString(), ethers.utils.parseUnits(amount.toString(), 18).toString());
        if (allowance.gte(ethers.utils.parseUnits(amount.toString(), 18))) {
          setApproved(true);
        }    
        else
          setApproved(false);
      } catch (error) {
        console.log("Error in checkAllowance(): ", error);
      }
    }
    
    checkAllowance();
  }, [amount])

  const ApproveToken = async () => {
   
    if (balance == false && data.tokenName && data.fromNetworkName && data.toNetworkName) {
      setLoading(true);

      try {
        const tokenInstance = getContract(tokenAddress, tokenABI.abi, library, account);
        let allowance = await tokenInstance.allowance(account, bridgeAddress(chainId));
        // console.log('ALLOWANCE: ', allowance.toString(), ethers.utils.parseUnits(amount.toString(), 18).toString());
        if (allowance.lt(ethers.utils.parseUnits(amount.toString(), 18))) {
          let tx = await tokenInstance.approve(bridgeAddress(chainId), MAX_APPROVAL_VALUE);
          await tx.wait();
        }
        setOpen(true);
      } catch (error) {
        console.log('Error in ApproveToken(): ', error);
      }
      setLoading(false);
    }

    // setTimeout(() => {
    //   setLoading(false);
    //   setOpen(true);
    // }, 1000);
  };
  const useHandleOpen = () => {
    // setOpen(true);
    // setOpen(true);
    
    if (amount && !utils.parseUnits(amount.toString(), 18).isZero() && data.tokenName && data.fromNetworkName && data.toNetworkName ) {
      setOpen(true);
    }
  };

  return (
    <>
      {!isApproved ? (
        <TransferButton
          onClick={ApproveToken}
          style={{
            background: balance ? '#C62828' : '#673ab7',
            boxShadow: balance ? '0px 7px 18px -2px rgba(198, 40, 40, 0.51)' : '',
          }}
        >
          {isLoading ? (
            <>
              <ClipLoader size={20} color="white" />
              &nbsp; Approve&nbsp;
              {amount && amount + ' ' + ticker}
            </>
          ) : (
            <>Approve {amount && amount + ' ' + ticker}</>
          )}
        </TransferButton>
      ) : (
        <TransferButton
          onClick={useHandleOpen}
          style={{
            background: balance ? '#C62828' : '#673ab7',
            boxShadow: balance ? '0px 7px 18px -2px rgba(198, 40, 40, 0.51)' : '',
          }}
        >
          {balance ? 'Insufficient Balance' : amount && ticker ? ('Transfer ' + amount + ' ' + ticker) : 'Transfer'}
        </TransferButton>
      )}

      <TransferModal 
        open={open} 
        close={() => setOpen(false)} 
        amount={amount && ethers.utils.parseUnits(amount.toString(), 18).toString()} 
        from={from} 
        ticker={ticker} 
        to={to} 
      />
    </>
  );
};

export default TransferBtn;
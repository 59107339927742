import { useObtainAppCohorts } from '../../store/application/hooks';
import { useObtainTokenlist } from '../../store/lists/hooks';
//import { useFillTokenList } from '../../store/Token/hooks';
import { useFetchAllFarms } from '../../store/farms/hooks';
//import { useFetchBalance } from '../../store/balance/hooks';
import { useObtainUserStakes, useSetClaimHistory } from '../../store/stakes/hooks';
import { useSetReferralData } from '../../store/referral/hooks';
import { useUpdateIdoData } from '../../store/ido/hooks';
import { useFetchFarmData, useFetchUserPublicFarmData, useFetchV2Farms } from '../../store/V2/farms/hooks';
import { useFetchUserClaim } from 'store/idoclaim/hooks';

export function QueryResolvers() {
  useObtainAppCohorts();
  useObtainTokenlist();
  useFetchAllFarms();
  useObtainUserStakes();
  useSetClaimHistory();
  useSetReferralData();
  useUpdateIdoData();
  useFetchUserClaim();
  useFetchV2Farms();
  useFetchFarmData();
  useFetchUserPublicFarmData();
  /* 
 useFetchBalance();
 useFillTokenList();
 */
  return null;
}

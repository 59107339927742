import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    maindiv: {
      height: 120,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#401395',
      '&:hover': {
        cursor: 'pointer',
      },
      [theme.breakpoints.down('xs')]: {},
    },
    span: {
      marginTop: '0.5rem',
      fontSize: '14px',
      fontWeight: 600,
      fontFamily: 'Inter',
    },
  })
);

interface IsWallet {
  image: string;
  imageWidth: number;
  name: string;
}

export default function Wallet({ image, imageWidth, name }: IsWallet) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.maindiv}>
        <img src={image} width={imageWidth} />
        <span className={classes.span}>{name}</span>
      </div>
    </>
  );
}

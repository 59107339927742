import styled from 'styled-components';

export const BoosterStakeButton = styled.button`
  width: 200px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: #ffffff;
  font-size: 12px;
  line-height: 21px;
  font-weight: 400;
  font-family: Inter;
  font-style: normal;
  border: none;
  border-radius: 10px;
  background: #673ab7;
  box-shadow: 0px 7px 18px -2px rgba(103, 58, 183, 0.56);
  cursor: pointer;
  @media (max-width: 430px) {
    width: 100%;
  }
`;

export const VersionWrapper = styled.span`
  width: 100%;
  font-size: 13px;
  line-height: 21px;
  font-weight: 400;
  font-family: Inter;
  font-style: normal;
  margin-left: 0.5rem;
  color: #000000;
  @media (max-width: 430px) {
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
  }
`;

export const DotWrapper = styled.div`
  width: 210px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 0 auto;
  margin-bottom:1rem;
`;

export const ViewButton = styled.button`
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 26px;
  border: none;
  color: white;
  background: #673ab7;
  box-shadow: 0px 7px 18px -2px rgba(103, 58, 183, 0.56);
  border-radius: 10px;
  cursor: pointer;
  margin-top: 30px;
  @media (max-width: 430px) {
    height: 45px;
    font-size: 12px;
  }
`;

export const ViewStakeButton = styled.button`
  width: 420px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 26px;
  border: none;
  color: white;
  background: #673ab7;
  box-shadow: 0px 7px 18px -2px rgba(103, 58, 183, 0.56);
  border-radius: 10px;
  cursor: pointer;
  margin: 0px auto;
  margin-top: 30px;
  @media (max-width: 430px) {
    width: 90%;
  }
`;
export const UnstackError = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #c62828;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const ApproveError = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
  gap: 0.5rem;
`;

export const ErrorText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  font-family: 'Inter';
  font-style: normal;
  color: #c62828;
  @media (max-width: 430px) {
    font-size: 12px;
  }
`;

import { gql } from '@apollo/client';

export const idoClaims = gql`
  query ClaimQuery {
    claimedEntities {
      id
      account
      tokenType
      amount
      timeStamp
    }
  }
`;

import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import { useV2Farms } from 'store/V2/farms/hooks';
import { Farm } from 'store/V2/farms/reducer';
import { useV2NFTs } from 'store/V2/mints/hooks';
import { MintDetails } from 'store/V2/mints/reducer';
import { computeStakingConfirmedRewards, getClaimedRewards, V2Reward } from 'utilities/V2/reward';
import { useBlockNumber } from './useMultiCall';

export interface UserNFTs extends MintDetails {
  /** farm */
  farm: Farm;
  /** earned rewards */
  earnedRewards: V2Reward[];
}

export const useUserNFts = (): UserNFTs[] => {
  // farms
  let farms = useV2Farms();
  // nfts
  let nfts = useV2NFTs();

  // mints
  let mints = nfts.mintData;
  let claimData = nfts.claimData;

  // blocknumber
  let blockNumber = useBlockNumber();

  // derive only active stakes
  let activeStakes = useMemo(() => {
    if (mints) {
      return mints.filter((e) => {
        return e.endBlock === 0;
      });
    }
  }, [mints]);

  return useMemo((): UserNFTs[] => {
    if (isEmpty(farms) || isEmpty(activeStakes)) return null;
    let userNFts = [] as UserNFTs[];
    for (let k = 0; k < activeStakes.length; k++) {
      let stake = activeStakes[k];
      let stakedFarm = farms.filter((e) => e.token.id.toLowerCase() === stake.farmId.toLowerCase());

      if (!isEmpty(stakedFarm)) {
        let farm = stakedFarm[0];
        let v2Rewards = computeStakingConfirmedRewards(farm, stake, blockNumber[farm?.farmDetails?.chainId]);
        let claims = claimData?.filter((e) => e.tokenId === stake.nftTokenId);

        userNFts.push({
          stake,
          claims,
          farm,
          earnedRewards: v2Rewards,
        });
      }
    }
    return userNFts;
  }, [farms, activeStakes, blockNumber, claimData]);
};

export const useUserClaims = (): UserNFTs[] => {
  // farms
  let farms = useV2Farms();
  // nfts
  let nfts = useV2NFTs();
  // mints
  let mints = nfts.mintData;

  let claimData = nfts.claimData;
  // derive only active stakes
  let claimedStakes = useMemo(() => {
    return mints?.filter((e) => {
      return e.endBlock > 0;
    });
  }, [mints]);

  return useMemo(() => {
    let userNFts = [] as UserNFTs[];

    for (let k = 0; k < claimedStakes?.length; k++) {
      let stake = claimedStakes[k];
      //console.log('stake', stake);
      let claims = claimData?.filter((e) => e.tokenId === stake?.nftTokenId);
      // staked farm
      let stakedFarm = farms?.filter((e) => {
        return e.token.id.toLowerCase() === stake.farmId.toLowerCase();
      });

      if (!isEmpty(stakedFarm)) {
        //console.log('i am in');
        let farm = stakedFarm[0];

        let { farmDetails, cohort } = farm;
        // get claimed rewards
        let combinedRValue = claims?.map((claim) => claim.rValue);

        let claimedRewards = getClaimedRewards(
          farmDetails.rewards,
          farmDetails.perBlockRewards,
          combinedRValue,
          cohort?.cohortVersion
        );

        // user nfts
        userNFts.push({
          farm,
          claims,
          stake,
          earnedRewards: claimedRewards,
        });
      }

      return userNFts;
    }
  }, [farms, claimedStakes, claimData]);
};

import { Button } from '@mui/material';
import styled from 'styled-components';
interface isDetailDivProps{
  isActive:boolean
}
export const DetailsDiv = styled.div<isDetailDivProps>`
  height: 70px;
  background: ${(props)=>props.isActive?'#e7e7e7':'#ffffff'};
  box-shadow: 0px -1px 0px 0px rgb(224 224 224) inset;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
`;

export const ButtonDiv = styled(Button)<isDetailDivProps>`
  color: ${(props)=>props.isActive?'black !important':'white !important;'}
  background: ${(props)=>props.isActive?'#e7e7e7!important;':'#673ab7 !important;'} 
  box-shadow: ${(props)=>props.isActive?'none':'0px 7px 18px -2px rgba(103, 58, 183, 0.56) !important;'}
  border-radius: 10px !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 8px !important;
  width: 140px !important;
  height: 45px !important;
  text-transform: capitalize !important;

`;

export const TokenDiv = styled.div`
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0.5rem;
  @media (max-width: 430px) {
    width: 90px;
  }
`;
export const TokenName = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #616161;
  @media (max-width: 430px) {
    font-size: 12px;
    text-align:left;
  }
`;
export const NetworksDiv = styled.div`
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 1rem;
  @media (max-width: 430px) {
    width: 104px;
  }
`;
export const LiquidityDiv = styled.div`
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: start;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #616161;
  @media (max-width: 430px) {
    display: none;
  }
`;

export const LiquidityButton = styled.div`
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: start;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #616161;
  cursor: pointer;
  @media (max-width: 430px) {
    display: none;
  }
`;
export const ViewDetails = styled.div`
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: start;
  cursor: pointer;
  @media (max-width: 430px) {
    display:none;
  }
`;
export const TokenIcon = styled.img`
  width: 25px;
  height: 25px;
  @media (max-width: 430px) {
    width: 18px;
    height: 18px;
  }
`;
import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';
import BoosterPack from '../../Common/BoosterPackDetails/index';
import UserStakingDetails from '../../Common/UserStakingDetails';
import { TimeLineComponent } from '../../ConfirmStakeModal/ConfirmStaking/StakeAction';
import { BoosterStakeButton, DotWrapper, VersionWrapper } from '../../Common/styled';
import { ApprovalTransactionStatus } from 'hooks/useApproval';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineConnector from '@mui/lab/TimelineConnector';
import { ethers } from 'ethers';
import { ClipLoader } from 'react-spinners';
import { useBoosterModalContext } from 'contexts/V2/BoosterModalContext';
import { BuyBoosterTransactionStatus } from 'hooks/useBuyBoosterPack';

const useStyles = makeStyles(() => ({
  timelineItem: {
    minHeight: '50px !important',
    '&:before': {
      display: 'none',
    },
  },
  activeTimelineContent: {
    height: '80px',
    margin: '5px 0px !important',
    border: '1px solid #E0E0E0',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  approvedTimelineContent: {
    height: '44px',
    margin: '5px 0px !important',
    border: '1px solid #009F42',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  timelineContent: {
    height: '44px',
    margin: '5px 0px !important',
    justifyContent: 'space-between',
    border: '1px solid #E0E0E0',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  timelineConnector: {
    border: '1px dashed #bdbdbd',
    backgroundColor: 'transparent !important',
  },
  timeLine: {
    padding: '0 !important',
    margin: '8px 0px !important',
  },
  timeLineSeparator: {
    paddingRight: '5px',
  },
  timeLineDot: {
    background: '#FFF !important',
    marginTop: '0px !important',
    marginBottom: '0px !important',
  },
  timeLineDotActive: {
    background: '#673AB7 !important',
    marginTop: '0px !important',
    marginBottom: '0px !important',
  },
  timeLineDotApproved: {
    background: '#009F42 !important',
    marginTop: '0px !important',
    marginBottom: '0px !important',
  },
}));

export default function ActivateBooster() {
  let { stakingFarmDetails, boosterPackDetails, boosterApproval, buyBoosterPack } = useBoosterModalContext();

  // derive staking farm details
  let { farmTokenName, farmTokenIcon, farmTokenPrice, cohortVersion, APY, stakingAmount, stakingAmountInUSD } =
    stakingFarmDetails || {};

  // derive booster pack details
  let { boosters, choosenBooster, selectBooster, boostedAPY } = boosterPackDetails || {};

  let classes = useStyles();

  return (
    <>
      {/** user staking detail component */}
      <UserStakingDetails
        farmName={farmTokenName}
        farmIcon={farmTokenIcon}
        farmTokenPrice={farmTokenPrice}
        cohortVersion={cohortVersion}
        APY={APY}
        noOfTokensStake={stakingAmount}
        noOfTokensStakeUSD={stakingAmountInUSD}
      />

      {/** booster pack details */}
      <BoosterPack
        showCheckBox={false}
        setChecked={null}
        checked={true}
        boosters={boosters}
        selectBooster={selectBooster}
        choosenBooster={choosenBooster}
        boostedAPY={boostedAPY}
      />

      {
        /** boost button */
        boosterApproval.isApprovalRequired ? (
          <Fragment>
            <DotWrapper>
              <TimelineDot className={classes.timeLineDotActive} />
              <TimelineConnector className={classes.timelineConnector} />
              <TimelineDot className={classes.timeLineDot} />
            </DotWrapper>
            <TimeLineComponent>
              {farmTokenIcon?.map((icon, index) => (
                <img src={icon} key={index} alt={farmTokenName} />
              ))}

              {/* <img src={farmTokenIcon} width={40} alt={farmTokenName} /> */}
              <VersionWrapper>Please approve the {farmTokenName} tokens</VersionWrapper>
              <BoosterStakeButton onClick={() => boosterApproval.triggeredApproval(ethers.constants.MaxUint256)}>
                {boosterApproval.approvalTxStatus === ApprovalTransactionStatus.APPROVAL_LOADING ? (
                  <>
                    <ClipLoader color="white" size={20} />
                    &nbsp; Approve {farmTokenName}
                  </>
                ) : (
                  `Approve ${farmTokenName}`
                )}
              </BoosterStakeButton>
            </TimeLineComponent>
          </Fragment>
        ) : (
          <Fragment>
            <DotWrapper>
              <TimelineDot className={classes.timeLineDotApproved} />
              <TimelineConnector className={classes.timelineConnector} />
              <TimelineDot className={classes.timeLineDotActive} />
            </DotWrapper>
            <TimeLineComponent>
              <VersionWrapper>Boost the transaction made</VersionWrapper>
              <BoosterStakeButton onClick={() => buyBoosterPack?.buyBoosterCallback()}>
                {buyBoosterPack.buyBoosterTxStaus === BuyBoosterTransactionStatus.BUYBOOSTER_LOADING ? (
                  <>
                    <ClipLoader color="white" size={20} />
                    &nbsp; Boost Transaction
                  </>
                ) : (
                  ` Boost Transaction`
                )}
                <RocketLaunchOutlinedIcon style={{ marginLeft: '5px', fontSize: 25 }} />
              </BoosterStakeButton>
            </TimeLineComponent>
          </Fragment>
        )
      }
    </>
  );
}

import React, { useMemo } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useAPYCalculatorInputs } from 'hooks/useAPYCalculator';
import { useV2StakeInput, useUserPublicData } from 'hooks/useInspectFarmHooks';
import { multiply } from 'lodash';
import { isEmpty } from 'lodash';
import { useApyCalculatorSetting, useEnableAPYCalculatorSetting } from 'store/V2/dapp/hooks';
import { useV2Farms } from 'store/V2/farms/hooks';
import styled from 'styled-components';
import Modal from '../../Modal/index';
import BoosterPack from './BoostedAPYDetails';
import Duration from './Duration';
import TokenInput from './Input';
import { useDebounce } from 'use-debounce/lib';
import { useV2RewardsForApyCalculator } from 'hooks/useV2Rewards';
import { useBlockNumber } from 'hooks/useMultiCall';
import { blocksMined } from 'constants/chain';
import { useApplicationUserState } from 'store/user/hooks';
import { useWeb3React } from '@web3-react/core';

const ModalContentDiv = styled.div`
  width: 100%;
  padding-top: 20px;
  padding-bottom: 40px;
  position: relative;
  @media (max-width: 430px) {
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

const useStyle = makeStyles((theme) =>
  createStyles({
    Root: {
      '&>.MuiDialog-container>.MuiPaper-root.MuiDialog-paperWidthSm ': {
        width: '550px !important',
        borderRadius: '15px !important',
        overflow: 'hidden',
      },
      [theme.breakpoints.down('xs')]: {
        '&>.MuiDialog-container>.MuiPaper-root.MuiDialog-paper': {
          width: '100% !important',
          margin: '15px !important',
        },
      },
    },
  })
);

function ApyCalculator() {
  // derive all farms
  let allFarms = useV2Farms();

  let { appChainId } = useApplicationUserState();
  let { account } = useWeb3React();

  // derive apy setting
  let apyCalculatorSetting = useApyCalculatorSetting();

  let { requestedFarmId, isAPYCalculatorOpen } = apyCalculatorSetting || {};
  let [requestFarmIdDeboucedValue] = useDebounce(requestedFarmId, isAPYCalculatorOpen ? null : 2000);

  // close the apy calculator
  let closeApyCalculator = useEnableAPYCalculatorSetting();

  let farm = useMemo(() => {
    let requestFarm = allFarms?.filter(
      (farmItems) => farmItems?.token?.id?.toLowerCase() === requestFarmIdDeboucedValue?.toLowerCase()
    );
    if (!isEmpty(requestFarm)) {
      return requestFarm[0];
    }
  }, [allFarms, requestFarmIdDeboucedValue]);

  const classes = useStyle();

  // lets destruct farm object
  let { farmDetails, token, farmData, cohort } = farm || {};

  let userPublicData = useUserPublicData(
    cohort?.id,
    token?.farmToken,
    account,
    token?.fid,
    parseInt(token?.decimals),
    farmDetails?.chainId
  );

  let [stakeAmount, setStakeAmount] = useV2StakeInput(
    !isEmpty(userPublicData) ? token?.userMaxStake - userPublicData?.userFarmTotalStaking : token?.userMaxStake
  );

  // dollor value
  let farmTokenPrice = farmDetails?.farmTokenPrice;
  let stakeAmountInUsd = multiply(stakeAmount, farmTokenPrice);

  // interval change hooks
  let { numberOfDays, changeNumberOfDays } = useAPYCalculatorInputs();

  let currentBlock = useBlockNumber();

  // staking block
  let stakingBlocks = useMemo(() => (numberOfDays * 86400) / blocksMined[appChainId], [appChainId, numberOfDays]);

  // v2 rewards
  let v2Rewards = useV2RewardsForApyCalculator(
    farm,
    !isEmpty(currentBlock) ? currentBlock[appChainId] : 0,
    stakeAmount,
    stakingBlocks
  );

  let { maxAPY, maxBoostedAPY, boostedRewardsInUSD, normalRewardsInUSD } = v2Rewards || {};

  return (
    <>
      <Modal
        open={isAPYCalculatorOpen}
        close={() => closeApyCalculator(false)}
        title="APY Calculator"
        className={classes.Root}
        headerClass="SettingHeader"
      >
        {/* <Box> */}
        <ModalContentDiv>
          <TokenInput
            farmTokenIcon={farmDetails?.farmIcon}
            numberOfTokens={stakeAmount}
            numberOfTokensInUSD={stakeAmountInUsd}
            inputHandler={setStakeAmount}
          />
          <Duration numberOfDays={numberOfDays} changeNumberOfDays={changeNumberOfDays} />
          <BoosterPack
            aggregatedRewardBooster={boostedRewardsInUSD}
            aggregatedRewardWithoutBooster={normalRewardsInUSD}
            APY={maxAPY}
            boostedAPY={maxBoostedAPY}
            isBoosterAvailable={farmDetails?.isBoosterAvailable}
            farmEnd={farmData?.farmEndTime}
          />
        </ModalContentDiv>
        {/* </Box> */}
      </Modal>
    </>
  );
}

export default ApyCalculator;

import { BSC_CHAIN, ETH_CHAIN, POLYGON_CHAIN } from 'constants/chain';
import { concat } from 'lodash';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '..';
import { fetchV2TokenList, fetchTokenList } from '../../utilities';
import { useApplicationUserState } from '../user/hooks';
import { fullfilledTokenList } from './actions';
import { TokenListV2, TokenMetaData, V2TokenList } from './reducer';

export const useObtainTokenlist = () => {
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    // fetch token list
    const fetchAllTokenList = async () => {
      let [tokenlist, tokenlistV2]: [{ [chainId: number]: TokenMetaData[] }, { [chainId: number]: TokenListV2 }] =
        await Promise.all([fetchTokenList(), fetchV2TokenList()]).then((values) => values);

      // dispatch all the token list
      dispatch(
        fullfilledTokenList({
          tokenlist,
          tokenlistV2,
        })
      );
    };
    fetchAllTokenList();
  }, []);
};

export const useTokenlist = (): TokenMetaData[] => {
  const { appChainId } = useApplicationUserState();
  const tokenlist = useSelector((state: AppState) => state.lists.tokenlist);
  return isEmpty(tokenlist) ? undefined : tokenlist[appChainId];
};

export const useV2Tokenlist = (): TokenListV2 => {
  // const { appChainId } = useApplicationUserState();
  const tokenlist = useSelector((state: AppState) => state.lists.tokenlistV2);
  const tokenLists = concat(tokenlist[ETH_CHAIN], tokenlist[BSC_CHAIN], tokenlist[POLYGON_CHAIN]);
  return isEmpty(tokenLists) ? undefined : tokenLists;
};

export const useAllV2TokenList = (): V2TokenList => {
  const tokenlist = useSelector((state: AppState) => state.lists.tokenlistV2);
  return isEmpty(tokenlist) ? undefined : tokenlist;
};

import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import { Provider } from 'react-redux';
import { Web3ReactProvider } from '@web3-react/core';
import { ExternalProvider, JsonRpcFetchFunc, Web3Provider } from '@ethersproject/providers';
import store from './store';
import { HelmetProvider } from 'react-helmet-async';
import Header from './components/Header/index';
import { ApolloProvider } from '@apollo/client';
import { client } from './graphql';
import { Web3Modal } from './components/Web3Modal';
import Web3ReactManager from './components/Web3ReactManager';
import NetworkPopUp from './components/NetworkPopUp';
import { CustomSnackBar } from './components/Snackbar/CustomSnackBar';
import ContentLoader from './components/ContentLoader';
//import { GraphQueriesResolver } from "./components/GraphQueriesResolver";
import ApplicationError from './components/ApplicationError';
import NotFound from './pages/NotFound';
import { QueryResolvers } from './components/QueryResolvers';
import FarmContext from 'contexts/V2/FarmContext';
import SelfHosting from 'pages/SelfHosting';
import EnsContext from './contexts/EnsContext';
import UnsContext from './contexts/UnsContext';
import ApyCalculator from 'components/V2/APYCalculator';
// BridgeState
// import BridgeState from 'components/Setu/Context/BridgeState';
import BoosterPackModal from 'pages/V2/MyStakes/BoosterPackModal';
// @ts-ignore
import { useFetchBlockNumber } from 'store/V2/blockUpdate/hooks';
import Bridge from './pages/Setu/index';
import BridgeTransaction from './pages/Setu/Transection/index';
import BridgePool from './pages/Setu/LiquidityPool/index';
import BridgePoolInspect from './pages/Setu/LiquidityPool/PoolInspect';
import BridgeState from 'components/Setu/Context/BridgeState';
// import { FeesSection } from 'components/FeesSection/FeesSection';

function Updaters() {
  // block number updater
  useFetchBlockNumber();
  return null;
}

function App() {
  const desc = 'Stake one token and earn multiple token as rewards with high APY and low risk';
  React.useEffect(() => {
    document.querySelector('meta[name="description"]').setAttribute('content', desc);
  });

  const getLibrary = (provider: ExternalProvider | JsonRpcFetchFunc) => {
    return new Web3Provider(provider);
  };
  /* const HomePage = React.lazy(async () => {
    await new Promise((resolve) => setTimeout(resolve, 2000));
    return import("./pages");
  });
  const Farms = React.lazy(async () => {
    await new Promise((resolve) => setTimeout(resolve, 2000));
    return import("./pages/Farms");
  });
  const MyStakes = React.lazy(async () => {
    await new Promise((resolve) => setTimeout(resolve, 2000));
    return import("./pages/MyStakes");
  });
  const ReferEarn = React.lazy(async () => {
    await new Promise((resolve) => setTimeout(resolve, 2000));
    return import("./pages/ReferEarn");
  });

  const Exchange = React.lazy(async () => {
    await new Promise((resolve) => setTimeout(resolve, 2000));
    return import("./pages/Exchange");
  });

  const Claim = React.lazy(async () => {
    await new Promise((resolve) => setTimeout(resolve, 2000));
    return import("./pages/Claim");
  });
  const Liquidity = React.lazy(async () => {
    await new Promise((resolve) => setTimeout(resolve, 2000));
    return import("./pages/Liquidity/Index");
  });
  const Governance = React.lazy(async () => {
    await new Promise((resolve) => setTimeout(resolve, 2000));
    return import("./pages/Governance/index");
  }); */

  const HomePage = lazy(() => import('./pages'));
  const RedirectAll = lazy(() => import('./pages/redirects'));

  const Farm = lazy(() => import('./pages/Farms'));
  const V2Farms = lazy(() => import('./pages/V2/Farms'));
  const MyStakes = lazy(() => import('./pages/MyStakes'));
  const V2Stakes = lazy(() => import('./pages/V2/MyStakes/index'));
  const ReferEarn = lazy(() => import('./pages/ReferEarn'));
  const Exchange = lazy(() => import('./pages/Exchange'));
  const Claim = lazy(() => import('./pages/Claim'));
  const Launch = lazy(() => import('./pages/Launch'));
  const LaunchPad = lazy(() => import('./pages/LaunchPad'));
  const Liquidity = lazy(() => import('./pages/Liquidity/Index'));
  const Governance = lazy(() => import('./pages/Governance'));
  const InspectStake = lazy(() => import('./pages/V2/InspectStake'));
  const InspectFarm = lazy(() => import('./pages/V2/InspectFarm'));
  const InspectClaim = lazy(() => import('./pages/V2/InspectClaim'));
  const Vesting = lazy(() => import('./pages/Vesting/index'));
  const Flip = lazy(() => import('./pages/Flip/index'));
  const FeesSectionUnifarm = lazy(() => import('./pages/Fees/index'));
  // const FeesSection = lazy(() => import('./components/FeesSection/FeesSection'));
  // const Setu = lazy(() => import('./pages/Setu/index'));
  // const SetuTransaction = lazy(() => import('./pages/Setu/Transection/index'));
  // const SetuPool = lazy(() => import('./pages/Setu/LiquidityPool/index'));
  // const SetuPoolInspect = lazy(() => import('./pages/Setu/LiquidityPool/PoolInspect'));
  const SetuPoolDetails = lazy(() => import('./pages/Setu/LiquidityPool/PoolDetails'));
  const BuyUfarm = lazy(() => import('./pages/BuyUafarm/index'));
  const TokenAndInsurance = lazy(() => import('./pages/TokenAndInsurance/index'));
  // const Placeorder=lazy(()=>import('./components/ClaimRewards/PlaceOrder'))

  // const history=useHistory()
  // useEffect(()=>{
  //   if(SwitchChange.V2Switch.toString() ==="true")
  //   {
  //     history.push('/V2farms')
  //   }
  //   else
  //   {
  //     history.push('/farms')
  //   }
  // },[])

  return (
    <div className="App">
      <Provider store={store}>
        <ApolloProvider client={client}>
          <Web3ReactProvider getLibrary={getLibrary}>
            <HelmetProvider>
              <EnsContext>
                <UnsContext>
                  <BridgeState>
                    <Router>
                      <Header>
                        <FarmContext>
                          <Suspense fallback={<ContentLoader color="rgb(99, 56, 188)" message="" />}>
                            <Switch>
                              {/** home page */}
                              <Route path="/" exact>
                                <HomePage />
                              </Route>

                              <Route path="/fees-section" exact>
                                <FeesSectionUnifarm />
                              </Route>
                              {/** oro ufarm merger page using filp component */}
                              <Route path="/oro-ufarm-merger" exact>
                                <Flip />
                              </Route>
                              {/** flip functionality */}
                              <Route path="/flip" exact>
                                <Flip />
                              </Route>
                              <Route path="/setu" exact>
                                <Bridge />
                              </Route>
                              <Route path="/setu-transaction" exact>
                                <BridgeTransaction />
                              </Route>
                              <Route path="/setu-pool" exact>
                                <BridgePool />
                              </Route>
                              <Route path="/SetuPool-inspect" exact>
                                <BridgePoolInspect />
                              </Route>
                              <Route path="/SetuPool-Details" exact>
                                <SetuPoolDetails />
                              </Route>

                              {/* /** v1 farms */}
                              <Route path="/v1/farms" exact>
                                <Farm />
                              </Route>
                              {/** v2 farms */}
                              <Route path="/v2/farms" exact>
                                <V2Farms />
                              </Route>
                              {/** redirection for farms */}
                              <Route path="/farms" exact>
                                <RedirectAll />
                                {/* <V2Farms /> */}
                              </Route>
                              <Route path="/stakes" exact>
                                <RedirectAll />
                              </Route>

                              {/** liquidity farming */}
                              <Route path="/v2/liquidity-farming" exact>
                                <V2Farms />
                                {/* <RedirectAll /> */}
                              </Route>
                              {/** redirection for farms */}
                              <Route path="/liquidity-farming" exact>
                                <RedirectAll />
                                {/* <V2Farms /> */}
                              </Route>

                              {/** liquidity farming inspect screen */}
                              <Route path="/liquidity-farming/inspect" exact>
                                <InspectFarm />
                              </Route>

                              {/** liquidity mining  stakes */}
                              <Route path="/liquidity-farming/stakes" exact>
                                <V2Stakes />
                              </Route>

                              <Route path="/liquidity-farming/stakes/inspect">
                                <InspectStake />
                              </Route>

                              <Route path="/liquidity-farming/claim/inspect">
                                <InspectClaim />
                              </Route>

                              {/** v1 stakes */}
                              <Route path="/v1/stakes" exact>
                                <MyStakes />
                              </Route>
                              {/** v2 stakes */}
                              <Route path="/v2/stakes" exact>
                                <V2Stakes />
                              </Route>
                              {/** exchange */}
                              <Route path="/exchange">
                                <Exchange />
                              </Route>
                              {/** vesting claim page */}
                              <Route path="/claim">
                                <Claim />
                              </Route>
                              {/** launch pad IDO */}
                              <Route path="/launch" exact>
                                <LaunchPad />
                              </Route>
                              {/** launchpad on specfic chainId */}
                              <Route path="/launch/:chainId" exact>
                                <LaunchPad />
                              </Route>
                              {/** open specfic project with projectId */}
                              <Route path="/launch/project/:projectId" exact>
                                <Launch />
                              </Route>
                              {/** v1 referral page */}
                              <Route path="/referral">
                                <ReferEarn />
                              </Route>
                              {/** add liquidity page */}
                              <Route path="/liquidity">
                                <Liquidity />
                              </Route>
                              {/** governance page */}
                              <Route path="/governance">
                                <Governance />
                              </Route>
                              {/** inspect farm for v2 */}
                              <Route path="/v2/farms/inspect" exact>
                                <InspectFarm />
                              </Route>
                              {/** inspect stake for v2 */}
                              <Route path="/v2/stakes/inspect" exact>
                                <InspectStake />
                              </Route>
                              {/** inspect claim for v2 */}
                              <Route path="/v2/claim/inspect" exact>
                                <InspectClaim />
                              </Route>
                              {/** vesting page */}
                              <Route path="/vesting">
                                <Vesting />
                              </Route>
                              {/** Ufarm Meta */}

                              {/** self hosting */}
                              <Route path="/self-hosting" exact>
                                <SelfHosting />
                              </Route>
                              {/* Buy Ufarm */}
                              <Route path="/buy-ufarm" exact>
                                <BuyUfarm />
                              </Route>
                              {/* Claim token and Insurance */}
                              <Route path="/claim-tokens" exact>
                                <TokenAndInsurance />
                              </Route>
                              {/** not found page */}
                              <Route path="*">
                                <NotFound />
                              </Route>
                            </Switch>
                          </Suspense>
                        </FarmContext>
                      </Header>
                      <BoosterPackModal />
                      <Web3Modal />
                    </Router>
                  </BridgeState>
                  <QueryResolvers />
                  {/* <ApplicationError /> */}
                  <NetworkPopUp />
                  <CustomSnackBar />
                  <Web3ReactManager />
                  <Updaters />
                  {/** for v2 */}
                  <ApyCalculator />
                </UnsContext>
              </EnsContext>
            </HelmetProvider>
          </Web3ReactProvider>
        </ApolloProvider>
      </Provider>
    </div>
  );
}

export default React.memo(App);

import React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { makeStyles } from '@material-ui/core';
import { AmountDiv } from './AddLiquidityStyled';



const useStyles = makeStyles((theme) => ({
    Root: {
        '&>.MuiDialog-container>.MuiPaper-root.MuiDialog-paperWidthSm ': {
            width: '490px !important',
            borderRadius: '15px !important',
        },
        [theme.breakpoints.down('xs')]: {
            '&>.MuiDialog-container>.MuiPaper-root.MuiDialog-paper': {
                width: '100% !important',
                margin: '15px !important',
            },
        },
    },
    Input: {
        width: '100%',
        '& .css-1480iag-MuiInputBase-root-MuiInput-root:before': {
            borderColor: '#673AB7 !important',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#673AB7',
        },
    },
    formTextInput: {
        fontSize: '14px !important',
    },
    toggle: {
        width: '100%',
        '&>.MuiButtonBase-root': {
            width: '100%',
            height: '40px !important',
            textTransform: 'capitalize !important',
            border: '1px solid #616161 !important',
            color: '#616161 !important',
            borderRadius: 5,
            fontFamily: 'Inter !important',
            [theme.breakpoints.down('xs')]: {
                width: '100%',
            },
        },
        '&>.MuiButtonBase-root.Mui-selected': {
            backgroundColor: '#616161 !important',
            color: '#ffffff !important',
        },
    },
    toggle2: {
        width: '100%',
        '&>.MuiButtonBase-root': {
            width: '100%',
            height: '40px !important',
            textTransform: 'capitalize !important',
            border: '1px solid #673AB7 !important',
            color: '#673AB7 !important',
            borderRadius: 5,
            fontFamily: 'Inter !important',
            [theme.breakpoints.down('xs')]: {
                width: '100%',
            },
        },
        '&>.MuiButtonBase-root.Mui-selected': {
            backgroundColor: '#673AB7 !important',
            color: '#ffffff !important',
        },
    },
    timelineConnector: { border: '1px dashed #bdbdbd', backgroundColor: 'transparent !important' },
    timeLineDotActive: {
        background: '#673AB7 !important',
        marginTop: '0px !important',
        marginBottom: '0px !important',
    },
    timeLineDotApproved: {
        background: '#009F42 !important',
        marginTop: '0px !important',
        marginBottom: '0px !important',
    },
}));
``;

interface Props {
    value:string
    handleClick: (newValue: any) => void
    onkeydown:(newValue: any)=>void
}

const Balance = ({ handleClick,value,onkeydown }: Props) => {
    const classes = useStyles();
    return (
        <>
            <AmountDiv>
                <TextField
                    variant="standard"
                    type="number"
                    placeholder='0.00'
                    
                    value={value}
                    onChange={handleClick}
                    onKeyDown={onkeydown}
                    InputProps={{
                        inputProps: {
                            style: { textAlign: 'right' },
                          },
                        classes: {
                            input: classes.formTextInput,
                        },
                    }}
                    className={classes.Input}
                />
            </AmountDiv>
        </>
    );
};

export default Balance;

import { StakeModalBoosterPackDetails, StakingFarmDetails } from 'components/V2/Modals/Common/types';
import { blocksMined } from 'constants/chain';
import { multiply, isEmpty } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useApplicationUserState } from 'store/user/hooks';
import { useV2Farms } from 'store/V2/farms/hooks';
import { Farm } from 'store/V2/farms/reducer';
import { roundValue, usdCurrencyFormat } from 'utilities';
import { computeV2FarmRewards, ComputV2Reward } from 'utilities/V2/reward';
import { Booster } from 'utilities/V2/types';
import { useBlockNumber } from './useMultiCall';

export interface WideFarm extends Farm, ComputV2Reward {}

export const useV2WideFarms = (): WideFarm[] => {
  // derive farms
  let farms = useV2Farms();
  // block number
  let currentBlock = useBlockNumber();
  // chainId
  let { appChainId } = useApplicationUserState();

  return useMemo(() => {
    if (!farms) return null;
    return farms.map((farm) => {
      return {
        ...farm,
        ...computeV2FarmRewards(farm, currentBlock[farm?.farmDetails?.chainId], blocksMined[appChainId]),
      };
    }) as WideFarm[];
  }, [farms, currentBlock, appChainId]);
};

export const useConstructFarmContextData = (farm: Farm, stakingAmount: number, apy: number): StakingFarmDetails => {
  // farm context data
  return useMemo(() => {
    if (!farm) return null;
    let { farmDetails, cohort, farmData } = farm || {};
    // staking amount usd
    let farmTokenPrice = farmDetails?.farmTokenPrice;
    let stakingAmountInUSD = usdCurrencyFormat(multiply(stakingAmount, farmTokenPrice));
    // return the construct data
    return {
      farmTokenIcon: farmDetails?.farmIcon,
      farmTokenName: farmDetails?.farmName,
      farmTokenAddress: farmData?.farmId,
      farmTokenPrice,
      stakingAmount,
      stakingAmountInUSD,
      cohortVersion: cohort?.cohortVersion,
      APY: roundValue(apy, 2),
    };
  }, [farm, apy, stakingAmount]);
};

export const useStakingBoosterPackUtils = (
  /** boosters */
  boosters: Booster[],
  /** computed booster APY */
  computedBoostedAPY: number
): StakeModalBoosterPackDetails | null => {
  // check if is booster availble
  let isBoosterAvailable = !isEmpty(boosters);

  // user booster check box state
  let [isUserWantsToBuyBoosterPack, setUserInterest] = useState<boolean>(false);

  let [bpid, selectBpid] = useState<number>(1);

  let selectBooster = (event: React.ChangeEvent<HTMLInputElement>) => {
    selectBpid(parseInt(event.target.value));
  };

  let choosenBooster = useMemo(() => {
    if (!boosters) return null;
    let seletedBoosterPack = boosters.filter((e) => e.bpid === bpid);
    if (!isEmpty(seletedBoosterPack)) {
      return seletedBoosterPack[0];
    }
  }, [boosters, bpid]);

  let setChecked = (checked: boolean) => {
    setUserInterest(checked);
  };
  // let
  // create utils for user booster

  return useMemo(() => {
    if (!boosters || !computedBoostedAPY) return null;
    return {
      selectBooster,
      choosenBooster,
      setChecked,
      boosters,
      boostedAPY: computedBoostedAPY,
      isBoosterAvailable,
      isUserWantsToBuyBoosterPack,
    };
  }, [
    boosters,
    choosenBooster,
    computedBoostedAPY,
    isBoosterAvailable,
    isUserWantsToBuyBoosterPack,
    selectBooster,
    setChecked,
  ]);
};

import styled from 'styled-components';
export const DetailsDiv = styled.div`
  height: 55px;
  background: #ffffff;
  box-shadow: 0px -1px 0px 0px rgba(224, 224, 224, 1) inset;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
`;

export const TokenDiv = styled.div`
  width: 146px;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0.5rem;
  @media (max-width: 430px) {
    width: 160px;
  }
`;
export const TokenName = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #616161;
  @media (max-width: 430px) {
    font-size: 12px;
  }
`;
export const NetworksDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 1rem;
  @media (max-width: 430px) {
    width: 104px;
    display: none;
  }
`;
export const LiquidityDiv = styled.div`
  width: 187px;
  display: flex;
  align-items: center;
  justify-content: start;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #616161;
  @media (max-width: 430px) {
    display: none;
  }
`;
export const ViewDetails = styled.div`
  width: 139px;
  display: flex;
  align-items: center;
  justify-content: start;
  cursor: pointer;
  @media (max-width: 430px) {
    width: 35px;
    display: none;
  }
`;
export const MobilHash = styled.div`
  display: flex;
  margin-left: 3%;
  column-gap: 7px;
`;
export const SuccessfullWrap=styled.div`
text-align: left;
    margin-left: 3%;
    font-weight: 600;
    font-size: 14px;
    color: #673AB7;
`
export const MobileDiv = styled.div``;
export const MobileTokenDiv = styled.div``;
import { useCallback, useEffect, useMemo, useState } from 'react';
import { isEmpty, round } from 'lodash';
import { useV2Farms } from 'store/V2/farms/hooks';
import { Farm, UserFarmData } from 'store/V2/farms/reducer';
import { useV2UserNftsContext } from 'contexts/V2/FarmContext';
import { toFixed, unitFormatter } from 'utilities';
import { sum } from 'lodash';
import { useApplicationUserState } from 'store/user/hooks';
import { ICall } from '@makerdao/multicall';
import { createCalls } from 'utilities/V2/multicall';
import { multicall } from 'utilities/multicall';
import { BigNumber } from 'ethers';
import { NETWORK_NAME } from 'constants/chain';
import { useWeb3React } from '@web3-react/core';

export const useV2StakeInput = (
  /** user stake limit */
  userStakeLimit: number,
  /** user balance */
  userBalance?: number,
  /** farmChainId */
  farmChainId?: number
): [number, (stakedAmount: number) => void, string] => {
  const { active } = useWeb3React();
  const [stakeInputValue, setStakeInputValue] = useState<number>(active ? userBalance : userStakeLimit);
  const { appChainId } = useApplicationUserState();

  // if value not exist
  useEffect(() => {
    if (!userStakeLimit) return null;
    setStakeInputValue(userBalance > 0 ? toFixed(userBalance, 6) : toFixed(userStakeLimit, 6));
  }, [userStakeLimit, userBalance, active]);

  const stakeInputHandler = (stakedAmount: number) => {
    setStakeInputValue(toFixed(stakedAmount, 6));
  };

  // stake input errors
  let stakeInputError = useMemo(() => {
    // if (appChainId != farmChainId) {
    //   return `Switch to ${NETWORK_NAME[farmChainId]}`;
    // }
    if (stakeInputValue <= 0) {
      return 'Stake Amount less or zero';
    }
    if (stakeInputValue > userBalance) {
      return 'Insufficient Balance';
    }
    if (!userBalance) {
      return 'connect wallet';
    }
    if (stakeInputValue > userStakeLimit) {
      return 'Staking Limit Reached';
    }
  }, [stakeInputValue, userBalance, userStakeLimit]);

  return [stakeInputValue, stakeInputHandler, stakeInputError];
};

export const useFilteredFarm = (farmId: string): Farm | undefined => {
  // get v2 farms
  let v2Farms = useV2Farms();
  const farm = useMemo(() => {
    if (!v2Farms) return null;
    let [farmDetails] = v2Farms.filter((farm) => farm.token.id?.toLowerCase() == farmId?.toLowerCase());
    if (!isEmpty(farmDetails)) {
      return farmDetails;
    }
  }, [farmId, v2Farms]);

  // return the farm
  return farm;
};

/**
 * derive accumulated total staked amount
 */
export const useAccumulatedTotalStakedAmount = (farmId: string): number => {
  // user nfts
  let userNFts = useV2UserNftsContext();

  return useMemo(() => {
    let stakings = userNFts?.filter((e) => e.stake.farmId.toLowerCase() === farmId.toLowerCase());
    if (isEmpty(stakings)) return 0;
    let stakeAmounts = [] as number[];
    // loop
    for (let k = 0; k < stakings.length; k++) {
      let { stake, farm } = stakings[k];
      stakeAmounts.push(unitFormatter(stake.stakedAmount, parseInt(farm.token.decimals)));
    }
    return sum(stakeAmounts);
  }, [farmId, userNFts]);
};

export const useUserPublicData = (
  /** cohort Id */
  cohortId: string,
  /** farm Token */
  farmToken: string,
  /** account */
  account: string,
  /** farm id */
  fid: number,
  /** decimals */
  decimals: number,
  /** chain Id of token */
  chainId: number
): UserFarmData => {
  // app chainId
  // let { appChainId } = useApplicationUserState();
  let [userFarmPublicData, setUserFarmPublicData] = useState<UserFarmData>();

  useEffect(() => {
    if (!cohortId || !farmToken || !account || !fid || !decimals) return null;
    async function fetchUserTotalStaking() {
      let calls = [] as ICall[];
      calls.push(
        createCalls(
          cohortId,
          'userTotalStaking(address,uint256)(uint256)',
          [account, fid.toString()],
          [[`USER_TOTALSTAKING`]]
        )
      );
      calls.push(createCalls(farmToken, 'balanceOf(address)(uint256)', [account], [[`BALANCE_OF`]]));
      let callResults = await multicall(chainId, calls);
      let originalResults = callResults.results.original;

      // user Farm Token Balance
      let userFarmTokenBalance = originalResults[`BALANCE_OF`] as BigNumber;
      let userFarmTotalStaking = originalResults[`USER_TOTALSTAKING`] as BigNumber;

      // format the amounts

      setUserFarmPublicData({
        userFarmTotalStaking: unitFormatter(userFarmTotalStaking, decimals),
        userFarmTokenBalance: unitFormatter(userFarmTokenBalance, decimals),
      });
    }
    fetchUserTotalStaking();
  }, [account, chainId, cohortId, fid, farmToken, decimals]);
  // returning user farm Data
  return userFarmPublicData;
};

import { Button } from '@mui/material';
import TransactionViewer from 'components/V2/Modals/Common/TransactionViewer';
import React, { useState } from 'react';
import Confetti from 'react-confetti';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  SuccessButtonWrapper,
  SuccessDetailWrapper,
  SuccessMainWrapper,
  SuccessStyledButton,
  SuccessStyledButton2,
  SuccessTitleWrapper,
  SuccessTokenPriceText,
  SuccessWrapper,
} from './AddLiquidityStyled';
import networksData from 'utilities/getNetworkData';
import { useWeb3React } from '@web3-react/core';
import { useHistory } from 'react-router-dom';
import SocialShare from 'components/V2/SocialShare';

interface IsProps {
  tokenIcone: string;
  tokenPrice: string;
  txHash: string;
  addMoreHandle: () => void;
  clickSocial: () => void;
}
const SuccessToken = ({ tokenIcone, tokenPrice, txHash, addMoreHandle, clickSocial }: IsProps) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { chainId } = useWeb3React();
  const history = useHistory();

  return (
    <SuccessMainWrapper>
      <>
        <Confetti width={mobile ? 345 : 592} height={mobile ? 450 : 488} run={true} recycle={false} numberOfPieces={400} />
        <SuccessWrapper>
          <img src={tokenIcone} width={70} />
          <SuccessTokenPriceText>{tokenPrice}</SuccessTokenPriceText>
          <SuccessTitleWrapper>Congratulations !</SuccessTitleWrapper>
          <SuccessDetailWrapper>You have successfully added liquidity</SuccessDetailWrapper>

          <TransactionViewer switchScreen={clickSocial} transactionUrl={networksData[chainId].explorerUrl + txHash} />
        </SuccessWrapper>
        <SuccessButtonWrapper>
          <SuccessStyledButton2 onClick={() => history.push('/setu-pool')}>View My Liquidity</SuccessStyledButton2>
          <SuccessStyledButton onClick={addMoreHandle}>Add More Liquidity</SuccessStyledButton>
        </SuccessButtonWrapper>
      </>
    </SuccessMainWrapper>
  );
};
export default SuccessToken;

import * as React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { TokenMetaData } from 'store/lists/reducer';
import { isEmpty } from 'lodash';

interface SelectBoxProps {
  tokens: TokenMetaData[];
  selectedToken: number;
  tokenSelectHandler: (index: number) => void;
}

const SelectBox = ({ tokens, tokenSelectHandler, selectedToken }: SelectBoxProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    tokenSelectHandler(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const tokenIcon = React.useMemo(() => {
    if (isEmpty(tokens)) return null;
    return tokens[selectedToken]?.icon;
  }, [tokens, selectedToken]);

  const token = React.useMemo(() => {
    return tokens;
  }, []);

  const tokenFunction = () => {
    if (isEmpty(tokens)) return null;
    return tokens.map((option: TokenMetaData, index) => (
      <MenuItem
        key={index}
        selected={index === selectedToken}
        onClick={(event) => handleMenuItemClick(event, index)}
        style={{ columnGap: 10 }}
      >
        <img src={option?.icon} width={30} /> {option?.symbol}
      </MenuItem>
    ));
  };

  return (
    <div>
      <List component="nav" aria-label="Device settings" sx={{ bgcolor: 'background.paper' }}>
        <ListItem
          button
          id="lock-button"
          aria-haspopup="listbox"
          aria-controls="lock-menu"
          aria-label="when device is locked"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClickListItem}
          style={{
            padding: '0px',
            columnGap: '6px',
            borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
            marginTop: '-6px',
            alignItems: 'center',
            width: 65,
          }}
        >
          {/* <ListItemText
            primary="When device is locked"
            secondary={options[selectedIndex].img}
          /> */}
          <img src={tokenIcon} width={30} style={{ paddingBottom: 6 }} />
          <ArrowDropDownIcon />
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
        style={{ width: 454 }}
      >
        {tokenFunction()}
      </Menu>
    </div>
  );
};
export default SelectBox;

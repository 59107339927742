import { createReducer } from '@reduxjs/toolkit';
import { setAppView, setAPYCalculatorSettings, setBoosterPackModalSettings, setVersion } from './action';

export enum DappVersion {
  V1,
  V2,
}

export enum View {
  LIST,
  GRID,
}

export type APYCalculatorSetting = {
  /** isAPYCalculatorOpen - APY Calculator Open status   */
  isAPYCalculatorOpen: boolean;
  /** requestedFarmId - request farm id  */
  requestedFarmId: string;
};

export type BoosterPackModalSetting = {
  /** is booster modal open */
  isBoosterPackModalOpen: boolean;
  /** requested Nft TokenId */
  requestedNftTokenId: number;
};

interface Dapp {
  /** dapp version */
  version: DappVersion;
  /** pop ups */
  popUp: null;
  /** apy calculator settings */
  apyCalculatorSettings: APYCalculatorSetting;
  /** booster Pack modal */
  boosterPackModalSettings: BoosterPackModalSetting;
  /** current view */
  view: View;
}

export const dappState: Dapp = {
  version: DappVersion.V2,
  popUp: null,
  apyCalculatorSettings: null,
  boosterPackModalSettings: null,
  view: View.LIST,
};

const dapp = createReducer<Dapp>(dappState, (builder) => {
  builder.addCase(setVersion, (state, { payload: { version } }) => {
    return {
      ...state,
      version,
    };
  });
  builder.addCase(setAppView, (state, { payload: { view } }) => {
    return {
      ...state,
      view,
    };
  });
  builder.addCase(setAPYCalculatorSettings, (state, { payload: { apyCalculatorSettings } }) => {
    return {
      ...state,
      apyCalculatorSettings,
    };
  });
  builder.addCase(setBoosterPackModalSettings, (state, { payload: { boosterPackModalSettings } }) => {
    return {
      ...state,
      boosterPackModalSettings,
    };
  });
});

export default dapp;

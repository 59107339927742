import { useCallback, useState } from 'react';

export const useValidateSolanaAddress = (): {
  priceProtectionChecked: boolean;
  idoTokenTicker: string;
  priceProtectionHandler: () => void;
  idoTokenTickerHandler: (idoTokenTicker: string) => void;
} => {
  const [priceProtectionChecked, setPriceProtctionChecked] = useState<boolean>(false);

  const priceProtectionHandler = () => {
    setPriceProtctionChecked(!priceProtectionChecked);
  };

  const [idoTokenTicker, setIdoTokenTicker] = useState<string>('1');

  const idoTokenTickerHandler = useCallback((idoTokenTicker: string) => {
    setIdoTokenTicker(idoTokenTicker);
  }, []);

  return {
    priceProtectionChecked,
    priceProtectionHandler,
    idoTokenTicker,
    idoTokenTickerHandler,
  };
};

import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '..';
import { getProjects } from '../../utilities/ido';
import { useApplicationUserState } from '../user/hooks';
import { setIdoProjects, setRefetch } from './action';
import { useWeb3React } from '@web3-react/core';
import { isEmpty } from 'lodash';
import { ZERO_ADDRESS } from '../../constants';
import { Ido } from 'graphql/Ido/typings';
import { unitFormatter } from 'utilities';

export const useUpdateIdoData = () => {
  const { appChainId } = useApplicationUserState();
  const dispatch = useDispatch<AppDispatch>();
  const { account, library, chainId } = useWeb3React();
  const refetch = useIDORefetch();
  // const [projects, setProjects] = useState<{
  //   data: {
  //     getIdo: Ido[];
  //   };
  // }>();

  // console.log('projects', projects?.data?.getIdo[0]);

  // useEffect(() => {
  //   const fetchProjects = async () => {
  //     const project = await getIDOGraphql(chainId);
  //     setProjects(project as any);
  //   };

  //   fetchProjects();
  // }, [chainId]);

  useEffect(() => {
    const fetchIdoProjectsData = async () => {
      let projects = await getProjects(appChainId, !account ? ZERO_ADDRESS : account, library);
      if (!projects) {
        dispatch(setIdoProjects({ projects: undefined }));
        return;
      }
      dispatch(
        setIdoProjects({
          projects,
        })
      );
    };
    fetchIdoProjectsData();
  }, [appChainId, dispatch, account, refetch]);
};

export const useDispatchRefetch = () => {
  const dispatch = useDispatch<AppDispatch>();
  return useCallback(() => {
    dispatch(setRefetch({ refetch: true }));
  }, [dispatch]);
};

export const useObtainIDOProjects = () => {
  const projects = useSelector((state: AppState) => state.ido.projects);
  return isEmpty(projects) ? undefined : projects;
};

export const useIDORefetch = () => {
  return useSelector((state: AppState) => state.ido.refetch);
};
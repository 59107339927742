import React, { Fragment } from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import { useWeb3React } from '@web3-react/core';
import { useEnsName } from 'contexts/EnsContext';
import { useUnstoppable } from 'contexts/UnsContext';
import useOnCopy from '../../hooks/useCopy';
import { ExternalLink } from '../ExternalLink';
import { explolers } from '../../constants/chain';
import { miniWalletAddress } from '../../utilities';
import { useDisconnect } from '../../hooks/useWeb3EagarConnect';

import { useHistory } from 'react-router-dom';
import { useClosePopup } from 'store/application/hooks';

const Container = styled.div`
  border: 1px solid #b1b1b1;
  border-radius: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
`;
const ThemeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem;
`;
const Connection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  gap: 0.5rem;
`;
const ConnectWidth = styled.div`
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  font-family: Inter;
  font-style: normal;
  color: #000000;
  @media (max-width: 430px) {
    font-size: 12px;
  }
`;
const WalletIcon = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
`;
const WalletImage = styled.img`
  width: 28px;
  height: 28px;
`;
const AccountNo = styled.div`
  font-size: 14px;
  font-weight: 400;
  font-family: Inter;
  font-style: normal;
  color: #000000;
  @media (max-width: 430px) {
    font-size: 12px;
  }
`;
const External = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0.5rem;
`;
const DisconnectDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const useStyles = makeStyles((theme: Theme) => ({
  ButtonStyle: {
    width: '125px',
    height: '35px',
    borderRadius: '5px',
    background: '#F07474',
    boxShadow: '0 8px 16px rgb(99 56 188 / 13%)',
    color: '#ffffff',
    textTransform: 'none',
    fontSize: '16px',
    '&:hover': {
      background: '#F07474',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100px',
      height: '30px',
      fontSize: '14px',
    },
  },
  CopyButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'transparent',
    border: 'none',
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: 'Inter !important',
    fontStyle: 'normal',
    color: 'grey',
    cursor: 'pointer',
    textTransform: 'none',
    '&:hover': {
      background: 'transparent',
    },
  },
}));

interface AccountDetailsProps {
  providerName: string;
  providerLogo: string;
  userAddress: string;
  disconnectHandler?: () => void;
}

const AccountDetails = ({ providerName, providerLogo, userAddress }: AccountDetailsProps) => {
  const { chainId, account } = useWeb3React();
  const disconnect = useDisconnect();

  const history = useHistory();
  console.log(history, 'history');
  const close = useClosePopup();
  const ens = useEnsName();
  let ensName = typeof ens === 'string' ? ens.toLowerCase() : '';
  const UnstoppableDomain = useUnstoppable();
  const unsDomain = typeof UnstoppableDomain === 'string' ? UnstoppableDomain : '';
  const [onCopy, isCopied] = useOnCopy(500, account);

  const classes = useStyles();

  const handleRedirect = () => {
    history.push('/fees-section');
    close();
  };

  return (
    <Fragment>
      <Container>
        <ThemeContainer>
          <Connection>
            <ConnectWidth>{`Connected with ${providerName}`}</ConnectWidth>
            <WalletIcon>
              <WalletImage src={providerLogo} alt="WalletImage" />
              <AccountNo>
                {unsDomain === ''
                  ? ensName === ''
                    ? miniWalletAddress(account)
                    : ensName.toLowerCase()
                  : unsDomain.toLowerCase()}
              </AccountNo>
            </WalletIcon>
          </Connection>
          <DisconnectDiv>
            <Button className={classes.ButtonStyle} onClick={disconnect}>
              Disconnect
            </Button>
          </DisconnectDiv>
        </ThemeContainer>

        <External>
          <Button className={classes.CopyButton} onClick={onCopy}>
            <ContentCopyIcon style={{ width: '20px', height: '20px', marginRight: '5px', color: 'gray' }} />
            {isCopied ? 'Copied' : 'Copy Address'}
          </Button>
          <Button
            className={classes.CopyButton}
            // onClick={() => {
            //   history.push('/fees-section');
            // }}
            onClick={handleRedirect}
          >
            {/* <ReceiptIcon style={{ width: '20px', height: '20px', marginRight: '5px', color: 'gray' }} /> */}
            <ReceiptOutlinedIcon style={{ width: '20px', height: '20px', marginRight: '5px', color: 'gray' }} />
            Fees Structure
          </Button>
          <ExternalLink url={`${explolers[chainId]}/address`} path={userAddress} />
        </External>
      </Container>
    </Fragment>
  );
};
export default AccountDetails;

import React, { /* useEffect */ useMemo } from 'react';
import { useWeb3React } from '@web3-react/core';
import BoosterModal from 'components/V2/Modals/BoosterModal';
import { NFT_MANAGER_ADDRESS } from '../../../constants/V2';
import BoosterModalContext, { BoosterModalContextData } from 'contexts/V2/BoosterModalContext';
import { useAllowance, useApproval } from 'hooks/useApproval';
import { useBuyBoosterPack } from 'hooks/useBuyBoosterPack';
import { useConstructFarmContextData, useStakingBoosterPackUtils } from 'hooks/useFarms';
import { useUserNFts } from 'hooks/useNFTs';
import { useV2ModalScreen } from 'hooks/useV2ModalScreen';
import { unitFormatter } from 'utilities';
import { generateTransactionUrl } from 'utilities/V2';
import { useBoosterSetting, useToggleBoosterPackModal } from 'store/V2/dapp/hooks';
import { isEmpty } from 'lodash';
import { useDebounce } from 'use-debounce/lib';
import useQuery from 'hooks/useQuery';

export default function BoosterPackModal() {
  // isOpen
  let [screen, switchScreen] = useV2ModalScreen();
  let userNfts = useUserNFts();

  // eslint-disable-next-line
  let query = useQuery();
  let toggle = useToggleBoosterPackModal();
  let { isBoosterPackModalOpen, requestedNftTokenId } = useBoosterSetting() || {};
  let [debouncedNftTokenId] = useDebounce(requestedNftTokenId, isBoosterPackModalOpen ? 0 : 2000);

  // open the booster pack modal by query as well
  /* useEffect(() => {
    // derive tokenId
    let stakedNftTokenId = parseInt(query.get('nftTokenId'));
    if (stakedNftTokenId !== undefined) {
      // dispatch this
      toggle(true, stakedNftTokenId);
    }
  }, [toggle, query, userNfts]); */

  let staking = useMemo(() => {
    let stake = userNfts?.filter((e) => e.stake.nftTokenId === debouncedNftTokenId);
    if (!isEmpty(stake)) {
      return stake[0];
    }
  }, [debouncedNftTokenId, userNfts]);

  // derstruct the farm and stake
  let { farm, stake } = staking || {};

  let { account, chainId } = useWeb3React();
  let nftManager = NFT_MANAGER_ADDRESS[chainId];

  // stakeAmount
  let stakeAmount = unitFormatter(stake?.stakedAmount, parseInt(farm?.token?.decimals));

  let stakingFarmDetails = useConstructFarmContextData(farm, stakeAmount, farm?.farmData?.apy);
  let boosterPackDetails = useStakingBoosterPackUtils(farm?.cohort?.boosters, farm?.farmData?.boostUptoAPY);

  let paymentToken = boosterPackDetails?.choosenBooster?.paymentToken;
  let boosterPackTokenAllowance = useAllowance(
    account,
    nftManager,
    paymentToken,
    farm?.farmDetails?.chainId,
    !farm?.farmDetails?.isBoosterAvailable
  );

  let boosterApproval = useApproval(
    boosterPackTokenAllowance,
    boosterPackDetails?.choosenBooster?.boosterPackAmount,
    nftManager,
    paymentToken
  );

  let buyBoosterPack = useBuyBoosterPack(
    farm?.cohort?.id,
    boosterPackDetails?.choosenBooster?.bpid,
    stake?.nftTokenId,
    boosterPackDetails?.choosenBooster?.paymentToken,
    boosterPackDetails?.choosenBooster?.boosterPackAmount
  );

  let boosterContextData = useMemo((): BoosterModalContextData => {
    return {
      isOpen: isBoosterPackModalOpen,
      toggle,
      stakingFarmDetails,
      boosterPackDetails,
      boosterApproval,
      buyBoosterPack,
      viewMyStakeUrl: '',
      transactionUrl: generateTransactionUrl(chainId, buyBoosterPack?.transactionHash),
      isBuyBoosterTransactionScreen: true,
      screen,
      switchScreen,
    };
  }, [
    boosterApproval,
    boosterPackDetails,
    buyBoosterPack,
    isBoosterPackModalOpen,
    toggle,
    chainId,
    screen,
    stakingFarmDetails,
    switchScreen,
  ]);

  return (
    <BoosterModalContext ctx={boosterContextData}>
      <BoosterModal />
    </BoosterModalContext>
  );
}

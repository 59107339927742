import React, { Fragment, useContext } from 'react';
import { useUnstoppableDomain } from '../hooks/useUnstoppableDomain';

export interface UnsContextProps {
    domain: string | undefined;
}
  
const Context = React.createContext<UnsContextProps | null>(null);

export const useUnstoppable = (): string | undefined => {
    return useContext(Context).domain;
};

const UnsContext = ({ children }: { children: React.ReactNode }): JSX.Element => {
    const domain = useUnstoppableDomain();
    console.log(domain, "Doomainnnn----------------------------------------")
    return (
      <Fragment>
        <Context.Provider value={{ domain }}> {children} </Context.Provider>
      </Fragment>
    );
};

  
export default UnsContext;
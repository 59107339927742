import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Button, makeStyles } from '@material-ui/core';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import { ClipLoader } from 'react-spinners';
import InputAdornment from '@mui/material/InputAdornment';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import RewardSnackbar from '../Snackbar/RewardSnackBar';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import FormHelperText from '@mui/material/FormHelperText';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import { BUY_STATE } from '../../hooks/useBuyToken';
import { Discount } from 'constants/ido';
import { round } from 'lodash';
import SelectBox from './SelectBox';
import { TokenMetaData } from 'store/lists/reducer';
import { useUpdateIdoData } from 'store/ido/hooks';
import { isEmpty } from 'lodash';
import { number } from 'prop-types';
import ToolTip from 'components/V2/ToolTip';

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto;
  margin-top: 2rem;
  width: 454px;
  height: auto;
  /* background/main */
  background: #ffffff;
  /* font/disabled */
  border: 1px solid rgba(224, 224, 224, 1);
  box-sizing: border-box;
  box-shadow: 4px 4px 25px #e0e0e0;
  border-radius: 15px;
  @media (max-width: 430px) {
    width: 100%;
    height: auto;
  }
`;

const CardTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  margin-top: 2rem;
  justify-content: center;
  width: 454px;
  height: 115px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  /* font/body */
  color: #616161;
  /* background/main */
  background: #ffffff;
  /* font/disabled */
  border: 1px solid rgba(224, 224, 224, 1);
  box-sizing: border-box;
  box-shadow: 4px 4px 25px #e0e0e0;
  border-radius: 15px;
  @media (max-width: 430px) {
    height: 115px;
    width: 100%;
    padding-top: 4px;
    font-size: 12px;
    padding-left: 6px;
    padding-right: 6px;
  }
`;
const TextHeader = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: 20px;
`;
const AligableText = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  margin-top: 2rem;
  text-align: left;
`;
const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;
export const TextTitleinput = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  /* identical to box height */
  margin-bottom: 5px;

  /* font/body */

  color: #616161;
`;

const LinkText = styled.span`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
`;
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
const TextSpan = styled.div`
  font-weight: 400;
  font-size: 13px;
  padding-bottom: 10px;
`;

interface PurchaseProps {
  paymentToken: TokenMetaData[];
  approvalLoading: boolean;
  approvalStatus: boolean;
  priceProtectionApprovalStatus: boolean;
  approveCallback: () => void;
  approveCallbackForPriceProtction: () => void;
  buyStatus: BUY_STATE;
  buyCallback: () => void;
  idoTokenIcon: string;
  idoTokenTicker: string;
  sellTokenIcon: string;
  sellTokenTicker: string;
  notEligible: boolean;
  allocateTokens: number;
  balance: number;
  priceProtectionChecked: boolean;
  // errorMessage: string;
  totalInsuranceSold: number;
  priceProtectionHandler: () => void;
  idoTokenTickerHandler: (idoTokenTicker: string) => void;
  priceOfToken: string;
  dollarValue: number;
  eligible: Discount;
  noOfToken: number;
  additionalToken: number;
  priceProtectionValue: any;
  selectedTokenIndex: number;
  tokenSelectHandler: (index: number) => void;
}

const Purchase = ({
  approvalLoading,
  approvalStatus,
  priceProtectionApprovalStatus,
  approveCallback,
  approveCallbackForPriceProtction,
  buyStatus,
  buyCallback,
  idoTokenIcon,
  idoTokenTicker,
  sellTokenTicker,
  sellTokenIcon,
  notEligible,
  allocateTokens,
  balance,
  eligible,
  priceProtectionChecked,
  priceProtectionHandler,
  idoTokenTickerHandler,
  priceOfToken,
  dollarValue,
  // noOfToken,
  additionalToken,
  priceProtectionValue,
  paymentToken,
  selectedTokenIndex,
  tokenSelectHandler,
  totalInsuranceSold,
}: PurchaseProps) => {
  const approvalState = useMemo(() => {
    return approvalStatus ? (priceProtectionChecked ? (priceProtectionApprovalStatus ? true : false) : true) : false;
  }, [approvalStatus, priceProtectionChecked, priceProtectionApprovalStatus]);

  const useStyles = makeStyles((theme) => ({
    ApproveButton: {
      width: '196px',
      height: '55px',
      color: 'white',
      background: approvalState ? 'Green' : notEligible ? '#EFEFEF' : '#673AB7',
      textTransform: 'capitalize',
      boxShadow: notEligible ? 'unset' : '0px 7px 18px -2px rgba(103, 58, 183, 0.56)',
      borderRadius: '10px',
      [theme.breakpoints.down('xs')]: {
        width: '48%',
      },
      '&:hover': {
        background: approvalState ? 'Green' : notEligible ? '#EFEFEF' : '#673AB7',
      },
      '&:disabled': {
        color: 'white !important',
        cursor: 'not-allowed !important',
      },
    },
    PurchaseButton: {
      width: '196px',
      height: '55px',
      textTransform: 'capitalize',
      color: 'white',
      background: approvalState ? '#673AB7' : '#EFEFEF',
      borderRadius: '10px',
      [theme.breakpoints.down('xs')]: {
        width: '48%',
      },
      '&:hover': {
        background: '#673AB7',
      },
    },
    PurchaseButtonDisable: {
      width: '196px',
      height: '55px',
      textTransform: 'capitalize',
      color: 'black',
      background: approvalState ? '#EFEFEF' : '#EFEFEF',
      borderRadius: '10px',
      [theme.breakpoints.down('xs')]: {
        width: '48%',
      },
      '&:hover': {
        background: '#EFEFEF',
        cursor: 'ponter',
      },
    },
    Devider: {
      width: '100%',
      height: '1px',
      marginTop: '2rem',
      background: 'black',
    },
    InsufficiantButton: {
      width: '402px',
      height: '55px',
      left: '0px',
      top: '0px',
      color: 'white !important',
      textTransform: 'capitalize',
      background: '#C62828',
      borderRadius: '10px',
      flex: 'none',
      order: 0,
      flexGrow: 0,
      margin: '0px 10px',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
      '&:hover': {
        background: '#C62828',
      },
    },
    cardTitle: {
      width: '424px',
      height: '66px',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        height: 'auto',
      },
    },
    link: {
      color: '#673AB7',
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    solanaInput: {
      width: '412px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '44px',
      marginBottom: '0.5rem',
      marginTop: '0.5rem',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        height: '77.91px',
      },
    },
    TextDiv: {
      fontFamily: "'Inter'",
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '16px',
      color: '#616161',
    },
    AdditionRoot: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    AdditionTitle: {
      fontFamily: "'Inter'",
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '14px',
      color: '#616161',
    },
    AddiionalValue: {
      fontFamily: "'Inter'",
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '14px',
      color: '#212121',
    },
    AdditionMainRoot: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: '7px',
      borderBottom: '1px solid #E0E0E0',
      paddingBottom: '8px',
    },
    ApproveMessageTitle: {
      fontFamily: "'Inter'",
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '14px',
      color: '#0000FF',
    },
  }));

  useUpdateIdoData();
  const classes = useStyles();
  const Theme = useTheme();
  const mobile = useMediaQuery(Theme.breakpoints.down('xs'));
  const [Open, setOpen] = React.useState(notEligible);
  // const [sportzChainToken] = React.useState<number>(5000);

  const [Open2, setOpen2] = React.useState(true);

  const helperTextStyles = makeStyles((theme) => ({
    root: {
      margin: 4,
      '&$error': {
        color: 'red',
      },
    },
    error: {
      '&.MuiFormHelperText-root.Mui-error': {
        color: theme.palette.error.main,
      },
    },
  }));

  const convertedToken = useMemo(() => {
    return dollarValue / Number(priceOfToken);
  }, [dollarValue, priceOfToken]);

  const eligibilityDiscount = useMemo(() => {
    if (dollarValue > 200 && eligible == 0) {
      return (convertedToken * 16.6) / 100;
      // return (dollarValue * 16.6) / 100 / Number(priceOfToken);
    } else if (dollarValue > 200 && eligible == 1) {
      return (convertedToken * 10) / 100;
      // return (dollarValue * 10) / Number(priceOfToken) / 100;
    }
    return 0;
  }, [dollarValue, eligible]);

  const tokenWithDiscount = useMemo(() => {
    return eligibilityDiscount + convertedToken;
  }, [eligibilityDiscount, convertedToken]);

  const symbol = useMemo(() => {
    if (isEmpty(paymentToken)) return null;
    // console.log(paymentToken[selectedTokenIndex].symbol, approvalStatus, priceProtectionChecked);
    return approvalStatus
      ? priceProtectionChecked && !priceProtectionApprovalStatus
        ? paymentToken[0].symbol
        : paymentToken[selectedTokenIndex].symbol
      : paymentToken[selectedTokenIndex].symbol;
  }, [approvalStatus, priceProtectionChecked, priceProtectionApprovalStatus, selectedTokenIndex]);

  // const callbacks = useMemo(() => {
  //   return approvalStatus ? approveCallbackForPriceProtction : approveCallback;
  // }, [approvalStatus, priceProtectionChecked, priceProtectionHandler]);

  // console.log('callbacks', callbacks);

  const helperTestClasses = helperTextStyles();
  const formatInput = (e) => {
    // Prevent characters that are not numbers ("e", ".", "+" & "-") ✨
    let checkIfNum;
    if (e.key !== undefined) {
      // Check if it's a "e", ".", "+" or "-"
      checkIfNum = e.key === '+' || e.key === '-';
    }

    return checkIfNum && e.preventDefault();
  };
  return (
    <>
      <CardTitle>
        <div className={classes.cardTitle}>
          Participate in the Sportzchain IDO by staking in any Yield Farming V2 Farms or Liquidity Farms. To know more
          details about the project.
          <a
            target="_blank"
            rel="noreferrer"
            href="https://medium.com/@UniFarm_/poget-whitelisted-for-sportzchains-ido-at-unilaunch-f668d7367686"
            className={classes.link}
          >
            Click here
          </a>
          &nbsp;to know more.
        </div>
      </CardTitle>
      <CardWrapper>
        <TextHeader>Purchase Token</TextHeader>
        {mobile ? null : <Divider className={classes.Devider} />}
        <div style={{ padding: 20, width: '100%', textAlign: 'left' }}>
          <AligableText>
            You are eligible for ${allocateTokens} worth of token at ${priceOfToken}
          </AligableText>
          <br />
          {/* <TextSpan>All the investments above $200 will be eligible for the Discount</TextSpan> */}
          <LinkWrapper>
            <img src={sellTokenIcon} width={55} />
            &nbsp;&nbsp;&nbsp;
            <LinkText>{sellTokenTicker}</LinkText>
          </LinkWrapper>
          <TextTitleinput>Pay Using</TextTitleinput>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <SelectBox tokens={paymentToken} selectedToken={selectedTokenIndex} tokenSelectHandler={tokenSelectHandler} />
            <TextField
              id="input-with-icon-textfield"
              placeholder="Enter the Amount"
              style={{ width: '100%' }}
              variant="standard"
              value={idoTokenTicker}
              onChange={(e) => idoTokenTickerHandler(e.target.value)}
              onKeyDown={formatInput}
              type="number"
              InputProps={{
                // startAdornment: (
                //   <InputAdornment position="start">
                //     <img src={idoTokenIcon} width={25} />
                //   </InputAdornment>
                // ),
                endAdornment: (
                  <InputAdornment position="end">
                    <span>${dollarValue}USD</span>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          {(dollarValue > 0 && dollarValue < 30) || dollarValue > 1000 ? (
            <div style={{ color: 'red' }}>Token value should be between $30 and $1000!</div>
          ) : null}

          <div className={classes.solanaInput}>
            {totalInsuranceSold < 20 && (
              <div>
                <Checkbox
                  checked={priceProtectionChecked}
                  onChange={() => priceProtectionHandler()}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                Price Protection
                <ToolTip
                  placement="top"
                  content="By availing price protection, you can claim either tokens or equivalent amount of stablecoins of your investment on the unlock date."
                />
              </div>
            )}
            <div className={classes.TextDiv}>{priceProtectionValue}</div>
          </div>
          {eligible == 0 && <TextSpan>All the investments above $200 will be eligible for the Discount</TextSpan>}
          <div className={classes.AdditionMainRoot}>
            <div className={classes.AdditionRoot}>
              <span className={classes.AdditionTitle}>No of Token</span>
              <span className={classes.AddiionalValue}>{round(convertedToken, 3)} SPN</span>
            </div>
            <div className={classes.AdditionRoot}>
              <span className={classes.AdditionTitle}>Additional Tokens</span>
              <span className={classes.AddiionalValue}>{round(eligibilityDiscount, 3)} SPN</span>
            </div>
          </div>
          <div className={classes.AdditionRoot} style={{ paddingTop: '0.5rem', paddingBottom: '0.8rem' }}>
            <span className={classes.AdditionTitle}>Total</span>
            <span className={classes.AddiionalValue} style={{ color: '#212121', fontWeight: 'bold' }}>
              {round(tokenWithDiscount, 3)} SPN
            </span>
          </div>
          {approvalStatus ? (
            <span className={classes.ApproveMessageTitle}>
              {priceProtectionChecked
                ? !priceProtectionApprovalStatus
                  ? 'Please approve ufarm for price protection'
                  : ''
                : ''}
            </span>
          ) : (
            ''
          )}
          {/* {eligibilityDiscount ? `Eligible for extra tokens worth of ${round(eligibilityDiscount - dollarValue)}` : ''} */}
          {balance > 0 || notEligible == true ? (
            <ButtonWrapper>
              {approvalState || notEligible ? (
                <Button className={classes.ApproveButton}>Approve {symbol}</Button>
              ) : (
                <Button
                  className={classes.ApproveButton}
                  onClick={approvalStatus ? approveCallbackForPriceProtction : approveCallback}
                  disabled={notEligible ? true : false}
                >
                  {approvalLoading ? (
                    <>
                      <ClipLoader size={20} color="white" />
                      &nbsp;&nbsp;Approve {symbol}
                    </>
                  ) : (
                    'Approve' + ' ' + `${symbol}` + ''
                  )}
                </Button>
              )}
              {(dollarValue > 0 && dollarValue < 30) || dollarValue > 1000 ? (
                <Button className={classes.PurchaseButtonDisable} onClick={buyCallback} disabled={true}>
                  {buyStatus === BUY_STATE.LOADING ? (
                    <>
                      <ClipLoader size={20} color="white" />
                      &nbsp;&nbsp;Purchase
                    </>
                  ) : (
                    'Purchase'
                  )}
                </Button>
              ) : (
                <Button
                  className={approvalState ? classes.PurchaseButton : classes.PurchaseButtonDisable}
                  onClick={buyCallback}
                  disabled={approvalState ? false : true}
                >
                  {buyStatus === BUY_STATE.LOADING ? (
                    <>
                      <ClipLoader size={20} color="white" />
                      &nbsp;&nbsp;Purchase
                    </>
                  ) : (
                    'Purchase'
                  )}
                </Button>
              )}
            </ButtonWrapper>
          ) : (
            <ButtonWrapper>
              <Button className={classes.InsufficiantButton} disabled>
                Insufficient Balance
              </Button>
            </ButtonWrapper>
          )}
        </div>
      </CardWrapper>
      {mobile ? null : notEligible ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '1rem',
          }}
        >
          <a href="https://unifarm.co/launch" style={{ color: '#212121' }}>
            How To Participate
          </a>
          &nbsp;
          <Tippy theme="light" placement="top" content="Help Content">
            <HelpOutlineIcon style={{ fontSize: 20 }} />
          </Tippy>
        </div>
      ) : (
        false
      )}
      {notEligible ? (
        <RewardSnackbar
          open={Open}
          severity="error"
          message="You are not eligible for IDO"
          handleClose={() => setOpen(false)}
        />
      ) : (
        <RewardSnackbar
          open={Open2}
          severity="success"
          message="You are eligible for IDO"
          handleClose={() => setOpen2(false)}
        />
      )}
    </>
  );
};

export default Purchase;

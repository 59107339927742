import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Button, makeStyles, Theme } from '@material-ui/core';
import {
  BackButton,
  StepButton,
  StepscreenRoot,
  StepButtonRoot,
  StepContentRoot,
  ContentHead,
  Content,
  ContentWrap,
} from './OnBoardingStyle';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Step1 from '../../assets/Onboardimages/BuyUfarm1.png';
import Step2 from '../../assets/Onboardimages/BuyUfarm2.png';
import Step3 from '../../assets/Onboardimages/BuyUfarm3.png';
import Step4 from '../../assets/Onboardimages/BuyUfarm4.png';

const steps = ['1', '2', '3', '4'];
const useStyles = makeStyles(() => ({
  root: {
    '&>.MuiStep-root .MuiStepLabel-root .MuiStepLabel-iconContainer .Mui-completed': {
      color: 'rgba(103, 58, 183, 1) !important',
    },
    '&>.MuiStep-root .MuiStepLabel-root .MuiStepLabel-iconContainer': {
      border: '0.5px solid',
      borderRadius: '16px',
      '&>.MuiSvgIcon-root': {
        color: 'white',
        '&>.MuiStepIcon-text': {
          fill: 'black',
        },
      },
    },
    '&>.MuiStep-root .MuiStepConnector-root .MuiStepConnector-line': {
      borderTopWidth: '0px',
      border: '2px dashed #616161',
    },
  },
}));
interface IsPropsIntalledStep {
  finishClick: () => void;
}
const BuyUfarmStep = ({ finishClick }: IsPropsIntalledStep) => {
  const classes = useStyles();
  const [isStep, setIsStep] = React.useState(1);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const RenderStepScreen = (step) => {
    switch (step) {
      case 1:
        return (
          <StepContent
            img={Step1}
            title="BUY UFARM"
            content={
              <>
                {mobile ? (
                  <>
                    Click on the Buy UFarm, next to the farm title after selecting the Farm to proceed further and Buy the
                    UFarm Tokens on Binance Network
                  </>
                ) : (
                  <ContentWrap>
                    <span>
                      Click on the Buy UFarm, next to the farm title after selecting the Farm to proceed further and Buy the
                      UFarm Tokens on
                    </span>
                    <span>Binance Network</span>
                  </ContentWrap>
                )}
              </>
            }
            isCustomeImage={true}
          />
        );
      case 2:
        return (
          <StepContent
            img={Step2}
            title="CONFIRM THE UFARM"
            content={
              <>
                {mobile ? (
                  <>A Pop up will open, here continue the steps to buy $UFARM using INR on Gpay, Paytm etc.</>
                ) : (
                  <ContentWrap>
                    <span>A Pop up will open, here continue the steps to buy $UFARM using INR on Gpay, Paytm etc.</span>
                  </ContentWrap>
                )}
              </>
            }
            isCustomeImage={true}
          />
        );
      case 3:
        return (
          <StepContent
            img={Step3}
            title="TOKENS ADDED TO WALLET"
            content={
              <ContentWrap>
                <span>The Tokens will be added to your Metamask Wallet</span>
              </ContentWrap>
            }
            isCustomeImage={true}
          />
        );
      case 4:
        return (
          <StepContent
            img={Step4}
            title="IMPORT TOKENS"
            content={
              <>
                {mobile ? (
                  <>
                    Import token contract address if you don’t see UFARM in your wallet <a target="_blank" href="https://www.youtube.com/shorts/qO46Z0g5R2U">Click here</a> to know on how to import
                    tokens to your Wallet.
                  </>
                ) : (
                  <ContentWrap>
                    <span>Import token contract address if you don’t see UFARM in your wallet </span>
                    <span><a target="_blank" href="https://www.youtube.com/shorts/qO46Z0g5R2U">Click here</a> to know on how to import tokens to your Wallet</span>
                  </ContentWrap>
                )}
              </>
            }
            isCustomeImage={true}
          />
        );
    }
  };
  const Next = () => {
    if (isStep > 0 && isStep < 4) {
      setIsStep(isStep + 1);
    } else {
      setIsStep(1);
    }
  };
  const Back = () => {
    setIsStep(isStep - 1);
  };
  return (
    <div style={{ margin: '0px auto', width: '100%', display: 'flex', justifyContent: 'center' }}>
      <Box sx={{ width: mobile ? '100%' : '85%' }}>
        <Stepper activeStep={isStep} alternativeLabel className={classes.root}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel></StepLabel>
            </Step>
          ))}
        </Stepper>
        <StepscreenRoot>{RenderStepScreen(isStep)}</StepscreenRoot>
        <StepButtonRoot>
          {mobile ? (
            <BackButton onClick={Back} style={{ display: isStep <= 1 ? 'none' : 'block' }}>
              Previous
            </BackButton>
          ) : (
            <BackButton onClick={Back} style={{ visibility: isStep <= 1 ? 'hidden' : 'visible' }}>
              Previous
            </BackButton>
          )}
          {isStep === 4 ? <StepButton onClick={finishClick}>Next</StepButton> : <StepButton onClick={Next}>Next</StepButton>}
        </StepButtonRoot>
      </Box>
    </div>
  );
};
export default BuyUfarmStep;
interface isStepContent {
  img: string;
  title: string;
  content: any;
  isCustomeImage: boolean;
}
const StepContent = ({ img, title, content, isCustomeImage }: isStepContent) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <StepContentRoot>
      {isCustomeImage ? <img src={img} width={mobile ? 350 : 550} /> : <img src={img} />}

      <ContentHead>{title}</ContentHead>
      <Content>{content}</Content>
    </StepContentRoot>
  );
};

import styled from 'styled-components';
export const DetailsSubDiv = styled.div`
  width: 230px;
  display: flex;
  align-items: start;
`;
export const WithdrawBtn = styled.button`
  width: 160px;
  height: 45px;
  border-radius: 10px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 26px;
  cursor: pointer;
`;
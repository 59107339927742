import React from 'react';
import style from 'styled-components';
import ProgressBar from '@ramonak/react-progress-bar';
import CustomizedProgressBars from './CustmizeProgress';
import { round } from 'lodash';
import { useCountDown } from 'hooks/useMiscellaneous';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Moment from 'moment';
import { useGetDate } from 'hooks/useGetDate';

const MainWrapper = style.div`
width: 454px;
height: 210px;
margin:0 auto;
margin-top:1rem;
/* background/main */
padding:20px;
background: #FFFFFF;
border-radius: 15px;
@media (max-width: 430px) {
  width: 100%;

}
`;
const DetailWraper = style.div`
display:flex;
justify-content:space-between
`;
const MainTextWrapper = style.div`
display:flex;
flex-direction:column
`;
const MainText = style.span`
font-family: Inter;
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 35px;
color: #212121;
text-align:left
`;
const DetailText = style.span`
font-family: Inter;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 35px;
color: #616161;
text-align:right;

`;
const ProgresDiv = style.div`
margin-top:7px;
`;
interface ProjectDetailProps {
  startTime: string;
  endTime: string;
  totalInvestors: number;
  purchaseCap: number;
  totalRaisedPercentage: number;
}

const ProjectDetail = ({ startTime, endTime, totalInvestors, purchaseCap, totalRaisedPercentage }: ProjectDetailProps) => {
  // const date = new Date(Moment(Number(1663002000) * 1000).format('MM/DD/YYYY'));
  // const time = Number(1663002000) * 1000;
  let { days, hours, minutes, seconds } = useCountDown(1663045200 * 1000);
  // const GetDate = useGetDate(date, time);

  return (
    <MainWrapper>
      <DetailWraper>
        <MainTextWrapper>
          {/* <MainText>Start Time</MainText> */}
          <MainText>End Time</MainText>
          {/* <MainText>Total Investors</MainText> */}
          <MainText>Purchase Cap</MainText>
          <MainText>Pool Filled</MainText>
        </MainTextWrapper>
        <MainTextWrapper>
          <DetailText style={{ display: 'flex', alignItems: 'center' }}>
            <AccessTimeIcon style={{ marginRight: '8px' }} /> {`${days}D : ${hours}H : ${minutes}M : ${seconds}S`}
          </DetailText>
          {/* <DetailText>{startTime}</DetailText>*/}
          {/* <DetailText>12 Sep 5PM UTC</DetailText> */}
          {/* <DetailText>{totalInvestors}</DetailText> */}
          <DetailText>$100,000</DetailText>
          <ProgresDiv>
            {' '}
            <CustomizedProgressBars value={round(totalRaisedPercentage, 3)} />
          </ProgresDiv>
        </MainTextWrapper>
      </DetailWraper>
    </MainWrapper>
  );
};
export default ProjectDetail;

import React, { Fragment } from 'react';
import Checkbox from '@mui/material/Checkbox';
// import FormControlLabel from '@mui/material/FormControlLabel';
import rocketImage from '../../../../../assets/images/BoosterPackRocket.png';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import { Booster } from 'utilities/V2/types';

const BoosterPackCont = styled.div`
  width: 100%;
  border: 1px solid #e0e0e0;
  margin: 15px 0px;
  border-radius: 10px;
  margin-top: 25px;
  @media (max-width: 430px) {
    margin-top: 20px;
  }
`;

const BoosterPackBannerCont = styled.div`
  display: flex;
  padding: 0px 20px;
  margin: 5px 0px;
  margin-bottom: 20px;
  align-items: center;
  justify-content: start;
  gap: 1rem;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-direction: column;
  gap: 0.5rem;
`;

const RocketImage = styled.img`
  width: 50px;
  height: 50px;
  @media (max-width: 430px) {
    display: none;
  }
`;

const BannerDetails = styled.div`
  width: 50%;
  display: flex;
  @media (max-width: 430px) {
    width: 100%;
  }
`;

const Column = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #616161;
`;

const BannerValues = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #4527a0;
`;

const DropdownCont = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 15px;
  margin-top: 15px;
`;

const CheckboxText = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: #000000;
`;

export interface BoosterPackDetailsProps {
  /** show check box */
  showCheckBox: boolean;
  /** selectBooster */
  selectBooster: (event: React.ChangeEvent<HTMLInputElement>) => void;
  /** choosen booster */
  choosenBooster: Booster;
  /** setChecked */
  setChecked: (checked: boolean) => void;
  /** checked */
  checked: boolean;
  /** booster packs */
  boosters: Booster[];
  /** boosted APY */
  boostedAPY: number;
}

const BoosterPackDetails = ({
  showCheckBox,
  selectBooster,
  choosenBooster,
  setChecked,
  checked,
  boosters,
  boostedAPY,
}: BoosterPackDetailsProps) => {
  return (
    <Fragment>
      <BoosterPackCont>
        {showCheckBox && !isEmpty(boosters) && (
          <div
            style={
              checked
                ? {
                    padding: '1rem 10px',
                    borderBottom: '2px solid #E0E0E0',
                  }
                : {
                    padding: '1rem 10px',
                    border: 'none !important',
                  }
            }
          >
            <Checkbox
              checked={checked}
              onChange={() => setChecked(!checked)}
              name="gilda"
              sx={{
                color: '#6338BC',
                '&.Mui-checked': {
                  color: '#6338BC',
                },
              }}
            />
            <CheckboxText>
              Boost your APY upto <b>{boostedAPY}%</b>&nbsp; with booster packs
            </CheckboxText>
          </div>
        )}

        {boosters && !isEmpty(boosters) && checked && (
          <div>
            <DropdownCont>
              <FormControl variant="standard" sx={{ m: 1, width: '100%' }}>
                <InputLabel id="demo-simple-select-standard-label">Choose Token for Booster</InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={choosenBooster?.bpid ?? 0}
                  onChange={selectBooster}
                >
                  <MenuItem value={''} disabled>
                    Select Token
                  </MenuItem>
                  {boosters.map((booster, i) => {
                    return (
                      <MenuItem key={i} value={booster.bpid}>
                        {booster.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </DropdownCont>

            <BoosterPackBannerCont>
              <RocketImage src={rocketImage} alt="Rocket" />
              <BannerDetails>
                <Row>
                  <Column>Boosted APY</Column>
                  <BannerValues>{boostedAPY}%</BannerValues>
                </Row>
                <Row>
                  <Column>Boosting fees</Column>
                  <BannerValues>
                    {choosenBooster?.formattedBoosterAmount} {choosenBooster?.name}
                  </BannerValues>
                </Row>
              </BannerDetails>
            </BoosterPackBannerCont>
          </div>
        )}
      </BoosterPackCont>
    </Fragment>
  );
};

export default BoosterPackDetails;

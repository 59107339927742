import { JsonRpcProvider } from "@ethersproject/providers";
import { rpcUrls } from "../constants/chain";

export const getDefaultRPCProvider = (chainId: number): JsonRpcProvider => {
  if (!chainId) return null;
  const provider = new JsonRpcProvider(rpcUrls[chainId]);
  return provider;
};

export const getJsonProvider = (networkId: number) => {
  const provider = new JsonRpcProvider(rpcUrls[networkId]);
  return provider;
}

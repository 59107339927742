import { createAction } from '@reduxjs/toolkit';
import { Vesting, VestingData } from './reducer';

export const setVestingScheduleData = createAction<{ vestingSchedule: Vesting }>(
  'vesting/setVestingScheduleData'
);

export const setTabPosition = createAction<{ tabPosition: number }>(
  'vesting/setTabPosition'
);

export const setVestingData = createAction<{ vestingData: VestingData }>(
  'vesting/setVestingData'
);

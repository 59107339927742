import React from 'react';
// import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';
import { ModalScreen } from 'hooks/useV2ModalScreen';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Congratulation from '../../Common/Congratulation';
import UserStakingDetails from '../../Common/UserStakingDetails';
import { ViewButton } from '../styled';
import TransactionViewer from '../TransactionViewer';
import { StakingFarmDetails } from '../types';

// const BoostButton = styled.button`
//   width: 100%;
//   height: 55px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   font-family: 'Inter';
//   font-style: normal;
//   font-weight: 600;
//   font-size: 15px;
//   line-height: 26px;
//   color: #03a9f4;
//   border: 1px solid #03a9f4;
//   background: #fff;
//   border-radius: 10px;
//   cursor: pointer;
//   margin-top: 30px;
//   @media (max-width: 430px) {
//     height: 45px;
//     font-size: 12px;
//   }
// `;

const NotBoosted = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
`;

interface TransactionCompletedProps {
  /** stake farm details */
  stakingFarmDetails: StakingFarmDetails;
  /** view my stake url */
  viewMyStakeUrl: string;
  /** transaction url  */
  transactionUrl: string;
  /** userWantsToBuyBooster */
  isBuyBoosterTransactionScreen: boolean;
  /** switch screen */
  switchScreen: (screen: ModalScreen) => void;
  /** if stake completed or booster */
  isStake: boolean;
}

export default function TransactionCompleted({
  stakingFarmDetails,
  switchScreen,
  viewMyStakeUrl,
  transactionUrl,
  isBuyBoosterTransactionScreen,
  isStake,
}: TransactionCompletedProps) {
  // destruct all properties
  let { farmTokenIcon, farmTokenName, farmTokenPrice, APY, stakingAmount, stakingAmountInUSD, cohortVersion } =
    stakingFarmDetails;
  const history = useHistory();

  const message = isStake ? 'You have successfully staked' : 'You have successfully Buyed the Booster';

  return (
    <>
      <>
        {/** congratulation message */}
        <Congratulation message={message} />

        {/** user staking details  */}
        <UserStakingDetails
          farmIcon={farmTokenIcon}
          farmName={farmTokenName}
          farmTokenPrice={farmTokenPrice}
          APY={APY}
          noOfTokensStake={stakingAmount}
          noOfTokensStakeUSD={stakingAmountInUSD}
          cohortVersion={cohortVersion}
        />
          {/* <ViewButton onClick={() => history.push(viewMyStakeUrl)}>View My Stake</ViewButton> */}
        {isBuyBoosterTransactionScreen ? (
          <ViewButton onClick={() => history.push(viewMyStakeUrl)}>View My Stake</ViewButton>
        ) : (
          <NotBoosted>
            <ViewButton onClick={() => history.push(viewMyStakeUrl)}>View My Stake</ViewButton>
            {/* <BoostButton onClick={() => switchScreen(ModalScreen.ACTIVATE_BOOSTER)}>
              <RocketLaunchOutlinedIcon style={{ marginRight: '5px' }} />
              Boost upto {210}%
            </BoostButton> */}
          </NotBoosted>
        )}

        {/** transaction viewer */}
        <TransactionViewer transactionUrl={transactionUrl} switchScreen={switchScreen} />
      </>
    </>
  );
}

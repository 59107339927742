import { gql } from '@apollo/client';

export const vestingQuery = gql`
  {
    vestingHistories {
      id
      claimedTokenAmount
      claimedDate
      txHash
    }
  }
`;

import { createReducer } from "@reduxjs/toolkit";
import { setBalance} from "./action";

export interface Balance {
    balances: string[],
    tokens: string[],
    wethAddress:string,
    wethBalance:number
}

const balanceState: Balance = {
    balances: null,
    tokens: null,
    wethAddress:"",
    wethBalance:0,
}

const balance = createReducer<Balance>(balanceState, (builder) => {
    builder.addCase(setBalance, (state, {payload: {balances, tokens,wethAddress,wethBalance}}) => {
        return {
            ...state,
            balances,
            tokens,
            wethAddress,
            wethBalance
        };
    });
});

export default balance;
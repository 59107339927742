import React, { useEffect } from 'react';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { makeStyles } from '@material-ui/core';
import Fire from '../../../assets/V2/Images/fire.png';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const FireImg = styled.img`
  width: 13px;
  height: 17px;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    '&>.MuiTabs-scroller .MuiTabs-flexContainer .MuiButtonBase-root.Mui-selected': {
      width: '225px !important',
      color: '#673AB7 !important',
      background: 'rgba(240, 235, 248, 1) !important',
      fontWeight: 600,
      fontFamily: 'inter',
      [theme.breakpoints.down('xs')]: {
        width: '150px !important',
        fontSize: '12px !important',
      },
    },
    '&>.MuiTabs-scroller .MuiTabs-flexContainer .MuiButtonBase-root': {
      width: '225px !important',
      fontWeight: 600,
      color: '#616161',
      fontFamily: 'inter',
      [theme.breakpoints.down('xs')]: {
        width: '150px !important',
        fontSize: '12px !important',
      },
    },
  },
  MainBox: {
    width: '100%',
    marginTop: '1rem',
    marginBottom: '1rem',
    [theme.breakpoints.down('xs')]: {
      marginTop: '0rem',
      marginBottom: '0rem',
    },
  },
}));

interface TabGroupProps {
  /** list of tabs content */
  tabs: string[];
  // while changing tab we will get tab name
  tab?: string;
  /** tab value 0/1 */
  tabValue: number;
  setTabValue?: (number) => void;
}

const TabGroup = ({ tabs, tabValue, setTabValue, tab }: TabGroupProps) => {
  // obtain classes
  let classes = useStyles();
  const [value, setValue] = React.useState(tabValue);

  console.log({ tabValue });

  // eslint-disable-next-line
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    // console.log("event",)

    // console.log({ newValue });
    setValue(newValue);
    // setTabValue(newValue);
  };

  console.log(value);

  // eslint-disable-next-line
  let history = useHistory();
  let location = useLocation();

  // redirect the url
  useEffect(() => {
    // get the current tab
    let tabV = tabs[value] as string;
    let allfarms = tabs[0];

    console.log({ allfarms, tabV });

    // console.log('value: ', tabV);

    // if(location.pathname === '/liquidity-farming' && location.search === '?tab=allfarms'){
    //     setValue(value);
    // }

    if (location.pathname === '/setu-pool' || location.pathname === '/setu-transaction') setTabValue(value);

    console.log(window.location.pathname);

    // push the url accordingly

    history.push(`${window.location.pathname}?tab=${tabV.replace(' ', '').toLowerCase()}`);
    // tab causes re-render so we remove this
    // eslint-disable-next-line
  }, [value, tab]);

  return (
    <div>
      <Box className={classes.MainBox}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            aria-label="scrollable auto tabs example"
            className={classes.root}
            TabIndicatorProps={{
              style: { background: '#673AB7' },
            }}
            sx={{
              [`& .${tabsClasses.scrollButtons}`]: {
                '&.Mui-disabled': { display: 'none', width: '0px' },
              },
            }}
          >
            {tabs.map((tab, position) => (
              <Tab
                key={position}
                label={
                  tab === 'HOT FARMS' ? (
                    <>
                      <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                        <FireImg src={Fire} />
                        {tab}
                      </div>
                    </>
                  ) : tab === 'MY TOKENS' ? (
                    <>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {tab}
                        <Tippy
                          theme="light"
                          placement="top"
                          content="This shows all tokens in your wallet that are eligible for farming"
                        >
                          <InfoOutlinedIcon
                            style={{
                              fontSize: '14px',
                              marginLeft: '5px',
                            }}
                          />
                        </Tippy>
                      </div>
                    </>
                  ) : (
                    tab
                  )
                }
                value={position}
              />
            ))}
          </Tabs>
        </Box>
      </Box>
    </div>
  );
};

export default TabGroup;

export const platformNft = [
  '0x87c45032b6A7e7B2D3fe99C68340C1F4b72ceBeD',
  '0x87c45032b6a7e7b2d3fe99c68340c1f4b72cebed',
  '0x179A6a8138A3eac7D588321ddAFd9797892783ed',
];

export const chainId = ['eth', 'bsc', 'polygon'];

export const airDropNft = '0xac483a5c3e02a1e42e7e4d759721bb23fe71f352';

export enum Discount {
  FULL,
  SEMI,
  NONE,
}

import { useWeb3React } from '@web3-react/core';
import { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import { Provider } from '@ethersproject/providers';

export const useBlockTimeStamp = () => {
  const [blockTimeStamp, setBlockTimeStamp] = useState<number | null>();
  const { library } = useWeb3React();
  useEffect(() => {
    if (!library) return null;
    const fetchBlockNumber = async () => {
      const blockNumber = await library.getBlockNumber();
      const block = await library.getBlock(blockNumber);
      const timestamp = Number(block.timestamp);
      setBlockTimeStamp(timestamp);
    };
    fetchBlockNumber();
  }, [library]);

  return blockTimeStamp;
};

export const useBlockNumber = () => {
  const [allBlockNumber, setAllBlockNumber] = useState<{ [chainId: number]: number }>(null);
  // library
  let { library } = useWeb3React();
  useEffect(() => {
    if (!library) return null;

    const fetchBlockNumberForAll = async () => {
      const ethereumProvider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_ETHEREUM_RPC_URL);
      const bscProvider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_BSC_RPC_URL);
      const polygonProvider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_ETHEREUM_RPC_URL);

      const [ethBlockNumber, bscBlockNumber, polygonBlockNumber] = await Promise.all([
        ethereumProvider.getBlockNumber(),
        bscProvider.getBlockNumber(),
        polygonProvider.getBlockNumber(),
      ]);

      setAllBlockNumber({
        1: ethBlockNumber,
        56: bscBlockNumber,
        137: polygonBlockNumber,
      });
    };
    fetchBlockNumberForAll();
  }, [library]);

  return allBlockNumber;
};

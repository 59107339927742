import { useState, useEffect, useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';
import { isMobile } from 'react-device-detect';
import { InjectedConnector } from '@web3-react/injected-connector';
// import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { useApplicationUserState } from '../store/user/hooks';
import { injected, coinbaseWallet, walletconnect } from '../connectors';
import { WalletLinkConnector } from '@web3-react/walletlink-connector';

const refreshState = () => {
  window.localStorage.setItem('wallet', '1');
};
export const useDisconnect = () => {
  const { connector, deactivate } = useWeb3React();
  return useCallback(() => {
    if (connector instanceof InjectedConnector && window.ethereum.isMetaMask) {
      deactivate();
      return;
    } else if (connector instanceof WalletLinkConnector) {
      (connector as any).close();
      refreshState();
      return;
    }
    deactivate();
  }, [connector, deactivate]);
};

export function useWeb3EagerConnect() {
  const { activate, active } = useWeb3React();

  const [tried, setTried] = useState(false);
  // useEffect(() => {
  //   if (isMobile) {
  //     activate(injected)
  //       .then(() => {
  //         setTried(true);
  //       })
  //       .catch(() => {
  //         setTried(false);
  //       });
  //     return;
  //   }

  //   injected.isAuthorized().then((isAuthorized: boolean) => {
  //     if (isAuthorized) {
  //       activate(injected, undefined, true).catch(() => {
  //         setTried(true);
  //       });
  //     } else {
  //       setTried(true);
  //     }
  //   });
  //   // eslint-disable-next-line
  // }, [activate]); // intentionally only running on mount (make sure it's only mounted once :))

  useEffect(() => {
    // if (isMobile) {
    async function connectwallet() {
      let walletindex = localStorage.getItem('wallet');
      if (walletindex == '1') {
        await activate(injected)
          .then(() => {
            // console.log("connect w metamask")
            // localStorage.setItem("wallet","1");
          })
          .catch(() => {
            console.log('error in metamask');
          });
        localStorage.setItem('wallet', '1');
      } else if (walletindex == '2') {
        await activate(coinbaseWallet)
          .then(() => {
            setTried(true);
          })
          .catch(() => {
            setTried(false);
            console.log('error in coinbase');
          });
        localStorage.setItem('wallet', '2');
      } else {
        await activate(injected)
          .then(() => {
            // console.log("connect w wallet connect")
            // localStorage.setItem("wallet","3");
          })
          .catch(() => {
            console.log('error in walletconnect');
          });
        localStorage.setItem('wallet', '1');
      }
    }
    connectwallet();
  }, []);

  // if the connection worked, wait until we get confirmation of that to flip the flag
  useEffect(() => {
    if (!tried && active) {
      setTried(true);
    }
  }, [tried, active]);

  return tried;
}

export function useInactiveListener(suppress: boolean = false) {
  const { active, error, activate } = useWeb3React();
  const { appChainId } = useApplicationUserState();
  useEffect(() => {
    const { ethereum } = window as any;
    if (ethereum && ethereum.on && !active && !error && !suppress) {
      const handleConnect = () => {
        activate(injected);
      };
      // eslint-disable-next-line
      const handleChainChanged = (chainId: string | number) => {
        activate(injected);
      };
      const handleAccountsChanged = (accounts: string[]) => {
        if (accounts.length > 0) {
          activate(injected);
        }
      };

      ethereum.on('connect', handleConnect);
      ethereum.on('chainChanged', handleChainChanged);
      ethereum.on('accountsChanged', handleAccountsChanged);

      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener('connect', handleConnect);
          ethereum.removeListener('chainChanged', handleChainChanged);
          ethereum.removeListener('accountsChanged', handleAccountsChanged);
        }
      };
    }
  }, [active, error, suppress, activate, appChainId]);
}

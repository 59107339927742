import { MenuItem } from '@material-ui/core';
import React, { useRef } from 'react';
import { networks } from '../../constants/chain';
import { useClosePopup, usePopupStatus } from '../../store/application/hooks';
import { PopUpTypes } from '../../store/application/reducer';
import { useApplicationUserState, useChangeAppChainId } from '../../store/user/hooks';
import Modal from '../Modal/index';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    Root: {
      '&>.MuiDialog-container>.MuiPaper-root.MuiDialog-paperWidthSm ': {
        width: '500px !important',
        borderRadius: '15px !important',
      },
      [theme.breakpoints.down('xs')]: {
        '&>.MuiDialog-container>.MuiPaper-root.MuiDialog-paper': {
          width: '100% !important',
          margin: '15px !important',
        },
      },
    },
    Main: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: '1rem',
      paddingBottom: '1rem',
      flexFlow: 'wrap',
      columnGap: 11,
      rowGap: 10,
      [theme.breakpoints.down('xs')]: {
        flexWrap: 'wrap',
        justifyContent: 'space-around',
      },
    },
    NetworkDiv: {
      width: '6rem',
      display: 'flex',
      flexDirection: 'column',
      borderRadius: 15,
      fontSize: '13px',
      fontWeight: 600,
      fontFamily: 'Inter',
    },
    IconWrapper: {
      position: 'absolute',
      left: '50px',
      top: '40px',
    },
    CheckIcon: {
      color: 'green',
    },
  })
);
const NetworkPopUp = (): JSX.Element => {
  const isOpen = usePopupStatus(PopUpTypes.NETWORK);
  const close = useClosePopup();
  const classes = useStyle();
  const changeAppNetwork = useChangeAppChainId();
  const { appChainId } = useApplicationUserState();
  const ref = useRef();

  console.log('appChainId', appChainId);

  return (
    <>
      <Modal open={isOpen} close={close} title="Switch Network" className={classes.Root} headerClass="SettingHeader">
        <div className={classes.Main}>
          {Object.keys(networks).map((keys) => {
            const network = networks[Number(keys)];
            return (
              <MenuItem
                ref={ref}
                onClick={() => changeAppNetwork(network?.chainId)}
                key={network.chainId}
                className={classes.NetworkDiv}
              >
                <div>
                  <img src={network.icon} height="55" width="55" />
                </div>
                <div>{network.name}</div>
                {appChainId === network.chainId && (
                  <div className={classes.IconWrapper}>
                    <CheckCircleIcon className={classes.CheckIcon} />
                  </div>
                )}
              </MenuItem>
            );
          })}
        </div>
      </Modal>
    </>
  );
};
export default NetworkPopUp;

import { useWeb3React } from '@web3-react/core';
import { blocksMined } from 'constants/chain';
import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import { useApplicationUserState } from 'store/user/hooks';
import { useBlockNumber } from 'store/V2/blockUpdate/hooks';
import { Farm } from 'store/V2/farms/reducer';
import { StakeV2 } from 'store/V2/mints/reducer';
import { unitFormatter } from 'utilities';
import {
  getEndCheckpoint,
  V2Reward,
  getBlockDiffrence,
  getV2UnConfirmedRewards,
  computeV2FarmRewards,
  computeV2FarmRewardsForApyCalculator,
  computeV2FarmRewardsBeforeStake,
} from 'utilities/V2/reward';

export const useV2Rewards = (farm: Farm, currentBlock: number, stakeAmount: number) => {
  let { chainId } = useWeb3React();
  return useMemo(() => {
    if (!isEmpty(farm) && currentBlock) {
      return computeV2FarmRewards(farm, currentBlock, blocksMined[chainId], stakeAmount);
    }
  }, [farm, stakeAmount, chainId, currentBlock]);
};

export const useV2RewardsBeforeStake = (
  farm: Farm,
  currentBlock: number,
  stakeAmount: number,
  userStakeLimit: number,
  chainId?: number
) => {
  const { appChainId } = useApplicationUserState();
  return useMemo(() => {
    if (!isEmpty(farm) && currentBlock) {
      return computeV2FarmRewardsBeforeStake(farm, currentBlock, stakeAmount, userStakeLimit);
    }
  }, [farm, stakeAmount, currentBlock]);
};

export const useV2RewardsForApyCalculator = (
  farm: Farm,
  currentBlock: number,
  stakeAmount: number,
  stakingForBlocks: number
) => {
  return useMemo(() => {
    if (!isEmpty(farm) && currentBlock) {
      return computeV2FarmRewardsForApyCalculator(farm, currentBlock, stakingForBlocks, stakeAmount);
    }
  }, [farm, stakeAmount, currentBlock, stakingForBlocks]);
};

export const useUnConfirmRewards = (
  farm: Farm,
  stake: StakeV2
): { unconfirmedRewards: V2Reward[]; nextCheckpoint: number } => {
  // block number
  let blockNumber = useBlockNumber();

  return useMemo(() => {
    if (isEmpty(farm) || isEmpty(stake)) return null;

    let { cohort, farmDetails, farmData, token } = farm || {};

    // current check point
    let currentCheckpoint = getEndCheckpoint(cohort?.startBlock, blockNumber, cohort?.epochBlocks, cohort?.endBlock);

    // considerable current block number
    let currentBlockNumber = blockNumber > cohort?.endBlock ? cohort?.endBlock : blockNumber;
    let blockDiffrence = getBlockDiffrence(currentBlockNumber, cohort?.startBlock);

    // confirm block
    let blocksConfirmed = currentCheckpoint * cohort?.epochBlocks;
    let minedBlocks = blockDiffrence - blocksConfirmed;

    // next checkpoint derivation
    let nextCheckpoint = currentCheckpoint;
    let stakedAmount = unitFormatter(stake?.stakedAmount, parseInt(token?.decimals));

    // deriving unconfirm rewards
    let unStableRewards = getV2UnConfirmedRewards({
      rewards: farmDetails?.rewards,
      activeStaking: farmData?.activeStaking,
      pbr: farmDetails?.perBlockRewards,
      nextCheckpoint,
      minedBlocks,
      stakedAmount,
      totalStakeLimit: token?.totalStakeLimit,
      priorCheckpointTVLs: farmData?.priorEpochTvls,
      isBoosted: stake?.hasBoosterBuyed,
    });

    return {
      unconfirmedRewards: unStableRewards?.unconfirmedRewards,
      nextCheckpoint: nextCheckpoint + 1,
    };
  }, [farm, stake, blockNumber]);
};

import React, { Fragment, useContext } from "react";
import { useENS } from "../hooks/useENS";

export interface EnsContextProps {
  ensName: string | undefined;
}

const Context = React.createContext<EnsContextProps | null>(null);

export const useEnsName = (): string | undefined => {
  return useContext(Context).ensName;
};

const EnsContext = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const ensName = useENS();
  return (
    <Fragment>
      <Context.Provider value={{ ensName }}> {children} </Context.Provider>
    </Fragment>
  );
};

export default EnsContext;

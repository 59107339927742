import { useCallback, useEffect, useState } from 'react';
import { useTokenContract } from './useContract';
import { estimatedGas, gasPrice, unitParser, unitFormatter } from '../utilities';
import { useWeb3React } from '@web3-react/core';
import { isEmpty } from 'lodash';
import { BigNumber } from 'ethers';
import { useApplicationUserState, useChangeAppChainId } from 'store/user/hooks';

// approval transactions
export enum ApprovalTransactionStatus {
  /** on loading */
  APPROVAL_LOADING,
  /** on confirmed */
  APPROVAL_CONFIRMED,
  /** on error */
  APPROVAL_ERROR,
}

export interface ApprovalResponse {
  /** isApprovalRequired */
  isApprovalRequired: boolean;
  /** transaction status */
  approvalTxStatus: ApprovalTransactionStatus;
  /** approval callback function */
  triggeredApproval: (amount: number | BigNumber) => Promise<void>;
  /** tx */
  tx?: boolean;
}

// to fetch allowance
export const useAllowance = (
  /** owner address */
  owner: string,
  /** spender address */
  spender: string,
  /** token address */
  token: string,
  /** chain Id */
  chainId?: number,
  /** noCall - true means call is not required */
  noCall?: boolean
): BigNumber => {
  let tokenContract = useTokenContract(token);
  let [allowance, setAllownace] = useState<BigNumber>();
  const { appChainId } = useApplicationUserState();

  useEffect(() => {
    if (!isEmpty(tokenContract) && spender && owner && chainId === appChainId) {
      // fetch allowance only once
      const derivedAllowance = async () => {
        let allowed = await tokenContract.allowance(owner, spender);
        setAllownace(allowed);
      };
      derivedAllowance();
    }
  }, [tokenContract, owner, spender, noCall]);
  return allowance;
};

export const useApproval = (
  allowedAmount: BigNumber,
  /** amount to spend */
  amountToSpend: BigNumber,
  /** spender address */
  spender: string,
  /** approval token Id */
  tokenId: string
): ApprovalResponse => {
  // to check if approval required
  let [isApprovalRequired, setApprovalRequired] = useState<boolean>(true);
  const [tx, setTx] = useState<boolean>(false);

  let [approvalTxStatus, setApprovalTxStatus] = useState<ApprovalTransactionStatus>();
  const { account, library } = useWeb3React();
  const instance = useTokenContract(tokenId);
  useEffect(() => {
    if (!allowedAmount || !amountToSpend) return null;
    if (unitFormatter(allowedAmount) > unitFormatter(amountToSpend) + 5000) {
      setApprovalRequired(false);
    }
  }, [allowedAmount, amountToSpend]);

  let amount = unitParser('100000000');
  const triggeredApproval = useCallback(async () => {
    try {
      if (!account || !instance || !spender) return null;
      // set approval status loading
      setApprovalTxStatus(ApprovalTransactionStatus.APPROVAL_LOADING);
      // fetch the gaslimit
      // console.log('amount', amount);
      const gasLimit = await estimatedGas(instance, 'approve', [spender, amount], account);
      // get the median gas price for latest 50 BLock.
      const gas_price = await gasPrice(library);

      const transaction = await instance.connect(library.getSigner()).approve(spender, amount, {
        from: account,
        gasLimit,
        gasPrice: gas_price,
      });

      // waiting atleast two confirmation
      await transaction.wait(2);

      setApprovalRequired(false);
      // set approval transaction status to confirm
      setApprovalTxStatus(ApprovalTransactionStatus.APPROVAL_CONFIRMED);
    } catch (err) {
      // set error
      setApprovalTxStatus(ApprovalTransactionStatus.APPROVAL_ERROR);
    }
  }, [spender, library, instance, account, amount]);

  return {
    isApprovalRequired,
    approvalTxStatus,
    triggeredApproval,
    tx,
  };
};

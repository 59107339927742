import { Button } from '@mui/material';
import styled from 'styled-components';
//Choose Token styled----->
const SelectButton = styled(Button)`
  width: 100%;
  display: flex !important;
  align-items: start !important;
  flex-direction: column !important;
  background: transparent !important;
  color: black !important;
  border-bottom: 1px solid #616161 !important;
  border-radius: 0px !important;
  text-transform: none !important;
  padding: 0px !important;
  &:hover {
    background-color: transparent !important;
  }
`;
const ChooseText = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  font-family: 'Inter';
  font-style: normal;
  color: #616161;
`;
const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`;
const IconImage = styled.img`
  width: 30px;
  height: 30px;
`;
const CommonTokenDiv = styled.div`
  margin-top: 25px;
  @media (max-width: 430px) {
    margin-top: 1rem;
  }
`;
const CommonText = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  color: #212121;
  @media (max-width: 430px) {
    font-size: 14px;
  }
`;
const CommonTokens = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  margin-top: 1rem;
`;
const CommonToken = styled.div`
  width: fit-content;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid #673ab7;
  border-radius: 50px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #673ab7;
  gap: 0.5rem;
  padding: 0.5rem;
  cursor: pointer;
  @media (max-width: 430px) {
    height: 30px;
    font-size: 12px;
  }
`;
const TokenIcon = styled.img`
  width: 20px;
  height: 20px;
`;
const Tokens = styled.div`
  width: 100%;
  height: 340px;
  overflow-y: scroll;
  margin-top: 25px;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 430px) {
    height: 275px;
    margin-top: 1rem;
  }
`;

// Manage Token styled----------->
const TokensDiv = styled.div`
  margin-top: 30px;
`;
const ManageTokens = styled.div`
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
`;
const TokenInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`;
const ManageTokenIcon = styled.img`
  width: 45px;
  height: 45px;
`;
const TokenName = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #616161;
  text-transform: uppercase;
`;

// Token Items styled----------->
const TokenWrapper = styled.div`
  width: 100%;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
  //   border-bottom: 1px solid #e0e0e0;
  padding-left: 20px;
  padding-right: 0.5rem;
  @media (max-width: 430px) {
    height: 55px;
  }
  :hover{
    background: #80808012;
    cursor: pointer;
  }
`;
const InfoDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;
const TokenItemIcon = styled.img`
  width: 45px;
  height: 45px;
  @media (max-width: 430px) {
    width: 30px;
    height: 30px;
  }
`;
const TokenDetails = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;
const TokenTitle = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  text-transform: uppercase;
  color: #673ab7;
  @media (max-width: 430px) {
    font-size: 14px;
  }
`;
const TokenDiscription = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #616161;
`;
const Amount = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  color: #673ab7;
`;
export {
  SelectButton,
  ChooseText,
  InputWrapper,
  IconImage,
  CommonTokenDiv,
  CommonText,
  CommonTokens,
  CommonToken,
  TokenIcon,
  Tokens,
  TokensDiv,
  ManageTokens,
  TokenInfo,
  ManageTokenIcon,
  TokenName,
  TokenWrapper,
  InfoDiv,
  TokenItemIcon,
  TokenDetails,
  TokenTitle,
  TokenDiscription,
  Amount
};

import { UserNFTs, useUserClaims, useUserNFts } from 'hooks/useNFTs';
import { orderBy } from 'lodash';
import React, { useContext } from 'react';

export interface FarmContextData {
  /** stakes */
  userNfts: UserNFTs[] | null;
  /** claims */
  userClaims: UserNFTs[] | null;
}

let Context = React.createContext<FarmContextData>({ userNfts: undefined, userClaims: undefined });

// nfts
export let useV2UserNftsContext = () => {
  return useContext(Context).userNfts;
};

export let useV2UserClaimsContext = () => {
  return useContext(Context).userClaims;
};

const FarmContext = ({ children }: { children: React.ReactNode }) => {
  // user NFts
  let userNfts = useUserNFts();
  userNfts = orderBy(userNfts, ['stake.startBlock'], ['desc']);
  // user claims
  let userClaims = useUserClaims();
  userClaims = orderBy(userClaims, ['stake.endBlock'], ['desc']);

  return <Context.Provider value={{ userNfts, userClaims }}>{children}</Context.Provider>;
};

export default FarmContext;

import { Button } from '@mui/material';
import styled from 'styled-components';
import Badge from '@mui/material/Badge';
export const TokenInfoWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff;
  // border: 1px solid #e0e0e0;
  // border-radius: 10px;
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 25px;
  @media (max-width: 430px) {
    flex-wrap: wrap;
    height: 150px;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
  }
`;
export const SubToken = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  @media (max-width: 430px) {
    padding-left: 1rem;
  }
`;
export const TokenIcon2 = styled.img`
  width: 60px;
  height: 60px;
  @media (max-width: 430px) {
    width: 30px;
    height: 30px;
  }
`;

export const TokenTextDiv1 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 0.5rem;
  flex-direction: column;
`;
export const TokenTextDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 0.5rem;
  flex-direction: column;
  @media (max-width: 430px) {
    width: 50%;
    align-items: center;
  }
`;
export const TokenTitle = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #616161;
  @media (max-width: 430px) {
    font-size: 12px;
  }
`;
export const TokenName = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #212121;
  @media (max-width: 430px) {
    font-size: 14px;
  }
`;

export const LiquidityWrapper = styled.div`
  width: 100%;
  margin: 0px auto;
  display: flex;
  background: #ffffff;
  // border: 1px solid #e0e0e0;
  // border-radius: 15px;
  margin-top: 1rem;
  @media (max-width: 430px) {
    flex-direction: column;
    width: 100%;
  }
`;
export const NetworkWrapper = styled.div`
  width: 100%;
  padding: 15px;
  @media (max-width: 430px) {
    width: 100%;
  }
`;

export const StyledButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 25px !important;
  padding: 0px;
  gap: 8px;
  color: white !important;
  width: 100%;
  text-transform: capitalize !important;
  height: 55px;
  background: #673ab7 !important;
  box-shadow: 0px 7px 18px -2px rgba(103, 58, 183, 0.56) !important;
  border-radius: 10px !important;
  margin-left: 10px !important;
`;

export const StyledButton2 = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 25px !important;
  padding: 0px;
  gap: 8px;
  color: #673ab7 !important;
  width: 100%;
  text-transform: capitalize !important;
  height: 55px;
  background: white !important;
  border: 1px solid #673ab7 !important;
  // box-shadow: 0px 7px 18px -2px rgba(103, 58, 183, 0.56);
  border-radius: 10px !important;
  margin-right: 10px !important;
`;

export const ButttonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BoosterWrapper = styled.div`
  border-radius: 10px;
  padding: 25px 15px;
  // margin-top: 25px;
  @media (max-width: 430px) {
    margin-top: 1rem;
  }
`;
export const EarnDiv = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
`;
export const EarnText = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  @media (max-width: 430px) {
    font-size: 14px;
  }
`;
export const ToggleGroupDiv = styled.div`
  margin-top: 1rem;
`;
export const InputIcon = styled.div`
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  margin-top: 1rem;
`;
export const InputIconLabel = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #616161;
  margin-bottom: 10px;
`;
export const ButtonDiv = styled(Button)`
  color: white !important;
  background: #673ab7 !important;
  box-shadow: 0px 7px 18px -2px rgba(103, 58, 183, 0.56) !important;
  border-radius: 10px !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 8px !important;
  width: 140px !important;
  height: 45px !important;
  text-transform: capitalize !important;
`;
export const ViewDetails = styled.div`
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: start;
  cursor: pointer;
  @media (max-width: 430px) {
    width: 20px;
  }
`;

export const DetailsSubDiv = styled.div`
  display: flex;
  align-items: start;
`;
export const TokenDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
`;
export const NetworkIcon = styled.img`
  width: 25px;
  height: 25px;
  @media (max-width: 430px) {
    width: 15px;
    height: 15px;
  }
`;

export const Title = styled.div`
  display: flex;
  justify-content: start;
  font-weight: 600;
  font-size: 20px;
  line-height: 39px;
  font-family: 'Inter';
  font-style: normal;
  color: #673ab7;
  margin-bottom: 3rem;
  margin-top: 1rem;
`;
export const TableTitleDiv = styled.div`
  height: 50px;
  background: #f0ebf8;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.12);
  border-radius: 5px 5px 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 2rem;
`;
export const TableList = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #673ab7;
  display: flex;
  justify-content: start;
`;
export const LiquidityList = styled.div`
  width: 200px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #673ab7;
  display: flex;
  justify-content: start;
  @media (max-width: 430px) {
    display: none;
  }
`;
export const ScrollWrapper = styled.div`
  height: 430px;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  @media (max-width: 430px) {
    width: 102%;
  }
`;
export const BadgeStyle = styled(Badge)`
  & > .BaseBadge-badge {
    background: rgb(103, 58, 183) !important;
  }
`;
export const BadgeWrapper = styled.div`
  position: absolute;
  left: 287px;
  top: 12px;
`;

export const DetailsSubDivProcess = styled.div`
  width: 230px;
  display: flex;
  align-items: start;
`;
export const TokenDivProcess = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
`;
export const TokenInfo = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: start;
  gap: 0.5rem;
  @media (max-width: 430px) {
    margin-top: -6px;
  }
`;
export const TokenNameProcess = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  font-family: 'Inter';
  font-style: normal;
  color: #212121;
`;
export const TokenValueDetail = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  font-family: 'Inter';
  font-style: normal;
  color: #616161;
  @media (max-width: 430px) {
    display: none;
  }
`;

export const DetailsSubDivDetail = styled.div`
  width: 230px;
  display: flex;
  align-items: start;
  @media (max-width: 430px) {
    width: 160px;
    margin-bottom: -15px;
  }
`;
export const TokenDivDetail = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
`;
export const TokenIcon = styled.img`
  width: 40px;
  height: 40px;
`;
export const TokenInfoDetail = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 0.5rem;
`;
export const TokenNameDetail = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  font-family: 'Inter';
  font-style: normal;
  color: #212121;
  @media (max-width: 430px) {
    color: #673ab7;
    font-size: 10px;
  }
`;
export const TokenValue = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  font-family: 'Inter';
  font-style: normal;
  color: #616161;
  @media (max-width: 430px) {
    font-weight: 600;
    font-size: 14px;
    color: #673ab7;
  }
`;
export const MobileDivWrapper = styled.div`
  margin-top: -15%;
  display: flex;
  align-items: center;
  column-gap: 5px;
`;

export const DetailsSubDivView = styled.div`
  width: 230px;
  display: flex;
  align-items: start;
`;

export const TokenDivView = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
`;
export const ViewText = styled.a`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #616161;
  text-decoration-line: underline;
  cursor: pointer;
`;
export const NotFoundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
  row-gap: 10px;
`;
export const NotFoundImage = styled.img`
  height: 150px;
  width: 200px;
`;

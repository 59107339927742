import React from 'react';
import { NetworkInputDiv, NetworkInputLabel, NetworkSelectDiv } from './AddLiquidityStyled';

import ChooseNetwork from './ChooseNetwork';



interface Props {
  chosenNetwork: any,
  networks: number[],
  handle: (newValue: any) => void
}

const Network = ({ chosenNetwork, networks, handle }: Props) => {

  const handleChange = (newValue: any) => {
    console.log(newValue);
    handle(newValue);
  }
  return (
    <>
      <NetworkInputDiv>
        <NetworkInputLabel>Connected Network</NetworkInputLabel>
        <NetworkSelectDiv>
          { chosenNetwork &&
            <ChooseNetwork chosenNetwork={chosenNetwork} networks={networks} handledoor={handleChange} /> 
          }
        </NetworkSelectDiv>
      </NetworkInputDiv>
    </>
  );
};

export default Network;

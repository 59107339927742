import Empty from 'components/Launch/Empty';
import { Button, makeStyles, Theme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import UfarmMetaFrame from 'components/UfarmMeta';
import Modal from 'components/Modal';
import { useApplicationUserState } from 'store/user/hooks';
import { WalletAddress } from 'utilities';
import { useLocation } from 'react-router-dom';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import UfarmLink from './UfarmLink';
import Transfi from '../../assets/BuyUfarmImage/transfi.png';
import Orionswap from '../../assets/BuyUfarmImage/OrienSwap.png';
import Onmeta from '../../assets/BuyUfarmImage/onmeta.png';
import ContentLoader from 'components/ContentLoader';
interface isBuyUfarm {
  buyUfarmClick: () => void;
}
const BuyUfarmButton = ({ buyUfarmClick }: isBuyUfarm) => {
  const [open, setOpen] = useState(false);

  const location = useLocation();
  const { active, account } = useWeb3React();
  const { appChainId } = useApplicationUserState();
  const [isProcess, setProcess] = useState(false);
  const [back, setBack] = useState(true);
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);
  const useStyles = makeStyles((theme: Theme) => ({
    Root: {
      '&>.MuiDialog-container>.MuiPaper-root.MuiDialog-paperWidthSm ': {
        width: '490px !important',
        borderRadius: '15px !important',
        height: isProcess ? '100% !important' : 'auto !important',
        background: 'rgb(251 251 251 / 1) !important',
      },
      '&>.MuiDialog-container>.MuiPaper-root.MuiDialog-paperWidthSm .MuiDialogContent-root': {
        display: 'flex !important',
        alignItems: 'center !important',
      },
      [theme.breakpoints.down('xs')]: {
        '&>.MuiDialog-container>.MuiPaper-root.MuiDialog-paper': {
          width: '100% !important',
          margin: '15px !important',
        },
        '&>.MuiDialog-container>.MuiPaper-root.MuiDialog-paperWidthSm .MuiDialogTitle-root h2 div div': {
          fontSize: '20px !important',
        },
      },
    },
    BuyUfarmButton: {
      background: 'linear-gradient(93.92deg,#cb218e 1.76%,#639 84%)',
      color: 'white',
      border: 'none',
      borderRadius: '5px',
      marginLeft: '0.5rem',
      padding: '6px 16px',
      textTransform: 'capitalize',

      boxShadow: '0 6px 24px 0 rgb(203 33 142 / 30%)',
      '&:hover': {
        background: 'linear-gradient(93.92deg,#cb218e 1.76%,#639 84%)',
      },
      [theme.breakpoints.down('xs')]: {
        marginBottom: '0.5rem',
        boxShadow: 'none',
      },
    },
    buyUfarmButtonDisable: {
      background: '#cccccc',
      color: '#666666',
      border: 'none',
      borderRadius: '5px',
      marginLeft: '0.5rem',
      padding: '6px 16px',

      '&:hover': {
        background: '#cccccc',
        cursor: 'not-allowed',
      },
      [theme.breakpoints.down('xs')]: {
        marginBottom: '0.5rem',
        boxShadow: 'none',
      },
    },
    buyUfarmWrapp: {
      display: 'flex',
      alignItems: 'center',
      height: '74vh',
      justifyContent: 'center',
    },
    linkRoot: {
      display: 'flex',
      alignItems: 'center',
      columnGap: 21,
      flexFlow: 'wrap',
      marginTop: '0.8rem',
      marginBottom: '1.5rem',
      rowGap: 20,
    },
    TextHead: {
      color: '#000000',
      fontSize: '16px',
      fontWeight: 'bold',
      textAlign: 'center',
      marginTop: '0.9rem',
    },
  }));
  const classes = useStyles();
  const handleClose = () => setOpen(false);

  const OpenBuyUfarm = () => {
    setOpen(true);
    setProcess(false);
  };
  const openWidget = (value) => {
    setValue(value);
    setProcess(true);
    setBack(true);
  };
  const backClick = () => {
    setBack(false);
    setProcess(false);
  };

  // useEffect(() => {
  //   setLoading(true);
  //   setTimeout(() => {
  //     setLoading(false);
  //   }, 2000);
  // }, [isProcess]);

  const Widgeteturn = (value) => {
    switch (value) {
      case 'transfi':
        return <UfarmMetaFrame widgetUrl={'https://buy.transfi.com/?apiKey=m1M2NinUL3NxQcsU'} />;

      // return (
      //   <>
      //     {loading ? (
      //       <ContentLoader color="#6338BC" message="" />
      //     ) : (
      //       // <Loader title="Loading" />
      //       <UfarmMetaFrame widgetUrl={'https://buy.transfi.com/?apiKey=m1M2NinUL3NxQcsU'} />
      //     )}
      //   </>
      // );

      case 'onMeta':
        return (
          <UfarmMetaFrame
            widgetUrl={
              'https://platform.onmeta.in/?apiKey=7b7d5244-a61d-4b2c-a254-90269b492e07&walletAddress=' +
              WalletAddress(account) +
              `&fiatAmount=&userEmail=&tokenAddress=&chainId=` +
              appChainId +
              ''
            }
          />
        );

      // return (
      //   <>
      //     {loading ? (
      //       <ContentLoader color="#6338BC" message="" />
      //     ) : (
      //       <UfarmMetaFrame
      //         widgetUrl={
      //           'https://platform.onmeta.in/?apiKey=7b7d5244-a61d-4b2c-a254-90269b492e07&walletAddress=' +
      //           WalletAddress(account) +
      //           `&fiatAmount=&userEmail=&tokenAddress=&chainId=` +
      //           appChainId +
      //           ''
      //         }
      //       />
      //     )}
      //   </>
      // );
      case 'orion':
        return (
          <UfarmMetaFrame
            widgetUrl={
              'https://trade.orionprotocol.io/widget?partnerId= 86&defaultTheme=light&assetFrom=usdt&assetTo=UFARM&allowedAssets=bnb,eth,ftm'
            }
          />
        );

      // return (
      //   <>
      //     {loading ? (
      //       <ContentLoader color="#6338BC" message="" />
      //     ) : (
      //       <UfarmMetaFrame
      //         widgetUrl={
      //           'https://trade.orionprotocol.io/widget?partnerId= 86&defaultTheme=light&assetFrom=usdt&assetTo=UFARM&allowedAssets=bnb,eth,ftm'
      //         }
      //       />
      //     )}
      //   </>
      // );
    }
  };
  return (
    <>
      {location.pathname === '/launch' ||
      location.pathname === '/launch/project/0x1c9311843DD73B22ED6830D36213eE12aeF86fD7' ? null : (
        <>
          {active ? (
            <Tippy theme="light" placement="top" content="Click to Buy UFARM with Fiat">
              <Button className={classes.BuyUfarmButton} onClick={OpenBuyUfarm}>
                BUY UFARM
              </Button>
            </Tippy>
          ) : (
            <Tippy theme="light" placement="top" content="Install Metamask and connect your wallet to Buy UFARM with Fiat">
              <Button className={classes.BuyUfarmButton} onClick={buyUfarmClick}>
                BUY UFARM
              </Button>
            </Tippy>
          )}
        </>
      )}

      <Modal
        className={classes.Root}
        open={open}
        headerClass="true"
        title="Buy UniFarm"
        close={handleClose}
        isBack={back}
        backClick={backClick}
      >
        {isProcess ? (
          Widgeteturn(value)
        ) : (
          <div>
            <div className={classes.TextHead}>Choose your preferred on-ramp provider</div>
            <div className={classes.linkRoot}>
              <UfarmLink img={Transfi} content="Buy with Fiat" title="TransFi" onClick={() => openWidget('transfi')} />
              <UfarmLink
                img={Orionswap}
                content="Buy with Crypto"
                title="Orion Protocol"
                onClick={() => openWidget('orion')}
              />
              <UfarmLink img={Onmeta} content="Buy with INR" title="OnMeta" onClick={() => openWidget('onMeta')} />
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};
export default BuyUfarmButton;

import React from 'react';
import styled from 'styled-components';
import Transfi from '../../assets/BuyUfarmImage/transfi.png';
const Wrapper = styled.div`
  width: 205px;
  height: 120px;
  padding: 20px 10px;
  background: #ffffff;
  /* modal_shadow */
  border: 1px solid #e0e0e0;

  border-radius: 13px;
  &:hover {
    cursor: pointer;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  }
  @media (max-width: 430px) {
    width: 100%;
  }
`;
const ImageDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Title = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
`;
const Content = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #616161;
  margin-top: 20px;
`;
interface isProps {
  img: string;
  title: string;
  content: string;
  onClick: () => void;
}
const UfarmLink = ({ img, title, content, onClick }: isProps) => {
  return (
    <Wrapper onClick={onClick}>
      <ImageDiv>
        <img src={img} width={50} />
        <Title>{title}</Title>
      </ImageDiv>
      <Content>{content}</Content>
    </Wrapper>
  );
};
export default UfarmLink;

import { Button, makeStyles, Theme } from '@material-ui/core';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import React from 'react';
import trees from '../../assets/V2/Images/trees.png';
import { useOpenWalletPopUp } from '../../store/application/hooks';

const Empty = () => {
  const useStyles = makeStyles((theme: Theme) => ({
    muiGrid: {
      '&>.MuiGrid-root .MuiPaper-root': {
        boxShadow: 'none',
        background: 'transparent',
        borderRadius: 10,
        marginTop: '1.5rem',
        [theme.breakpoints.down('xs')]: {
          background: '#FFFFFF',
        },
      },
    },
    paperOn: {
      ...theme.typography.body2,
      border: '1px solid #E0E0E0',
      marginTop: '10px',
      height: '396px',
      padding: theme.spacing(4),
      borderRadius: 20,
      textAlign: 'center',
      color: theme.palette.text.secondary,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      boxShadow: '0 0 0.5px rgba(6, 10, 13, 0.4), 0 8px 16px rgba(113, 121, 128, 0.08)',
    },
    trees: {},
    noff: {
      width: '274px',
      height: '55px',
      color: 'white',
      background: '#673AB7',
      marginTop: '1rem',
      boxShadow: '0px 7px 18px -2px rgba(103, 58, 183, 0.56)',
      borderRadius: '10px',

      '&:hover': {
        background: '#673AB7',
      },
    },
  }));

  const classes = useStyles();

  const openWalletModal = useOpenWalletPopUp();
  return (
    <>
      <Grid container className={classes.muiGrid}>
        <Grid item xs={12}>
          <Paper className={classes.paperOn}>
            <img src={trees} width={406} height={160} className={classes.trees} />
            <Button className={classes.noff} style={{ textTransform: 'capitalize' }} onClick={() => openWalletModal()}>
              Connect Wallet
            </Button>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
export default Empty;

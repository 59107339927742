import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch, AppState } from 'store';
import { useWeb3React } from '@web3-react/core';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { POLYGON_CHAIN } from 'constants/chain';
import { getIdoClaimedData } from 'utilities/V2/farms';
import { unitFormatter } from 'utilities';
import { setUserIdoClaims } from './action';
import { IdoClaimData } from './reducer';

export const useFetchUserClaim = (): void => {
  const { chainId, account } = useWeb3React();

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchClaims = async () => {
      console.log(chainId, account);
      if (!account || chainId !== POLYGON_CHAIN) return null;

      let claimIdo = [] as {
        id: string;
        account: string;
        tokenType: number;
        amount: number;
        timeStamp: number;
      }[];

      let claims = await getIdoClaimedData(POLYGON_CHAIN);

      if (isEmpty(claims)) return null;
      for (let i = 0; i < claims.length; i++) {
        if (String(claims[i].account).toLowerCase() === String(account).toLowerCase()) {
          claimIdo.push({
            id: claims[i].id,
            account: claims[i].account,
            tokenType: Number(claims[i].tokenType),
            amount: Number(claims[i].tokenType) == 0 ? unitFormatter(claims[i].amount) : unitFormatter(claims[i].amount, 6),
            timeStamp: Number(claims[i].timeStamp) * 1000,
          });
        }
      }

      dispatch(setUserIdoClaims({ ido: claimIdo }));
    };

    fetchClaims();
  }, [chainId, account]);
};

export const useIdoClaim = (): IdoClaimData[] => {
  return useSelector((state: AppState) => state.idoClaims.claimData);
};

import React from 'react';
import styled from 'styled-components';
import CallMadeIcon from '@mui/icons-material/CallMade';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import scheduleImg from '../../assets/images/New/idoSchedule.png';

const SelfHostingContainer = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  gap: 30px;
`;
const InfoWrapper = styled.div`
  flex: 0.6;
`;
const PurchaseWrapper = styled.div`
  flex: 0.4;
`;
const InfoItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 0px;
`;
const AboutCont = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin-top: 40px;
`;
const Title = styled.h3`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
`;
const Text = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #616161;
  text-align: left;
`;
const ScheduleCont = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin-top: 40px;
`;

const ScheduleImage = styled.img`
  width: 100%;
  object-fit: contain;
`;
const Link = styled.a`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: left;
`;
function SelfHosting() {
  return (
    <SelfHostingContainer>
      <InfoWrapper>
        <InfoItems>
          <InfoItem id={1} title="Market Cap" amount="$414.65B USD" up trend={5.66} />
          <InfoItem id={2} title="Max Supply" amount="1117,878,943 " up={false} trend={2.06} />
          <InfoItem id={3} title="Circulating Supply" amount="1117,878,943 " up trend={4.77} />
          <InfoItem id={4} title="All-Time High" amount="$1.1 USD" up={false} trend={20.33} />
          <InfoItem id={5} title="24h Volume" amount="$18.82B USD" up trend={12.19} />
        </InfoItems>
        <AboutCont>
          <Title>About IDO </Title>
          <Text>
            USDT is a stablecoin (stable-value cryptocurrency) that mirrors the price of the U.S. dollar,
            issued by a Hong Kong-based company Tether. The token’s peg to the USD is achieved via
            maintaining a sum of commercial paper, fiduciary deposits, cash, reserve repo notes
          </Text>
        </AboutCont>
        <ScheduleCont>
          <Title>Unlock Schedule</Title>
          <ScheduleImage src={scheduleImg} alt="image" />
          <Text>
            {` USDT is a stablecoin (stable-value cryptocurrency) that mirrors the price of the U.S. dollar, issued by a Hong Kong-based company Tether. The tokens peg to the USD is achieved via maintaining a sum of commercial paper, fiduciary deposits, cash, reserve repo notes `}
            <Link href="#">click here</Link> to view more information
          </Text>
        </ScheduleCont>
      </InfoWrapper>
      <PurchaseWrapper></PurchaseWrapper>
    </SelfHostingContainer>
  );
}
export default SelfHosting;
interface InfoItemProps {
  title: string;
  amount: string;
  trend: number;
  up: boolean;
  id: number;
}
const InfoItemCont = styled.div<{ id?: number }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  flex: 0.35;
  flex-grow: 1;
  max-width: 33%;
  height: 155px;
  padding: 20px;
  flex-basis: 33%;
  border-top: ${(props) => (props.id === 4 || props.id === 5 ? '1px solid #E0E0E0' : 'none')};
  border-right: ${(props) => (props.id === 3 ? 'none' : '1px solid #E0E0E0')};
  border-bottom: ${(props) => (props.id === 3 ? '1px solid #E0E0E0' : 'none')};
`;
const ItemTitle = styled.div`
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  color: #212121;
`;
const ItemAmount = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #212121;
`;

function InfoItem({ title, amount, trend, up /* id */ }: InfoItemProps) {
  return (
    <InfoItemCont>
      <ItemTitle>{title}</ItemTitle>
      <ItemAmount> {amount} </ItemAmount>
      <Trend up={up} trend={trend} />
    </InfoItemCont>
  );
}

interface TrendProps {
  up: boolean;
  trend: number;
}

function Trend({ up, trend }: TrendProps) {
  const TrendCont = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: ${up ? '#00C853' : '#C62828'};
  `;
  return (
    <TrendCont>
      {up ? <CallMadeIcon /> : <CallReceivedIcon />}
      {trend}
    </TrendCont>
  );
}

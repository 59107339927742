import { createReducer } from '@reduxjs/toolkit';
import { flipTokens } from 'constants/flip';
import { setFlipToken } from './action';

export interface FlipToken {
  name: string;
  symbol: string;
  baseToken: string;
  flipingToken: string;
  icon: string;
  decimals: number;
  price: number;
  chainId: number;
}

export interface Flip {
  flipToken: FlipToken | null;
}

const flipState: Flip = {
  flipToken: flipTokens['UFARM'],
};

const flip = createReducer<Flip>(flipState, (builder) => {
  builder.addCase(setFlipToken, (state, { payload: { flipToken } }) => {
    return {
      ...state,
      flipToken,
    };
  });
});

export default flip;

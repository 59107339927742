import * as React from 'react';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { Helmet } from 'react-helmet-async';
import Switch from './SwitchVersion/index';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
`;
const PageTitle = styled.span`
  font-size: 28px;
  font-weight: bold;
  line-height: 24px;
  font-family: 'Inter';
  font-style: normal;
  color: #6338bc;
`;
const TitleText = styled.span`
  width: 450px;
  font-size: 15px;
  font-weight: 500;
  line-height: 21px;
  font-family: 'Inter';
  font-style: normal;
  color: #616161;
  margin-top: 0.5rem;
`;

interface PageHeaderProps {
  /** tilte of a page */
  title: string;
  /** content of a page */
  content: any;
  /** has show the v2 switch */
  hasShowSwitch: boolean;
}

function PageHeader({ title, content, hasShowSwitch }: PageHeaderProps) {
  const Theme = useTheme();
  const Mobile = useMediaQuery(Theme.breakpoints.down('sm'));

  return (
    <>
      {Mobile ? (
        <>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Helmet>
              <title>
                {title === "Unifarm's Staking Dashboard" ? "Unifarm's Staking Dashboard" : `Unifarm Dashboard | ${title}`}{' '}
              </title>
              <meta name="description" content={content} />
            </Helmet>
            <Box
              style={{ width: '100%', textAlign: 'left' }}
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: 'fit-content',
                marginTop: '1rem',
                border: (theme) => `0px solid ${theme.palette.divider}`,
                borderRadius: 1,
                color: 'text.secondary',
                '& svg': {
                  m: 1.5,
                },
                '& hr': {
                  mx: 0.5,
                },
              }}
            >
              <h2
                style={{
                  color: '#6338BC',
                  marginRight: '0.5rem',
                  fontSize: 18,
                  marginBottom: 0,
                  marginTop: 0,
                  fontWeight: 600,
                }}
              >
                {title}
              </h2>
              <Divider orientation="vertical" flexItem />
              <p style={{ marginLeft: '0.5rem', width: '100%', marginTop: '20px' }}>{content}</p>
            </Box>
          </div>
          {hasShowSwitch && <Switch />}
        </>
      ) : (
        <>
          <Wrapper>
            <Helmet>
              <title>
                {title === "Unifarm's Staking Dashboard" ? "Unifarm's Staking Dashboard" : `Unifarm Dashboard | ${title}`}{' '}
              </title>
              <meta name="description" content={content} />
            </Helmet>
            <Box style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', textAlign: 'left'}}>
              <PageTitle>{title}</PageTitle>
              <Divider orientation="vertical" style={{ height: '60px' }} />
              <TitleText>{content}</TitleText>
            </Box>
            {hasShowSwitch && <Switch />}
          </Wrapper>
        </>
      )}
    </>
  );
}

export default PageHeader;

import { createReducer } from '@reduxjs/toolkit';
import { fullfilledTokenList } from './actions';

export interface TokenMetaData {
  /** token name */
  name: string;
  /** token symbol */
  symbol: string;
  /** token address */
  address: string;
  /** token icon */
  icon: string;
  /** decimals */
  decimals: number;
  /** price */
  price: number;
  /** chainId */
  chainId: number;
  /** token tags */
  tags: string[] | [];
}

interface TokenPricing {
  /** prices are map with cohort version */
  [version: string]: number;
}

export interface LiquidityPoolToken {
  /** lp token address */
  lpToken: string;
  /** price points */
  pricePoints: TokenPricing;
  /** lp token decimals */
  decimals: number;
  /** dex name */
  dex: string;
  /** tokens baiscally token0 and token1 */
  tokens: string[];
  /** chainId */
  chainId: number;
  /** dexAddLiquidityPage */
  dexAddLiquidityPage: string;
}

export interface TokenMetaDataV2 {
  /** token name */
  name: string;
  /** token symbol */
  symbol: string;
  /** token address */
  address: string;
  /** token icon */
  icon: string;
  /** decimals */
  decimals: number;
  /** price */
  pricePoints: TokenPricing;
  /** chainId */
  chainId?: number;
  /** token tags */
  tags?: string[] | [];
}

export interface TokenListV2 {
  /** tokens */
  tokens: TokenMetaDataV2[];
  /** lp tokens */
  lpTokens: LiquidityPoolToken[];
}

export interface V2TokenList {
  [chainId: number]: TokenListV2;
}

interface TokenList {
  /** token list for v1 */
  tokenlist: { [chainId: number]: TokenMetaData[] };
  /** token list for v2 */
  tokenlistV2: { [chainId: number]: TokenListV2 };
}

const tokenlistState: TokenList = {
  tokenlist: null,
  tokenlistV2: null,
};

const lists = createReducer(tokenlistState, (builder) => {
  builder.addCase(fullfilledTokenList, (state, { payload: { tokenlist, tokenlistV2 } }) => {
    return {
      ...state,
      tokenlist,
      tokenlistV2,
    };
  });
});

export default lists;

import React from 'react';
import { Button, Divider } from '@material-ui/core';
import Menu, { MenuProps } from '@mui/material/Menu';
import { alpha, styled } from '@mui/material/styles';
import { divide } from 'lodash';
import { useHistory } from 'react-router-dom';
import Style from 'styled-components';
import useStyles from '../Header/style';
import { formatCurrency, usdCurrencyFormat } from '../../utilities';
import { useUnifarmBreakDown } from '../../hooks/useUnifarmBreakDown';
import Udfarm from '../../assets/images/others/ufarm_mob.png';
import Unifarmer from '../../assets/images/others/unifarmerer.png';
import one from '../../assets/images/New/1.png';
import two from '../../assets/images/New/2.png';
import three from '../../assets/images/New/3.png';
import four from '../../assets/svg/pangolin.svg';

const MainPrice = Style.div`
width: 330px;
height: 130px;
display:flex;
flex-direction: column;
align-items: center;
justify-content: center;
@media (max-width: 430px) {
  height: 100px;
}
`;
const IconImage = Style.img`
width:45px;
height:45px;
@media (max-width: 430px) {
  width:40px;
  height:40px;
}
`;
const PriceTitle = Style.div`
font-size: 20px;
font-weight: 700;
line-height: 24px;
font-family: 'Inter';
font-style: normal;
color: #2E2E2E;
margin-top: 0.5rem;
@media (max-width: 430px) {
  font-size: 18px;
}
`;
const ActionDiv = Style.div`
display: flex;
justify-content: center;
margin-top: 1rem;
margin-bottom: 0.5rem;
`;
const ClaimBtn = Style.button`
width:90%;
height:50px;
background: #673AB7;
box-shadow: 0px 7px 18px -2px rgba(103, 58, 183, 0.56);
border-radius: 10px;
border:none;
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 15px;
line-height: 26px;
color: #FFFFFF;
cursor: pointer;
@media (max-width: 430px) {
  height:40px;
font-size: 14px;
font-weight: 500;
cursor: pointer;
  }
`;
const MenuContainer = Style.div`
padding: 15px;
@media (max-width: 430px) {
padding: 10px;
}
`;
const MenuContent = Style.div`
display: flex;
justify-content: space-between;
margin-top:1rem;
@media (max-width: 430px) {
margin-top:0.8rem;
  }
`;
const MenuTitle = Style.span`
font-size: 16px;
font-weight: 500;
line-height: 20px;
font-family: 'Inter';
font-style: normal;
color: #2E2E2E;
@media (max-width: 430px) {
  font-size: 14px;
    }
`;
const TradeTitle = Style.span`
font-size: 18px;
font-weight: 600;
line-height: 20px;
font-family: 'Inter';
font-style: normal;
color: #2E2E2E;
@media (max-width: 430px) {
  font-size: 16px;
    }
`;
const MenuValue = Style.span`
font-size: 14px;
font-weight: 400;
line-height: 18px;
font-family: 'Inter';
font-style: normal;
`;

const StyledMenu1 = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 10,
    marginTop: theme.spacing(1),
    minWidth: '331px',
    minHeight: '332px',
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));
const TradeOn = () => {
  const classes = useStyles();
  const [anchorEl3, setAnchorEl3] = React.useState<null | HTMLElement>(null);
  const open3 = Boolean(anchorEl3);
  const history = useHistory();
  const handleClick3 = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl3(event.currentTarget);
  };
  const handleClose3 = () => {
    setAnchorEl3(null);
  };

  const unifarmBreakDown = useUnifarmBreakDown();

  return (
    <div>
      <Button className={classes.HeaderBtn} onClick={handleClick3}>
        <img src={Unifarmer} alt="TokenIcon" />
        &nbsp;&nbsp;
        {unifarmBreakDown?.unifarmPrice ? usdCurrencyFormat(unifarmBreakDown?.unifarmPrice) : '--'}
      </Button>

      <StyledMenu1
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl3}
        open={open3}
        onClose={handleClose3}
      >
        <MainPrice>
          <IconImage src={Udfarm} alt="IconImage" />
          <PriceTitle>
            UFARM Price:&nbsp;
            {unifarmBreakDown?.unifarmPrice ? usdCurrencyFormat(unifarmBreakDown?.unifarmPrice) : '--'}
          </PriceTitle>
        </MainPrice>
        <Divider />
        <div>
          <MenuContainer>
            <MenuContent>
              <MenuTitle>Total Circulation</MenuTitle>
              <MenuValue>
                {unifarmBreakDown?.unifarmMarketCap
                  ? formatCurrency(divide(unifarmBreakDown?.unifarmMarketCap, unifarmBreakDown?.unifarmPrice), '')
                  : '--'}
              </MenuValue>
            </MenuContent>
            <MenuContent>
              <MenuTitle>Total Supply</MenuTitle>
              <MenuValue>{formatCurrency(1000000000, '')}</MenuValue>
            </MenuContent>
            <MenuContent>
              <MenuTitle>Market Cap</MenuTitle>
              <MenuValue>
                {unifarmBreakDown?.unifarmMarketCap ? usdCurrencyFormat(unifarmBreakDown?.unifarmMarketCap) : '--'}
              </MenuValue>
            </MenuContent>
            <MenuContent>
              <TradeTitle>Trade On</TradeTitle>
              <div style={{ width: '120px' }}>
                <a
                  href="https://exchange.dfyn.network/#/swap?outputCurrency=0xa7305ae84519ff8be02484cda45834c4e7d13dd6&inputCurrency=MATIC"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={one} width={30} style={{ marginRight: '0.6rem' }} />
                </a>
                <a
                  href="https://app.uniswap.org/#/swap?outputCurrency=0x40986a85b4cfcdb054a6cbfb1210194fee51af88&inputCurrency=ETH"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={two} width={30} style={{ marginRight: '0.6rem' }} />
                </a>
                <a
                  href="https://pancakeswap.finance/swap?outputCurrency=0x0a356f512f6fce740111ee04ab1699017a908680&inputCurrency=wbnb"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={three} width={30} style={{ marginRight: '0.6rem' }} />
                </a>
                <a
                  href="https://app.pangolin.exchange/#/swap?outputCurrency=0xd60effed653f3f1b69047f2d2dc4e808a548767b"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={four} width={30} style={{ marginRight: '0.6rem', marginTop: '0.6rem' }} />
                </a>
              </div>
            </MenuContent>
          </MenuContainer>

          <ActionDiv>
            <ClaimBtn
              onClick={() => {
                return history.push('/vesting'), setAnchorEl3(null);
              }}
            >
              Claim UFARM
            </ClaimBtn>
          </ActionDiv>
        </div>
      </StyledMenu1>
    </div>
  );
};
export default TradeOn;

import React from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import InfoIcon from '@material-ui/icons/Info';

interface ToolTipProps {
  /** placement of tool tip */
  placement: Tippy.Placement;
  /** content of tooltip */
  content: string | any;
  /** children */
}

const ToolTip = ({ placement, content }: ToolTipProps) => {
  return (
    <>
      <Tippy placement={placement} theme="light" content={content}>
        <InfoIcon style={{ fontSize: '12px', color: '#c4c4c4' }} />
      </Tippy>
    </>
  );
};

export default ToolTip;

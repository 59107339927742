import { Button } from '@mui/material';
import React from 'react';
import { ClipLoader } from 'react-spinners';
import { LoadingTokenDetailWrapper, LoadingTokenMainWrapper, LoadingTokenStyledButton, LoadingTokenTitleWrapper, LoadingTokenWrapper } from './AddLiquidityStyled';


const LoadingToken = () => {
  return (
    <LoadingTokenMainWrapper>
      <LoadingTokenWrapper>
        <ClipLoader size={50} />
        <LoadingTokenTitleWrapper>Waiting for Confirmation</LoadingTokenTitleWrapper>
        <LoadingTokenDetailWrapper>Please Confirm Transaction in metamask</LoadingTokenDetailWrapper>
      </LoadingTokenWrapper>
      {/* <LoadingTokenStyledButton>View on Explorer</LoadingTokenStyledButton> */}
    </LoadingTokenMainWrapper>
  );
};
export default LoadingToken;

import { LinearProgress, useMediaQuery, useTheme } from '@material-ui/core';
import { useWeb3React } from '@web3-react/core';
import { mapchainIdToNetworkId } from 'constants/bridge';
import React, { useEffect, useState } from 'react';
import networksData from 'utilities/getNetworkData';

import BridgeContext from '../Setu/Context/BridgeContext';
// import { createStyles, makeStyles } from '@material-ui/core/styles';

import TransferModal from '../Setu/Transfer/TransferModal';
import { DetailsSubDiv, WithdrawBtn } from './WithDrawButtonStyle';

// const useStyle = makeStyles((theme) =>
//   createStyles({
//     Root: {
//       '&>.MuiDialog-container>.MuiPaper-root.MuiDialog-paperWidthSm ': {
//         width: '620px !important',
//         borderRadius: '15px !important',
//         maxWidth: '620px !important',
//       },
//       [theme.breakpoints.down('xs')]: {
//         '&>.MuiDialog-container>.MuiPaper-root.MuiDialog-paper': {
//           width: '100% !important',
//           margin: '15px !important',
//         },
//       },
//     },
//     Step: {
//       '& .MuiStepIcon-active': { color: '#673AB7' },
//       '& .MuiStepIcon-completed': { color: '#673AB7' },
//       '& .Mui-disabled .MuiStepIcon-root': { color: '#616161' },
//       [theme.breakpoints.down('xs')]: {
//         paddingLeft: '0px',
//         paddingRight: '0px',
//       },
//     },
//     MainStep: {
//       [theme.breakpoints.down('xs')]: {
//         paddingLeft: '0px',
//         paddingRight: '0px',
//       },
//     },
//   })
// );

interface IsTransferModal {
  status: number;
  amount: number;
  from: number;
  to: number;
  ticker: string;
  tokenImage: string;
  transferIndex: number;
  isProgress: boolean;
  clickWithdraw: () => void;
  transactionHash: any;
}

const WithdrawButton = ({
  status,
  amount,
  from,
  to,
  ticker,
  tokenImage,
  transferIndex,
  isProgress,
  clickWithdraw,
  transactionHash
}: IsTransferModal) => {
  console.log('satus', status);
  const [open, setOpen] = useState(false);
  // const classes = useStyle();
  const { chainId } = useWeb3React();
  const [data, setData] = useState(status && status + 1);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  // console.log("STATUS: ", status, data);

  const data1 = React.useContext(BridgeContext);
  useEffect(() => {
    if (!status || !amount) return;

    // if(status == 2 && chainId !== mapchainIdToNetworkId(to)) {
    //   data1.setCount(status);
    //   setData(status);
    // }
    // else {
    //   data1.setCount(status + 1);
    //   setData(status + 1);
    // }
    data1.setPrice(amount);
    data1.setFromNetwork(networksData[from].name);
    data1.setToNetwork(networksData[to].name);
    data1.setFromImage(networksData[from].logo);
    data1.setToImage(networksData[to].logo);
    data1.setName(ticker);
    data1.setImage(tokenImage);
  }, [open]);

  useEffect(() => {
    if (!status || !chainId) return;

    if (status == 1 || (status == 2 && chainId !== to)) {
      data1.setCount(status);
      setData(status);
    } else {
      // data1.setCount(status + 1 > 3 ? 3 : status + 1);
      data1.setCount(status + 1);
      setData(status + 1);
    }
  }, [open, chainId]);
  // console.log('count', data1.count);
  // const data = data1.count;

  const handleOpen = () => {
    if (data == 1) return;
    if (data == 2) {
      setOpen(true);
      clickWithdraw();
    } else if (data == 3) {
      data1.setWidthDraw(true);
      clickWithdraw();
      setOpen(true);
    }
  };
  return (
    <>
      {console.log(data)}
      <DetailsSubDiv>
        {data == 1 ? (
          <div style={{ width: '160px', position: 'relative', marginTop: '0px' }}>
            <span
              style={{
                color: 'white',
                position: 'absolute',
                zIndex: 1,
                fontSize: '15PX',
                fontWeight: 700,
                left: mobile ? '97px' : '43px',
                top: mobile ? '9px' : '11px',
              }}
            >
              Loading....
            </span>
            <LinearProgress
              variant="determinate"
              className="progress-bar-linear"
              value={data1.progress}
              style={{ height: mobile ? 45 : 45, borderRadius: 10 }}
            />
          </div>
        ) : (
          <WithdrawBtn
            style={{
              background: data <= 3 ? '#673AB7' : '#EFEFEF',
              color: data <= 3 ? '#ffffff' : '#616161',
            }}
            onClick={handleOpen}
          >
            {data == 0 ? 'Approve' : ''}
            {data == 2 ? 'Switch Network' : ''}
            {data == 3 ? 'Withdraw' : ''}
            {data == 4 ? 'Claimed' : ''}
          </WithdrawBtn>
        )}

        {/* {data == 3 && isProgress ? (
          <>
            {data1.progress >= 100 ? (
              <WithdrawBtn
                style={{
                  background: '#00C301',
                  color: 'white',
                }}
                onClick={handleOpen}
              >
                Withdraw
              </WithdrawBtn>
            ) : (
              <div style={{ width: '160px', position: 'relative', marginTop: '0px' }}>
                <span
                  style={{
                    color: 'white',
                    position: 'absolute',
                    zIndex: 1,
                    fontSize: '15PX',
                    fontWeight: 700,
                    left: mobile ? '97px' : '43px',
                    top: mobile ? '9px' : '11px',
                  }}
                >
                  Loading....
                </span>
                <LinearProgress
                  variant="determinate"
                  className="progress-bar-linear"
                  value={data1.progress}
                  style={{ height: mobile ? 45 : 45, borderRadius: 10 }}
                />
              </div>
            )}
          </>
        ) : (
          <>
            {data == 3 && data1.progress>=100? (
              <WithdrawBtn
                style={{
                  background: '#00C301',
                  color: 'white',
                }}
                onClick={handleOpen}
              >
                Withdraw
              </WithdrawBtn>
            ) : (
              <WithdrawBtn
                style={{
                  background: data <= 3 ? '#673AB7' : '#EFEFEF',
                  color: data <= 3 ? '#ffffff' : '#616161',
                }}
                onClick={handleOpen}
              >
                {data == 0 ? 'Approve' : ''}

                {data == 1 ? 'Awaiting confirmation' : ''}
                {data == 2 ? 'Switch Network' : ''}
                {data == 3 ? 'Withdraw' : ''}
                {data == 4 ? 'Claimed' : ''}
              </WithdrawBtn>
            )}
          </>
        )} */}
        <>
          <TransferModal
            open={open}
            close={() => setOpen(false)}
            amount={data1.tokenPrice}
            from={from}
            to={to}
            ticker={data1.tokenName}
            transferIndex={transferIndex}
            transactionHash={transactionHash}
          />
        </>
      </DetailsSubDiv>
    </>
  );
};

export default WithdrawButton;

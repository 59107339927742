import React from 'react';
import { Button } from '@material-ui/core';
import { getProviderLogo, miniWalletAddress } from '../../utilities';
import { useOpenWalletPopUp } from '../../store/application/hooks';
import { useWeb3React } from '@web3-react/core';
import styled from 'styled-components';
import { useEnsName } from 'contexts/EnsContext';

const ConnectWalletFlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledButton = styled(Button)<{ isActive: boolean }>`
  background: ${(props) => (props.isActive ? '#F8F8F9' : '#6338BC')};
  border: ${(props) => (props.isActive ? '1px solid transparent' : 'unset')};
  border-radius: 5px;
  margin-left: 0.5rem;
  padding: 6px 16px;
  letter-spacing: 2px;
  text-transform: normal;
  text-indent: 5px;
  text-transform: lowercase;
  box-shadow: ${(props) =>
    props.isActive ? '1px solid transparent' : '0 8px 16px rgb(99 56 188 / 13%)'};
  color: ${(props) => (props.isActive ? 'black' : 'white')};
  &: hover {
    background: ${(props) => (props.isActive ? 'transperent' : '#6338BC')};
  }
`;

export default function ConnectWallet(): JSX.Element {
  const { active, connector, account } = useWeb3React();

  const activeProviderLogo = getProviderLogo(connector);
  const open = useOpenWalletPopUp();

  let ensName = useEnsName();

  return (
    <StyledButton onClick={open} isActive={active}>
      {active ? (
        <ConnectWalletFlexWrapper>
          <img src={activeProviderLogo} alt="provider" />
          <span>{!ensName ? miniWalletAddress(account) : ensName}</span>
        </ConnectWalletFlexWrapper>
      ) : (
        <span>Connect Wallet</span>
      )}
    </StyledButton>
  );
}

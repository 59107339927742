import React from 'react';
import styled from 'styled-components';
import { TwitterShareButton, FacebookShareButton, TelegramShareButton, LinkedinShareButton } from 'react-share';

// social share logos
import facebook from '../../../assets/images/New/Facebook.png';
// import linkedin from '../../../assets/images/New/linkedin.png';
import telegram from '../../../assets/images/New/telegram.png';
import twitter from '../../../assets/images/New/twiteer.png';

const ShareCont = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
`;
const ShareItem = styled.div`
  width: 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin: 30px 0px;
  padding-bottom: 10px;
    padding-top: 10px;
    border-radius: 23px;
  &:hover{
    box-shadow:inset 0px 0px 0px 5px rgb(255 255 255 / 75%), 0px 3px 10px rgb(0 0 0 / 41%);
  }
  @media (max-width: 430px) {
    width: 130px;
    margin: 20px 0px;
  }
`;
const ShareIconImg = styled.img`
  width: 70px;
  height: 70px;
  margin: 10px;
`;
const ShareIconText = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
`;

function SocialShareItem({ platform, socialIcon }: { platform: string; socialIcon: string }): JSX.Element {
  return (
    <ShareItem>
      <ShareIconImg src={socialIcon} alt={platform} />
      <ShareIconText>Share on {platform}</ShareIconText>
    </ShareItem>
  );
}

interface SocialShareProps {
  /** title */
  title: string;
}

const SocialShare = ({ title }: SocialShareProps) => {
  return (
    <ShareCont>
      {/** twitter */}
      <TwitterShareButton url={`https://twitter.com/unifarm_`} title={title} via="Unifarm">
        <SocialShareItem platform="Twitter" socialIcon={twitter} />
      </TwitterShareButton>
      {/** facebook */}

      <FacebookShareButton url={`https://www.facebook.com/unifarmofficial`} quote={title}>
        <SocialShareItem platform="Facebook" socialIcon={facebook} />
      </FacebookShareButton>
      {/** telegram */}
      <TelegramShareButton url={`https://telegram.me/unifarm`} title={title}>
        <SocialShareItem platform="Telegram" socialIcon={telegram} />
      </TelegramShareButton>
    </ShareCont>
  );
};

export default SocialShare;

import { createAction } from '@reduxjs/toolkit';
import { APYCalculatorSetting, BoosterPackModalSetting, DappVersion } from './reducer';
import { View } from './reducer';

export const setVersion = createAction<{ version: DappVersion }>('v2/dapp/setVersion');
export const setAppView = createAction<{ view: View }>('v2/dapp/setAppView');
export const setAPYCalculatorSettings = createAction<{
  apyCalculatorSettings: APYCalculatorSetting;
}>('v2/dapp/setAPYCalculatorSettings');

export const setBoosterPackModalSettings = createAction<{
  boosterPackModalSettings: BoosterPackModalSetting;
}>('v2/dapp/setBoosterPackModalSettings');

import { getYF2GraphqlClient } from '../../graphql/V2';
import { ClaimHistory, GraphBlock, Transfer } from '../../graphql/V2/typings';
import { v2Claims, v2Transfer, graphBlockNumber } from '../../graphql/V2/queries';
import { IKeysValues } from '@makerdao/multicall';
import { StakeV2 } from 'store/V2/mints/reducer';
import { BigNumber } from 'ethers';
import { isEmpty } from 'lodash';
import { ZERO_ADDRESS } from '../.././constants';

export const getUserMints = async <T>(chainId: number, user: string, where: T) => {
  if (!user) return null;
  const client = getYF2GraphqlClient(chainId);
  if (!client) return null;
  const results = await client.query<{ transfers: Transfer[] }>({
    query: v2Transfer,
    variables: {
      where,
      first: 1000,
    },
  });
  return results.data.transfers as Transfer[];
};

export const getUserClaims = async (chainId: number, user: string): Promise<ClaimHistory[]> => {
  if (!user) return null;
  const client = getYF2GraphqlClient(chainId);
  const results = await client.query<{ claimHistories: ClaimHistory[] }>({
    query: v2Claims,
    variables: {
      where: {
        user,
      },
      first: 1000,
    },
  });

  return results.data.claimHistories as ClaimHistory[];
};

export const formatUserStakings = (
  /** call results */
  callResults: IKeysValues,
  /** cohortId */
  cohortId: string,
  /** token id */
  tokenId: string,
  /** timestamp */
  timestamp: number
): StakeV2 => {
  // farmId
  let fid = callResults[tokenId.concat('_').concat('fid')];
  return {
    farmId: `${cohortId}-0x${fid}`,
    fid,
    nftTokenId: parseInt(tokenId),
    stakedAmount: callResults[tokenId.concat('_').concat('stakedAmount')] as BigNumber,
    startBlock: (callResults[tokenId.concat('_').concat('startBlock')] as BigNumber).toNumber(),
    endBlock: (callResults[tokenId.concat('_').concat('endBlock')] as BigNumber).toNumber(),
    hasBoosterBuyed: callResults[tokenId.concat('_').concat('isBooster')] as boolean,
    stakedOn: timestamp,
  };
};

export const getUserNftTransfers = (
  fromSideTransfers: Transfer[],
  toSideTransfers: Transfer[],
  account: string
): Transfer[] => {
  let transfers = [] as Transfer[];

  if (!isEmpty(toSideTransfers) && account) {
    if (!isEmpty(toSideTransfers)) {
      // loop
      for (let k = 0; k < toSideTransfers.length; k++) {
        // destruct the tokenId
        let { tokenId } = toSideTransfers[k];
        // filter the from Side as well if match
        let matched = fromSideTransfers?.filter((e) => e.tokenId === tokenId && e.to !== ZERO_ADDRESS);

        if (isEmpty(matched)) {
          transfers.push(toSideTransfers[k]);
        }
        // EOL
      }
    }
  }
  // filtered transfers
  return transfers;
};

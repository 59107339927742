import React, { useState } from 'react';
import { CloseRoot, DetailRoot, OnBoardRoot } from './OnBoardingStyle';
import CloseIcon from '@mui/icons-material/Close';
import Metamask from './Metamask';
import InstalledStep from './InstallStep';
import BuyUfarmStep from './BuyUfarmStep';
interface isProps {
  CloseClick: () => void;
}
const BuyUfarmOnboarding = ({ CloseClick }: isProps) => {
    const [renderScreen,setRenderScreen]=useState(1)
    const InstallStepClick=()=>
    {
        setRenderScreen(2)
    }
    const BuyUfarmScreen=()=>
    {
        setRenderScreen(3)
    }
    const FinsishStepClick=()=>
    {
      setRenderScreen(1)
    }
  const RenderScreen = (screen) => {
    switch (screen) {
      case 1:
        return <Metamask installMetamaskClick={InstallStepClick} buyUfarmClick={BuyUfarmScreen}/>;
      case 2:
        return <BuyUfarmStep finishClick={FinsishStepClick}/>;
      case 3:
        return <InstalledStep finishClick={FinsishStepClick}/>;
    }
  };
  return (
    <OnBoardRoot>
      <CloseRoot onClick={CloseClick}>
        Close
        <CloseIcon style={{ fontSize: 14, marginTop: '2px' }} />
      </CloseRoot>
      <DetailRoot>
        {
            RenderScreen(renderScreen)
        }
        
      </DetailRoot>
    </OnBoardRoot>
  );
};
export default BuyUfarmOnboarding;

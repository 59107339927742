import { chainId } from 'constants/ido';

// Cohort registry address
export const COHORT_REGISTRYS: { [chainId: number]: string } = {
  // eth
  1: '0xdBB277570e1cB6FBec0ca34F126e08eA83ea29d8',
  // bsc
  56: '0xdBB277570e1cB6FBec0ca34F126e08eA83ea29d8',
  // polygon
  137: '0xE4279753d6921aC36c476CB68273284a84041F71',
  // avax
  43114: null,
};

// action magic values
export const MAGIC_VALUES = ['0x1bcc0f4c', '0x8ca9a95e'];

export const WRAPPED_TOKENS: { [chainId: number]: string } = {
  // for ethereum rinkeby
  1: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
  // for bsc testnet
  56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  // for polygon testnet
  137: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
  // for avax c chain testnet
  43114: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
};

export const NFT_MANAGER_ADDRESS: { [chainId: number]: string } = {
  // for ethereum rinkeby
  1: '0x87c45032b6A7e7B2D3fe99C68340C1F4b72ceBeD',
  // for bsc testnet
  56: '0x87c45032b6A7e7B2D3fe99C68340C1F4b72ceBeD',
  // for polygon testnet
  137: '0x179A6a8138A3eac7D588321ddAFd9797892783ed',
  // for avax c chain testnet
  43113: null,
};

export const blockV2Cohort: string[] = ['LF/V2'];

export const NFT_HAS_ISSUE: { [chainId: number]: number[] } = {
  56: [
    675, 673, 617, 538, 683, 672, 677, 676, 396, 627, 681, 849, 846, 852, 532, 533, 534, 535, 536, 539, 540, 541, 542, 556,
    559, 594, 595, 603, 606, 608, 616, 619, 626, 641, 642, 643, 645, 662, 663, 678, 679, 680, 684, 685, 686, 691, 693, 698,
    704, 724, 725, 814, 853, 854, 866, 19, 408, 409, 410, 411, 413, 419, 421, 428, 431, 432, 434, 435, 436, 437, 438, 439,
    440, 441, 442, 447, 448, 449, 450, 451, 452, 453, 454, 455, 456, 457, 458, 459, 460, 461, 462, 463, 464, 465, 466, 467,
    468, 469, 470, 471, 472, 473, 474, 475, 476, 477, 479, 480, 481, 482, 483, 484, 485, 486, 487, 488, 489, 490, 491, 492,
    494, 495, 496, 497, 498, 499, 501, 502, 503, 504, 505, 506, 507, 508, 509, 510, 511, 512, 513, 514, 515, 516, 517, 518,
    519, 520, 521, 522, 523, 524, 525, 526, 529, 544, 546, 554, 555, 557, 560, 562, 565, 566, 568, 569, 570, 571, 572, 573,
    574, 576, 577, 585, 586, 587, 588, 589, 590, 591, 592, 596, 599, 600, 601, 609, 610, 611, 613, 621, 622, 623, 627, 630,
    658, 665, 668, 671, 682, 688, 689, 692, 695, 696, 697, 699, 701, 706, 719, 721, 727, 735, 759, 760, 761, 777, 778, 781,
    782, 786, 787, 790, 803, 817, 818, 855, 857, 858, 859, 860, 861, 864, 865, 867, 878, 902, 929, 936, 938,
  ],
};

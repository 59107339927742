import { FlipToken } from 'store/flip/reducer';

// flip address
export const flipAddress: string = '0x13e3d873ee621c65d2f783ecf3c625be60222e83';

// flip tokens available on bsc right now
export const flipTokens: { [token: string]: FlipToken } = {
  ORO: {
    name: 'Oropocket by OpenDefi',
    symbol: 'ORO',
    baseToken: '0x9f998d62b81af019e3346af141f90cccd679825e',
    flipingToken: '0x3fF2348e44d09f07017BCDaaCc4be575c0Ec467f',
    icon: 'https://my.oropocket.com/unifarm/assets/icons/oro.png',
    decimals: 18,
    price: 0.16,
    chainId: 56,
  },
  UFARM: {
    name: 'Unifarm Token',
    symbol: 'UFARM',
    baseToken: '0x40986a85b4cfcdb054a6cbfb1210194fee51af88',
    flipingToken: '0x0A356f512f6fCE740111ee04Ab1699017a908680',
    icon: 'https://my.oropocket.com/unifarm/assets/icons/ufarm.png',
    decimals: 18,
    price: 0.12,
    chainId: 56,
  },
  UDO: {
    name: 'Unido EP',
    symbol: 'UDO',
    baseToken: '0x559cd5b11ca882cedda823ac06275558a92b7064',
    flipingToken: '0x70802af0ba10dd5bb33276b5b37574b6451db3d9',
    icon: 'https://raw.githubusercontent.com/InfernalHeir/tokenlist/master/.github/assets/icons/unido.png',
    decimals: 18,
    price: 0.3,
    chainId: 56,
  },
};

// ORO Merger contracts
export const mergers: { [chainId: number]: string } = {
  1: '0xC6BC40b8EF6A09Bd1a216513BeD3D9A2a5217FFa',
  56: '0xbcdefbf03b7b89fd99e0553cc9f4f24c7530af9a',
  137: '0x5C73e2371818BcEb6b196ecF089309eC60e208Ad',
  43114: '0x3A25947689073544770F5Fb1807EDdD38777B907',
};

// ORO Merger this is by chainId
export const ORO_MERGER_LIST: { [chainId: number]: FlipToken } = {
  1: {
    name: 'Oropocket by OpenDefi',
    symbol: 'ORO',
    baseToken: '0xc3Eb2622190c57429aac3901808994443b64B466',
    flipingToken: '0x40986a85b4cfcdb054a6cbfb1210194fee51af88',
    icon: 'https://my.oropocket.com/unifarm/assets/icons/oro.png',
    decimals: 18,
    price: 0.16,
    chainId: 1,
  },
  56: {
    name: 'Oropocket by OpenDefi',
    symbol: 'ORO',
    baseToken: '0x3fF2348e44d09f07017BCDaaCc4be575c0Ec467f',
    flipingToken: '0x0A356f512f6fCE740111ee04Ab1699017a908680',
    icon: 'https://my.oropocket.com/unifarm/assets/icons/oro.png',
    decimals: 18,
    price: 0.16,
    chainId: 56,
  },
  137: {
    name: 'Oropocket by OpenDefi',
    symbol: 'ORO',
    baseToken: '0x2651b9c63290e543902da4eb63c34029382ff552',
    flipingToken: '0xa7305ae84519ff8be02484cda45834c4e7d13dd6',
    icon: 'https://my.oropocket.com/unifarm/assets/icons/oro.png',
    decimals: 18,
    price: 0.16,
    chainId: 97,
  },
  43114: {
    name: 'Oropocket by OpenDefi',
    symbol: 'ORO',
    baseToken: '0x612B6F08d7A149a668EF9AB16EA3147969B2e85B',
    flipingToken: '0xd60effed653f3f1b69047f2d2dc4e808a548767b',
    icon: 'https://my.oropocket.com/unifarm/assets/icons/oro.png',
    decimals: 18,
    price: 0.16,
    chainId: 97,
  },
};

import React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import { usdCurrencyFormat } from 'utilities';
const useStyle = makeStyles(() =>
  createStyles({
    textField: {
      width: '100%',
      fontSize: '14px',
      flex: '0.5',
      padding: '5px',
      '&>.css-1480iag-MuiInputBase-root-MuiInput-root': {
        margin: '20px 0px',
        paddingBottom: '5px',
        fontSize: '14px',
      },
    },
  })
);

interface TokenInputProps {
  /** farm token icon */
  farmTokenIcon: string[];
  /** number of tokens */
  numberOfTokens: number;
  /** number of tokens In USD */
  numberOfTokensInUSD: number;
  /** input Handler */
  inputHandler: (stakedAmount: number) => void;
}

const TokenInput = ({
  farmTokenIcon,
  numberOfTokens,
  numberOfTokensInUSD,
  inputHandler,
}: TokenInputProps) => {
  const classes = useStyle();

  return (
    <div>
      <TextField
        className={classes.textField}
        id="input-with-icon-textfield"
        label="Token to Stake"
        value={numberOfTokens ? numberOfTokens : ''}
        onChange={(event) => inputHandler(parseFloat(event.target.value))}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {farmTokenIcon?.map((farmIcon, i) => (
                <img
                  key={i}
                  src={farmIcon}
                  width={28}
                  alt="imgd"
                  style={{ paddingBottom: '10px', paddingTop: '5px' }}
                />
              ))}
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="start">
              {usdCurrencyFormat(numberOfTokensInUSD ? numberOfTokensInUSD : 0)}
            </InputAdornment>
          ),
        }}
        variant="standard"
      />
    </div>
  );
};

export default TokenInput;

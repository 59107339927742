import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Button, makeStyles, Theme } from '@material-ui/core';
import {
  BackButton,
  StepButton,
  StepscreenRoot,
  StepButtonRoot,
  StepContentRoot,
  ContentHead,
  Content,
  ContentWrap,
} from './OnBoardingStyle';
import AddMetamask from '../../assets/Onboardimages/AddMetamask.png';
import Step2 from '../../assets/Onboardimages/step2.png';
import Step3 from '../../assets/Onboardimages/step3.png';
import Step4 from '../../assets/Onboardimages/step4.png';
import Step5 from '../../assets/Onboardimages/step5.png';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
const steps = ['1', '2', '3', '4', '5'];
const useStyles = makeStyles(() => ({
  root: {
    '&>.MuiStep-root .MuiStepLabel-root .MuiStepLabel-iconContainer .Mui-completed': {
      color: 'rgba(103, 58, 183, 1) !important',
    },
    '&>.MuiStep-root .MuiStepLabel-root .MuiStepLabel-iconContainer': {
      border: '1px solid',
      borderRadius: '16px',
      '&>.MuiSvgIcon-root': {
        color: 'white',
        '&>.MuiStepIcon-text': {
          fill: 'black',
        },
      },
    },
    '&>.MuiStep-root .MuiStepConnector-root .MuiStepConnector-line': {
      borderTopWidth: '0px',
      border: '2px dashed #616161',
    },
  },
}));
interface IsPropsIntalledStep{
  finishClick:()=>void
}
const InstalledStep = ({finishClick}:IsPropsIntalledStep) => {
  const classes = useStyles();
  const [isStep, setIsStep] = React.useState(1);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const RenderStepScreen = (step) => {
    switch (step) {
      case 1:
        return (
          <StepContent
            img={AddMetamask}
            title="Add Metamask extension to your browser "
            content={
              <>
                {mobile ? (
                  <>
                    Most cryptocurrency users only have wallets linked to the exchanges on which they trade. It resembles a
                    typical email/password log which gives people the erroneous notion of how blockchain addresses function.
                    Metamask is a non-custodial wallet that can be accessed from your browser or mobile and it provides you
                    with complete control over your assets.
                  </>
                ) : (
                  <ContentWrap>
                    <span>
                      Most cryptocurrency users only have wallets linked to the exchanges on which they trade. It resembles a
                      typical email/
                    </span>
                    <span>
                      password log which gives people the erroneous notion of how blockchain addresses function. Metamask is
                      a non-custodial
                    </span>
                    <span>
                      wallet that can be accessed from your browser or mobile and it provides you with complete control over
                      your assets.
                    </span>
                  </ContentWrap>
                )}
              </>
            }
            isCustomeImage={false}
          />
        );
      case 2:
        return (
          <StepContent
            img={Step2}
            title="Create your wallet"
            content={
              <ContentWrap>
                <span>Create your wallet on the Metamask extension</span>
                <span>here are the Video steps to create your wallet on metamask</span>
                <a target="_blank" href="https://www.youtube.com/watch?v=gfD_kuoZt-Q">https://www.youtube.com/watch?v=gfD_kuoZt-Q</a>
              </ContentWrap>
            }
            isCustomeImage={false}
          />
        );
      case 3:
        return (
          <StepContent
            img={Step3}
            title="Add networks"
            content={
              <>
                {mobile ? (
                  <>
                    We support Ethereum, BSC, Polygon, and Avalanche. A blockchain network is a technical infrastructure that
                    provides applications ledger and smart contract (chaincode) services. There are many networks, its
                    depended on speed, cost and safety. We are connected with the best networks, <a href="https://www.youtube.com/shorts/qO46Z0g5R2U">Click here</a>{' '}
                    to know how add networks to your wallet.
                  </>
                ) : (
                  <ContentWrap>
                    <span>
                      We support Ethereum, BSC, Polygon, and Avalanche. A blockchain network is a technical infrastructure
                      that provides
                    </span>
                    <span>
                      applications ledger and smart contract (chaincode) services. There are many networks, its depended on
                      speed, cost and{' '}
                    </span>
                    <span>
                      safety. We are connected with the best networks, <a target="_blank" href="https://www.youtube.com/shorts/qO46Z0g5R2U">Click here</a> to know how add networks to
                      your wallet
                    </span>
                  </ContentWrap>
                )}
              </>
            }
            isCustomeImage={true}
          />
        );
      case 4:
        return (
          <StepContent
            img={Step4}
            title="Connect with app.unifarm.co"
            content="Visit app.unifarm.co and Connect your wallet"
            isCustomeImage={true}
          />
        );
      case 5:
        return (
          <StepContent
            img={Step5}
            title="Choose the network to buy"
            content={
              <>
                {mobile ? (
                  <>
                    Choosing the network depends on the pool you want to stake there are different yield farming pools where
                    users can stake one token and farm multiple tokens. The network also depends on the difference in gas
                    fees.
                  </>
                ) : (
                  <ContentWrap>
                    <span>Choosing the network depends on the pool you want to stake </span>
                    <span>
                      there are different yield farming pools where users can stake one token and farm multiple tokens. The
                      network also depends
                    </span>
                    <span>on the difference in gas fees.</span>
                  </ContentWrap>
                )}
              </>
            }
            isCustomeImage={true}
          />
        );
    }
  };
  const Next = () => {
    if (isStep > 0 && isStep < 5) {
      setIsStep(isStep + 1);
    } else {
      setIsStep(1);
    }
  };
  const Back = () => {
    setIsStep(isStep - 1);
  };
  return (
    <div style={{ margin: '0px auto', width: '100%', display: 'flex', justifyContent: 'center' }}>
      <Box sx={{ width: mobile ? '100%' : '85%' }}>
        <Stepper activeStep={isStep} alternativeLabel className={classes.root}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel></StepLabel>
            </Step>
          ))}
        </Stepper>

        <StepscreenRoot>{RenderStepScreen(isStep)}</StepscreenRoot>
        <StepButtonRoot>
          {mobile ? (
            <BackButton onClick={Back} style={{ display: isStep <= 1 ? 'none' : 'block' }}>
              Previous
            </BackButton>
          ) : (
            <BackButton onClick={Back} style={{ visibility: isStep <= 1 ? 'hidden' : 'visible' }}>
              Previous
            </BackButton>
          )}
          {isStep === 5 ? <StepButton onClick={finishClick}>Next</StepButton> : <StepButton onClick={Next}>Next</StepButton>}
        </StepButtonRoot>
      </Box>
    </div>
  );
};
export default InstalledStep;
interface isStepContent {
  img: string;
  title: string;
  content: any;
  isCustomeImage: boolean;
}
const StepContent = ({ img, title, content, isCustomeImage }: isStepContent) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <StepContentRoot>
      {isCustomeImage ? (
        <img src={img} width={mobile ? 275 : 550} />
      ) : mobile ? (
        <img src={img} width={270} />
      ) : (
        <img src={img} />
      )}

      <ContentHead>{title}</ContentHead>
      <Content>{content}</Content>
    </StepContentRoot>
  );
};

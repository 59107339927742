import { ICall } from '@makerdao/multicall';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch, AppState } from 'store';
import { useWeb3React } from '@web3-react/core';
import { isEmpty } from 'lodash';
import { useCallback, useEffect } from 'react';
import { NFT_MANAGER_ADDRESS } from '../../../constants/V2';
import { multicall } from '../../../utilities/multicall';
import { getUserClaims, getUserMints, formatUserStakings, getUserNftTransfers } from '../../../utilities/V2/mints';
import { createCalls } from '../../../utilities/V2/multicall';
import { ClaimData, StakeV2 } from './reducer';
import { setUserClaims, setUserStakeDetails } from './action';
import { getMulticallNftOwners } from '../../../utilities/multicall';
import { BigNumber } from 'ethers';

export const useFetchUserNFts = (): void => {
  // fetch chainId
  const { chainId, account } = useWeb3React();
  // const account = '0xafb1051858Ca3122335551ED14163e04673e630C';
  // dispatch
  const dispatch = useDispatch<AppDispatch>();

  // nft manager
  let nftManager = NFT_MANAGER_ADDRESS[chainId];

  useEffect(() => {
    async function fetchStakings() {
      if (!account || !chainId) return null;
      // taking both side tranfers

      let userTransfers = await Promise.all([
        getUserMints<{ from: string }>(chainId, account, { from: account }),
        getUserMints<{ to: string }>(chainId, account, { to: account }),
      ]).then((values) => values);

      let [fromSideTransfers, toSideTransfers] = userTransfers || [];

      // get the active nft
      let transfers = getUserNftTransfers(fromSideTransfers, toSideTransfers, account);

      if (isEmpty(transfers)) {
        dispatch(setUserStakeDetails({ stake: [] }));
        return;
      }

      // create calls for grabing cohort addresses
      let calls = [] as ICall[];

      for (var e = 0; e < transfers.length; e++) {
        calls.push(
          createCalls(
            nftManager,
            'tokenIdToCohortId(uint256)(address)',
            [transfers[e].tokenId],
            [[`${transfers[e].tokenId}`, (val: string) => val]]
          )
        );
      }

      if (!isEmpty(calls)) {
        let tokenIdToCohortIdCallResults = await multicall(chainId, calls);
        let original = tokenIdToCohortIdCallResults.results.original;

        // push all the staking calls into this
        let stakeCalls = [] as ICall[];

        // create calls for stakings
        for (var k = 0; k < transfers.length; k++) {
          const { tokenId } = transfers[k];
          const cohort = original[tokenId] as string;
          stakeCalls.push(
            createCalls(
              cohort,
              'viewStakingDetails(uint256)(uint32,uint256,uint256,uint256,uint256,address,address,bool)',
              [tokenId],
              [
                [tokenId.concat('_').concat('fid')],
                [tokenId.concat('_').concat('nftTokenId')],
                [tokenId.concat('_').concat('stakedAmount')],
                [tokenId.concat('_').concat('startBlock')],
                [tokenId.concat('_').concat('endBlock')],
                [tokenId.concat('_').concat('originalOwner')],
                [tokenId.concat('_').concat('referralAddress')],
                [tokenId.concat('_').concat('isBooster')],
              ]
            )
          );
        }
        if (isEmpty(stakeCalls)) return null;
        // grab stakes
        let stakes = await multicall(chainId, stakeCalls);
        let originalStakesCallResult = stakes.results.original;

        // deriving stakings
        let stakings: StakeV2[] = [];
        for (var m = 0; m < transfers.length; m++) {
          let { tokenId, timestamp } = transfers[m];
          const cohort = original[tokenId] as string;
          let stake = formatUserStakings(originalStakesCallResult, cohort, tokenId, parseInt(timestamp));
          stakings.push(stake);
        }
        // dispatch the user stakings
        dispatch(setUserStakeDetails({ stake: stakings }));
      }
    }
    fetchStakings();
  }, [account, dispatch, chainId, nftManager]);
};

export const useFetchUserClaims = () => {
  // derive nfts
  let { chainId, account } = useWeb3React();
  // const account = '0xbb0cb646e9Aea0084BF7Df484a998cf8951770D8';

  let dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const fetchUserClaims = async () => {
      if (account) {
        // do some thing for claims
        let userClaims = await getUserClaims(chainId, account);

        let claims = [] as ClaimData[];
        if (!isEmpty(userClaims)) {
          for (let k = 0; k < userClaims.length; k++) {
            let { tokenId, rValue, blockNumber, transactionHash, timestamp } = userClaims[k];
            claims.push({
              tokenId: parseInt(tokenId),
              rValue: BigNumber.from(rValue),
              blockNumber: parseInt(blockNumber),
              transactionHash,
              timeStamp: parseInt(timestamp),
            });
          }
        }

        if (!isEmpty(claims)) {
          // dispatch the claim
          dispatch(setUserClaims({ claims }));
        }
      }
    };

    // dispatch the claims
    fetchUserClaims();
  }, [account, chainId, dispatch]);
};

export const useFetchNFtsAndClaims = () => {
  // for nfts
  useFetchUserNFts();
  // for claims
  useFetchUserClaims();
};

export const useV2NFTs = () => {
  return useSelector((state: AppState) => state.mints);
};

export const useNftOwner = (nftIds: number[]) => {
  const { chainId } = useWeb3React();

  return useCallback(async () => {
    if (!chainId) return null;

    const nftOwners = await getMulticallNftOwners(nftIds, chainId, NFT_MANAGER_ADDRESS[chainId]);
    return nftOwners;
  }, [chainId, nftIds]);
};

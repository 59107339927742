import Moment from 'moment';
export const useGetDate = (date2,time) => {
  const currentDate: any = new Date(Moment().format('MM/DD/YYYY'));
  const diffInMs = Math.abs(date2 - currentDate);
  const days=(diffInMs / (1000 * 60 * 60 * 24)).toString()
  const hours=Moment(time).format("h")
  const minutes=Moment(time).format("mm")
 
  return {days,hours,minutes} ;
};

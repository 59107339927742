import React, { useEffect, useState } from 'react';
import THT from '../../../assets/V2/Images/tk1.png';
import AddButton from '../../../components/SetuPool/AddLiquidity/AddButton';
import Balance from '../../../components/SetuPool/AddLiquidity/Balance';
import Booster from '../../../components/SetuPool/AddLiquidity/Booster';
import Network from '../../../components/SetuPool/AddLiquidity/Network';
import TokenDetails from '../../../components/SetuPool/AddLiquidity/TokenDetails';
import Ufarm from '../../../assets/V2/Images/Ufarm.png';
// import SuccessToken from 'components/SetuPool/AddLiquidity/Success';
import AddLiquidityStepper from 'components/SetuPool/AddLiquidity/AddLiquidityStepper';
import Modal from '../../../components/Modal/index';
import Info from '../../../assets/flip/info.png';
import IsBoosterStep from 'components/SetuPool/AddLiquidity/IsBoosterStep';
import BackStep from 'components/SetuPool/AddLiquidity/BackSteps';
import ConfirmToken from 'components/SetuPool/AddLiquidity/ConfirmToken';
import LoadingToken from 'components/SetuPool/AddLiquidity/LoadingToken';
import SuccessToken from 'components/SetuPool/AddLiquidity/Success';
import { useApproval } from '../../../hooks/useApproval';
import { ethers } from 'ethers';
import { useTokenContract } from 'hooks/contracts/useTokenContract';
import { bridgeAddress, bridgeRegistry, MAX_APPROVAL_VALUE } from 'constants/bridge';
import {
  BalanceDiv,
  BoostBtn,
  ButtonDiv,
  CardView,
  InfoText,
  InputWrapper,
  LiquidityWrapper,
  MessagenWrapper,
  ModalWrapper,
  NetworkWrapper,
  StepperDiv,
  WarningIcon,
  YesBtn,
} from './Style';
import { useLocation } from 'react-router-dom';
import setuHttp from '../../../utilities/http';
import { useWeb3React } from '@web3-react/core';
import bridgeRegistryContract from 'hooks/useBridgeRegistry';
import bridgeRegistryABI from '../../../constants/ABI/bridge/BridgeRegistryAbi.json';
import bridgeABI from '../../../constants/ABI/bridge/Bridge.json';
import tokenABI from '../../../constants/ABI/bridge/Token.json';
import { useChangeAppChainId } from 'store/user/hooks';
import BigNumber from 'bignumber.js';
import { IconButton, Snackbar } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { Alert } from '@mui/material';
import { formatWithoutRounding, unitFormatter } from 'utilities';
import { round } from 'lodash';
import SocialShare from 'components/V2/SocialShare';
import { BalanceValue } from '../Style';
import { formatUnits } from '@ethersproject/units';

interface TokenData {
  id: number;
  networks: number[];
  tokenAddress: string;
  token_image: string;
  token_name: string;
  token_ticker: string;
}

export interface BoosterConfig {
  tokenTicker: string;
  tokenName: string;
  tokenAddress: string;
  price: string;
  tokenImageUrl: string;
  // isTokenApprove: boolean;
}

const BridgePoolInspect = () => {
  const [value, setValue] = React.useState<number>();
  const [isApproveLoading, setApproveLoading] = React.useState(false);
  const [select, setSelect] = React.useState('');
  const [isCheck, setCheck] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [isFixedBoost, setBoost] = React.useState(false);
  const [isBooster, setBooster] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);
  const [step, setStep] = React.useState(1);
  const [tokenData, setTokenData] = useState<TokenData>();
  const [txHash, setTxHash] = useState('');
  const [isConfirmLoading, setConfirmLoading] = useState(false);
  const location = useLocation();
  let { account, chainId, library } = useWeb3React();
  // let bridgeRegistryInstance = bridgeRegistryContract(chainId);
  const [boosterConfig, setBoosterConfig] = useState<BoosterConfig>();
  const [userBalance, setUserBalance] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarError, setSnackbarError] = useState('');
  const [balance, setBalance] = useState('0');
  const [isSocial, setSocial] = useState(false);
  const useSocialOpen = () => {
    setSocial(true);
  };
  const changeAppNetwork = useChangeAppChainId();

  useEffect(() => {
    if (!account) return;
    console.log('INSIDE EFFECT');
    setSelect(chainId.toString());

    async function getTokenData() {
      try {
        const tokenTicker = new URLSearchParams(location.search).get('ticker');
        const res = await setuHttp.get(`/api/token/${tokenTicker}`);
        // console.log('TokenData: ', res.data);
        let tokenDetails: TokenData = res.data;

        let bridgeRegistryInstance = new ethers.Contract(bridgeRegistry(chainId), bridgeRegistryABI.abi, library);
        const tokenMetadata = await bridgeRegistryInstance.bridgeTokenMetadata(tokenTicker);
        // console.log('tokenMetadata: ', tokenMetadata);
        tokenDetails.tokenAddress = tokenMetadata['tokenAddress'];
        setTokenData(tokenDetails);

        // fetching the booster token details
        let bridgeInstance = new ethers.Contract(bridgeAddress(chainId), bridgeABI.abi, library);
        let boosterConfig = await bridgeInstance.boosterConfig();

        let tokenInstance = new ethers.Contract(tokenMetadata.tokenAddress, tokenABI.abi, library);
        let userBalance = await tokenInstance.balanceOf(account);
        console.log('userBal: ', userBalance.toString());
        setUserBalance(userBalance.toString());

        let boosterTokenInstance = new ethers.Contract(boosterConfig.tokenAddress, tokenABI.abi, library);
        let boosterTokenName = await boosterTokenInstance.name();
        let boosterTokenTicker = await boosterTokenInstance.symbol();

        let config = {
          tokenTicker: boosterTokenTicker,
          tokenName: boosterTokenName,
          tokenAddress: boosterConfig.tokenAddress,
          price: boosterConfig.price.toString(),
          tokenImageUrl: boosterConfig.imageUrl,
        };
        setBoosterConfig(config);
      } catch (error) {
        console.log('Error in getTokenData(): ', error);
      }
    }
    getTokenData();
  }, [account, chainId]);

  const formatInput = (e) => {
    // Prevent characters that are not numbers ("e", ".", "+" & "-") ✨
    let checkIfNum;
    if (e.key !== undefined) {
      // Check if it's a "e", ".", "+" or "-"
      checkIfNum = e.key === '+' || e.key === '-';
    }

    return checkIfNum && e.preventDefault();
  };
  const handleChange = (newValue: any) => {
    if (newValue.target.value < 0) {
      setValue(0);
    } else {
      let str: string = newValue.target.value.toString();
      if(str.indexOf('.') >= 0 && str.length - str.indexOf('.') > 6)
        return;
      setValue(newValue.target.value);
    }
    //   const value = newValue.target.value.replace(/[^\d]/, '');

    //   if (+value !== 0) {
    //     setValue(value);
    //   } else {
    //     setValue(null);
    //   }
    //   // setValue(newValue.target.value);
  };

  // const handleChange2 = (newValue: any) => {
  //   setSelect(newValue);
  // };
  const HandleBooster = () => {
    setCheck(!isCheck);
  };
  const TransferHandle = async () => {
    if (isCheck) {
      await checkAllowance();
      setBoost(true);
      setBooster(true);
    } else {
      setOpen(true);
    }
  };
  const WithBooster = () => {
    setCheck(true);
    setOpen(false);
  };
  const WithoutBooster = async () => {
    await checkAllowance();
    setBoost(true);
    setBooster(false);
    setOpen(false);
  };
  const checkAllowance = async () => {
    let tokenInstance = new ethers.Contract(tokenData.tokenAddress, tokenABI.abi, library);
    let allowance = await tokenInstance.allowance(account, bridgeAddress(chainId));
    console.log('Allowance: ', allowance.toString(), ethers.utils.parseUnits(value.toString(), 18).toString());
    // checking token allowance
    if (allowance.gte(ethers.utils.parseUnits(value.toString(), 18))) {
      if (isCheck) {
        let boosterTokenInstance = new ethers.Contract(boosterConfig.tokenAddress, tokenABI.abi, library);
        const totalPrice = BigInt(boosterConfig.price) * BigInt(alignment);
        let boosterAllowance = await boosterTokenInstance.allowance(account, bridgeAddress(chainId));
        // console.log("ALLOWANCE: ", allowance.toString(), totalPrice);
        // checking booster allowance
        if (boosterAllowance >= totalPrice) {
          setStep(3);
          return;
        }
      }

      setStep(2);
    }
  };

  const HandleBack = () => {
    setBoost(false);
  };

  const ApproveToken = async () => {
    setApproveLoading(true);

    try {
      let tokenInstance = new ethers.Contract(tokenData.tokenAddress, tokenABI.abi, library);
      let tokenInstanceWithSigner = await tokenInstance.connect(library.getSigner(account));

      let allowance = await tokenInstance.allowance(account, bridgeAddress(chainId));
      // console.log("ALLOWANCE: ", allowance.toString(), ethers.utils.parseUnits(value.toString(), 18).toString());
      // allowance and value, both are of type ethers.BigNumber
      if (allowance.lt(ethers.utils.parseUnits(value.toString(), 18))) {
        let tx = await tokenInstanceWithSigner.approve(bridgeAddress(chainId), MAX_APPROVAL_VALUE);
        await tx.wait(3);
      }

      if (isCheck) checkAllowance();
      else setStep(2);
    } catch (error) {
      console.log('Error in liquidity token ApproveToken(): ', error);
      if (error?.code === 4001 || error?.code === 'ACTION_REJECTED') setSnackbarError('User denied the transaction');
      else setSnackbarError('Some error occurred');
      setSnackbarOpen(true);
    }
    setApproveLoading(false);

    // if (isCheck) {
    //   // to approve the booster tokens
    //   console.log("Inside approve BOOSTER token");
    //   setApproveLoading(true);

    //   try {
    //     // let tokenInstance = new ethers.Contract(
    //     //   boosterConfig.tokenAddress,
    //     //   ['function approve(address,uint256) external'],
    //     //   library
    //     // );
    //     // let tokenInstanceWithSigner = await tokenInstance.connect(library.getSigner(account));

    //     // let tx = await tokenInstanceWithSigner.approve(bridgeAddress(chainId), value);
    //     // await tx.wait();

    //     setStep(2);
    //   } catch (error) {
    //     console.log("Error in booster token ApproveBooster(): ", error);
    //   }
    //   setApproveLoading(false);

    //   // setTimeout(() => {
    //   //   setApproveLoading(false);
    //   //   setStep(2);
    //   // }, 1000);

    // } else {
    //   // to approve the token being added as liquidity
    //   console.log("Inside approve LIQUIDITY token");
    //   setApproveLoading(true);

    //   try {
    //     // let tokenInstance = new ethers.Contract(
    //     //   tokenData.tokenAddress,
    //     //   ['function approve(address,uint256) external'],
    //     //   library
    //     // );
    //     // let tokenInstanceWithSigner = await tokenInstance.connect(library.getSigner(account));

    //     // let tx = await tokenInstanceWithSigner.approve(bridgeAddress(chainId), value);
    //     // await tx.wait();

    //     setStep(2);
    //   } catch (error) {
    //     console.log("Error in liquidity token ApproveBooster(): ", error);
    //   }
    //   setApproveLoading(false);

    //   // setTimeout(() => {
    //   //   setApproveLoading(false);
    //   //   setStep(2);
    //   // }, 1000);
    // }
  };
  const HandleBackApprve = (step) => {
    setStep(step);
    setBoost(true);
  };

  // const confirmToken = async () => {
  //   if (isCheck) {
  //     setStep(3);
  //   } else {
  //     setLoading(true);
  //     setStep(3);

  //     try {
  //       // add liquidity function
  //       let bridgeInstance = new ethers.Contract(
  //         bridgeAddress(80001),
  //         ['function addLiquidity(string,uint256) external'],
  //         library
  //       );
  //       let bridgeInstanceWithSigner = await bridgeInstance.connect(library.getSigner(account));

  //       let tx = await bridgeInstanceWithSigner.addLiquidity(tokenData.token_ticker, value);
  //       setTxHash(tx.hash);
  //       await tx.wait();

  //       setStep(4);
  //     } catch (error) {
  //       console.log('Error in confirmToken(): ', error);
  //     }
  //     setLoading(false);

  //     // setTimeout(() => {
  //     //   setLoading(false);
  //     //   setStep(4);
  //     // }, 1000);
  //   }
  // };

  const confirmBooster = async () => {
    setConfirmLoading(true);

    try {
      let tokenInstance = new ethers.Contract(boosterConfig.tokenAddress, tokenABI.abi, library);
      let tokenInstanceWithSigner = await tokenInstance.connect(library.getSigner(account));

      const totalPrice = BigInt(boosterConfig.price) * BigInt(alignment);
      // console.log("totalPrice: ", totalPrice);
      let allowance = await tokenInstance.allowance(account, bridgeAddress(chainId));
      // console.log("ALLOWANCE: ", allowance.toString(), totalPrice);
      if (allowance < totalPrice) {
        let tx = await tokenInstanceWithSigner.approve(bridgeAddress(chainId), MAX_APPROVAL_VALUE);
        await tx.wait();
      }

      setStep(3);
    } catch (error) {
      console.log('Error in confirmBooster(): ', error);
      if (error?.code === 4001 || error?.code === 'ACTION_REJECTED') setSnackbarError('User denied the transaction');
      else setSnackbarError('Some error occurred');
      setSnackbarOpen(true);
    }
    setConfirmLoading(false);

    // setTimeout(() => {
    //   setConfirmLoading(false);
    //   setStep(3);
    // }, 1200);
  };

  const addLiquidityWithBooster = async () => {
    setLoading(true);

    try {
      let bridgeInstance = new ethers.Contract(bridgeAddress(chainId), bridgeABI.abi, library);
      let bridgeInstanceWithSigner = await bridgeInstance.connect(library.getSigner(account));

      let tx = await bridgeInstanceWithSigner.addLiquidity(
        tokenData.token_ticker,
        ethers.utils.parseUnits(value.toString(), 18),
        alignment
      );
      setTxHash(tx.hash);
      await tx.wait();

      setStep(4);
    } catch (error) {
      setStep(3);
      console.log('Error in addLiquidityWithBooster(): ', error);
      if (error?.code === 4001 || error?.code === 'ACTION_REJECTED') setSnackbarError('User denied the transaction');
      else setSnackbarError('Some error occurred');
      setSnackbarOpen(true);
    }
    setLoading(false);

    // setTimeout(() => {
    //   setLoading(false);
    //   setStep(4);
    // }, 1200);
  };

  const AddLiquidityWithoutBooster = async () => {
    setStep(3);
    setLoading(true);

    try {
      let bridgeInstance = new ethers.Contract(bridgeAddress(chainId), bridgeABI.abi, library);
      let bridgeInstanceWithSigner = await bridgeInstance.connect(library.getSigner(account));

      let tx = await bridgeInstanceWithSigner.addLiquidity(
        tokenData.token_ticker,
        ethers.utils.parseUnits(value.toString(), 18),
        0
      );
      setTxHash(tx.hash);
      await tx.wait();

      setStep(4);
    } catch (error) {
      setStep(2);
      console.log('Error in AddLiquidityWithoutBooster(): ', error);
      if (error?.code === 4001 || error?.code === 'ACTION_REJECTED') setSnackbarError('User denied the transaction');
      else setSnackbarError('Some error occurred');
      setSnackbarOpen(true);
    }
    setLoading(false);

    // setTimeout(() => {
    //   setLoading(false);
    //   setStep(4);
    // }, 1200);
  };
  const AddMoreLiquidity = () => {
    setStep(1);
    setBoost(false);
  };

  const handleNetworkChange = (newValue: any) => {
    // setSelect(newValue);
    changeAppNetwork(newValue);
  };

  const [alignment, setAlignment] = React.useState(1);
  const handleChange2 = (newAlignment: number) => {
    setAlignment(newAlignment);
  };

  const StepActive = (step) => {
    switch (step) {
      case 1:
        if (isFixedBoost) {
          return (
            <IsBoosterStep
              icon={tokenData?.token_image}
              tokenTicker={new URLSearchParams(location.search).get('ticker')}
              tokenName={tokenData?.token_name}
              noOfToken={value?.toString()}
              network={select}
              booster={boosterConfig?.tokenTicker}
              boosterIcon={boosterConfig?.tokenImageUrl}
              buyFor={boosterConfig?.price}
              isBooster={isBooster}
              handleApprveBooster={ApproveToken}
              isLoading={isApproveLoading}
              isAddliquidity={false}
              alignment={alignment}
              toggleBoosters={handleChange2}
            />
          );
        } else {
          return (
            <>
              <LiquidityWrapper>
                <NetworkWrapper>
                  <InputWrapper>
                    <Network
                      chosenNetwork={select}
                      networks={tokenData && tokenData['networks']}
                      handle={handleNetworkChange}
                    />

                    <Balance handleClick={handleChange} value={value && value.toString()} onkeydown={formatInput} />
                  </InputWrapper>

                  {/* <BalanceDiv>Balance&nbsp;:&nbsp;<BalanceValue onClick={()=>setValue(new BigNumber(formatUnits(userBalance, 18)).toString())}>{userBalance && round(unitFormatter(userBalance, 18), 3)}</BalanceValue></BalanceDiv> */}
                  <BalanceDiv>Balance&nbsp;:&nbsp;<BalanceValue onClick={()=>setValue(formatWithoutRounding(new BigNumber(formatUnits(userBalance, 18)).toString(), 3))}>{userBalance && round(unitFormatter(userBalance, 18), 3)}</BalanceValue></BalanceDiv>
                  <Booster
                    check={isCheck}
                    handleCheck={HandleBooster}
                    transferHandle={TransferHandle}
                    boosterConfig={boosterConfig}
                    toggleBoosters={handleChange2}
                    balance={userBalance}
                    amount={value?.toString()}
                  />
                </NetworkWrapper>
              </LiquidityWrapper>
            </>
          );
        }
        break;
      case 2:
        if (isCheck) {
          return (
            <ConfirmToken
              metamaskText="Please approve in metamask"
              permissionText={'We need permission to use your ' + boosterConfig?.tokenTicker}
              tokenIcone={boosterConfig?.tokenImageUrl}
              confirmTokenHandle={confirmBooster}
              isAddLiquidity={false}
              isLoading={isConfirmLoading}
            />
          );
        } else {
          return (
            <IsBoosterStep
              icon={tokenData?.token_image}
              tokenTicker={tokenData?.token_ticker}
              tokenName={tokenData?.token_name}
              noOfToken={value?.toString()}
              network={select}
              booster={boosterConfig?.tokenTicker}
              boosterIcon={boosterConfig?.tokenImageUrl}
              buyFor={boosterConfig?.price}
              isBooster={isBooster}
              handleApprveBooster={AddLiquidityWithoutBooster}
              isLoading={isApproveLoading}
              isAddliquidity={true}
              alignment={alignment}
              toggleBoosters={handleChange2}
            />
          );
        }

        break;
      case 3:
        if (isLoading) {
          return <LoadingToken />;
        } else {
          if (isCheck) {
            return (
              <IsBoosterStep
                icon={tokenData?.token_image}
                tokenTicker={tokenData?.token_ticker}
                tokenName={tokenData?.token_name}
                noOfToken={value?.toString()}
                network={select}
                booster={boosterConfig?.tokenTicker}
                boosterIcon={boosterConfig?.tokenImageUrl}
                buyFor={boosterConfig?.price}
                isBooster={isBooster}
                handleApprveBooster={addLiquidityWithBooster}
                isLoading={isApproveLoading}
                isAddliquidity={true}
                alignment={alignment}
                toggleBoosters={handleChange2}
                isStepShow={3}
              />
            );
          } else {
            return (
              <>
                {isSocial ? (
                  <SocialShare title="" />
                ) : (
                  <SuccessToken
                    tokenIcone={tokenData?.token_image}
                    tokenPrice={value + ' ' + tokenData?.token_ticker}
                    txHash={txHash}
                    addMoreHandle={AddMoreLiquidity}
                    clickSocial={useSocialOpen}
                  />
                )}
              </>
            );
          }
        }
        break;
      case 4:
        return (
          <>
            {isSocial ? (
              <SocialShare title="Check this out 👀 I have just bridged my tokens and added liquidity on @unifarm_ using their Setu bridge.I can seamlessly transfer tokens between chains now while staking and generate passive income! Thanks @unifarm_.😁"/>
            ) : (
              <SuccessToken
                tokenIcone={tokenData?.token_image}
                tokenPrice={value + ' ' + tokenData?.token_ticker}
                txHash={txHash}
                addMoreHandle={AddMoreLiquidity}
                clickSocial={useSocialOpen}
              />
            )}
          </>
        );
        break;
      default:
        break;
    }
  };
  const HederActive = (step) => {
    switch (step) {
      case 1:
        if (isFixedBoost) {
          return <BackStep title="Confirm" backHandle={HandleBack} />;
        } else {
          return (
            <TokenDetails
              icon={tokenData && tokenData['token_image']}
              tokenName={tokenData && tokenData['token_name']}
              ticker={tokenData && tokenData['token_ticker']}
            />
          );
        }
        break;
      case 2:
        return <BackStep title="Confirm" backHandle={() => HandleBackApprve(1)} />;
        break;
      case 3:
        if (isLoading) {
          return <MessagenWrapper>In Progress</MessagenWrapper>;
        } else {
          return <BackStep title="Confirm" backHandle={() => HandleBackApprve(2)} />;
        }
        break;
      case 4:
        if (isSocial) {
          return <BackStep title="Share" backHandle={() => setSocial(false)} />
        } else {
          return <MessagenWrapper>Transaction Successful</MessagenWrapper>;
        }

        break;
      default:
        break;
    }
  };
  console.log('text value: ', value);
  console.log('select value: ', select);
  const WithBoosterStep = ['1', '2', '3', '4', '5'];
  const WithoutBoosterStep = ['1', '2', '3', '4'];

  const snackbarCancelButton = (
    <React.Fragment>
      <IconButton size="small" aria-label="close" color="inherit" onClick={() => setSnackbarOpen(false)}>
        <Close fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <>
      <AddButton />
      <CardView>
        
          {isSocial ? (
            HederActive(step)
          ) : (
            <StepperDiv style={{ marginTop: step === 4 ? '34px' : isLoading === true ? '34px' : null }}>
              {HederActive(step)}
              {isCheck ? (
                <AddLiquidityStepper activeStep={step} stepArray={WithBoosterStep} />
              ) : (
                <AddLiquidityStepper activeStep={step} stepArray={WithoutBoosterStep} />
              )}
           </StepperDiv>
          )}
        

        {StepActive(step)}
      </CardView>
      {/* <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarError}
        action={snackbarCancelButton}
      /> */}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        // message={snackbarError}
        action={snackbarCancelButton}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="error" sx={{ width: '100%' }}>
          {snackbarError}
        </Alert>
      </Snackbar>
      <Modal open={open} close={() => setOpen(false)} title="Confirmation" className="" headerClass="SettingHeader">
        <ModalWrapper>
          <WarningIcon src={Info} />
          <InfoText>
            Are you sure you want to continue without the booster?
            <br />
            In this case, you will lose some boosted rewards.
          </InfoText>
          <ButtonDiv>
            <YesBtn onClick={WithoutBooster}>Yes Continue</YesBtn>
            <BoostBtn onClick={WithBooster}>I want Booster</BoostBtn>
          </ButtonDiv>
        </ModalWrapper>
      </Modal>
    </>
  );
};

export default BridgePoolInspect;

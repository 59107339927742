import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import TextField from '@mui/material/TextField';
// import axios from "axios";
// import styled from 'styled-components';
// import THT from '../../../assets/V2/Images/tk1.png';
import Modal from '../../Modal/index';
import { ChooseText, IconImage, InputWrapper, SelectButton, Tokens } from './TokenStyled';
// import TokenItem from './TokenItem';

// const ManageTokenBtn = styled(Button)`
//   width: 100%;
//   height: 55px;
//   display: flex !important;
//   align-items: center !important;
//   color: #ffffff !important;
//   background: #673ab7 !important;
//   box-shadow: 0px 7px 18px -2px rgba(103, 58, 183, 0.56);
//   border-radius: 10px !important;
//   text-transform: none !important;
//   font-weight: 600 !important;
//   font-size: 15px !important;
//   font-style: normal !important;
//   line-height: 26px !important;
//   padding: 0px !important;
//   margin-top: 25px !important;
//   @media (max-width: 430px) {
//     height: 45px;
//     font-size: 14px !important;
//   }
// `;

const useStyle = makeStyles((theme) =>
  createStyles({
    Root: {
      '&>.MuiDialog-container>.MuiPaper-root.MuiDialog-paperWidthSm ': {
        width: '500px !important',
        borderRadius: '15px !important',
      },
      [theme.breakpoints.down('xs')]: {
        '&>.MuiDialog-container>.MuiPaper-root.MuiDialog-paper': {
          width: '100% !important',
          margin: '15px !important',
        },
      },
    },
    Input: {
      width: '100%',
      marginTop: '25px !important',
      '& .css-1480iag-MuiInputBase-root-MuiInput-root:before': {
        borderColor: '#673AB7 !important',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#673AB7',
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: '1rem !important',
      },
    },
  })
);
interface isProps {
  // open token modal
  isShowList: boolean;

  //close token modal
  close: () => void;

  //function for choos token wrapp
  selectClick: () => void;

  //selected token image
  tokenImage: string;

  //selected token name
  tokenName: string;

  //menuitem element
  children: React.ReactNode;
}
const ChooseToken = ({ children, tokenName, tokenImage, isShowList, selectClick, close }: isProps) => {
  const classes = useStyle();




  return (
    <>
      <SelectButton onClick={selectClick}>
        <ChooseText>Choose Token</ChooseText>
        {tokenName ? (
          <InputWrapper>
            <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
              <IconImage src={tokenImage} alt="THT" />
              <span>{tokenName}</span>
            </div>
            <ArrowDropDownIcon />
          </InputWrapper>
        ) : (
          <InputWrapper>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
              Select Token
              <ArrowDropDownIcon />
            </div>
          </InputWrapper>
        )}
      </SelectButton>

      <Modal open={isShowList} close={close} title={'Select a Token'} className={classes.Root} headerClass="SettingHeader">
        <div style={{ paddingBottom: '25px' }}>
          <TextField variant="standard" placeholder="Search Tokens" className={classes.Input} />
          
          <Tokens>{children}</Tokens>
          {/* <ManageTokenBtn onClick={() => setManage(true)}>Manage token list</ManageTokenBtn> */}
        </div>
      </Modal>
    </>
  );
};

export default ChooseToken;

import { Button } from '@mui/material';
import styled from 'styled-components';
const IconeWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 1rem;
  margin-bottom: 3rem;
  @media (max-width: 430px) {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
`;

const TextWrapper = styled.div`
  margin-left: 20px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 39px;
  display: flex;
  align-items: center;
  color: #673ab7;
  @media (max-width: 430px) {
    font-size: 18px;
  }
`;
const BackWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 105px;

  @media (max-width: 430px) {
    padding-left: 0px;
  }
`;
const TitleText = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #212121;
  @media (max-width: 430px) {
    font-size: 16px;
  }
`;
const AmountDiv = styled.div`
  margin-top: 25px;
  width: 100%;
  @media (max-width: 430px) {
    // margin-top: 1rem;
  }
`;
const BoosterWrapper = styled.div`
  border-radius: 10px;
  padding: 25px 15px;
  margin-top: 25px;
  @media (max-width: 430px) {
    margin-top: 1rem;
  }
`;
const EarnDiv = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
`;
const EarnText = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  @media (max-width: 430px) {
    font-size: 14px;
  }
`;
const ToggleGroupDiv = styled.div`
  margin-top: 1rem;
`;

const InputDiv = styled.div`
  display: flex;
  justify-content: start;
  align-items: start;
`;

const BuyDiv1 = styled.div`
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #616161;
  @media (max-width: 430px) {
    font-size: 10px;
  }
`;

const BuyDiv2 = styled.div`
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #212121;
  margin-top: 10px;
  @media (max-width: 430px) {
    font-size: 14px;
  }
`;

const InputIcon = styled.div`
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  margin-top: 1rem;
  width: 60%;
`;
const InputIconLabel = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #616161;
  margin-bottom: 10px;
`;
const StyledButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 25px !important;
  padding: 0px;
  gap: 8px;
  color: white !important;
  width: 100%;
  text-transform: capitalize !important;
  height: 55px;
  font-weight: 600 !important;
  font-size: 15px !important;
  background: #673ab7 !important;
  box-shadow: 0px 7px 18px -2px rgba(103, 58, 183, 0.56);
  border-radius: 10px !important;
`;
const NetworkButton = styled(Button)`
  width: 100%;
  display: flex !important;
  align-items: start !important;
  flex-direction: column !important;
  background: transparent !important;
  color: #673ab7 !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  border-bottom: 1px solid #616161 !important;
  border-radius: 0px !important;
  text-transform: none !important;
  padding: 0px !important;
  &:hover {
    background-color: transparent !important;
  }
  @media (max-width: 430px) {
    width: 100%;
  }
`;
const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.5rem;
  padding-bottom: 0.2rem;
  @media (max-width: 430px) {
    padding-bottom: 0.1rem;
  }
`;
const NetworkWrapper = styled.div`
  width: 100%;
  height: 350px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 430px) {
    height: 330px;
  }
`;

const IconImg = styled.img`
  width: 24px;
  height: 24px;
`;
const IconName = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #673ab7;
`;
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
const IconDiv = styled.div`
  width: 28x;
  height: 28px;
  background: #fdfdfd;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 340px;
  row-gap: 10px;
  @media (max-width: 430px) {
    height: 220px;
  }
`;
const MainWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 1.5rem;
`;
const TitleWrapper = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #616161;
  @media (max-width: 430px) {
    font-size: 13px;
  }
`;
const DetailWrapper = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #616161;
  @media (max-width: 430px) {
    font-size: 13px;
  }
`;
const StyledButton2 = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 25px !important;
  padding: 0px;
  gap: 8px;
  color: white !important;
  width: 100%;
  text-transform: capitalize !important;
  height: 55px;
  background: #673ab7 !important;
  box-shadow: 0px 7px 18px -2px rgba(103, 58, 183, 0.56);
  border-radius: 10px !important;
  font-weight: 600 !important;
  font-size: 15px !important;
`;

const TokenInfoWrapper = styled.div`
  width: 100%;
  border-radius: 10px;
  height: 326px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  align-items: flex-start;
  column-gap: 15px;
  padding-left: 25px;
  padding-right: 25px;
  margin-top: 25px;
  @media (max-width: 430px) {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 0px;
  }
`;
const SubToken = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  gap: 0.5rem;
  @media (max-width: 430px) {
  }
`;

const TokenTextDiv1 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 0.5rem;
  flex-direction: column;
  width: 160px;
  @media (max-width: 430px) {
    width: 60%;
    align-items: start;
  }
`;
const TokenTextDivBooster1 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 0.5rem;
  flex-direction: column;
  margin-left: 7px;
  width: 160px;
  @media (max-width: 430px) {
    width: 60%;
    align-items: start;
    margin-left: 13px;
  }
`;
const TokenTextDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 0.5rem;
  flex-direction: column;
  width: 160px;
  @media (max-width: 430px) {
    width: 28%;
    align-items: center;
  }
`;
export const InsufficientAmount = styled(Button)`
  background: #c62828 !important;
  box-shadow: 0px 7px 18px -2px rgba(198, 40, 40, 0.51) !important;
  border-radius: 10px !important;
  color: #ffffff !important;
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 15px !important;
  width: 100% !important;
  height: 55px !important;
  margin-top: 1rem !important;
  text-transform: capitalize !important;
`;
export const TokenTextDivBuyFor = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 0.5rem;
  flex-direction: column;
  width: 200px;
  @media (max-width: 430px) {
    width: 28%;
    align-items: center;
  }
`;
const TokenTitle = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #616161;
  @media (max-width: 430px) {
    font-size: 12px;
  }
`;
const TokenName = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #212121;

  @media (max-width: 430px) {
    font-size: 11px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-flow: wrap;
  row-gap: 14px;
  // column-gap: 60px;
  @media (max-width: 430px) {
    // column-gap: 26px;
    display: flex;
    //  column-gap: 15px;
  }
`;
const StyledButton3 = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 25px !important;
  padding: 0px;
  gap: 8px;
  color: white !important;
  margin-left: 0px !important;
  width: 100%;
  text-transform: capitalize !important;
  height: 55px;
  background: #673ab7 !important;
  box-shadow: 0px 7px 18px -2px rgba(103, 58, 183, 0.56) !important;
  border-radius: 10px !important;
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
  font-weight: 600 !important;
  font-size: 15px !important;
  width: 92% !important;
`;

const BuyDiv = styled.div`
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;
  margin-top: 1rem;
  margin-left: 2rem;
  width: 40%;
  @media (max-width: 430px) {
    margin-top: 0.5rem;
    margin-left: 1rem;
  }
`;

const WithoutBoosterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 8px;
  padding-left: 20px;
`;
const TipsWrapper = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: #616161;
`;
const TipsContentWrapper = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #212121;
  column-gap: 3px;
  @media (max-width: 430px) {
    font-size: 12px;
    text-align: left;
    column-gap: 11px;
  }
`;

const LoadingTokenWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 385px;
  row-gap: 10px;
`;
const LoadingTokenMainWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 1.5rem;
`;
const LoadingTokenTitleWrapper = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #616161;
`;
const LoadingTokenDetailWrapper = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #616161;
`;
const LoadingTokenStyledButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 25px !important;
  padding: 0px;
  gap: 8px;
  color: #673ab7 !important;
  width: 100%;
  text-transform: capitalize !important;
  height: 55px;
  font-weight: 600 !important;
  font-size: 15px !important;
  border-radius: 10px !important;
  border: 1px solid #673ab7 !important;
`;
const NetworkSelectDiv = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 6px;
  width: 100%;
  @media (max-width: 430px) {
    flex-direction: column;
    align-items: center;
  }
`;

const NetworkInputDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  flex-direction: column;
`;
const NetworkInputLabel = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #673ab7;
`;

const SuccessWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  justify-content: center;
  width: 100%;
  height: 385px;
  row-gap: 10px;
`;
const SuccessMainWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 1.5rem;
  position: relative;
`;
const SuccessTitleWrapper = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #616161;
`;
const SuccessDetailWrapper = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #616161;
`;
const SuccessStyledButton = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 25px !important;
  padding: 0px;
  gap: 8px;
  color: white !important;
  width: 100%;
  text-transform: capitalize !important;
  height: 55px;
  background: #673ab7 !important;
  box-shadow: 0px 7px 18px -2px rgba(103, 58, 183, 0.56);
  border-radius: 10px !important;
  font-weight: 600 !important;
  font-size: 15px !important;
  @media (max-width: 430px) {
    font-size: 11px !important;
  }
`;
const SuccessStyledButton2 = styled(Button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 25px !important;
  padding: 0px;
  gap: 8px;
  color: #673ab7 !important;
  width: 100%;
  text-transform: capitalize !important;
  height: 55px;
  font-weight: 600 !important;
  font-size: 15px !important;
  border-radius: 10px !important;
  border: 1px solid #673ab7 !important;
  @media (max-width: 430px) {
    font-size: 11px !important;
  }
`;
const SuccessButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  column-gap: 20px;
`;
const SuccessTokenPriceText = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: #212121;
`;
const TokenDetailInfoWrapper = styled.div`
  // width: 600px;
  height: 125px;
  background: #ffffff;
  // border: 1px solid #e0e0e0;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  align-items: center;
  padding-left: 25px;
  padding-right: 25px;
  // margin-top:25px;
  width:334px;
  @media (max-width: 430px) {
    flex-wrap: wrap;
    height: 90px;
    padding-left: 0px;
    padding-right: 0rem;
    width: 100%;
  }
`;
const SubTokenDetail = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  @media (max-width: 430px) {
    padding-left: 1rem;
  }
`;
const TokenDetailIcon = styled.img`
  width: 60px;
  height: 60px;
  border-radius:40px;
  @media (max-width: 430px) {
    width: 30px;
    height: 30px;
  }
`;
const TokenDetailTextDiv1 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 0.5rem;
  flex-direction: column;
  @media (max-width: 430px) {
    gap: 0rem;
  }
`;

const TokenDetilTitle = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #616161;
  @media (max-width: 430px) {
    font-size: 12px;
  }
`;
const TokenDetailName = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #212121;
  @media (max-width: 430px) {
    font-size: 14px;
  }
`;
export {
  IconeWrapper,
  TextWrapper,
  BackWrapper,
  TitleText,
  AmountDiv,
  BoosterWrapper,
  EarnDiv,
  EarnText,
  ToggleGroupDiv,
  InputDiv,
  BuyDiv,
  BuyDiv1,
  BuyDiv2,
  InputIcon,
  InputIconLabel,
  StyledButton,
  NetworkButton,
  IconDiv,
  IconWrapper,
  IconName,
  IconImg,
  NetworkWrapper,
  InputWrapper,
  Wrapper,
  MainWrapper,
  TitleWrapper,
  DetailWrapper,
  StyledButton2,
  TokenInfoWrapper,
  TipsContentWrapper,
  TipsWrapper,
  WithoutBoosterWrapper,
  StyledButton3,
  ContentWrapper,
  TokenName,
  TokenTitle,
  TokenTextDiv,
  TokenTextDivBooster1,
  TokenTextDiv1,
  SubToken,
  LoadingTokenWrapper,
  LoadingTokenMainWrapper,
  LoadingTokenTitleWrapper,
  LoadingTokenStyledButton,
  LoadingTokenDetailWrapper,
  NetworkSelectDiv,
  NetworkInputDiv,
  NetworkInputLabel,
  SuccessWrapper,
  SuccessMainWrapper,
  SuccessTitleWrapper,
  SuccessDetailWrapper,
  SuccessStyledButton,
  SuccessStyledButton2,
  SuccessButtonWrapper,
  SuccessTokenPriceText,
  TokenDetailInfoWrapper,
  SubTokenDetail,
  TokenDetailIcon,
  TokenDetailTextDiv1,
  TokenDetilTitle,
  TokenDetailName,
};

import { useCallback, useState, useMemo } from 'react';
import { gasPrice } from '../utilities';
import { useWeb3React } from '@web3-react/core';
import {
  getManagerEncodeCallData,
  getManagerInterface,
  // getParsedLogs
} from 'utilities/V2';
import { BigNumber } from 'ethers';
import { Web3Provider } from '@ethersproject/providers';
import { NFT_MANAGER_ADDRESS } from '../constants/V2';
import { WRAPPED_TOKENS } from 'constants/V2';
// import { useNFTManagerContract } from './useContract';
// import _ from 'lodash';

export enum MintTransactionStatus {
  MINT_LOADING,
  MINT_COMPLETED,
  MINT_ERROR,
}

export interface MintTransactionResponse {
  /** mint transaction status */
  mintTransactionStatus: MintTransactionStatus;
  /** tokenId */
  tokenId: number;
  /** mint callback  */
  mintCallback: () => void;
  /** transaction hash */
  transactionHash: string;
}

export const useMint = (
  /** referral address by default ZERO_ADDRESS */
  referralAddress: string,
  /** staked amount */
  sAmount: BigNumber,
  /** cohort address */
  cohortAddress: string,
  /** farm token address */
  farmTokenAddress: string,
  /** farm Id */
  farmId: number,
  /** bpid */
  selectedBoosterPackId: number,
  /** is booster available */
  isBoosterAvailable: boolean,
  /** is user wants to buy booster pack */
  isUserWantsToBuyBoosterPack: boolean,
  /** booster pack payment token address */
  paymentToken?: string,
  /** booster pack Amount */
  boosterPackAmount?: BigNumber
): MintTransactionResponse => {
  // mint transaction status
  const [mintTransactionStatus, setMintTransacionStatus] = useState<MintTransactionStatus>();

  // set transaction data
  // eslint-disable-next-line
  const [transactionData, setTransactionData] = useState<{
    tokenId: number;
    transactionHash: string;
  }>();

  const { library, account, chainId } = useWeb3React() as {
    library: Web3Provider;
    account: string;
    chainId: number;
  };

  // nft manager contract address
  let nftManger = NFT_MANAGER_ADDRESS[chainId];

  let iface = getManagerInterface();

  // get memorized call data
  let callData = useMemo(() => {
    if (!sAmount || !cohortAddress || !farmTokenAddress || !farmId) return null;
    if (isBoosterAvailable && isUserWantsToBuyBoosterPack) {
      return getManagerEncodeCallData(iface, 'stakeAndBuyBoosterPackOnUnifarm', [
        cohortAddress,
        referralAddress,
        farmTokenAddress,
        selectedBoosterPackId,
        sAmount,
        farmId,
      ]);
    } else {
      return getManagerEncodeCallData(iface, 'stakeOnUnifarm', [
        cohortAddress,
        referralAddress,
        farmTokenAddress,
        sAmount,
        farmId,
      ]);
    }
  }, [
    iface,
    isBoosterAvailable,
    isUserWantsToBuyBoosterPack,
    farmId,
    cohortAddress,
    farmTokenAddress,
    referralAddress,
    sAmount,
    selectedBoosterPackId,
  ]);

  // wrapped token
  let wrappedToken = WRAPPED_TOKENS[chainId];

  const mintCallback = useCallback(async () => {
    if (!library || !account || !callData) return null;
    try {
      // check if booster availble and values are undefined
      // if (isBoosterAvailable) {
      //   if (paymentToken === undefined && boosterPackAmount === undefined) return null;
      //   // return;
      // }

      let ethValueToSend = paymentToken?.toLowerCase() === wrappedToken?.toLowerCase() ? boosterPackAmount : 0;

      // set Mint Transaction to loading
      setMintTransacionStatus(MintTransactionStatus.MINT_LOADING);

      // estimate gas on
      let gasLimit = await library.getSigner().estimateGas({
        from: account,
        to: nftManger,
        data: callData,
        value: ethValueToSend,
      });

      // gas price
      let gas_price = await gasPrice(library);

      const mint = await library.getSigner().sendTransaction({
        from: account,
        to: nftManger,
        data: callData,
        gasLimit,
        gasPrice: gas_price,
        value: ethValueToSend,
      });

      // atleast two confirmation required
      const tx = await mint.wait(2);
      setMintTransacionStatus(MintTransactionStatus.MINT_COMPLETED);

      // derive logs
      // const logs = tx.logs;
      // parsed logs
      // let events = getParsedLogs(iface, logs);

      // filter the parse log
      // let event = events.filter((e) => e.name === 'Transfer')[0];
      // let [, , tokenId] = event.args;

      // set the transaction status to completed
      // set the transaction data

      setTransactionData({
        tokenId: null,
        transactionHash: tx.transactionHash,
      });
    } catch (err) {
      console.log(err.message);
      // set error
      setMintTransacionStatus(null);
    }
  }, [account, callData, library, nftManger, boosterPackAmount, paymentToken, wrappedToken]);

  return {
    mintTransactionStatus,
    tokenId: transactionData?.tokenId,
    mintCallback,
    transactionHash: transactionData?.transactionHash,
  };
};

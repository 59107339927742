import { Button } from '@mui/material';
import styled from 'styled-components';
export const CardWrapper2 = styled.div`
  width: 540px;
  // height: 100%;
  background: #ffffff;
  box-shadow: 4px 4px 25px #e0e0e0;
  border-radius: 15px;
  margin: 0px auto;
  padding: 25px;
  @media (max-width: 430px) {
    width: 100%;
    margin-top: 1rem;
    padding: 1rem;
  }
`;
export const ChooseText = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  font-family: 'Inter';
  font-style: normal;
  color: #616161;
`;
export const FromText = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #673ab7;
`;
export const NetworkSelectDivTitle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  @media (max-width: 430px) {
    display: none;
  }
`;
export const NetworkSelectDiv = styled.div`
  display: flex;
  margin-top: 25px;
  justify-content: space-between;
  @media (max-width: 430px) {
    flex-direction: column;
    align-items: center;
  }
`;
export const FromDiv = styled.div`
  width: 180px;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 10px;
`;
export const TransferDiv = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #6338bc;
  box-shadow: 4px 3px 14px 2px rgba(103, 58, 183, 0.58);
  cursor: pointer;
  border: none;
  flex-shrink: 0;
  @media (max-width: 430px) {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
`;
export const BalanceDiv = styled.div`
  display: flex;
  justify-content: flex-start;
`;
export const BalanceValue=styled.span`
font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    font-family: 'Inter';
    font-style: normal;
    color: #0000ffa8;
    margin-top: 7px;
    border-bottom: 0.1px solid;
&:hover{
  cursor: pointer;
}
@media (max-width: 430px) {
  margin-top: 10px;
}
`
export const BalanceText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  font-family: 'Inter';
  font-style: normal;
  color: #616161;
  margin-top: 7px;
 
  @media (max-width: 430px) {
    margin-top: 10px;
  }
`;
export const ConnectWallete = styled(Button)`
  background: #673ab7 !important;
  box-shadow: 0px 7px 18px -2px rgba(103, 58, 183, 0.56) !important;
  border-radius: 10px !important;
  color: #ffffff !important;
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 15px !important;
  width: 100% !important;
  height: 55px !important;
  margin-top: 1rem !important;
  text-transform: capitalize !important;
`;
export const ApproveButton = styled(Button)`
  background: #efefef !important;
  border-radius: 10px !important;
  border-radius: 10px !important;
  color: #616161 !important;
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 15px !important;
  width: 100% !important;
  height: 55px !important;
  margin-top: 1rem !important;
  text-transform: capitalize !important;
`;
export const InsufficientAmount = styled(Button)`
  background: #c62828 !important;
  box-shadow: 0px 7px 18px -2px rgba(198, 40, 40, 0.51) !important;
  border-radius: 10px !important;
  color: #ffffff !important;
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 15px !important;
  width: 100% !important;
  height: 55px !important;
  margin-top: 1rem !important;
  text-transform: capitalize !important;
`;
export const NetworkButton = styled(Button)`
  background: #c62828 !important;
  box-shadow: 0px 7px 18px -2px rgba(198, 40, 40, 0.51) !important;
  border-radius: 10px !important;
  color: #ffffff !important;
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 15px !important;
  width: 100% !important;
  height: 55px !important;
  margin-top: 1rem !important;
  text-transform: capitalize !important;
`;
// export const MaxButton = styled.span`
//   font-family: 'Inter';
//   font-style: normal;
//   font-weight: 400;
//   font-size: 14px;
//   line-height: 24px;
//   color: #673ab7;
//   text-decoration-line: underline;
//   cursor: pointer;
//   margin-top: 5px;
// `;

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid;
  :hover {
    cursor: pointer;
  }
`;

export const IconImg = styled.img`
  width: 24px;
  height: 24px;
`;
export const IconName = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 19px;
  color: #673ab7;
`;
export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
export const IconDiv = styled.div`
  width: 28x;
  height: 28px;
  background: #fdfdfd;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const NetworkWrapper = styled.div`
  width: 100%;
  height: 350px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 430px) {
    height: 330px;
  }
`;
export const ChangeWrapper = styled.div`
  width: 40%;
  @media (max-width: 430px) {
    width: 100%;
  }
`;
export const SelectErrorDiv = styled.div`
  font-size: 15px;
  color: #c62828;
  margin-top: 15px;
`;
import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import { useEffect, useState } from 'react';

export const useENS = (): string => {
  const [ens, setENS] = useState<string>();
  const { account, chainId, library } = useWeb3React() as {
    account: string;
    chainId: number;
    library: Web3Provider;
  };
  // const account = '0xF33FeF8B41e4F83Fc5b9B9d61EBa973cC7d9E49B';

  useEffect(() => {
    const getENS = async () => {
      try {
        if (chainId === 1) {
          const lookUpAddress = (await library.lookupAddress(account)) as string;
          setENS(lookUpAddress);
        } else {
          setENS(null);
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    getENS();
  }, [account, chainId, library]);
  return ens;
};
